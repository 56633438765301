import {
    BANKS_LIST as CRUD_ACTION_LIST,
    BANKS_CREATE as CRUD_ACTION_CREATE,
    BANKS_SHOW as CRUD_ACTION_SHOW,
    BANKS_UPDATE as CRUD_ACTION_UPDATE,
    BANKS_DELETE as CRUD_ACTION_DELETE,
    BANKS_PUBLIC_LIST as CRUD_ACTION_PUBLIC_LIST
} from "../actions/banks";
import axioses from "@/axios/config";

const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const routePrefix='banks';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${routePrefix}/create`, fData,additionalHeaders)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });

        axioses.base
            .put(`/${routePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_PUBLIC_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
            commit('START_LOADING', params.withLoading, { root: true });
            axioses.publicPortal
                .get(`/${routePrefix}`,{ params })
                .then(response => {
                    resolve(response);
                    commit('STOP_LOADING', params.withLoading, { root: true });
                })
                .catch(error => {
                    commit('STOP_LOADING', params.withLoading, { root: true });
                    reject(error);
                });
        });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
