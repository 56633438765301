<template>
    <div class="card">
        <!-- Card header -->
        <card-header :title="title" :hint="headerHint" :withSearch="withSearch" :filtersIsEmpty="filtersIsEmpty" :searchHandler="searchHandler">
            <slot name="card-header"></slot>

            <template v-slot:search>
                <slot name="card-header-search"></slot>
            </template>

        </card-header>

        <!-- Light table -->
        <table-container :headers="headers">
            <slot name="table-rows"></slot>
        </table-container>

        <slot name="items-container"></slot>

        <!-- Card footer -->
        <card-footer :lastPage="lastPage" :currentPage="currentPage" :clickHandler="clickHandler" :totalElements="totalElements">
            <template v-slot:bulk-actions>
                <slot name="bulk-actions"></slot>
            </template>
            <slot name="card-footer"></slot>
        </card-footer>
    </div>
</template>


<script>
    export default {
        props: {
            title: {
                type: String
            },
            headerHint: {
                type: String
            },
            headers: {
                type: Array
            },
            lastPage: {
                type: Number
            },
            currentPage: {
                type: Number
            },
            totalElements: {
                type: Number
            },
            clickHandler: {
                type: Function,
                default: () => { }
            },
            searchHandler:{
                type: Function,
                default: () => { }
            },
            withSearch: {
                type: Boolean,
                default:false,
            },
            filtersIsEmpty:{
                type:Boolean,
                default:true
            }
        },
        name: "CardContainer",
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>
