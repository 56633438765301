<template>
  <div aria-labelledby="order_timeline-tab" class="tab-pane fade" id="order_timeline" role="tabpanel">
    <div class="row">
      <div class="col-md-12">
        <div class="vertical-timeline vertical-timeline--animate" v-for="item in getItemSortedByDate">
          <div class="vertical-timeline-element">
            <div>
              <span class="vertical-timeline-element-icon bounce-in" v-bind:style="{ color: item.statusColor }">
                <i class="fas fa-dot-circle"></i>
              </span>
              <div class="vertical-timeline-element-content bounce-in">
                <h4 class="timeline-title">{{ item.statusDisplayName }}</h4>
                <h6 class="timeline-message">{{ item.note }}</h6>
                <p></p>
                <span class="vertical-timeline-element-date text-direction-ltr">{{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const CRUD_CODE = "orders";
  export default {
    name: "PlatformsPortalOrderTabsHistory",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: function(){
          return []
        }
      },
      headers: {
        type: Array,
        default: function(){
          return []
        }
      },
    },
    data(){
      return {
      }
    },
    computed:{
      getItemSortedByDate(){
        return this.items.sort(function(a, b) {
          let dateA = new Date(a.createdAt),
                  dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
      }
    },
  };
</script>
<style scoped>
</style>