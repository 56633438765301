export default {
    methods: {
        sanitizeField($field){
            if(this[$field] || ['boolean','number'].includes(typeof this[$field])){
                if(['number'].includes(typeof this[$field])){
                    return "" + this[$field];
                }
                return this[$field];
            }else if(['edit'].includes(this.getFormMode()) && ['string'].includes(typeof this[$field])){
                return this[$field];
            }

            return null;
        },
        prepareFile($field,key){
            let file={};
            if(this.sanitizeField($field).file){
                file[key]=this[$field].file;
            }
            return file;
        },
        prepareDateTime($field){
            if(this[$field]){
                return this.$moment(this[$field]).format("Y-MM-DDTHH:mm") + ":00.000+03:00";
            }
            return null;
        },
        getFormMode(){
            return this.formMode?this.formMode:'create';
        },
        onlyDigits(event){
            const charCode = event.keyCode;
            if ((charCode > 31 && !((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105))) && charCode !== 46) {
                event.preventDefault();
            }
            event.target.value = event.target.value.replace(/[^0-9]+/g, '');
        }
    }
}
