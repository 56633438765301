<template>
  <div>
    <maps-tracking :scopedByCaptains="true" :captainShowAction="captainShowAction"/>
    <captains></captains>
    <counters></counters>
  </div>
</template>
<script>

  import { CAPTAINS_SHOW as CRUD_ACTION_SHOW } from 'actions/suppliers_portal/captains';
  import search from "./partials/search";
  import captains from "./partials/captains";
  import counters from "./partials/counters";
  const CRUD_CODE=`suppliers_portal.tracking`;
  export default {
    name: "SuppliersPortalTrackingIndex",
    components:{
      search,
      captains,
      counters
    },
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        captainShowAction: CRUD_ACTION_SHOW,
      }
    },
  };
</script>
