<template>
    <div :id="id" class="google-maps"></div>
</template>

<script>
    export default {
        name: "FieldsMapsTasks",
        props: {
          centerPosition:{
            type:Object,
            default:function(){
                return {lat: 24.7253981, lng: 46.3398977};
            }
          },
          zoom:{
            type:Number,
            default:6,
          },
          id: {
            type:String,
            default:'map'
          },
          error:{
            type:String
          },
          items:{
              type:Array,
              default:function(){
                  return [];
              }
          }
        },
        data(){
            return {
                map:null,
                google:null,
                points:[],
                options:{
                    libraries:[],
                }
            }
        },
        mounted() {
            this.initMap();
        },
        watch: {
            items: function(newVal, oldVal) {
                this.prepareItems();
            }
        },
        methods: {
            initMap(){
                this.$google.then(google=>{
                    this.google=google;
                    this.map = new this.google.maps.Map(document.getElementById(this.id), {
                        center: this.getCenterPosition(),
                        zoom: this.zoom,
                    });
                    if(this.items.length){
                        this.prepareItems();
                    }

                }).catch(error=>{});
            },
            setMapCenter(){
              this.map.setCenter(this.getCenterPosition());
            },
            getCenterPosition(){
                if(this.points.length){
                    let max_x=Math.max.apply(Math, this.points.map(function(point) { return point[1]; }));
                    let min_x=Math.min.apply(Math, this.points.map(function(point) { return point[1]; }));

                    let max_y=Math.max.apply(Math, this.points.map(function(point) { return point[0]; }));
                    let min_y=Math.min.apply(Math, this.points.map(function(point) { return point[0]; }));

                    return {lat:(max_x+min_x)/2,lng:(max_y+min_y)/2};
                }
                return this.centerPosition;
            },
            addMarker(options){
                let marker =new this.google.maps.Marker({
                    position: {lat:options.latitude,lng:options.longitude},
                    map:this.map,
                    icon: this.getIconByType(options.type),
                    zIndex: options.rank
                });
            },
            getIconByType(type){
                let icons= {
                    'PICK_UP'                   : require('@/assets/theme/icons/task-types/PICK_UP.png'),
                    'ON_DEMAND_PICK_UP'         : require('@/assets/theme/icons/task-types/PICK_UP.png'),
                    'DELIVERY'                  : require('@/assets/theme/icons/task-types/DELIVERY.png'),
                    'RETURNING'                 : require('@/assets/theme/icons/task-types/RETURNING.png'),
                    'RETURN_TO_AREA'            : require('@/assets/theme/icons/task-types/RETURNING.png'),
                    'DEFAULT'                   : require('@/assets/theme/icons/task-types/PICK_UP.png')
                };
                return icons[type]??icons['DEFAULT'];
            },
            setPoints(){
                let points=[];
                Object.keys(this.items).forEach(key=>{
                    let item=this.items[key];
                    points.push({
                        0:item.longitude,
                        1:item.latitude
                    })
                });

                this.points=points;
            },
            addMarkers(){
                Object.keys(this.items).forEach(key=>{
                    this.addMarker(this.items[key]);
                });
            },
            setMapZoom(zoom){
                if(this.map){
                    this.map.setZoom(zoom?zoom:this.zoom);
                }
            },
            prepareItems(){
                this.setPoints();
                this.addMarkers();
                this.setMapCenter();
                this.setMapZoom(13);
            }
        }
    }
</script>

<style scoped>

</style>
