<template>
  <div aria-labelledby="captain-tab" class="tab-pane fade" id="captain" role="tabpanel" v-if="fields.id">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="id">{{ $t(`fields.id`) }}</label>
          <input v-model="fields.id" id="id" type="text" class="form-control" name="id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name">{{ $t(`fields.name`) }}</label>
          <input v-model="fields.name" id="name" type="text" class="form-control" name="name" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="email">{{ $t(`fields.email`) }}</label>
          <input v-model="fields.email" id="email" type="text" class="form-control" name="email" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="mobile_number">{{ $t(`fields.mobile_number`) }}</label>
          <input v-model="fields.mobileNumber" id="mobile_number" type="text" class="form-control" name="mobile_number" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="id_number">{{ $t(`captains.fields.id_number`) }}</label>
          <input v-model="fields.idNumber" id="id_number" type="text" class="form-control" name="id_number" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="id_type">{{ $t(`captains.fields.id_type`) }}</label>
          <select-captain-id-type v-model="fields.idType" :disabled="true" />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="status">{{ $t(`fields.status`) }}</label>
          <select-captain-status v-model="fields.status" id="status" :disabled="true"/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="working_status">{{ $t(`captains.fields.working_status`) }}</label>
          <select-captain-working-status v-model="fields.workingStatus" :disabled="true"/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <div class="col-8">
            <label for="ready_to_work">{{ $t(`captains.fields.ready_to_work`) }}</label>
          </div>
          <div class="col-4 text-right">
            <label class="custom-toggle">
              <input type="checkbox" id="ready_to_work" v-model="fields.readyToWork" :disabled="true"/>
              <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <div class="col-8">
            <label for="connected">{{ $t(`captains.fields.connected`) }}</label>
          </div>
          <div class="col-4 text-right">
            <label class="custom-toggle">
              <input type="checkbox" id="connected" v-model="fields.connected" :disabled="true" />
              <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group row">
          <div class="col-8">
            <label for="suspended">{{ $t(`captains.fields.suspended`) }}</label>
          </div>
          <div class="col-4 text-right">
            <label class="custom-toggle">
              <input type="checkbox" id="suspended" v-model="fields.suspended" :disabled="true"/>
              <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCaptain",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
  };
</script>
