import Vue from "vue";
import store from "../store";

// Make Format Custom Directive
Vue.directive("format", {
  bind: function(el, binding) {
    el.style.fontSize = binding.value;

    if (binding.modifiers.bold) {
      el.style.fontWeight = "bold";
    }
  }
});

const canImplementation=function (el, binding, vnode) {
  let permission=`${binding.value}${binding.arg?`_can_${binding.arg}`:''}`;
  const can = store.getters.can(permission);
  if (!can) {
    if(vnode.elm.parentElement){
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
};

Vue.directive('can', {
  inserted: canImplementation,
  update: canImplementation,
});

const cantImplementation=function (el, binding, vnode) {
  let permission=`${binding.value}${binding.arg?`_can_${binding.arg}`:''}`;
  const can = store.getters.can(permission);
  if (can) {
    if(vnode.elm.parentElement){
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
};

Vue.directive('can-not', {
  inserted: cantImplementation,
  update: cantImplementation,
});
