<template>
  <div class="row">
    <div class="col">
      <card-container :title="$router.currentRoute.meta.title">
        <template v-slot:card-header />
        <template v-slot:card-header-search>
          <search
            v-model="lastPage"
            :listHandler="getList"
            style="margin-top: 30px"
          />
        </template>
        <template v-slot:table-rows>
          <maps-heat
            v-if="is_loaded"
            :points="mapPoints"
            :zoom="8"
            :centerPosition="defaultCenter"
            id="heatmap"
          />
        </template>
      </card-container>
    </div>
  </div>
</template>

<script>
import search from "./partials/range_selector";
import { mapGetters } from "vuex";
import { ORDERS_SHOW as CRUD_ACTION_SHOW } from "actions/suppliers_portal/orders";

export default {
  name: "SuppliersPortalOrderIndex",
  components: {
    search,
  },
  data() {
    return {
      routePrefix: "HEADER",
      transPrefix: "orders",
      permissionPrefix: "supplier_orders",
      exportCode: "orders",
      is_loaded: false,
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
      defaultCenter: { lat: 24.7253981, lng: 46.3398977 },
    };
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersIfExists",
      "getSearchFiltersSensitized",
    ]),
    mapPoints() {
      let preparedData = [];
      this.items.forEach(function(item) {
        preparedData.push(
          new google.maps.LatLng(item.latitude, item.longitude)
        );
      });
      return preparedData;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.is_loaded = false;
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          ...this.getSearchFiltersSensitized,
        })
        .then((response) => {
          console.log("response: ", response.data);
          this.items = response.data;
          this.is_loaded = true;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
