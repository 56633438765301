<template>
    <div class="row">
      <div class="col">
        <card-container
                :title="$router.currentRoute.meta.title"
                :headers="tableHeaders"
                :lastPage="lastPage"
                :totalElements="totalElements"
                :currentPage="getCurrentPage()"
                :clickHandler="pagination">

          <template v-slot:card-header>
            <router-link :to="{ name: `${zonesRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
              <i class="fa fa-arrow-left"></i>
            </router-link>
            <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
              <i class="fa fa-plus"></i>
            </router-link>
          </template>

          <template v-slot:table-rows>
            <tr v-for="item in items" :key="item.id">
              <td> {{ item.id }} </td>
              <td> {{ item.type }} </td>
              <td> {{ item.amount}} </td>
              <td> {{ item.x}} </td>
              <td> {{ item["isVisibleToCaptain"] }} </td>
              <table-item-actions :actions="tableItemActions" :params="{platform_id: id, owner_type: 'ZONE', owner_id: zone_id, pricing_rule_id :item.id}"/>
            </tr>
            <tr class="text-center" v-if="!items.length">
              <td colspan="999">{{ $t('messages.no_result_found')}}</td>
            </tr>
          </template>
        </card-container>
      </div>
    </div>
</template>
<script>

    import { PLATFORMS_AREAS_PRICING_RULES_LIST as CRUD_ACTION_LIST, PLATFORMS_AREAS_PRICING_RULES_DELETE as CRUD_ACTION_DELETE} from 'actions/platforms';
    const PLATFORMS_CRUD_CODE="platforms";
    const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
    const CRUD_CODE=`${ZONES_CRUD_CODE}.pricing_rules`;
    const CRUD_TRANS_CODE=`${CRUD_CODE}`;
    const CRUD_PERMISSION_CODE=`platform_area_shifts`;
    export default {
      name: "PlatformZonePricingRuleIndex",
      components:{
      },
      data(){
        return {
          routePrefix: `${CRUD_CODE}`,
          transPrefix: `${CRUD_TRANS_CODE}`,
          permissionPrefix: CRUD_PERMISSION_CODE,
          zonesRoutePrefix: ZONES_CRUD_CODE,
          id:this.$router.currentRoute.params.id,
          zone_id:this.$router.currentRoute.params.zone_id,
          tableHeaders:[
            {
               'title':`fields.id`
            },
            {
               'title':`fields.type`
            },
            {
              'title':`fields.amount`
            },
            {
              'title':`fields.x`
            },
            {
              'title':`fields.isVisibleToCaptain`
            },
            {
              'title':`fields.actions`
            }
          ],
          tableItemActions:[
            {
              code:'show',
              route_name:`${CRUD_CODE}.show`,
              can:`${CRUD_PERMISSION_CODE}_can_view`,
            },
            {
              code:'edit',
              route_name:`${CRUD_CODE}.edit`,
              can:`${CRUD_PERMISSION_CODE}_can_edit`,
            },
            {
              code:'delete',
              can:`${CRUD_PERMISSION_CODE}_can_delete`,
              action:CRUD_ACTION_DELETE,
              successCallback:this.getList
            },
          ],
          items: [],
          perPage:20,
          totalElements:0,
          lastPage:0
        }
      },
      mounted() {
        this.getList();
      },
      methods: {
        getList(){
          this.$store.dispatch(CRUD_ACTION_LIST,{
            platform_id:this.id,
            owner_type: "ZONE",
            owner_id: this.zone_id,
            pageId: this.getCurrentPage()-1,
          }).then(response=>{
            this.items=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
          });
        },
        pagination(page){
          if(page!== this.getCurrentPage()){
            this.$router.push({
              name: `${this.routePrefix}.index`,
              query:{
                page
              }
            });
            this.getList();
          }
        },
        getCurrentPage(){
          let currentPage=this.$route.query.page??1;
          return parseInt(currentPage>=1?currentPage:1);
        },
      }
    };
  </script>
