<template>
    <li class="nav-item dropdown">
        <a class="nav-link px-3" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-language"></i>
        </a>
        <div class="dropdown-menu  dropdown-menu-right ">
            <a href="javascript:;" v-for="locale in locales" :key="locale.code" @click="changeLocale(locale.code)" class="dropdown-item" v-bind:class="{'active disabled':$i18n.locale===locale.code}">
                {{ locale.name }}
            </a>
        </div>
    </li>
</template>

<script>
    import { getSupportedLocales } from "@/util/i18n/supported-locales";
    export default {
        name: "TopNavbarLanguageSwitcher",
        data: () => ({ locales: getSupportedLocales() }),
        methods: {
            changeLocale(locale) {

                this.$router.push({
                    name:this.$router.currentRoute.name,
                    params:{locale},
                    query:this.$router.currentRoute.query
                })
            }
        }
    }
</script>

<style scoped>

</style>
