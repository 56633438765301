<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:update="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <files-image v-model="image" :disabled="true" :thumbnailImage="image.thumbnail"/>
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="en_name">{{ $t(`fields.en_name`)}}</label>
                  <input  v-model="en_name"
                          id="en_name"
                          type="text"
                          class="form-control"
                          name="en_name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="ar_name">{{ $t(`fields.ar_name`)}}</label>
                  <input  v-model="ar_name"
                          id="ar_name"
                          type="text"
                          class="form-control"
                          name="ar_name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="jwt_callback_token">{{ $t(`${transPrefix}.fields.jwt_callback_token`)}}</label>
                  <input  v-model="jwt_callback_token"
                          id="jwt_callback_token"
                          type="text"
                          class="form-control"
                          name="jwt_callback_token"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="web_hook_url">{{ $t(`${transPrefix}.fields.web_hook_url`)}}</label>
                  <input  v-model="web_hook_url"
                          id="web_hook_url"
                          type="text"
                          class="form-control"
                          name="web_hook_url"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="tasks_webhook_url">{{ $t(`${transPrefix}.fields.tasks_webhook_url`)}}</label>
                  <input  v-model="tasks_webhook_url"
                          id="tasks_webhook_url"
                          type="text"
                          class="form-control"
                          name="tasks_webhook_url"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="order_prefix_url">{{ $t(`${transPrefix}.fields.order_prefix_url`)}}</label>
                  <input  v-model="order_prefix_url"
                          id="order_prefix_url"
                          type="text"
                          class="form-control"
                          name="order_prefix_url"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="client_id">{{ $t(`${transPrefix}.fields.client_id`)}}</label>
                  <input  v-model="client_id"
                          id="client_id"
                          type="text"
                          class="form-control"
                          name="client_id"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="client_secret">{{ $t(`${transPrefix}.fields.client_secret`)}}</label>
                  <input  v-model="client_secret"
                          id="client_secret"
                          type="text"
                          class="form-control"
                          name="client_secret"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="country_id">{{ $t(`fields.country`)}}</label>
                  <select-countries v-model="country_id" :disabled="true"/>
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="enabled">{{ $t(`fields.enabled`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="enabled" v-model="enabled" :disabled="true">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_SHOW as CRUD_ACTION_SHOW } from 'actions/platforms';
  const CRUD_CODE="platforms";
  export default {
    name: "PlatformShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        ar_name: '',
        en_name: '',
        jwt_callback_token: '',
        web_hook_url: '',
        tasks_webhook_url: '',
        order_prefix_url: '',
        country_id: "",
        enabled: false,
        client_id: '',
        client_secret: '',
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.en_name=response.data.name;
          this.ar_name=response.data.nameAr;
          this.client_id=response.data.clientId;
          this.client_secret=response.data.clientSecret;
          this.jwt_callback_token=response.data.jwtCallbackToken;
          this.web_hook_url=response.data.webHookUrl;
          this.tasks_webhook_url=response.data.tasksWebhookUrl;
          this.order_prefix_url=response.data.orderPrefixUrl;
          this.country_id=response.data.country?response.data.country.id:null;
          this.enabled=response.data.enabled;
          this.image.thumbnail=response.data.image?response.data.image.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
