import {
    ORDERS_LIST as CRUD_ACTION_LIST,
    ORDERS_SHOW as CRUD_ACTION_SHOW

} from "../../actions/suppliers_portal/orders";
console.log("New List page: ", CRUD_ACTION_SHOW, CRUD_ACTION_LIST)
import axioses from "@/axios/config";
import qs from "qs";

const routePrefix='orders';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });

      axioses.supplierPortal
          .get(`${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });

      axioses.supplierPortal
          .get(`${routePrefix}/heat-map`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },

};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
