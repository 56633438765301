<template>
    <abstract-dropdown
            v-model="selectedValue"
            :error="error"
            :id="id"
            :disabled="disabled"
            :multiple="multiple"
            :customLabelHandler="customLabel"
            :paginationHandler="pagination"
            :items="items"
            :placeholder="placeholder"
            :isLoading="isLoading"
            :noResultMessage="noResultMessage"
            :allow-empty="allowEmpty"
    />
</template>

<script>
    import {PLATFORMS_BRANCHES_LIST as CRUD_ACTION_LIST} from 'actions/platforms';

    export default {
        name: "FieldsDropdownPlatformBranches",
        props: {
            value: {},
            id: {
                type: String,
                default: 'platform_branches_id'
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'placeholders.dropdown',
            },
            noResultMessage: {
                type: String,
                default: 'messages.no_result_found',
            },
            error: {
                type: String
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            params: {
                type: Object,
                default: () => {
                }
            },
            action: {
                type: String,
                default: CRUD_ACTION_LIST
            },
            platform_id: {},

        },
        data() {
            return {
                currentPage: -1,
                items: [],
                perPage: 20,
                totalElements: 0,
                lastPage: 1,
                selectedValue: this.value,
                isLoading: false,
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            },
            platform_id: function (newVal, oldVal) {
                this.items = [];
                this.currentPage = -1;
                this.lastPage = 1;
                this.selectedValue = null;
                this.$emit('input', null);
                this.pagination(true);
            }
        },
        mounted() {
            if (!this.isEmpty(this.value)) {
                this.pagination(true);
            }
        },
        methods: {
            getList() {
                if (this.platform_id) {
                    this.isLoading = true;
                    this.$store.dispatch(this.action, {
                        pageId: this.getCurrentPage(),
                        withLoading: false,
                        platformId: this.platform_id,
                        ...this.params
                    }).then(response => {
                        if (response.data.perPage) {
                            this.items = [...this.items, ...response.data.data];
                            this.perPage = response.data.perPage;
                            this.totalElements = response.data.totalElements;
                            this.lastPage = response.data.pageCount - 1;
                        } else {
                            this.items = [...this.items, ...this.prepareResponseData(response.data)];
                            this.lastPage = this.lastPage - 1;
                        }
                        this.isLoading = false;
                    });
                }
            },
            pagination(isVisible) {
                if (this.hasNextPage() && isVisible) {
                    this.currentPage += 1;
                    this.getList();
                }
            },
            getCurrentPage() {
                return this.currentPage;
            },
            getLastPage() {
                return this.lastPage;
            },
            hasNextPage() {
                return this.getCurrentPage() < this.getLastPage();
            },
            customLabel(value) {
                return value.name;
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
            prepareResponseData(data){
                return data;
            }
        }
    }
</script>

<style scoped>

</style>
