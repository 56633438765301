import {
    AUDIT_LOGS_LIST as CRUD_ACTION_LIST,
    AUDIT_LOGS_SHOW as CRUD_ACTION_SHOW,
    AUDIT_LOGS_ITEMS_TYPES_LIST as CRUD_ACTION_ITEMS_TYPES_LIST,
    AUDIT_LOGS_EVENTS_TYPES_LIST as CRUD_ACTION_EVENTS_TYPES_LIST
} from "../actions/audit_logs";
import axioses from "@/axios/config";
import qs from "qs";

const routePrefix='audit_logs';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_ITEMS_TYPES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/items/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_EVENTS_TYPES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/events/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
