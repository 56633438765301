import Index from "@/views/suppliers_portal/reports/index";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../../helper"

const URL_PREFIX = 'suppliers_portal/reports';
const CODE = 'suppliers_portal.reports';
const TRANS_CODE = 'suppliers.reports';
const PERMISSION_PREFIX = 'supplier_report';
const routes = [
    {
        path: `${URL_PREFIX}`,
        name: `${CODE}.index`,
        component: Index,
        meta: {
            'can': [`${PERMISSION_PREFIX}_can_view`],
            'removeClass': [`bg-default`],
            'title': `${TRANS_CODE}.list_title`,
            'breadDetails': {
                'title': `${TRANS_CODE}.title`,
                'index_route': `${CODE}.index`,
                'show': true,
                'items': [
                    {
                        'title': `crud.list`,
                    }
                ]
            }
        },
        beforeEnter: function (to, from, next) {
            if (ifAuthenticated(to, from, next)) {
                commonBeforeEnter(to, from, next);
            }
        },
    },
];

export default routes;
