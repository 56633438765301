<template>
  <div aria-labelledby="map-tab" class="tab-pane fade" id="map" role="tabpanel">
    <div class="row">
      <div class="col-12">
        <maps-tasks id="tasks-map" :items=getTaskMapItems() :mode="'show'" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "PlatformsPortalOrderTabsMap",
    props: {
      items: {
        type: Array,
        default: function(){
          return []
        }
      },
    },
    data(){
      return {}
    },
    methods:{
      getTaskMapItems(){
        let taskMapItems=[];
        if(this.items.length){
          Object.keys(this.items).forEach(key=>{
            let task=this.items[key];
            taskMapItems.push({
              'type':task.taskType,
              'latitude':task.address.latitude,
              'longitude':task.address.longitude
            });
          });
        }
        return taskMapItems;
      },
    }
  };
</script>