<template>
  <a
    href="javascript:;"
    @click.prevent="showAttachCaptainPopUp(platformId, branchId, shiftId)"
    class="btn btn-success btn-sm"
  >
    <span class="d-none d-lg-inline mr-2">
      {{ $t(`labels.attach_captain`) }}
    </span>
    <span>
      <i class="fas fa-link"></i>
    </span>
  </a>
</template>

<script>
import { PLATFORMS_AREAS_SHIFTS_CAPTAINS_ATTACH as CRUD_ACTION_ATTACH_CAPTAIN } from 'actions/platforms';
export default {
  name: 'ShiftActionsAttachCaptain',
  props: {
    platformId: {},
    branchId: {},
    shiftId: {},
    areaType: {},
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    showAttachCaptainPopUp(platformId, branchId, shiftId) {
      swal({
        title: this.$i18n.t('labels.attach_captains'),
        text: this.$i18n.t('labels.attach_captain_ids'),
        type: 'input',
        showCancelButton: true,
        closeOnConfirm: false,
        content: 'input',
        buttons: [
          this.$i18n.t('labels.cancel'),
          this.$i18n.t('labels.attach_captains'),
        ],
        inputPlaceholder: 'Write something',
      }).then((value) => {
        if (!value) return;
        const captainIds = value.trim();
        if (captainIds) {
          this.attachCaptainToShift(platformId, branchId, shiftId, captainIds);
        }
      });
    },
    attachCaptainToShift(platformId, branchId, shiftId, captainIds) {
      this.$props.callback(true);
      this.$store
        .dispatch(CRUD_ACTION_ATTACH_CAPTAIN, {
          platformId,
          areaType: this.$props.areaType,
          areaId: branchId,
          shiftId,
          captainIds,
        })
        .then((res) => {
          this.$props.callback(false);
          const responseData = res.data.errorMessages;
          const resultString = Object.entries(responseData)
            .filter(([key, value]) => value !== '')
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n');
          const message = resultString;
          if (message) {
            swal({
              icon: 'error',
              text: message,
              buttons: false,
              timer: 5000,
              html: true
            });
          }
        })
        .catch((error) => {
          this.$props.callback(false);
          let message = '';
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors && responseData.errors.error) {
              message = responseData.errors.error;
            } else {
              message = this.$i18n.t('messages.attach_captain_error');
            }
          }
          if (message) {
            swal({
              icon: 'error',
              text: message,
              buttons: false,
              timer: 5000,
            });
          }
        });
    },
  },
};
</script>

<style scoped></style>
