import {
  STOMP_UPDATE
} from "../../actions/sockets/stomp";
import {AUTH_LOGOUT} from "../../actions/auth";

const state = { configurations: localStorage.getItem("stomp_configurations") || {}};

const getters = {
  getStomp(state){
    let configurations=state.configurations;
    if(typeof configurations ==='string'){
      configurations=JSON.parse(configurations);
    }
    return configurations;
  },
  isStompLoaded(state, getters){
    let configurations=getters.getStomp;
    return typeof configurations === 'object' && !!configurations.connectionUrl
  }
};

const actions = {};

const mutations = {
  [STOMP_UPDATE]: (state,stomp) => {
    localStorage.setItem("stomp_configurations",JSON.stringify(typeof stomp !== 'undefined' ?  stomp : {}));
    state.configurations=stomp;
  },
  [AUTH_LOGOUT]: state => {
    state.configurations = {};
    localStorage.removeItem("stomp_configurations");
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
