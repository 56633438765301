<template>
    <div>
        <select-capabilities v-bind:value="capabilitiesIds" :disabled="false" :onAddHandler="addCapability" :onRemoveHandler="removeCapability" :multiple="true" v-if="can(`captains_capabilities_can_edit`)"/>
        <select-capabilities v-bind:value="capabilitiesIds" placeholder="messages.no_item_found" :disabled="true" :multiple="true" v-if="!can(`captains_capabilities_can_edit`)"/>
        <span v-if="loading">
            <span class="spinner-border spinner-border-sm"></span>
            {{ $t(`labels.saving`)}}
        </span>
        <span v-if="message" class="" v-bind:class="{'text-success':isSuccessMessage,'text-danger':!isSuccessMessage}">
            <i class="fa" v-bind:class="{'fa-check-circle':isSuccessMessage,'fa-window-close':!isSuccessMessage}"></i>
            {{$t(message)}}
        </span>
    </div>
</template>

<script>
    import { AREA_PRICING_RULES_ADD_CAPABILITIES as CRUD_ACTION_ADD_CAPABILITIES, AREA_PRICING_RULES_REMOVE_CAPABILITIES as CRUD_ACTION_REMOVE_CAPABILITIES, } from 'actions/platforms';
    export default {
        name: "BranchPricingRulesFieldsCapabilities",
        props: {
            itemID:{},
            capabilities:{
                type:Array,
                default:function(){
                    return [];
                }
            },
            permissionPrefix:{
                type: String,
                default: ""
            },
        },
        data(){
            return {
                loading:false,
                message:"",
                isSuccessMessage:true,
            }
        },
        computed:{
            capabilitiesIds :{
                get: function () {
                    let capabilitiesIds=[];
                    Object.keys(this.capabilities).forEach(key=>{
                        let capability=this.capabilities[key];
                        capabilitiesIds.push("" + capability.id);
                    });
                    return capabilitiesIds;
                },
            }
        },
        methods:{
            addCapability(capability){
                let action=CRUD_ACTION_ADD_CAPABILITIES;
                this.addRemoveCapability(action,capability,this.itemID);
            },
            removeCapability(capability){
                let action=CRUD_ACTION_REMOVE_CAPABILITIES;
                this.addRemoveCapability(action,capability,this.itemID);
            },
            addRemoveCapability(action,capability,id){
                this.loading=true;
                this.message="";
                this.$store.dispatch(action, {
                    id,
                    capability_id:capability.id,
                })
                .then(() => {
                    this.loading=false;
                    this.message="messages.capability_updated_successfully";
                    this.isSuccessMessage=true;
                })
                .catch(error => {
                    this.loading=false;
                    this.isSuccessMessage=false;
                    this.message="";
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }else{
                            message = this.$i18n.t("messages.capability_updated_error");
                        }
                    }
                    if(message){
                        this.message=message;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
