<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :error="fieldErrors.profilePicture"/>
                  <span v-if="fieldErrors.profilePicture" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.profilePicture }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          v-bind:class="{'is-invalid':fieldErrors.email}"
                  >
                  <span v-if="fieldErrors.email" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.email }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          v-bind:class="{'is-invalid':fieldErrors['userMobile.mobileNumber']}"
                  >
                  <span v-if="fieldErrors['userMobile.mobileNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['userMobile.mobileNumber'] }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`,params:{id} }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_USERS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';
  const PLATFORMS_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CODE}.users`;
  export default {
    name: "PlatformUserCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        platformsRoutePrefix: `${PLATFORMS_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CODE}`,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        email: '',
        name: '',
        mobile_number: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          ...this.prepareFile('image',"profilePicture"),
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
          platformId:this.id,
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`, params:{id:this.id} })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    }
  };
</script>
