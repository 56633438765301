<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="legalName">{{
                    $t(`${transPrefix}.fields.legal_name`)
                  }}</label>
                  <input
                    v-model="legalName"
                    id="legalName"
                    type="text"
                    step="any"
                    class="form-control"
                    name="legalName"
                    v-bind:class="{ 'is-invalid': fieldErrors.legalName }"
                  />
                  <span
                    v-if="fieldErrors.legalName"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.legalName }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="legalNameAr">{{
                    $t(`${transPrefix}.fields.legal_name_ar`)
                  }}</label>
                  <input
                    v-model="legalNameAr"
                    id="legalNameAr"
                    type="text"
                    step="any"
                    class="form-control"
                    name="legalNameAr"
                    v-bind:class="{ 'is-invalid': fieldErrors.legalNameAr }"
                  />
                  <span
                    v-if="fieldErrors.legalNameAr"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.legalNameAr }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="perTask">{{
                    $t(`${transPrefix}.fields.billing_email`)
                  }}</label>
                  <input
                    v-model="billingEmail"
                    id="billingEmail"
                    type="email"
                    step="any"
                    class="form-control"
                    name="billingEmail"
                    v-bind:class="{ 'is-invalid': fieldErrors.billingEmail }"
                  />
                  <span
                    v-if="fieldErrors.billingEmail"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.billingEmail }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="vatNumber">{{
                    $t(`${transPrefix}.fields.vat_number`)
                  }}</label>
                  <input
                    v-model="vatNumber"
                    id="vatNumber"
                    type="text"
                    step="any"
                    class="form-control"
                    name="vatNumber"
                    v-bind:class="{ 'is-invalid': fieldErrors.vatNumber }"
                  />
                  <span
                    v-if="fieldErrors.vatNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.vatNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="crNumber">{{
                    $t(`${transPrefix}.fields.cr_number`)
                  }}</label>
                  <input
                    v-model="crNumber"
                    id="crNumber"
                    type="text"
                    step="any"
                    class="form-control"
                    name="crNumber"
                    v-bind:class="{ 'is-invalid': fieldErrors.crNumber }"
                  />
                  <span
                    v-if="fieldErrors.crNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.crNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="zipCode">{{
                    $t(`${transPrefix}.fields.zip_code`)
                  }}</label>
                  <input
                    v-model="zipCode"
                    id="zipCode"
                    type="text"
                    step="any"
                    class="form-control"
                    name="zipCode"
                    v-bind:class="{ 'is-invalid': fieldErrors.zipCode }"
                  />
                  <span
                    v-if="fieldErrors.zipCode"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.zipCode }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="poBox">{{
                    $t(`${transPrefix}.fields.po_box`)
                  }}</label>
                  <input
                    v-model="poBox"
                    id="poBox"
                    type="text"
                    step="any"
                    class="form-control"
                    name="poBox"
                    v-bind:class="{ 'is-invalid': fieldErrors.poBox }"
                  />
                  <span
                    v-if="fieldErrors.poBox"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.poBox }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="addressLine1">{{
                    $t(`${transPrefix}.fields.address_line_1`)
                  }}</label>
                  <input
                    v-model="addressLine1"
                    id="addressLine1"
                    type="text"
                    step="any"
                    class="form-control"
                    name="addressLine1"
                    v-bind:class="{ 'is-invalid': fieldErrors.addressLine1 }"
                  />
                  <span
                    v-if="fieldErrors.addressLine1"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.addressLine1 }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="addressLine2">{{
                    $t(`${transPrefix}.fields.address_line_2`)
                  }}</label>
                  <input
                    v-model="addressLine2"
                    id="addressLine2"
                    type="text"
                    step="any"
                    class="form-control"
                    name="addressLine2"
                    v-bind:class="{ 'is-invalid': fieldErrors.addressLine2 }"
                  />
                  <span
                    v-if="fieldErrors.addressLine2"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.addressLine2 }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="invoiceDueDateAfter">{{
                    $t(`${transPrefix}.fields.invoice_due_date_after`)
                  }}</label>
                  <input
                    v-model="invoiceDueDateAfter"
                    id="invoiceDueDateAfter"
                    type="number"
                    step="any"
                    class="form-control"
                    name="invoiceDueDateAfter"
                    v-bind:class="{ 'is-invalid': fieldErrors.invoiceDueDateAfter }"
                  />
                  <span
                    v-if="fieldErrors.invoiceDueDateAfter"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.invoiceDueDateAfter }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link
                  :to="{ name: `${routePrefix}.show` }"
                  class="btn btn-neutral btn-sm"
                >
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button
                  v-if="isItemFound()"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  :disabled="loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_DETAILS_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_DETAILS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.details`;
export default {
  name: "PlatformDetailEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      legalName: "",
      legalNameAr : "",
      billingEmail: "",
      vatNumber: "",
      crNumber: "",
      zipCode: "",
      poBox: "",
      addressLine1: "",
      addressLine2: "",
      invoiceDueDateAfter: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: 'edit',
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
        })
        .then((response) => {
          this.billingEmail = response.data.billingEmail;
          this.legalNameAr = response.data.legalNameAr;
          this.legalName = response.data.legalName;
          this.vatNumber = response.data.vatNumber;
          this.crNumber = response.data.crNumber;
          this.zipCode = response.data.zipCode;
          this.invoiceDueDateAfter = response.data.invoiceDueDateAfter;
          this.poBox = response.data.poBox;
          this.addressLine1 = response.data.addressLine1;
          this.addressLine2 = response.data.addressLine2;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        billingEmail: this.sanitizeField("billingEmail"),
        legalName: this.sanitizeField("legalName"),
        legalNameAr : this.sanitizeField("legalNameAr"),
        crNumber: this.sanitizeField("crNumber"),
        vatNumber: this.sanitizeField("vatNumber"),
        invoiceDueDateAfter: this.sanitizeField("invoiceDueDateAfter"),
        zipCode: this.sanitizeField("zipCode"),
        poBox: this.sanitizeField("poBox"),
        addressLine1: this.sanitizeField("addressLine1"),
        addressLine2: this.sanitizeField("addressLine2"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.show`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
