<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <h3 style="padding: 1.25rem 1.5rem">
          {{ en_name }}
        </h3>
        <div class="container-fluid mt-4">
          <div class="row justify-content-center">
            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert bg-teal">
                {{ 'Total' }}: {{ totalCaptainsInsidePolygon }}
              </div>
            </div>
            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert alert-success">
                {{ `Connected` }}:
                {{ totalConnected === null ? 0 : totalConnected }}
              </div>
            </div>
            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert bg-red text-white">
                {{ `Offline` }}:
                {{ totalDisconnected }}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert bg-gray text-white">
                {{ `Unassigned Orders` }}:
                {{ countryUnassignedOrders }}
              </div>
            </div>
            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert bg-yellow">
                {{ 'Busy' }}: {{ busyCaptains }}
              </div>
            </div>

            <div class="col-12 col-md-3 col-xl-counters col-2xl-counters col-xl-counters-connections">
              <div class="alert bg-purple text-white">
                {{ 'Free' }}: {{ freeCaptains }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  CITIES_SHOW as CRUD_ACTION_SHOW,
  CITIES_CONNECTIVITY_SHOW as CRUD_ACTION_CONNECTIVITY_SHOW,
} from 'actions/cities';
const CRUD_CODE = 'countries';
const CITIES_CODE = 'cities';
import Stomp from 'webstomp-client';
import { isCaptainInsidePolygon } from '../../../../util/isCaptainInsidePolygon';

export default {
  name: 'CityConnections',
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      citiesRoutePrefix: `${CRUD_CODE}.${CITIES_CODE}`,
      citiesTransPrefix: `${CITIES_CODE}`,
      citiesPermissionPrefix: `${CITIES_CODE}`,
      userMap: {},
      points: [],
      oldPoints: [],
      en_name: '',
      ar_name: '',
      serverError: '',
      responseStatus: '',
      id: this.$router.currentRoute.params.id,
      city_id: this.$router.currentRoute.params.city_id,
      stompClient: null,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 10000,
        },
      },
      captainsLocationHash: {},
      captainsConnectionHash: {},
      totalCaptainsInsidePolygon: 0,
      totalConnected: null,
      totalDisconnected: 0,
      intervalHandler: null,
      busyCaptains: 0,
      freeCaptains: 0,
      cityUnassignedOrders: 0,
    };
  },
  mounted() {
    if (this.isStompLoaded) {
      this.connect();
    }
  },
  watch: {
    isStompLoaded: function(newVal, oldVal) {
      if (newVal) {
        this.connect();
      }
    },
  },
  computed: {
    ...mapGetters([
      'isStompLoaded',
      'getStompConnectionUrl',
      'getStompConnectionsTopic',
      'getStompLocationsTopic',
      'getAuthorizationHeader',
      'getScopedCaptains',
      'getConnectedCaptainsSize',
    ]),
    ...mapGetters(['getSearchFilters', 'getSearchFiltersSensitized']),
  },
  beforeDestroy() {
    clearInterval(this.intervalHandler);
    this.disconnect();
  },
  mounted() {
    this.getItem();
    this.connect();
    this.getConnectivityStats();
    this.intervalHandler = setInterval(this.getConnectivityStats, 60000);
  },
  methods: {
    connect() {
      this.stompClient = Stomp.client(
        this.getStompConnectionUrl,
        this.stompOptions
      );
      this.stompClient.connect(
        { 'X-Authorization': this.getAuthorizationHeader },
        (frame) => {
          this.stompClient.subscribe(this.getStompConnectionsTopic, (tick) => {
            const dataPoint = JSON.parse(tick.body);
            if (this.captainsLocationHash[dataPoint.captainId] === true) {
              this.captainsConnectionHash[dataPoint.captainId] =
                dataPoint.connected;
            }

            this.totalDisconnected = Object.values(
              this.captainsConnectionHash
            ).filter((i) => i === false).length;

            this.totalConnected =
              this.totalConnected === null
                ? this.totalCaptainsInsidePolygon
                : Object.values(this.captainsConnectionHash).filter(
                    (i) => i === true
                  ).length;

            this.totalCaptainsInsidePolygon = Object.keys(
              this.captainsConnectionHash
            ).length;
          });

          this.stompClient.subscribe(this.getStompLocationsTopic, (tick) => {
            const dataPoint = JSON.parse(tick.body);
            this.captainsLocationHash[
              dataPoint.captainId
            ] = isCaptainInsidePolygon(
              [dataPoint.longitude, dataPoint.latitude],
              this.oldPoints
            );
            if (this.captainsLocationHash[dataPoint.captainId]) {
              this.captainsConnectionHash[dataPoint.captainId] = true;
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          id: this.city_id,
        })
        .then((response) => {
          this.en_name = response.data.name;
          this.ar_name = response.data.nameAr;
          this.oldPoints = this.points = response.data.geom
            ? response.data.geom.coordinates[0][0]
            : [];
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = '';

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = 'Invalid Request.';
            }
          } else {
            this.serverError = this.$i18n.t('messages.no_internet_connection');
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },

    disconnect() {
      if (this.isConnected()) {
        this.stompClient.disconnect();
      }
    },
    isConnected() {
      return this.stompClient ? this.stompClient.connected : false;
    },
    getConnectivityStats() {
      this.$store
        .dispatch(CRUD_ACTION_CONNECTIVITY_SHOW, {
          cityIds: [this.city_id],
          captainIds: Object.keys(this.captainsConnectionHash),
        })
        .then((response) => {
          this.cityUnassignedOrders =
            response.data.cityUnassignedOrders[this.city_id];
          this.busyCaptains = response.data.captains
            ? response.data.captains['BUSY']
            : 0;
          this.freeCaptains = response.data.captains
            ? response.data.captains['FREE']
            : 0;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = '';
          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = 'Invalid Request.';
            }
          } else {
            this.serverError = this.$i18n.t('messages.no_internet_connection');
          }
          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
