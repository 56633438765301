export const CAPTAINS_REGISTRATION_REQUESTS_LIST =
  "CAPTAINS_REGISTRATION_REQUESTS_LIST";
export const CAPTAINS_REGISTRATION_REQUESTS_CREATE =
  "CAPTAINS_REGISTRATION_REQUESTS_CREATE";
export const CAPTAINS_REGISTRATION_REQUESTS_UPDATE =
  "CAPTAINS_REGISTRATION_REQUESTS_UPDATE";
export const CAPTAINS_REGISTRATION_REQUESTS_DELETE =
  "CAPTAINS_REGISTRATION_REQUESTS_DELETE";
export const CAPTAINS_REGISTRATION_REQUESTS_SHOW =
  "CAPTAINS_REGISTRATION_REQUESTS_SHOW";
export const CAPTAINS_REGISTRATION_REQUESTS_UPDATE_STATUS =
  "CAPTAINS_REGISTRATION_REQUESTS_UPDATE_STATUS";
export const CAPTAINS_REGISTRATION_REQUESTS_STATUS_LIST =
  "CAPTAINS_REGISTRATION_REQUESTS_STATUS_LIST";
export const CAPTAINS_REGISTRATION_REQUESTS_NOTES_LIST =
  "CAPTAINS_REGISTRATION_REQUESTS_NOTES_LIST";
export const CAPTAINS_REGISTRATION_REQUESTS_NOTES_CREATE =
  "CAPTAINS_REGISTRATION_REQUESTS_NOTES_CREATE";
export const CAPTAINS_REGISTRATION_REQUESTS_NOTES_TYPE_LIST =
  "CAPTAINS_REGISTRATION_REQUESTS_NOTES_TYPE_LIST";
export const CAPTAINS_REGISTRATION_REQUESTS_PREFERRED_VERTICAL_LIST =
  "CAPTAINS_REGISTRATION_REQUESTS_PREFERRED_VERTICAL_LIST";
