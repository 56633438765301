export const TRACKING_ADD_UPDATE_CAPTAIN = "TRACKING_ADD_UPDATE_CAPTAIN";
export const TRACKING_UPDATE_CAPTAIN_STATUS = "TRACKING_UPDATE_CAPTAIN_STATUS";
export const TRACKING_ADD_UPDATE_BUSY_CAPTAINS = "TRACKING_ADD_UPDATE_BUSY_CAPTAINS";
export const TRACKING_RESET_CAPTAINS_CAPTAINS = "TRACKING_RESET_CAPTAINS_CAPTAINS ";
export const TRACKING_SET_ACTIVE_ORDER = "TRACKING_SET_ACTIVE_ORDER";
export const TRACKING_REFRESH_LIST = "TRACKING_REFRESH_LIST";
export const TRACKING_SET_BRANCH = "TRACKING_SET_BRANCH";
export const TRACKING_RESET_BRANCH = "TRACKING_RESET_BRANCH";
export const TRACKING_SET_SCOPED_CAPTAINS = "TRACKING_SET_SCOPED_CAPTAINS";
export const TRACKING_RESET_SCOPED_CAPTAINS = "TRACKING_RESET_SCOPED_CAPTAINS";
export const TRACKING_SET_SCOPED_CAPTAINS_ORDERS = "TRACKING_SET_SCOPED_CAPTAINS_ORDERS";
export const TRACKING_RESET_SCOPED_CAPTAINS_ORDERS = "TRACKING_RESET_SCOPED_CAPTAINS_ORDERS";
export const TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS = "TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS";
export const TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS = "TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS";
export const TRACKING_SET_ZONE = "TRACKING_SET_ZONE";
export const TRACKING_RESET_ZONE = "TRACKING_RESET_ZONE";