<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${orderRoutePrefix}.show`,params:{order_id} }"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.captainId }} </td>
            <td> {{ $t(`${transPrefix}.lists.status.${item.status}`) }} </td>
            <td> {{ item.earnings }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { ALLOCATION_WINDOWS_HISTORY as CRUD_ACTION_LIST} from 'actions/allocation_windows';
  const CRUD_CODE="allocation_windows";
  const ORDERS_CODE="orders";
  export default {
    name: "OrderAllocationWindowIndex",
    data(){
      return {
        routePrefix: `${ORDERS_CODE}.${CRUD_CODE}`,
        transPrefix: `${ORDERS_CODE}.${CRUD_CODE}`,
        orderRoutePrefix: ORDERS_CODE,
        orderTransPrefix: ORDERS_CODE,
        id:this.$router.currentRoute.params.allocation_window_id,
        order_id:this.$router.currentRoute.params.id,
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title':`fields.captain_id`
          },
          {
            'title':`fields.status`
          },
          {
            'title':`${ORDERS_CODE}.${CRUD_CODE}.fields.earnings`
          },
          {
            'title':`fields.created_at`
          }
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          data:{
            pageId: this.getCurrentPage()-1
          },
          id:this.id
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
