<template>
    <abstract-dropdown
            v-model="selectedValue"
            :error="error"
            :id="id"
            :disabled="disabled"
            :multiple="multiple"
            :customLabelHandler="customLabelHandler"
            :onAddHandler="onAddHandler"
            :onRemoveHandler="onRemoveHandler"
            :paginationHandler="pagination"
            :items="items"
            :placeholder="placeholder"
            :isLoading="isLoading"
            :noResultMessage="noResultMessage"
            :allow-empty="allowEmpty"
            :canSelectAll="canSelectAll"
            :hide-selected="hideSelected"
    />
</template>

<script>
    export default {
        name: "AbstractsDropdownApi",
        props: {
            value: {},
            id: {
                type: String,
                default: 'dropdown-api'
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hideSelected: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'placeholders.dropdown',
            },
            noResultMessage: {
                type: String,
                default: 'messages.no_result_found',
            },
            error: {
                type: String
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            canSelectAll: {
                type: Boolean,
                default: false,
            },
            params: {
                type: Object,
                default: () => {
                }
            },
            action: {
                type: String,
                required: true
            },
            customLabelHandler: {
                type: Function,
                default: (value) => {
                    return value.name;
                }
            },
            prepareResponseDataHandler: {
                type: Function,
                default: (data) => {
                    return data;
                }
            },
            onAddHandler: {
                type:Function,
                default: () => { }
            },
            onRemoveHandler: {
                type:Function,
                default: () => { }
            },
        },
        data() {
            return {
                currentPage: -1,
                items: [],
                perPage: 20,
                totalElements: 0,
                lastPage: 1,
                selectedValue: this.value,
                isLoading: false,
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        mounted() {
            if (!this.isEmpty(this.value)) {
                this.pagination(true);
            }
        },
        methods: {
            getList() {
                this.isLoading = true;
                this.$store.dispatch(this.action, {
                    pageId: this.getCurrentPage(),
                    withLoading: false,
                    ...this.params
                }).then(response => {
                    if (response.data.perPage) {
                        this.items = [...this.items, ...response.data.data];
                        this.perPage = response.data.perPage;
                        this.totalElements = response.data.totalElements;
                        this.lastPage = response.data.pageCount - 1;
                    } else {
                        this.items = [...this.items, ...this.prepareResponseDataHandler(response.data)];
                        this.lastPage = this.lastPage - 1;
                    }
                    this.isLoading = false;
                });
            },
            pagination(isVisible) {
                if (this.hasNextPage() && isVisible) {
                    this.currentPage += 1;
                    this.getList();
                }
            },
            getCurrentPage() {
                return this.currentPage;
            },
            getLastPage() {
                return this.lastPage;
            },
            hasNextPage() {
                return this.getCurrentPage() < this.getLastPage();
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
        }
    }
</script>

<style scoped>

</style>
