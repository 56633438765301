<template>
    <div class="row" v-bind:class="{'mt-6':!items.length}">
      <div v-for="item in items" class="col-xl-4 col-md-6">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0 text-custom-dashboard">{{ item.label }}</h5>
                <span class="h2 font-weight-bold mb-0">{{ item.value }}</span>
              </div>
              <div class="col-auto">
                <div class="icon icon-shape text-white rounded-circle shadow" v-bind:class="[item.color]">
                  <i class="" v-bind:class="[item.icon]"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

  import { DASHBOARD_LIST as CRUD_ACTION_LIST} from 'actions/dashboard';
  import { USER_REQUEST } from "actions/user";
  const CRUD_CODE="dashboard";
  export default {
    name: "DashboardIndex",
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        items:[],
      }
    },
    mounted() {
      this.getProfile();
      this.getList();
    },
    methods: {
      getList(){
        if(this.can('dashboard_can_view')){
          this.$store.dispatch(CRUD_ACTION_LIST,{}).then(response=>{
            this.items=response.data;
          });
        }
      },
      getProfile: function () {
        this.$store.dispatch(USER_REQUEST);
      }
    }
  };
</script>
