<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :thumbnailImage="image.thumbnail" :error="fieldErrors.profilePicture"/>
                  <span v-if="fieldErrors.profilePicture" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.profilePicture }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="identity">{{ $t(`${transPrefix}.fields.identity`)}}</label>
                  <files-image id="identity" v-model="identity" :thumbnailImage="identity.thumbnail" :error="fieldErrors.identity"/>
                  <span v-if="fieldErrors.identity" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.identity }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="driving_license">{{ $t(`${transPrefix}.fields.driving_license`)}}</label>
                  <files-image id="driving_license" v-model="driving_license" :thumbnailImage="driving_license.thumbnail" :error="fieldErrors.drivingLicense"/>
                  <span v-if="fieldErrors.drivingLicense" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.drivingLicense }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="registration_license">{{ $t(`${transPrefix}.fields.registration_license`)}}</label>
                  <files-image id="registration_license" v-model="registration_license" :thumbnailImage="registration_license.thumbnail" :error="fieldErrors.registrationLicense"/>
                  <span v-if="fieldErrors.registrationLicense" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.registrationLicense }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          v-bind:class="{'is-invalid':fieldErrors.email}"
                          @keydown.space.prevent
                  >
                  <span v-if="fieldErrors.email" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.email }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          v-bind:class="{'is-invalid':fieldErrors['userMobile.mobileNumber']}"
                  >
                  <span v-if="fieldErrors['userMobile.mobileNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['userMobile.mobileNumber'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="date_of_birth">{{ $t(`fields.date_of_birth`)}}</label>
                  <input  v-model="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          class="form-control"
                          name="date_of_birth"
                          v-bind:class="{'is-invalid':fieldErrors['details.dateOfBirth']}"
                  >
                  <span v-if="fieldErrors['details.dateOfBirth']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.dateOfBirth'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality"  class="w-25"
                                    @onSelect="onSelectNationality"
                                    :defaultCountry="nationality"
                                    :dropdownOptions="{ disabledDialCode: true }"
                  />
                  <input  :value="nationality_label"
                          type="text"
                          class="form-control w-75 d-inline-block"
                          name="nationality"
                          v-bind:class="{'is-invalid':fieldErrors['details.nationality']}"
                          disabled
                  >
                  <span v-if="fieldErrors['details.nationality']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.nationality'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="capabilities">{{ $t(`fields.capabilities`)}}</label>
                  <capabilities-field :itemID="id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_number">{{ $t(`${transPrefix}.fields.id_number`)}}</label>
                  <input  v-model="id_number"
                          id="id_number"
                          type="text"
                          class="form-control"
                          name="id_number"
                          v-bind:class="{'is-invalid':fieldErrors.idNumber}"
                          @keydown.space.prevent
                  >
                  <span v-if="fieldErrors.idNumber" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.idNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_type">{{ $t(`${transPrefix}.fields.id_type`)}}</label>
                  <select-captain-id-type v-model="id_type" />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_plate_number">{{ $t(`${transPrefix}.fields.vehicle_plate_number`)}}</label>
                  <input  v-model="vehicle_plate_number"
                          id="vehicle_plate_number"
                          type="text"
                          class="form-control"
                          name="vehicle_plate_number"
                          v-bind:class="{'is-invalid':fieldErrors['details.vehiclePlateNumber']}"
                  >
                  <span v-if="fieldErrors['details.vehiclePlateNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.vehiclePlateNumber'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_sequence_number">{{ $t(`${transPrefix}.fields.vehicle_sequence_number`)}}</label>
                  <input  v-model="vehicle_sequence_number"
                          id="vehicle_sequence_number"
                          type="text"
                          class="form-control"
                          name="vehicle_sequence_number"
                          v-bind:class="{'is-invalid':fieldErrors['details.vehicleSequenceNumber']}"
                  >
                  <span v-if="fieldErrors['details.vehicleSequenceNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.vehicleSequenceNumber'] }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_SHOW as CRUD_ACTION_SHOW,CAPTAINS_UPDATE as CRUD_ACTION_UPDATE } from 'actions/suppliers_portal/captains';
  import capabilitiesField from "./fields/capabilities";

  const CAPTAINS_CODE = "captains";
  const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
  const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;
  export default {
    name: "CaptainEdit",
    components:{
      capabilitiesField,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CAPTAINS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        name: '',
        capabilities:[],
        country_id: "",
        mobile_number: '',
        nationality: '',
        nationality_label: '',
        date_of_birth: '',
        email: '',
        id_number:'',
        id_type:'',
        platform_id: "",
        vehicle_plate_number:'',
        vehicle_sequence_number:'',
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus && this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.id_number=response.data.idNumber;
          this.id_type=response.data.idType;
          this.email=response.data.user?response.data.user.email:'';
          this.country_id=response.data.user?response.data.user.userMobile.countryId:'';
          this.mobile_number=response.data.user?response.data.user.userMobile.mobileNumber:'';
          this.date_of_birth=response.data.dob?this.$moment(response.data.dob).format("Y-MM-DD"):"";
          this.nationality=response.data.nationality;
          this.name=response.data.user?response.data.user.name:'';
          this.platform_id=response.data.platformId;
          this.vehicle_plate_number=response.data.vehiclePlateNumber?response.data.vehiclePlateNumber:'';
          this.vehicle_sequence_number=response.data.vehicleSequenceNumber?response.data.vehicleSequenceNumber: '';
          this.image.thumbnail=response.data.user?(response.data.user.profilePicture?response.data.user.profilePicture.url:''):'';
          this.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
          this.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
          this.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
          this.capabilities=response.data.capabilities;

          this.responseStatus=response.status;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareDetailsRequestData() {
        let details={};
        if(this.date_of_birth || this.nationality){
          details= {
            "details.nationality": this.sanitizeField('nationality'),
            "details.dateOfBirth": this.sanitizeField('date_of_birth'),
             "details.vehiclePlateNumber": this.sanitizeField('vehicle_plate_number'),
            "details.vehicleSequenceNumber": this.sanitizeField('vehicle_sequence_number'),
          };
        }
        return details;
      },
      prepareRequestData(){
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          idNumber: this.sanitizeField('id_number'),
          idType: this.sanitizeField('id_type'),
          platformId: this.platform_id,
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          "userMobile.countryId":this.country_id,
          ...this.prepareFile('image',"profilePicture"),
          ...this.prepareFile('driving_license',"drivingLicense"),
          ...this.prepareFile('registration_license',"registrationLicense"),
          ...this.prepareFile('identity',"identity"),
          ...this.prepareDetailsRequestData(),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
    },
  };
</script>
