<template>
  <div>
    <maps-tracking :scopedByCaptains="true"/>
    <active-orders></active-orders>
    <branch-captains></branch-captains>
    <counters></counters>
  </div>
</template>
<script>

  import search from "./partials/search";
  import activeOrders from "./partials/activeOrders";
  import branchCaptains from "./partials/branchCaptains";
  import counters from "./partials/counters";
  const PLATFORMS_CRUD_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CRUD_CODE}.branches.tracking`;
  const CRUD_TRANS_CODE=`${CRUD_CODE}`;
  const CRUD_PERMISSION_CODE=`platform_branches`;
  export default {
    name: "TrackingIndex",
    components:{
      search,
      activeOrders,
      branchCaptains,
      counters
    },
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
      }
    },
  };
</script>
