<template>
    <div v-if="isBreadShow" class="header bg-primary pb-6">
        <div class="container-fluid">
            <div class="header-body">
                <div class="row align-items-center py-4">
                    <div class="col-lg-9 col-9">
                        <h6 class="h2 text-white d-inline-block mb-0">{{ $t(getBreadTitle) }}</h6>
                        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                            <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'dashboard.index' }">
                                        <i class="fas fa-home"></i>
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item"><router-link :to="{ name: getBreadIndexRoute }">{{ $t(getBreadTitle) }}</router-link></li>
                                <template v-for="item in getBreadItems">
                                    <li class="breadcrumb-item" v-bind:class="{'active': !item.link}" aria-current="page">{{ $t(item.title) }}</li>
                                </template>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        name: "CrudHeader",
        data(){
            return {

            }
        },
        computed: {
            ...mapGetters(["getBreadTitle", "getBreadItems",'getBreadIndexRoute','isBreadShow']),
        }
    }
</script>

<style scoped>

</style>