<template>
    <div v-if="tag==='div'" class="nav-item d-xl-none position-absolute left-4 top-4">
        <!-- Sidenav toggler -->
        <div class="sidenav-toggler sidenav-toggler-dark" @click="showHIdeSidenav" data-target="#sidenav-main">
            <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-primary"></i>
                <i class="sidenav-toggler-line bg-primary"></i>
                <i class="sidenav-toggler-line bg-primary"></i>
            </div>
        </div>
    </div>
    <li v-else class="nav-item ">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler sidenav-toggler-dark" @click="showHIdeSidenav" data-target="#sidenav-main">
            <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
            </div>
        </div>
    </li>
</template>

<script>
    import $ from "jquery";
    export default {
        props: {
            tag: String
        },
        name: "TopNavbarSideMenu",
        methods: {
            pinSidenav(){
                $('body').removeClass('g-sidenav-hidden').addClass('g-sidenav-pinned');
            },
            unpinSidenav(){
                $('.sidenav-toggler').removeClass('active');
                $('body').removeClass('g-sidenav-pinned').addClass('g-sidenav-hidden');
            },
            showHIdeSidenav(){

                if($('body').hasClass('g-sidenav-hidden')){
                    this.pinSidenav();
                }else {
                    this.unpinSidenav();
                }
            }
        },
        mounted() {
            if ($('body').hasClass('g-sidenav-hidden')) {
                $('.sidenav-toggler').removeClass('active');
            }else {
                $('.sidenav-toggler').addClass('active');
            }
        }
    }
</script>

<style scoped>

</style>
