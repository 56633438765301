<template>
  <div>
    <maps-tracking
      v-model="points"
      :points="oldPoints"
      :scopedByCaptains="true"
    />
    <active-orders></active-orders>
    <zone-captains></zone-captains>
    <counters></counters>
  </div>
</template>
<script>
import { PLATFORMS_ZONES_TRACKING_LIST as CRUD_ACTION_LIST } from 'actions/platforms';
import search from './partials/search';
import activeOrders from './partials/activeOrders';
import zoneCaptains from './partials/zoneCaptains';
import counters from './partials/counters';
const PLATFORMS_CRUD_CODE = 'platforms';
const CRUD_CODE = `${PLATFORMS_CRUD_CODE}.zones.tracking`;
export default {
  name: 'TrackingIndex',
  components: {
    search,
    activeOrders,
    zoneCaptains,
    counters,
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      id: this.$router.currentRoute.params.id,
      zone_id: this.$router.currentRoute.params.zone_id,
      points: [],
      oldPoints: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          platformId: this.id,
          zoneId: this.zone_id,
        })
        .then((response) => {
          this.oldPoints = this.points = response.data.geom
            ? response.data.geom.coordinates[0][0]
            : [];
        });
    },
  },
};
</script>
