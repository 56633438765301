<template>
  <div class="dropdown" v-if="crudCode && !disabled">
    <a class="btn btn-sm btn btn-primary" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fas fa-ellipsis-v"></i> {{ $t(`labels.export`)}}
    </a>
    <div class="dropdown-menu  dropdown-menu-right left-0">
      <a class="dropdown-item" v-for="type in types" :key="type.code" href="#" @click.prevent="exportTo(crudCode,type.code)">
        {{ $t(`export_types.${type.code}`)}}
      </a>
    </div>
  </div>
</template>
<script>

  import { mapActions, mapGetters } from "vuex";
  import { CRUD_EXPORT_ACTION } from "actions/crud";
  export default {
    name: "Export",
    props: {
      crudCode:{
        type: String,
        default: null
      },
      portalType:{ // Include [ platforms , operations, public ,suppliers]
        type: String,
        default: "operations"
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        types:[
          {
            'code':'csv'
          },
        ],
      }
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersSensitized"
      ])
    },
    methods: {
      exportTo(type){
        this.$store.dispatch(CRUD_EXPORT_ACTION, {
          type,
          portalType:this.portalType,
          code:this.crudCode,
          filters:this.getSearchFiltersSensitized,
        })
        .then(response => {
          swal({
            icon: 'success',
            text: response.data.message,
            buttons:false,
            timer: 5000
          });
        })
        .catch(error => {
          let message="";
          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors && responseData.errors.error){
              message = responseData.errors.error;
            }
            else{
              message = this.$i18n.t("messages.export_error");
            }
          }
          if(message){
            swal({
              icon: 'error',
              text: message,
              buttons:false,
              timer: 5000
            });
          }
        })
      }
    }
  };
</script>
