<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList" style="margin-top: 30px"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td> {{ item.id }} </td>
            <td> {{ item.user.name }} </td>
            <td> {{ item.city.name }} </td>
            <td> {{ item.user.email }} </td>
            <td> {{ item.status }} </td>
            <td>
              <label class="custom-toggle" v-can:suspend="permissionPrefix">
                <input type="checkbox" id="suspended" v-model="item.suspended" @change="changeSuspendedStatus(index, item.id, item.suspended)">
                <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
              </label>
              <label class="custom-toggle" v-can-not:suspend="permissionPrefix">
                <input type="checkbox" id="suspended-disabled" v-model="item.suspended" disabled>
                <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
              </label>
            </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
              <table-item-actions 
                :actions="getTableItemActions(item.status)" 
                :params="{ 'id': item.id }"
              />
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found') }}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { CAPTAINS_LIST as CRUD_ACTION_LIST } from 'actions/suppliers_portal/captains';
  import search from "./partials/search";
  
  const CAPTAINS_CODE = "captains";
  const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
  const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;

  export default {
    name: "SuppliersPortalCaptainIndex",
    components: {
      search,
    },
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CAPTAINS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders: [
          { 'title': `fields.image` },
          { 'title': `fields.id` },
          { 'title': `fields.name` },
          { 'title': `fields.city` },
          { 'title': `fields.email` },
          { 'title': `fields.status` },
          { 'title': `${CAPTAINS_CODE}.fields.suspended` },
          { 'title': `fields.created_at` },
          { 'title': `fields.actions` },
        ],
        tableItemActions: [
          {
            code: 'show',
            route_name: `${CRUD_CODE}.show`,
            can: `${CRUD_PERMISSION_CODE}_can_view`,
          },
        ],
        items: [],
        perPage: 20,
        totalElements: 0,
        lastPage: 0,
      };
    },
    mounted() {
      this.getList();
    },
    computed: {
      ...mapGetters([
        "getSearchFilters",
        "getSearchFiltersIfExists",
        "getSearchFiltersSensitized",
      ]),
    },
    methods: {
      getList() {
        this.$store.dispatch(CRUD_ACTION_LIST, {
          pageId: this.getCurrentPage() - 1,
          ...this.getSearchFiltersSensitized,
        }).then((response) => {
          this.items = response.data.data;
          this.perPage = response.data.perPage;
          this.totalElements = response.data.totalElements;
          this.lastPage = response.data.pageCount;
        });
      },
      pagination(page) {
        if (page !== this.getCurrentPage()) {
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query: {
              page,
              ...this.getSearchFiltersIfExists,
            },
          });
          this.getList();
        }
      },
      getCurrentPage() {
        let currentPage = this.$route.query.page ?? 1;
        return parseInt(currentPage >= 1 ? currentPage : 1);
      },
      changeSuspendedStatus(index, id, isSuspended) {
        // Handle suspension status change...
      },
      requestChangeSuspendedStatus(id, isSuspended, reason) {
        // Handle suspension status update...
      },
      getImageThumbnail(item) {
        let image = '';
        if (item.user && item.user.profilePicture) {
          image = item.user.profilePicture.url;
        }
        return image;
      },
      getTableItemActions(status) {
        if (status === 'NAQL_BLOCKED') {
          return [
            { code: 'show', route_name: `${CRUD_CODE}.show`, can: `${CRUD_PERMISSION_CODE}_can_view` },
            { code: 'edit', route_name: `${CRUD_CODE}.edit`, can: `${CRUD_PERMISSION_CODE}_can_edit` },
          ];
        }
        return [
          { code: 'show', route_name: `${CRUD_CODE}.show`, can: `${CRUD_PERMISSION_CODE}_can_view` },
        ];
      },
    },
  };
</script>
