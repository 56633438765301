<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :error="fieldErrors.platformImage" :thumbnailImage="image.thumbnail"/>
                  <span v-if="fieldErrors.platformImage" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.platformImage }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="en_name">{{ $t(`fields.en_name`)}}</label>
                  <input  v-model="en_name"
                          id="en_name"
                          type="text"
                          class="form-control"
                          name="en_name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="ar_name">{{ $t(`fields.ar_name`)}}</label>
                  <input  v-model="ar_name"
                          id="ar_name"
                          type="text"
                          class="form-control"
                          name="code"
                          v-bind:class="{'is-invalid':fieldErrors.nameAr}"
                  >
                  <span v-if="fieldErrors.nameAr" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.nameAr }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="jwt_callback_token">{{ $t(`${transPrefix}.fields.jwt_callback_token`)}}</label>
                  <input  v-model="jwt_callback_token"
                          id="jwt_callback_token"
                          type="text"
                          class="form-control"
                          name="jwt_callback_token"
                          v-bind:class="{'is-invalid':fieldErrors.jwtCallbackToken}"
                  >
                  <span v-if="fieldErrors.jwtCallbackToken" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.jwtCallbackToken }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="web_hook_url">{{ $t(`${transPrefix}.fields.web_hook_url`)}}</label>
                  <input  v-model="web_hook_url"
                          id="web_hook_url"
                          type="text"
                          class="form-control"
                          name="web_hook_url"
                          v-bind:class="{'is-invalid':fieldErrors.webHookUrl}"
                  >
                  <span v-if="fieldErrors.webHookUrl" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.webHookUrl }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="tasks_webhook_url">{{ $t(`${transPrefix}.fields.tasks_webhook_url`)}}</label>
                  <input  v-model="tasks_webhook_url"
                          id="tasks_webhook_url"
                          type="text"
                          class="form-control"
                          name="tasks_webhook_url"
                          v-bind:class="{'is-invalid':fieldErrors.tasksWebhookUrl}"
                  >
                  <span v-if="fieldErrors.tasksWebhookUrl" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.tasksWebhookUrl }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="order_prefix_url">{{ $t(`${transPrefix}.fields.order_prefix_url`)}}</label>
                  <input  v-model="order_prefix_url"
                          id="order_prefix_url"
                          type="text"
                          class="form-control"
                          name="order_prefix_url"
                          v-bind:class="{'is-invalid':fieldErrors.orderPrefixUrl}"
                  >
                  <span v-if="fieldErrors.orderPrefixUrl" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.orderPrefixUrl }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="country_id">{{ $t(`fields.country`)}}</label>
                  <select-countries v-model="country_id" :error="fieldErrors.countryId"/>
                  <span v-if="fieldErrors.countryId" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.countryId }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_SHOW as CRUD_ACTION_SHOW,PLATFORMS_UPDATE as CRUD_ACTION_UPDATE } from 'actions/platforms';
  const CRUD_CODE="platforms";
  export default {
    name: "PlatformEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        image:{
          file:'',
          thumbnail:'',
        },
        ar_name: '',
        en_name: '',
        jwt_callback_token: '',
        web_hook_url: '',
        tasks_webhook_url: '',
        order_prefix_url: '',
        country_id: "",
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.en_name=response.data.name;
          this.ar_name=response.data.nameAr;
          this.jwt_callback_token=response.data.jwtCallbackToken;
          this.web_hook_url=response.data.webHookUrl;
          this.tasks_webhook_url=response.data.tasksWebhookUrl;
          this.order_prefix_url=response.data.orderPrefixUrl;
          this.country_id=response.data.country?response.data.country.id:null;
          this.image.thumbnail=response.data.image?response.data.image.url:'';
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          name: this.sanitizeField("en_name"),
          nameAr: this.sanitizeField("ar_name"),
          jwtCallbackToken: this.sanitizeField("jwt_callback_token"),
          webHookUrl: this.sanitizeField("web_hook_url"),
          tasksWebhookUrl: this.sanitizeField("tasks_webhook_url"),
          orderPrefixUrl: this.sanitizeField("order_prefix_url"),
          countryId: this.sanitizeField("country_id"),
          ...this.prepareFile('image',"platformImage"),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
