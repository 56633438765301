import Index from "@/views/platforms/index";
import Create from "@/views/platforms/create";
import Show from "@/views/platforms/show";
import Edit from "@/views/platforms/edit";
import UsersIndex from "@/views/platforms/users/index";
import UsersCreate from "@/views/platforms/users/create";
import UsersShow from "@/views/platforms/users/show";
import AppsIndex from "@/views/platforms/apps/index";
import AppsCreate from "@/views/platforms/apps/create";
import AppsShow from "@/views/platforms/apps/show";
import AppsEdit from "@/views/platforms/apps/edit";
import ConfigsShow from "@/views/platforms/configs/show";
import ConfigsEdit from "@/views/platforms/configs/edit";
import FilesShow from "@/views/platforms/files/show";
import InvoicesIndex from "@/views/platforms/invoices/index";
import InvoicesCreate from "@/views/platforms/invoices/create";
import DetailsShow from "@/views/platforms/details/show";
import DetailsEdit from "@/views/platforms/details/edit";
import SettingsShow from "@/views/platforms/settings/show";
import SettingsEdit from "@/views/platforms/settings/edit";
import DashboardIndex from "@/views/platforms/dashboard/index";
import BranchesIndex from "@/views/platforms/branches/index";
import BranchesShow from "@/views/platforms/branches/show";
import BranchesCreate from "@/views/platforms/branches/create";
import BranchesEdit from "@/views/platforms/branches/edit";
import BranchesShiftsIndex from "@/views/platforms/branches/shifts/index";
import BranchesShiftsShow from "@/views/platforms/branches/shifts/show";
import BranchesShiftsCreate from "@/views/platforms/branches/shifts/create";
import BranchesShiftsEdit from "@/views/platforms/branches/shifts/edit";
import BranchesSettingsShow from "@/views/platforms/branches/settings/show";
import ZonesSettingsShow from "@/views/platforms/zones/settings/show";
import ZonesSettingsEdit from "@/views/platforms/zones/settings/edit";
import BranchesSettingsEdit from "@/views/platforms/branches/settings/edit";
import BranchesTrackingIndex from "@/views/platforms/branches/tracking/index";
import BranchesShiftsSummariesIndex from "@/views/platforms/branches/shifts/summaries/index";
import BranchesShiftsPricingRulesIndex from "@/views/platforms/branches/shifts/pricing_rules/index";
import BranchesShiftsPricingRulesShow from "@/views/platforms/branches/shifts/pricing_rules/show";
import BranchesShiftsPricingRulesCreate from "@/views/platforms/branches/shifts/pricing_rules/create";
import BranchesShiftsPricingRulesEdit from "@/views/platforms/branches/shifts/pricing_rules/edit";
import ZonesShiftsDashboardIndex from "@/views/platforms/zones/shifts/dashboard/index";
import BranchShiftsDashboardIndex from "@/views/platforms/branches/shifts/dashboard/index";
import BranchesPricingRulesIndex from "@/views/platforms/branches/pricing_rules/index";
import BranchesPricingRulesShow from "@/views/platforms/branches/pricing_rules/show";
import BranchesPricingRulesCreate from "@/views/platforms/branches/pricing_rules/create";
import BranchesAttendancesIndex from "@/views/platforms/branches/attendances";
import ZonesCreate from "@/views/platforms/zones/create";
import ZonesIndex from "@/views/platforms/zones/index";
import ZonesShow from "@/views/platforms/zones/show";
import ZonesEdit from "@/views/platforms/zones/edit";
import ZonesPricingRulesIndex from "@/views/platforms/zones/pricing_rules/index";
import ZonesPricingRulesCreate from "@/views/platforms/zones/pricing_rules/create";
import ZonesPricingRulesShow from "@/views/platforms/zones/pricing_rules/show";
import ZonesPricingRulesEdit from "@/views/platforms/zones/pricing_rules/edit";
import ZonesShiftsIndex from "@/views/platforms/zones/shifts/index";
import ZonesShiftsCreate from "@/views/platforms/zones/shifts/create";
import ZonesShiftsShow from "@/views/platforms/zones/shifts/show";
import ZonesShiftsEdit from "@/views/platforms/zones/shifts/edit";
import ZonesShiftsPricingRulesIndex from "@/views/platforms/zones/shifts/pricing_rules/index";
import ZonesShiftsPricingRulesCreate from "@/views/platforms/zones/shifts/pricing_rules/create";
import ZonesShiftsPricingRulesShow from "@/views/platforms/zones/shifts/pricing_rules/show";
import ZonesShiftsPricingRulesEdit from "@/views/platforms/zones/shifts/pricing_rules/edit";
import ZonesShiftsSummariesIndex from "@/views/platforms/zones/shifts/summaries/index";
import ZonesAttendancesIndex from "@/views/platforms/zones/attendances";
import BranchesPricingRulesEdit from "@/views/platforms/branches/pricing_rules/edit";
import ShiftsLateAttendanceIndex from "@/views/platforms/branches/shifts/late_attendances/index";
import ZonesShiftsLateAttendanceIndex from "@/views/platforms/zones/shifts/late_attendances/index";
import ZonesTrackingIndex from "@/views/platforms/zones/tracking/index";

import {
  commonBeforeEnter,
  ifAuthenticated,
  ifNotAuthenticated,
} from "../helper";

const CODE = "platforms";
const PERMISSION_PREFIX = "platforms";
const USERS_CODE = "users";
const USERS_PERMISSION_PREFIX = "platform_users";
const APPS_CODE = "apps";
const APPS_PERMISSION_PREFIX = "platform_apps";
const CONFIGS_CODE = "configs";
const CONFIGS_PERMISSION_PREFIX = "platform_configs";
const FILES_CODE = "files";
const FILES_PERMISSION_PREFIX = "platform_files";
const DASHBOARD_CODE = "dashboard";
const DASHBOARD_PERMISSION_PREFIX = "orders";
const INVOICES_CODE = "invoices";
const INVOICES_PERMISSION_PREFIX = "platform_invoices";
const DETAILS_CODE = "details";
const DETAILS_PERMISSION_PREFIX = "platforms";
const SETTINGS_CODE = "settings";
const SETTINGS_PERMISSION_PREFIX = "platform_settings";
const BRANCHES_CODE = "branches";
const BRANCHES_PERMISSION_PREFIX = "platform_branches";
const ZONES_CODE = "zones";
const ZONES_PERMISSION_PREFIX = "platform_zones";
const AREAS_SHIFTS_CODE = "shifts";
const AREAS_SHIFTS_PERMISSION_PREFIX = "platform_area_shifts";
const AREAS_SETTINGS_PERMISSION_PREFIX = "platform_area_settings";
const BRANCHES_TRACKING_CODE = "tracking";
const BRANCHES_TRACKING_PERMISSION_PREFIX = BRANCHES_PERMISSION_PREFIX;
const AREAS_SHIFTS_SUMMARIES_CODE = "summaries";
const AREAS_SHIFTS_SUMMARIES_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const AREAS_SHIFTS_PRICING_RULES_CODE = "pricing_rules";
const AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const BRANCHES_SHIFTS_DASHBOARD_CODE = "dashboard";
const ZONES_SHIFTS_DASHBOARD_CODE = "dashboard";
const BRANCHES_SHIFTS_DASHBOARD_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const ZONES_SHIFTS_DASHBOARD_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const SHIFT_LATE_ATTENDANCES_CODE = "late_attendances";
const SHIFT_LATE_ATTENDANCES_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const AREAS_SHIFTS_ATTENDANCES_PERMISSION_PREFIX = AREAS_SHIFTS_PERMISSION_PREFIX;
const ATTENDANCES_CODE = "attendances";

const routes = [
  {
    path: `${CODE}`,
    name: `${CODE}.index`,
    component: Index,
    meta: {
      can: [`${PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/create`,
    name: `${CODE}.create`,
    component: Create,
    meta: {
      can: [`${PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id`,
    name: `${CODE}.show`,
    component: Show,
    meta: {
      can: [`${PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: `:id`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/edit`,
    name: `${CODE}.edit`,
    component: Edit,
    meta: {
      can: [`${PERMISSION_PREFIX}_can_update`],
      removeClass: [`bg-default`],
      title: `${CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: `:id`,
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}`,
    name: `${CODE}.${USERS_CODE}.index`,
    component: UsersIndex,
    meta: {
      can: [`${USERS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${USERS_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${USERS_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/create`,
    name: `${CODE}.${USERS_CODE}.create`,
    component: UsersCreate,
    meta: {
      can: [`${USERS_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${USERS_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${USERS_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${USERS_CODE}/:user_id`,
    name: `${CODE}.${USERS_CODE}.show`,
    component: UsersShow,
    meta: {
      can: [`${USERS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${USERS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${USERS_CODE}.title`,
          },
          {
            title: ":user_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}`,
    name: `${CODE}.${APPS_CODE}.index`,
    component: AppsIndex,
    meta: {
      can: [`${APPS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${APPS_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${APPS_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/create`,
    name: `${CODE}.${APPS_CODE}.create`,
    component: AppsCreate,
    meta: {
      can: [`${APPS_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${APPS_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${APPS_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/:app_id`,
    name: `${CODE}.${APPS_CODE}.show`,
    component: AppsShow,
    meta: {
      can: [`${APPS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${APPS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${APPS_CODE}.title`,
          },
          {
            title: ":app_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${APPS_CODE}/:app_id/edit`,
    name: `${CODE}.${APPS_CODE}.edit`,
    component: AppsEdit,
    meta: {
      can: [`${APPS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${APPS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${APPS_CODE}.title`,
          },
          {
            title: ":app_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}`,
    name: `${CODE}.${CONFIGS_CODE}.show`,
    component: ConfigsShow,
    meta: {
      can: [`${CONFIGS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${CONFIGS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CONFIGS_CODE}/edit`,
    name: `${CODE}.${CONFIGS_CODE}.edit`,
    component: ConfigsEdit,
    meta: {
      can: [`${CONFIGS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${CONFIGS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${CONFIGS_CODE}.title`,
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${FILES_CODE}`,
    name: `${CODE}.${FILES_CODE}.show`,
    component: FilesShow,
    meta: {
      can: [`${FILES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${FILES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${FILES_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${INVOICES_CODE}`,
    name: `${CODE}.${INVOICES_CODE}.index`,
    component: InvoicesIndex,
    meta: {
      can: [`${INVOICES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${INVOICES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${INVOICES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${INVOICES_CODE}/create`,
    name: `${CODE}.${INVOICES_CODE}.create`,
    component: InvoicesCreate,
    meta: {
      can: [`${INVOICES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${INVOICES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${INVOICES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}`,
    name: `${CODE}.${DETAILS_CODE}.show`,
    component: DetailsShow,
    meta: {
      can: [`${DETAILS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${DETAILS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DETAILS_CODE}/edit`,
    name: `${CODE}.${DETAILS_CODE}.edit`,
    component: DetailsEdit,
    meta: {
      can: [`${DETAILS_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${DETAILS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${DETAILS_CODE}.title`,
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${SETTINGS_CODE}`,
    name: `${CODE}.${SETTINGS_CODE}.show`,
    component: SettingsShow,
    meta: {
      can: [`${SETTINGS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${SETTINGS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${SETTINGS_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${SETTINGS_CODE}/edit`,
    name: `${CODE}.${SETTINGS_CODE}.edit`,
    component: SettingsEdit,
    meta: {
      can: [`${SETTINGS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${SETTINGS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${SETTINGS_CODE}.title`,
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${DASHBOARD_CODE}`,
    name: `${CODE}.${DASHBOARD_CODE}.index`,
    component: DashboardIndex,
    meta: {
      can: [`${DASHBOARD_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`, "g-sidenav-pinned"],
      addClass: [`g-sidenav-hidden`],
      title: `${CODE}.${DASHBOARD_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: ``,
        show: false,
        container_fluid: false,
        add_primary_color: false,
        items: [],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.index`,
    component: BranchesIndex,
    meta: {
      can: [`${BRANCHES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/create`,
    name: `${CODE}.${BRANCHES_CODE}.create`,
    component: BranchesCreate,
    meta: {
      can: [`${BRANCHES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id`,
    name: `${CODE}.${BRANCHES_CODE}.show`,
    component: BranchesShow,
    meta: {
      can: [`${BRANCHES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/edit`,
    name: `${CODE}.${BRANCHES_CODE}.edit`,
    component: BranchesEdit,
    meta: {
      can: [`${BRANCHES_PERMISSION_PREFIX}_can_update`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.index`,
    component: BranchesShiftsIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/create`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.create`,
    component: BranchesShiftsCreate,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.show`,
    component: BranchesShiftsShow,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${SETTINGS_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.show`,
    component: BranchesSettingsShow,
    meta: {
      can: [`${AREAS_SETTINGS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${SETTINGS_CODE}/:settings_id/edit`,
    name: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.edit`,
    component: BranchesSettingsEdit,
    meta: {
      can: [`${AREAS_SETTINGS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${SETTINGS_CODE}.title`,
          },
          {
            title: ":settings_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/edit`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.edit`,
    component: BranchesShiftsEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${BRANCHES_TRACKING_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${BRANCHES_TRACKING_CODE}.index`,
    component: BranchesTrackingIndex,
    meta: {
      can: [`${BRANCHES_TRACKING_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`, "g-sidenav-pinned"],
      addClass: [`g-sidenav-hidden`],
      title: `${CODE}.${BRANCHES_CODE}.${BRANCHES_TRACKING_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: ``,
        show: false,
        container_fluid: false,
        add_primary_color: false,
        items: [],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_SUMMARIES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.index`,
    component: BranchesShiftsSummariesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_SUMMARIES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.index`,
    component: BranchesShiftsPricingRulesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/create`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create`,
    component: BranchesShiftsPricingRulesCreate,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show`,
    component: BranchesShiftsPricingRulesShow,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id/edit`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit`,
    component: BranchesShiftsPricingRulesEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/${AREAS_SHIFTS_CODE}/${BRANCHES_SHIFTS_DASHBOARD_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${BRANCHES_SHIFTS_DASHBOARD_CODE}.index`,
    component: BranchShiftsDashboardIndex,
    meta: {
      can: [`${BRANCHES_SHIFTS_DASHBOARD_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${BRANCHES_SHIFTS_DASHBOARD_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${BRANCHES_SHIFTS_DASHBOARD_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },

  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_PRICING_RULES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.index`,
    component: BranchesPricingRulesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/create`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create`,
    component: BranchesPricingRulesCreate,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show`,
    component: BranchesPricingRulesShow,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id/edit`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit`,
    component: BranchesPricingRulesEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${AREAS_SHIFTS_CODE}/:shift_id/${SHIFT_LATE_ATTENDANCES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.index`,
    component: ShiftsLateAttendanceIndex,
    meta: {
      can: [`${SHIFT_LATE_ATTENDANCES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/create`,
    name: `${CODE}.${ZONES_CODE}.create`,
    component: ZonesCreate,
    meta: {
      can: [`${ZONES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.index`,
    component: ZonesIndex,
    meta: {
      can: [`${ZONES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id`,
    name: `${CODE}.${ZONES_CODE}.show`,
    component: ZonesShow,
    meta: {
      can: [`${ZONES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/edit`,
    name: `${CODE}.${ZONES_CODE}.edit`,
    component: ZonesEdit,
    meta: {
      can: [`${ZONES_PERMISSION_PREFIX}_can_update`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${SETTINGS_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${SETTINGS_CODE}.show`,
    component: ZonesSettingsShow,
    meta: {
      can: [`${AREAS_SETTINGS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${SETTINGS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${SETTINGS_CODE}.title`,
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${SETTINGS_CODE}/:settings_id/edit`,
    name: `${CODE}.${ZONES_CODE}.${SETTINGS_CODE}.edit`,
    component: ZonesSettingsEdit,
    meta: {
      can: [`${AREAS_SETTINGS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${SETTINGS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${SETTINGS_CODE}.title`,
          },
          {
            title: ":settings_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/create`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create`,
    component: ZonesPricingRulesCreate,
    meta: {
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_PRICING_RULES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.index`,
    component: ZonesPricingRulesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show`,
    component: ZonesPricingRulesShow,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id/edit`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit`,
    component: ZonesPricingRulesEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/create`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.create`,
    component: ZonesShiftsCreate,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.show`,
    component: ZonesShiftsShow,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/edit`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.edit`,
    component: ZonesShiftsEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.index`,
    component: ZonesShiftsIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.index`,
    component: ZonesShiftsPricingRulesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show`,
    component: ZonesShiftsPricingRulesShow,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.show_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.show`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/create`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create`,
    component: ZonesShiftsPricingRulesCreate,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_add`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.create_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: `crud.create`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_PRICING_RULES_CODE}/:pricing_rule_id/edit`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit`,
    component: ZonesShiftsPricingRulesEdit,
    meta: {
      can: [`${AREAS_SHIFTS_PRICING_RULES_PERMISSION_PREFIX}_can_edit`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.edit_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_PRICING_RULES_CODE}.title`,
          },
          {
            title: ":pricing_rule_id",
          },
          {
            title: `crud.edit`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/${AREAS_SHIFTS_CODE}/${ZONES_SHIFTS_DASHBOARD_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${ZONES_SHIFTS_DASHBOARD_CODE}.index`,
    component: ZonesShiftsDashboardIndex,
    meta: {
      can: [`${ZONES_SHIFTS_DASHBOARD_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${ZONES_SHIFTS_DASHBOARD_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${ZONES_CODE}.title`,
          },
          {
            title: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${ZONES_SHIFTS_DASHBOARD_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${AREAS_SHIFTS_SUMMARIES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.index`,
    component: ZonesShiftsSummariesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_SUMMARIES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${AREAS_SHIFTS_SUMMARIES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${AREAS_SHIFTS_CODE}/:shift_id/${SHIFT_LATE_ATTENDANCES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.index`,
    component: ZonesShiftsLateAttendanceIndex,
    meta: {
      can: [`${SHIFT_LATE_ATTENDANCES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.title`,
          },
          {
            title: ":shift_id",
          },
          {
            title: `${ZONES_CODE}.${AREAS_SHIFTS_CODE}.${SHIFT_LATE_ATTENDANCES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${BRANCHES_TRACKING_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${BRANCHES_TRACKING_CODE}.index`,
    component: ZonesTrackingIndex,
    meta: {
      can: [`${ZONES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`, "g-sidenav-pinned"],
      addClass: [`g-sidenav-hidden`],
      title: `${ZONES_CODE}.${BRANCHES_TRACKING_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: ``,
        show: false,
        container_fluid: false,
        add_primary_color: false,
        items: [],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${BRANCHES_CODE}/:branch_id/${ATTENDANCES_CODE}`,
    name: `${CODE}.${BRANCHES_CODE}.${ATTENDANCES_CODE}.index`,
    component: BranchesAttendancesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_ATTENDANCES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${CODE}.${BRANCHES_CODE}.${ATTENDANCES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.title`,
          },
          {
            title: ":branch_id",
          },
          {
            title: `${CODE}.${BRANCHES_CODE}.${ATTENDANCES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${ZONES_CODE}/:zone_id/${ATTENDANCES_CODE}`,
    name: `${CODE}.${ZONES_CODE}.${ATTENDANCES_CODE}.index`,
    component: ZonesAttendancesIndex,
    meta: {
      can: [`${AREAS_SHIFTS_ATTENDANCES_PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      title: `${ZONES_CODE}.${ATTENDANCES_CODE}.list_title`,
      breadDetails: {
        title: `${CODE}.title`,
        index_route: `${CODE}.index`,
        show: true,
        items: [
          {
            title: ":id",
          },
          {
            title: `${ZONES_CODE}.title`,
          },
          {
            title: ":zone_id",
          },
          {
            title: `${ZONES_CODE}.${ATTENDANCES_CODE}.title`,
          },
          {
            title: `crud.list`,
          },
        ],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
