<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name"
                    >{{ $t(`fields.name`) }}
                    <small class="text-danger"
                      >* ({{ $t(`labels.required`) }})</small
                    ></label
                  >
                  <input
                    v-model="name"
                    id="name"
                    type="text"
                    class="form-control"
                    name="name"
                    v-bind:class="{ 'is-invalid': fieldErrors.name }"
                  />
                  <span
                    v-if="fieldErrors.name"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-top:39px">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="wallet_enabled">{{
                      $t(`fields.uses_wallet`)
                    }}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input
                        type="checkbox"
                        id="wallet_enabled"
                        v-model="usesWallet"
                        @change="changeWalletEnabledStatus(usesWallet)"
                      />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12">
                <maps-general
                  v-model="points"
                  :points="oldPoints"
                  :mode="map_mode"
                />
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link
                  :to="{ name: `${routePrefix}.index`, params: { id } }"
                  class="btn btn-neutral btn-sm"
                >
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button
                  v-if="isItemFound()"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  :disabled="loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_ZONES_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_ZONES_UPDATE as CRUD_ACTION_UPDATE,
  PLATFORMS_ZONES_SUPPLIER,
} from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.zones`;
export default {
  name: "PlatformZoneEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      zone_id: this.$router.currentRoute.params.zone_id,
      name: "",
      usesWallet: false,
      points: [],
      oldPoints: [],
      map_mode: "edit",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: "edit",
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(PLATFORMS_ZONES_SUPPLIER, {
          id: this.id,
          zone_id: this.zone_id,
        })
        .then((response) => {
          this.usesWallet = response?.data?.usesWallet;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
          id: this.zone_id,
        })
        .then((response) => {
          this.name = response.data.name;
          this.oldPoints = this.points = response.data.geom
            ? response.data.geom.coordinates[0][0]
            : [];
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    changeWalletEnabledStatus(usesWallet) {
      this.usesWallet = usesWallet;
    },
    prepareGeomRequestData() {
      let geom = {};
      if (this.points.length) {
        geom.geom = {
          type: "MultiPolygon",
          coordinates: [[this.points]],
        };
      }
      return geom;
    },
    prepareRequestData() {
      return {
        name: this.sanitizeField("name"),
        usesWallet: this.sanitizeField("usesWallet"),
        ...this.prepareGeomRequestData(),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          id: this.zone_id,
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.index`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
