import {
    CAPTAIN_WORKING_SUMMARIES_LIST as CRUD_ACTION_LIST,
    CAPTAIN_WORKING_SUMMARIES_CREATE as CRUD_ACTION_CREATE,
    CAPTAIN_WORKING_SUMMARIES_SHOW as CRUD_ACTION_SHOW,
    CAPTAIN_WORKING_SUMMARIES_UPDATE as CRUD_ACTION_UPDATE,
    CAPTAIN_WORKING_SUMMARIES_DELETE as CRUD_ACTION_DELETE,
} from "../actions/captain_working_summaries";
import axioses from "@/axios/config";
import qs from "qs"

const routePrefix='captains/working_summaries';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
      return new Promise((resolve, reject) => {
          axioses.base
              .post(`/${routePrefix}/create`, params)
              .then(response => {
                  resolve(response);
              })
              .catch(error => {
                  reject(error);
              });
      });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
