<template>
  <div class="">
    <button class="active-order-tracking-btn btn btn-success" @click.prevent="openSideMenu()">
      <span>
        <i class="fa fa-arrow-circle-left"></i>
      </span>
      {{ $t('labels.orders') }}
    </button>
    <nav class="navbar navbar-vertical pt-5 navbar-expand-xs navbar-light opened right-0 pb-0 component-sidenav" id="sidenav-active-order-tracking">
      <div class="scrollbar-inner bg-lighter">
        <div class="d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100">
          <div class="pt-3 mx-0 row flex-row-reverse w-100">
            <div class="col-6 p-0 text-right">
              <a href="#" class="text-success p-2"  @click.prevent="refreshList(true)" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.refresh')">
                <i class="fa fa-sync"></i>
              </a>
              <a href="#" class="p-2"  @click.prevent="showHideAdditionalFilters()" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.additional_filters')">
                <i class="fa fa-search-location"></i>
              </a>
              <a href="#" class="p-2"  @click.prevent="hideSideMenu()">
                <i class="fa fa-arrow-circle-right"></i>
              </a>
            </div>
            <div class="col-6">
              <div class="form-group position-relative search mr--4 mt--1 mb-2">
                <label for="filter_order_id" class="position-absolute left-2 top-1 opacity-7">
                  <i class="fa fa-search"></i>
                </label>
                <input  v-model="filters.order_id"
                        id="filter_order_id"
                        type="text"
                        class="form-control form-control-sm border-primary border-2"
                        name="filter_order_id"
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="showAdditionalFilters" class="bg-lighter pr-0 position-fixed top-5 left-0 w-100" style="z-index: 999999;box-shadow: 0px 11px 10px 0 #8898aa;">
          <hr class="my-2">
          <div class="row p-0 m-0">
            <div class="col-12">
              <div class="form-group">
                <label for="filter_captain_id">{{ $t(`fields.captain_id`)}}</label>
                <input  v-model="filters.captain_id"
                        id="filter_captain_id"
                        type="text"
                        class="form-control form-control-sm border-primary border-2"
                        name="filter_captain_id"
                >
              </div>
            </div>
            <div class="col-6">
              <button @click.stop="search()" class="text-white btn btn-block btn-sm btn-primary my-2">
                <span><i class="fas fa-search"></i></span>
                <span class="mr-2">
                  {{ $t('labels.search')}}
                </span>
              </button>
            </div>
            <div class="col-6 m-0">
              <button @click.stop="reset()" class="text-white btn btn-block btn-sm btn-primary my-2 mb-3">
                <span><i class="fas fa-sync"></i></span>
                <span class="mr-2">
                  {{ $t('labels.reset')}}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
            <div class="collapse navbar-collapse">
              <div v-if="loading" class="text-center position-fixed overflow-hidden bottom-0 top-0 mt-5 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal">
                <span class="spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"></span>
              </div>
              <!-- Nav items -->
              <ul class="navbar-nav">
                <li class="nav-item nav-link tracking-order-item mx-0 px-0 row" v-bind:class="{ 'active' : activeItem.id===item.id, 'too-late' : isTooLate(item.createdAt) }" v-for="item in getItemsByFilter()" :key="item.id" @click="clickItemHandler(item)">
                  <div class="col-8">
                    <h4 class="order-id mb-0 font-size-12">{{ item.backendId }}</h4>
                  </div>
                  <div class="col-4 text-right">
                    <span class="payment-type badge pb-1" v-bind:class="{ 'bg-danger text-white': item.paymentType == 'PREPAID', 'bg-green text-white': item.paymentType == 'CASH_ON_DELIVERY' }">
                      {{ item.paymentType }}
                    </span>
                  </div>
                  <div class="col-6 mt--1">
                    <span class="status font-size-12" v-bind:style="{ color: item.statusColor }">
                      <strong class="font-size-10"> {{ item.status }} </strong>
                    </span>
                  </div>
                  <div class="col-6 text-right mt--1">
                    <span class="total-distance font-size-10">
                      {{ item.totalDistance }}
                    </span>
                  </div>
                  <div class="col-8 text-left mt--1">
                    <span class="platform font-size-10">
                      {{ item.platform }} - {{ item.city }} - <span class="text-direction-ltr"> {{ item.createdAt | moment("hh:mm:ss A") }}</span>
                    </span>
                  </div>
                  <div class="col-4 text-right mt--1" v-if="item.captainId">
                    <span class="font-size-10">
                      <i class="fas fa-car text-blue"></i>
                      {{ item.captainId }}
                    </span>
                  </div>
                  <div class="" v-bind:class="{ 'col-6 pr-1 order-show-btn': item.canManuallyAssign, 'col-12': !item.canManuallyAssign }">
                    <a @click.stop="showItemHandler(item)" class="text-white btn btn-block btn-sm btn-primary mt-1 font-size-12" v-can:view="orderPermissionPrefix">
                      <span><i class="fas fa-eye"></i></span>
                      <span class="mr-2">
                        {{ $t('crud.show')}}
                      </span>
                    </a>
                  </div>
                  <div class="" v-bind:class="{ 'col-6 pl-1 order-manual-assign-btn': item.canManuallyAssign, 'col-12': !item.canManuallyAssign }">
                    <manual-assign-action class="font-size-12" v-model="item.canManuallyAssign" v-if="item.canManuallyAssign" :itemID="item.id" v-can:manually_assign="orderPermissionPrefix"/>
                  </div>
                </li>
                <li class="nav-item nav-link  mx-0 px-0 row"  v-if="getItemsByFilter().length===0">
                  <div class="col-12 text-center">
                    <h2 class="order-id"> {{ $t('messages.no_result_found')}} </h2>
                  </div>
                </li>
                <div v-observe-visibility="pagination" v-if="hasNextPage()"/>
              </ul>
            </div>
          </div>

      </div>
    </nav>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import $ from "jquery";
  import { PLATFORMS_BRANCHES_TRACKING_LIST as CRUD_ACTION_LIST} from 'actions/platforms';
  import manualAssignAction from "./../actions/manual_assign";
  const CRUD_CODE="tracking";
  const CRUD_PERMISSION_CODE=`${CRUD_CODE}`;
  const ORDER_CODE=`orders`;
  const ORDER_PERMISSION_CODE=`${ORDER_CODE}`;
  import { TRACKING_ADD_UPDATE_BUSY_CAPTAINS, TRACKING_SET_ACTIVE_ORDER, TRACKING_REFRESH_LIST } from "actions/sockets/tracking";
  import { CRUD_SEARCH_RESET } from "actions/crud";
  import { TRACKING_SET_SCOPED_CAPTAINS_ORDERS, TRACKING_RESET_SCOPED_CAPTAINS_ORDERS } from "actions/sockets/tracking";

  export default {
    name: "TrackingActiveOrders",
    components:{
      manualAssignAction
    },
    props: {},
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        orderRoutePrefix: ORDER_CODE,
        orderTransPrefix: ORDER_CODE,
        orderPermissionPrefix: ORDER_PERMISSION_CODE,
        id:this.$router.currentRoute.params.id,
        branch_id:this.$router.currentRoute.params.branch_id,
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0,
        currentPage:1,
        loading: false,
        activeItem:"",
        filters:{
          order_id:'',
        },
        showAdditionalFilters:false,
        currentDate: new Date(),
        clockIntervalID: ''
      }
    },
    watch:{
      items:function(newVal,oldVal){
        let busyCaptainIds=new Set();
        Object.keys(newVal).forEach(key=>{
          let item=newVal[key];
          if(item.captainId){
            busyCaptainIds.add(item.captainId);
          }
        });

        this.$store.commit(TRACKING_ADD_UPDATE_BUSY_CAPTAINS,{
          busyCaptains:busyCaptainIds
        });
      },
      getActiveOrder:function(newVal,oldVal){
        this.activeItem = newVal;
      },
      refreshListListener:function(newVal,oldVal){
        if(newVal.refresh && newVal.key !== "ACTIVE_LIST"){
          this.refreshList();
          this.$store.commit(TRACKING_REFRESH_LIST,{
            key: "ACTIVE_LIST",
            refresh: newVal.key==='ALL'
          });
        }
      }
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getActiveOrder", "refreshListListener"
      ])
    },
    mounted() {
      $('.scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome').scrollbar().scrollLock();
      this.refreshList();
      this.clockIntervalID = setInterval(this.getNow, 1000);
    },
    beforeDestroy() {
      clearInterval(this.clockIntervalID);
      this.clickItemHandler({});
      this.$store.commit(TRACKING_RESET_SCOPED_CAPTAINS_ORDERS);
    },
    methods: {
      openSideMenu() {
        $('#sidenav-active-order-tracking').removeClass('closed').addClass('opened');
      },
      hideSideMenu() {
        $('#sidenav-active-order-tracking').removeClass('opened').addClass('closed');
      },
      groupBy(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      },
      getList(clearList = false){
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_LIST,{
          platformId:this.id,
          branchId: this.branch_id,
          pageId: this.getCurrentPage(),
        }).then(response=>{
          if(clearList){
            this.items=[];
          }
          this.items=[...this.items,...response.data.orders];
          this.perPage=20;
          this.totalElements=15;
          this.lastPage=0;
          this.loading = false;

          const captainsOrders = this.groupBy(this.items, 'captainId');

          this.$store.commit(TRACKING_SET_SCOPED_CAPTAINS_ORDERS, captainsOrders);
        })
      },
      pagination(isVisible){
        if(this.hasNextPage() && isVisible){
          this.currentPage+=1;
          this.getList();
        }
      },
      getCurrentPage(){
        return this.currentPage-1;
      },
      getLastPage(){
        return this.lastPage-1;
      },
      hasNextPage(){
        return this.getCurrentPage() <this.getLastPage() && this.items.length>0;
      },
      refreshList(refreshOtherLists = false){
        this.clickItemHandler({});
        this.currentPage=1;
        this.getList(true);
        this.filters = this.clearFilters(this.filters);

        if(refreshOtherLists){
          this.$store.commit(TRACKING_REFRESH_LIST,{
            key: "ACTIVE_LIST",
            refresh: true
          });
        }
      },
      clickItemHandler(item){

        if(!item.ignoreDefault && this.activeItem.id === item.id && typeof this.activeItem.id !=="undefined") {
          this.clickItemHandler({});
        } else {
          this.activeItem=item;
          this.$store.commit(TRACKING_SET_ACTIVE_ORDER,{
            ...this.activeItem
          });
        }

      },
      showItemHandler(item){
        let routeData = this.$router.resolve({name: `${this.orderRoutePrefix}.show`, params: {id: item.id}});
        window.open(routeData.href, '_blank');
      },
      getItemsByFilter(){
        let items=this.items;
        Object.keys(this.filters).forEach(key=>{
          let filterValue=this.filters[key];
          if(filterValue){
            switch (key) {
              case 'order_id':
                items=items.filter(function(item){
                  return item.backendId.search(`${filterValue}`)!==-1;
                });
                break;
            }
          }
        });
        return items;
      },
      showHideAdditionalFilters(){
        this.showAdditionalFilters = !this.showAdditionalFilters;
      },
      search(){
        this.setSearchParams(this.filters, {}, {routeParams: this.getFilters(this.filters)});
        this.clickItemHandler({ignoreDefault:true});
      },
      reset(){
        this.$store.dispatch(CRUD_SEARCH_RESET);
        this.filters = this.clearFilters(this.filters);
        this.clickItemHandler({ignoreDefault:true});
      },
      getTimeDiff(startTime){
        let startDate = this.$moment(startTime),
            endDate   = this.$moment(this.currentDate);

        return this.$moment.duration(endDate.diff(startDate)).asMinutes();
      },
      isTooLate(startTime){
        return this.getTimeDiff(startTime) >= 45;
      },
      getNow: function() {
        this.currentDate = new Date();
      }
    }
  };
</script>
<style type="text/css"  scoped>
  .scrollbar-inner .scroll-element.scroll-y{
    background-color: #e5e5e594 !important;
    width: 8px !important;
    opacity: 1 !important;
  }
  .scrollbar-inner > .scroll-element .scroll-element_track, .scrollbar-inner > .scroll-element .scroll-bar{
    opacity: unset!important;
  }

  .loader.force-right-0{
    right:0!important;
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-9 {
    font-size: 9px;
  }

  .font-size-10 {
    font-size: 10px;
  }

</style>
