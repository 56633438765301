<template>
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-gradient-primary py-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <div class="container">
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12 px-5">
            <h1 class="text-lead text-white">
              {{ $t('page_unauthorized.title') }} 403.
              <a href="javascript:;" @click="logout" class="text-white-50">
                <span>{{ $t('labels.logout') }}</span>
              </a>
              {{ $t('labels.or') }}
              {{ $t('labels.click') }}
              <router-link :to="{ name: 'auth.login' }" class="text-white-50">{{ $t('labels.here') }}</router-link>
              {{ $t('page_not_found.go_to_home') }}.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "actions/auth";
export default {
  name: "PageUnauthorized",
  methods: {
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push({ name :'auth.login'}));
    }
  },
};
</script>

<style scoped></style>
