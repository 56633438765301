import Vue                    from "vue";
import App                    from "./App.vue";
import router                 from "./router";
import store                  from "./store";
import titleMixin             from './mixins/titleMixin'
import permissionMixin        from './mixins/permissionMixin'
import filterMixin            from './mixins/filterMixin'
import fieldMixin            from './mixins/fieldMixin'
import i18n                   from './i18n';
import VueObserveVisibility   from 'vue-observe-visibility'
import VueCountryCode         from "vue-country-code";
import Notifications          from 'vue-notification'
import VueNativeNotification  from 'vue-native-notification'
import VueGoogleCharts        from 'vue-google-charts'
import cloneDeepPlugin        from './plugins/cloneDeepPlugin'
import { VBTooltipPlugin }    from 'bootstrap-vue'
import 'sweetalert';
import "vue-multiselect/src/index";
import "bootstrap/dist/js/bootstrap.min"
import "./sass/argon.scss";
import "./sass/main.scss";
import "./filters/filters";
import "./directives/directives";
import "./prototypes/prototypes";
import "./components/global/global";



Vue.use(Notifications);

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
}); 

Vue.use(VueObserveVisibility);

Vue.use(require('vue-moment'));

Vue.use(VueCountryCode);

Vue.use(VueGoogleCharts);

Vue.mixin(titleMixin);

Vue.mixin(permissionMixin);

Vue.mixin(filterMixin);

Vue.mixin(fieldMixin);

Vue.use(VBTooltipPlugin);

Vue.use(cloneDeepPlugin);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
