import {
  CAPTAINS_REGISTRATION_REQUESTS_LIST as CRUD_ACTION_LIST,
  CAPTAINS_REGISTRATION_REQUESTS_CREATE as CRUD_ACTION_CREATE,
  CAPTAINS_REGISTRATION_REQUESTS_SHOW as CRUD_ACTION_SHOW,
  CAPTAINS_REGISTRATION_REQUESTS_UPDATE as CRUD_ACTION_UPDATE,
  CAPTAINS_REGISTRATION_REQUESTS_DELETE as CRUD_ACTION_DELETE,
  CAPTAINS_REGISTRATION_REQUESTS_UPDATE_STATUS as CRUD_ACTION_UPDATE_STATUS,
  CAPTAINS_REGISTRATION_REQUESTS_STATUS_LIST as STATUS_ACTION_LIST,
  CAPTAINS_REGISTRATION_REQUESTS_NOTES_LIST as NOTES_ACTION_LIST,
  CAPTAINS_REGISTRATION_REQUESTS_NOTES_CREATE as NOTES_ACTION_CREATE,
  CAPTAINS_REGISTRATION_REQUESTS_NOTES_TYPE_LIST as NOTES_ACTION_TYPE_LIST,
  CAPTAINS_REGISTRATION_REQUESTS_PREFERRED_VERTICAL_LIST as VERTICALS_ACTION_LIST,
} from "../actions/captains_registration_requests";
import axioses from "@/axios/config";
import qs from "qs";
const additionalHeaders = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
const routePrefix = "captains/requests";
const notesRoutePrefix = "notes";
const verticalsRoutePrefix = "verticals";

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .get(`/${routePrefix}`, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { skipNulls: true, indices: false });
          },
        })
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      let fData = new FormData();
      Object.keys(params).forEach((key) => {
        if (typeof params[key] !== "undefined" && params[key] !== null) {
          fData.append(key, params[key]);
        }
      });
      axioses.base
        .post(`/${routePrefix}/create`, fData, additionalHeaders)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.base
        .get(`/${routePrefix}/${params.id}`)
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.base
        .put(`/${routePrefix}/${params.id}`, { ...params.data })
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.base
        .delete(`/${routePrefix}/${params.id}`)
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.base
        .patch(`/${routePrefix}/${params.id}/${params.type}`, {
          ...params.data,
        })
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [STATUS_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      let response = {
        data: [
          "PENDING",
          "ACCEPTED",
          "REJECTED",
          "APPEALED",
          "ALREADY_REGISTERED",
          "BLOCKED",
        ],
      };
      resolve(response);
    });
  },
  [NOTES_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .get(
          `/${routePrefix}/${params.captainRegistrationId}/${notesRoutePrefix}`,
          { params }
        )
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [NOTES_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
        .post(
          `/${routePrefix}/${params.captainRegistrationId}/${notesRoutePrefix}/create`,
          params
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [NOTES_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .get(
          `/${routePrefix}/${params.captainRegistrationId}/${notesRoutePrefix}/types`,
          { params }
        )
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [VERTICALS_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .get(`/${routePrefix}/${verticalsRoutePrefix}`)
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
