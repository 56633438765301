import Index from "@/views/countries/cities/zones/index";
import Create from "@/views/countries/cities/zones/create";
import Show from "@/views/countries/cities/zones/show";
import Edit from "@/views/countries/cities/zones/edit";
import ConnectionShow from "@/views/countries/cities/zones/connections/show"
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='countries';
const PERMISSION_PREFIX='locals';
const CITIES_CODE='cities';
const ZONES_CODE='zones';
const CONNECTIONS_CODE='connections'
const routes = [
  {
    path: `${CODE}/:id/${CITIES_CODE}/:city_id/${ZONES_CODE}`,
    name:`${CODE}.${CITIES_CODE}.${ZONES_CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${ZONES_CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CITIES_CODE}.title`,
          },
          {
            'title': ':city_id',
          },
          {
            'title': `${ZONES_CODE}.title`,
          },
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CITIES_CODE}/:city_id/${ZONES_CODE}/create`,
    name:`${CODE}.${CITIES_CODE}.${ZONES_CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${ZONES_CODE}.create_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CITIES_CODE}.title`,
          },
          {
            'title': ':city_id',
          },
          {
            'title': `${ZONES_CODE}.title`,
          },
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CITIES_CODE}/:city_id/${ZONES_CODE}/:zone_id`,
    name:`${CODE}.${CITIES_CODE}.${ZONES_CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${ZONES_CODE}.show_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CITIES_CODE}.title`,
          },
          {
            'title': ':city_id',
          },
          {
            'title': `${ZONES_CODE}.title`,
          },
          {
            'title': ':zone_id',
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${CODE}/:id/${CITIES_CODE}/:city_id/${ZONES_CODE}/:zone_id/${CONNECTIONS_CODE}`,
    name:`${CODE}.${CITIES_CODE}.${ZONES_CODE}.${CONNECTIONS_CODE}.show`,
    component: ConnectionShow,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${ZONES_CODE}.connections_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CITIES_CODE}.title`,
          },
          {
            'title': ':city_id',
          },
          {
            'title': `${ZONES_CODE}.title`,
          },
          {
            'title': ':zone_id',
          },
          {
            'title': `Connections`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  
  {
    path: `${CODE}/:id/${CITIES_CODE}/:city_id/${ZONES_CODE}/:zone_id/edit`,
    name:`${CODE}.${CITIES_CODE}.${ZONES_CODE}.edit`,
    component: Edit,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${ZONES_CODE}.edit_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': ':id',
          },
          {
            'title': `${CITIES_CODE}.title`,
          },
          {
            'title': ':city_id',
          },
          {
            'title': `${ZONES_CODE}.title`,
          },
          {
            'title': ':zone_id',
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
