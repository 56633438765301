import Vue from "vue";
import {Loader} from "google-maps";

Vue.prototype.$google=  getGoogle();

function getGoogle(){
  const LoaderOptions = {
    libraries:['drawing', 'visualization']
  };
  const loader = new Loader(process.env.VUE_APP_GOOGLE_MAP_API_KEY,LoaderOptions);
  return loader.load();
}