<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="external_id"
                    >{{ $t(`fields.external_id`) }}
                    <small class="text-danger"
                      >* ({{ $t(`labels.required`) }})</small
                    ></label
                  >
                  <input
                    v-model="external_id"
                    id="external_id"
                    type="text"
                    class="form-control"
                    name="external_id"
                    v-bind:class="{ 'is-invalid': fieldErrors.external_id }"
                  />
                  <span
                    v-if="fieldErrors.external_id"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.external_id }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name"
                    >{{ $t(`fields.name`) }}
                    <small class="text-danger"
                      >* ({{ $t(`labels.required`) }})</small
                    ></label
                  >
                  <input
                    v-model="name"
                    id="name"
                    type="text"
                    class="form-control"
                    name="name"
                    v-bind:class="{ 'is-invalid': fieldErrors.name }"
                  />
                  <span
                    v-if="fieldErrors.name"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="latitude"
                    >{{ $t(`fields.latitude`) }}
                    <small class="text-danger"
                      >* ({{ $t(`labels.required`) }})</small
                    ></label
                  >
                  <a
                    href="#"
                    class="mx-1 text-blue"
                    @click.prevent="showPickupMapPicker"
                  >
                    <i class="fa fa-map-pin "></i>
                  </a>
                  <input
                    class="form-control"
                    id="latitude"
                    name="latitude"
                    type="number"
                    step="any"
                    v-model="latitude"
                    v-bind:class="{ 'is-invalid': fieldErrors.latitude }"
                  />
                  <span
                    v-if="fieldErrors.latitude"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.latitude }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="longitude"
                    >{{ $t(`fields.longitude`) }}
                    <small class="text-danger"
                      >* ({{ $t(`labels.required`) }})</small
                    ></label
                  >
                  <a
                    href="#"
                    class="mx-1 text-blue"
                    @click.prevent="showPickupMapPicker"
                  >
                    <i class="fa fa-map-pin "></i>
                  </a>
                  <input
                    class="form-control"
                    id="longitude"
                    name="longitude"
                    type="number"
                    step="any"
                    v-model="longitude"
                    v-bind:class="{ 'is-invalid': fieldErrors.longitude }"
                  />
                  <span
                    v-if="fieldErrors.longitude"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.longitude }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="phone">{{ $t(`fields.phone`) }}</label>
                  <input
                    v-model="phone"
                    id="phone"
                    type="text"
                    class="form-control"
                    name="phone"
                    v-bind:class="{ 'is-invalid': fieldErrors.phone }"
                  />
                  <span
                    v-if="fieldErrors.phone"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.phone }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="country_id">{{ $t(`fields.country`) }}</label>
                  <select-countries
                    v-model="country_id"
                    :error="fieldErrors.countryId"
                  />
                  <span
                    v-if="fieldErrors.countryId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.countryId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="is_loaded">
                <div class="form-group">
                  <label for="city_id">{{ $t(`fields.city`) }}</label>
                  <select-cities
                    v-model="city_id"
                    :country_id="country_id"
                    id="city_id"
                    :disabled="isCityDisabled()"
                    :error="fieldErrors.cityId"
                  />
                  <span
                    v-if="fieldErrors.cityId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.cityId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="is_loaded">
                <div class="form-group ">
                  <label for="zone_id">{{ $t(`fields.zone`) }}</label>
                  <select-zones
                    v-model="zone_id"
                    :city_id="city_id"
                    id="zone_id"
                    :disabled="isZoneDisabled()"
                    :error="fieldErrors.zoneId"
                  />
                  <span
                    v-if="fieldErrors.zoneId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.zoneId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-top:39px">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="wallet_enabled">{{
                      $t(`fields.uses_wallet`)
                    }}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input
                        type="checkbox"
                        id="wallet_enabled"
                        v-model="usesWallet"
                        @change="changeWalletEnabledStatus(usesWallet)"
                      />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group ">
                  <label for="description">{{
                    $t(`fields.description`)
                  }}</label>
                  <textarea
                    v-model="description"
                    class="form-control"
                    id="description"
                    name="description"
                    rows="5"
                    v-bind:class="{ 'is-invalid': fieldErrors.description }"
                  ></textarea>
                  <span
                    v-if="fieldErrors.description"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.description }}</strong>
                  </span>
                </div>
              </div>
              <div
                v-if="pickupMapPicker"
                class="col-12 position-fixed top-9 left-0 p-5"
                style="z-index: 99999"
              >
                <button
                  class="btn btn-danger btn-sm"
                  @click.prevent="hidePickupMapPicker"
                >
                  {{ $t(`labels.close`) }}
                  <i class="fa fa-window-close"></i>
                </button>
                <maps-pick-address
                  :position="{ lat: latitude, lng: longitude }"
                  v-model="pickupMarkerPosition"
                />
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link
                  :to="{ name: `${routePrefix}.index`, params: { id } }"
                  class="btn btn-neutral btn-sm"
                >
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button
                  v-if="isItemFound()"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  :disabled="loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_BRANCHES_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_BRANCHES_UPDATE as CRUD_ACTION_UPDATE,
  PLATFORMS_BRANCHES_SUPPLIER,
} from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.branches`;
export default {
  name: "PlatformBranchEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      branch_id: this.$router.currentRoute.params.branch_id,
      phone: "",
      external_id: "",
      name: "",
      usesWallet: false,
      longitude: "",
      latitude: "",
      description: "",
      pickupMapPicker: false,
      pickupMarkerPosition: {},
      country_id: "",
      city_id: "",
      zone_id: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: "edit",
      loading: false,
      is_loaded: false,
    };
  },
  mounted() {
    this.getItem();
  },
  watch: {
    pickupMarkerPosition: function(newVal, oldVal) {
      this.latitude = newVal.lat;
      this.longitude = newVal.lng;
    },
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(PLATFORMS_BRANCHES_SUPPLIER, {
          platformId: this.id,
          id: this.branch_id,
        })
        .then((response) => {
          this.usesWallet = response?.data?.usesWallet;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
          id: this.branch_id,
        })
        .then((response) => {
          this.latitude = response.data.latitude;
          this.name = response.data.name;
          this.longitude = response.data.longitude;
          this.description = response.data.description;
          this.phone = response.data.phone;
          this.external_id = response.data.externalId;
          this.zone_id = response.data.zone?.id;
          this.city_id = response.data.zone?.city?.id;
          this.country_id = response.data.zone?.city?.countryId;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    changeWalletEnabledStatus(usesWallet) {
      this.usesWallet = usesWallet;
    },
    prepareRequestData() {
      return {
        phone: this.sanitizeField("phone"),
        externalId: this.sanitizeField("external_id"),
        name: this.sanitizeField("name"),
        usesWallet: this.sanitizeField("usesWallet"),
        longitude: this.sanitizeField("longitude"),
        latitude: this.sanitizeField("latitude"),
        description: this.sanitizeField("description"),
        zoneId: this.sanitizeField("zone_id"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          id: this.branch_id,
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.index`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    hidePickupMapPicker() {
      this.pickupMapPicker = false;
    },
    showPickupMapPicker() {
      this.pickupMapPicker = true;
    },
    isCityEnabled() {
      return this.country_id;
    },
    isCityDisabled() {
      return !this.isCityEnabled();
    },
    isZoneEnabled() {
      return this.city_id;
    },
    isZoneDisabled() {
      return !this.isZoneEnabled();
    },
  },
};
</script>
