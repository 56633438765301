<template>
    <div class="card-header border-0">
        <div class="row justify-content-end">
            <div class="col">
                <h3 class="mb-0"> {{ $t(title) }} <span class="text-warning">{{ $t(hint) }}</span></h3>
            </div>
            <div class="col text-right">
                <slot/>
            </div>
        </div>
        <slot name="search"></slot>
    </div>
</template>


<script>
    import { mapGetters, mapState } from "vuex";
    export default {
        props: {
            title: {
                type: String
            },
            hint: {
                type: String,
                default:""
            },
            withSearch:{
                type: Boolean,
                default:false,
            },
                // filtersIsEmpty:{
                //     type:Boolean,
                //     default:true
                // },
            searchHandler:{
                type: Function,
                default: () => { }
            },
            resetSearchHandler:{
                type: Function,
                default: () => { }
            }
        },
        name: "CardHeader",
        data(){
            return {

            }
        },
        methods:{
            resetSearch(event){
                this.searchHandler(event,true);
            }
        },
        computed: {
            ...mapGetters(["filtersIsEmpty", "getSearchFilters","isSearchShow"]),
        }

    }
</script>

<style scoped>

</style>
