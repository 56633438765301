<template>
    <div :id="id" class="google-maps"></div>
</template>

<script>
    export default {
        name: "FieldsMapsGeneral",
        props: {
          points:{
              type:Array,
              default:function(){
                  return [];
              }
          },
          centerPosition:{
            type:Object,
            default:function(){
                return {lat: 24.7253981, lng: 46.3398977};
            }
          },
          zoom:{
            type:Number,
            default:6,
          },
          mode:{
            type:String,
            default:'create'
          },
          id: {
            type:String,
            default:'map'
          },
          error:{
            type:String
          }
        },
        data(){
            return {
                map:null,
                drawingManager:null,
                selectedShape:null,
                google:null,
                options:{
                    libraries:[],
                }
            }
        },
        mounted() {
            this.prepareOptions();
            this.initMap();
        },
        watch: {
            points: function(newVal, oldVal) {
                this.setMapCenter();
                this.deleteSelectedShape();
                this.drawingShapeForCurrentPoints();
                if(this.points.length){
                    this.setDrawingMode();
                }
            }
        },
        methods: {
            initMap(){

                this.$google.then(google=>{
                    this.google=google;
                    this.map = new this.google.maps.Map(document.getElementById(this.id), {
                        center: this.getCenterPosition(),
                        zoom: this.zoom,
                    });

                    if(this.hasDrawingLibrary()){
                        this.addDrawingManagerToMap();
                    }
                    this.drawingShapeForCurrentPoints();
                    // Define the LatLng coordinates for the outer path.

                }).catch(error=>{});
            },
            setMapCenter(){
              this.map.setCenter(this.getCenterPosition());
            },
            getCenterPosition(){
                if(this.points.length){
                    let max_x=Math.max.apply(Math, this.points.map(function(point) { return point[1]; }));
                    let min_x=Math.min.apply(Math, this.points.map(function(point) { return point[1]; }));

                    let max_y=Math.max.apply(Math, this.points.map(function(point) { return point[0]; }));
                    let min_y=Math.min.apply(Math, this.points.map(function(point) { return point[0]; }));

                    return {lat:(max_x+min_x)/2,lng:(max_y+min_y)/2};
                }
                return this.centerPosition;
            },
            prepareOptions(){
               if(['create','edit'].includes(this.mode)){
                   this.options.libraries.push('drawing');
               }
           },
           hasDrawingLibrary(){
                return this.options.libraries.includes('drawing');
           },
           getDefaultDrawingMode(){
                let drawingMode=null;
                if(['create','edit'].includes(this.mode)){
                    drawingMode= this.google.maps.drawing.OverlayType.POLYGON;
                }
             return drawingMode;
           },
           setDrawingMode(type=null){
             if(this.hasDrawingLibrary()){
                 this.drawingManager.setDrawingMode(null);
             }
           },
           addDrawingManagerToMap(){
               this.drawingManager = new this.google.maps.drawing.DrawingManager({
                   drawingMode: this.getDefaultDrawingMode(),
                   drawingControl: true,
                   drawingControlOptions: {
                       position: this.google.maps.ControlPosition.TOP_CENTER,
                       drawingModes: [
                           this.google.maps.drawing.OverlayType.POLYGON,
                       ],
                   },
                   polygonOptions: {
                       editable: true,
                       draggable:true
                   },
               });
               this.drawingManager.setMap(this.map);
               this.google.maps.event.addListener(this.drawingManager, 'overlaycomplete',this.onCompleteDrawingShape);
           },
           drawingShapeForCurrentPoints(){
                if(this.points.length){
                   // Define the LatLng coordinates for the polygon.
                   let shapeCoords = [];

                   this.points.map(function(point, key) {
                       shapeCoords.push({lat:point[1],lng:point[0]});
                   });

                   // Construct the polygon.
                   const selectedShape = new this.google.maps.Polygon({
                       paths: shapeCoords,
                       editable: this.hasDrawingLibrary()?true:false,
                       draggable:this.hasDrawingLibrary()?true:false
                   });

                   selectedShape.setMap(this.map);
                   this.addEventsToShape(selectedShape);
                }
           },
            addEventsToShape(shape){
                this.google.maps.event.addListener(shape.getPath(), 'set_at',this.onUpdateShape);
                this.google.maps.event.addListener(shape.getPath(), 'insert_at',this.onUpdateShape);
                this.google.maps.event.addListener(shape.getPath(), 'remove_at',this.onUpdateShape);
                this.selectedShape=shape;
            },
            deleteSelectedShape(){
                if(this.selectedShape){
                    this.selectedShape.setMap(null);
                    this.selectedShape = null;
                }
            },
            setGeomPointsOfShape(){
                let vertices=this.selectedShape.getPath(), points=[],geomPoints=[],firstPoint=[];
                // Iterate over the vertices.
                vertices.forEach(function(vertex,index){
                    if(index===0){
                        firstPoint=[vertex.lng(),vertex.lat()];
                    }
                    points.push({x:vertex.lat(),y:vertex.lng()});
                    geomPoints.push([vertex.lng(),vertex.lat()]);
                });
                geomPoints.push(firstPoint);

                this.$emit('input', geomPoints);
            },
            onUpdateShape(){
                this.setGeomPointsOfShape();
            },
            onCompleteDrawingShape(event){
                //Delete Selected Shape if Exists
                this.deleteSelectedShape();

                this.addEventsToShape(event.overlay);
                this.setGeomPointsOfShape();

                //Set Drawing Mode To null
                this.setDrawingMode();
            }
        }
    }
</script>

<style scoped>

</style>