import {
    PROFILE_ME as PROFILE_ME,
    PROFILE_ME_EDIT as PROFILE_ME_EDIT,
    PROFILE_CHANGE_PASSWORD as PROFILE_CHANGE_PASSWORD,
} from "../actions/profile";
import axioses from "@/axios/config";
import i18n from "@/i18n";

const additionalHeaders={
  headers: {
      'Content-Type': 'multipart/form-data'
  }
};

const routePrefix='users';

const state = {};

const getters = {};

const actions = {
  [PROFILE_ME]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', null, { root: true });
      axioses.base
          .get(`/${routePrefix}/me`)
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', null, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', null, { root: true });
            reject(error);
          });
    });
  },
  [PROFILE_ME_EDIT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });

        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });

        axioses.base
            .put(`/${routePrefix}/update-profile`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PROFILE_CHANGE_PASSWORD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      if(params.newPassword!==params.newPasswordConfirmed){
          reject({
              response:{
                  data:{
                      errors:{
                          newPasswordConfirmed:i18n.t('validation.password_confirmed')
                      }
                  }
              }
          });
      }else{
          axioses.base
              .post(`/${routePrefix}/change-password`, params)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
      }
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
