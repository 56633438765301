<template>
  <span>
    {{prefix}} {{ hours | twoDigits }}:{{ minutes | twoDigits }}:{{ seconds | twoDigits }}
  </span>
</template>

<script>
export default {
  name: "DigitalClock",
  props: {
    date: {
      type: String
    },
    prefix: {
      type: String
    }
  },
  created() {
    this.interval = setInterval(() => this.getDateDiff(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
      interval: null
    };
  },
  methods: {
    getDateDiff() {
      if (this.date) {
        // Defining current moment and end date
        let now = this.$moment();
        let end = this.$moment(this.date);

        // Getting the difference in hours, minutes and seconds
        let hours = now.diff(end, "hours");
        let minutes =  now.diff(end, "minutes") - (60 * hours);
        let seconds = now.diff(end, "seconds") - ((60 * 60 * hours) + (60 * minutes));

        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }
    }
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    }
  }
};
</script>

