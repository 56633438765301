<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title">
          <router-link
            v-if="!serverError"
            :to="{ name: `${routePrefix}.edit`, params: { id } }"
            class="btn btn-primary btn-sm"
            v-can:edit="permissionPrefix"
          >
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="perTask">{{ $t(`${transPrefix}.fields.per_task`) }}</label>
                <input
                  v-model="perTask"
                  id="perTask"
                  type="text"
                  class="form-control"
                  name="perTask"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="kmStartAfter">{{ $t(`${transPrefix}.fields.km_start_after`) }}</label>
                <input
                  v-model="kmStartAfter"
                  id="kmStartAfter"
                  type="text"
                  class="form-control"
                  name="kmStartAfter"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="perKm">{{ $t(`${transPrefix}.fields.per_km`) }}</label>
                <input
                  v-model="perKm"
                  id="perKm"
                  type="text"
                  class="form-control"
                  name="perKm"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="maxOrderAmount">{{ $t(`${transPrefix}.fields.max_order_amount`) }}</label>
                <input
                  v-model="maxOrderAmount"
                  id="maxOrderAmount"
                  type="text"
                  class="form-control"
                  name="maxOrderAmount"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link
                :to="{ name: `platforms.index` }"
                class="btn btn-neutral btn-sm"
              >
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PLATFORMS_CONFIGS_SHOW as CRUD_ACTION_SHOW } from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.configs`;
const CRUD_PERMISSION_CODE = `platform_configs`;
export default {
  name: "PlatformConfigShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      perTask: "",
      kmStartAfter: "",
      maxOrderAmount: "",
      perKm: "",
      serverError: "",
      responseStatus: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id
        })
        .then((response) => {
          this.perTask = response.data.data.perTask;
          this.kmStartAfter = response.data.data.kmStartAfter;
          this.maxOrderAmount = response.data.data.maxOrderAmount;
          this.perKm = response.data.data.perKm;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
