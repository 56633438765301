<template>
    <multiselect v-bind:class="{'is-invalid':error}" :id="id" :disabled="disabled" track-by="id" v-model="selectedValue" @open="open" @input="updateValue"  :searchable="true" :custom-label="customLabel" :close-on-select="true" :show-labels="false" :placeholder="$t(placeholder)" open-direction="bottom" :options="items" :loading="isLoading" :internal-search="false" :hide-selected="false" :clear-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="true" @search-change="getList">
        <template slot="option" slot-scope="props">{{ customLabel(props.option) }}</template>
        <span slot="noResult">{{ $t(noResultMessage) }}</span>
        <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="selectedValue" @mousedown.prevent.stop="clearSelected(props.search)"></div>
        </template>
    </multiselect>
</template>


<script>
    import { BRANCHES_LIST as CRUD_ACTION_LIST } from 'actions/platforms_portal/branches';
    export default {
        name: "FieldsSearchPlatformBranches",
        props: {
            value:{},
            id: {
                type:String,
                default:'platform_branch_id'
            },
            disabled:{
                type:Boolean,
                default:false,
            },
            placeholder:{
                type:String,
                default:'placeholders.dropdown',
            },
            noResultMessage:{
                type:String,
                default:'messages.no_result_found',
            },
            error:{
                type:String
            },
        },
        data(){
            return {
                currentPage:1,
                items: [],
                perPage:20,
                totalElements:0,
                lastPage:0,
                selectedValue:null,
                isLoading: false,
            }
        },
        methods: {
            getList(query){
                if(query!==""){
                    this.isLoading = true;
                    this.$store.dispatch(CRUD_ACTION_LIST,{
                        pageId: this.getCurrentPage(),
                        name:query,
                        withLoading:false,
                    }).then(response=>{
                        this.items=response.data.data;
                        this.perPage=response.data.perPage;
                        this.totalElements=response.data.totalElements;
                        this.lastPage=response.data.pageCount;
                        this.isLoading = false;
                    });
                }
            },
            getCurrentPage(){
                return this.currentPage-1;
            },
            clearSelected(){
                this.selectedValue=null;
                this.$emit('input', null);
            },
            updateValue(value){
                this.$emit('input', value);
            },
            customLabel(value){
                return this.$i18n.locale=='ar'?value.nameAr:value.name;
            },
            open(){
                if(this.items.length===0){
                    this.getList();
                }
            }
        }
    }
</script>
