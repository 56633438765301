import Index from "@/views/suppliers_portal/captain_registration_requests/index";
import Show from "@/views/suppliers_portal/captain_registration_requests/show";
import Edit from "@/views/suppliers_portal/captain_registration_requests/edit";

import {commonBeforeEnter, ifAuthenticated} from "../../helper"

const URL_PREFIX='suppliers_portal/captain_registration_requests';
const CODE='suppliers_portal.captain_registration_requests';
const TRANS_CODE='captain_registration_requests';
// const PERMISSION_PREFIX='captain_registration_requests';

const routes = [
  {
    path: `${URL_PREFIX}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      // 'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.list_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      // 'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.show_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/:id`,
    name:`${CODE}.edit`,
    component: Edit,
    meta: {
      // 'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.show_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
