<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${shiftsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.shiftId }} </td>
            <td> {{ item.captainId }} </td>
            <td> {{ item.type }} </td>
            <td> {{ item.delayMinutes }} </td>
            <td> {{ item.action }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { SHIFT_LATE_ATTENDANCES as CRUD_ACTION_LIST} from 'actions/platforms';
  const PLATFORMS_CRUD_CODE="platforms";
  const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
  const SHIFTS_CRUD_CODE=`${ZONES_CRUD_CODE}.shifts`;
  const CRUD_CODE=`${SHIFTS_CRUD_CODE}.late_attendances`;
  const CRUD_TRANS_CODE=`${CRUD_CODE}`;
  const CRUD_PERMISSION_CODE=`platform_area_shifts`;
  export default {
    name: "PlatformZoneShiftLateAttendanceIndex",
    components:{
    },
    data(){
      return {
        routePrefix: `${CRUD_CODE}`,
        transPrefix: `${CRUD_TRANS_CODE}`,
        permissionPrefix: CRUD_PERMISSION_CODE,
        shiftsRoutePrefix: SHIFTS_CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        zone_id:this.$router.currentRoute.params.zone_id,
        shift_id:this.$router.currentRoute.params.shift_id,
        tableHeaders:[
          {
             'title':`fields.id`
          },
          {
             'title':`fields.shift_id`
          },
          {
            'title':`fields.captain_id`
          },
          {
            'title':`fields.type`
          },
          {
            'title':`fields.delay`
          },
          {
            'title':`fields.action`
          },  
          {
            'title':`fields.created_at`
          },  
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          platform_id:this.id,
          area_type: "ZONE",
          area_id: this.zone_id,
          shift_id: this.shift_id,
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
