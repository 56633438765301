<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="external_id">{{ $t(`fields.external_id`) }}</label>
                <input
                  v-model="external_id"
                  id="external_id"
                  type="text"
                  class="form-control"
                  name="external_id"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="name">{{ $t(`fields.name`) }}</label>
                <input
                  v-model="name"
                  id="name"
                  type="text"
                  class="form-control"
                  name="name"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="latitude">{{ $t(`fields.latitude`) }}</label>
                <input
                  class="form-control"
                  id="latitude"
                  name="latitude"
                  type="text"
                  v-model="latitude"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="longitude">{{ $t(`fields.longitude`) }}</label>
                <input
                  class="form-control"
                  id="longitude"
                  name="longitude"
                  type="text"
                  v-model="longitude"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="phone">{{ $t(`fields.phone`) }}</label>
                <input
                  v-model="phone"
                  id="phone"
                  type="text"
                  class="form-control"
                  name="phone"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="country_id">{{ $t(`fields.country`) }}</label>
                <select-countries v-model="country_id" :disabled="true" />
              </div>
            </div>
            <div class="col-12 col-md-6" v-if="is_loaded">
              <div class="form-group ">
                <label for="city_id">{{ $t(`fields.city`) }}</label>
                <select-cities
                  v-model="city_id"
                  :country_id="country_id"
                  id="city_id"
                  :disabled="isCityDisabled()"
                />
              </div>
            </div>
            <div class="col-12 col-md-6" v-if="is_loaded">
              <div class="form-group ">
                <label for="zone_id">{{ $t(`fields.zone`) }}</label>
                <select-zones
                  v-model="zone_id"
                  :city_id="city_id"
                  id="zone_id"
                  :disabled="isZoneDisabled()"
                />
              </div>
            </div>
            <div class="col-12 col-md-6" style="margin-top:39px">
              <div class="form-group row">
                <div class="col-8">
                  <label for="wallet_enabled">{{
                    $t(`fields.uses_wallet`)
                  }}</label>
                </div>
                <div class="col-4 text-right">
                  <label class="custom-toggle">
                    <input
                      type="checkbox"
                      id="wallet_enabled"
                      v-model="usesWallet"
                      disabled
                    />
                    <span
                      class="custom-toggle-slider rounded-circle"
                      :data-label-off="$t('labels.no')"
                      :data-label-on="$t('labels.yes')"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group ">
                <label for="description">{{ $t(`fields.description`) }}</label>
                <textarea
                  v-model="description"
                  class="form-control"
                  id="description"
                  name="description"
                  rows="5"
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link
                :to="{ name: `${routePrefix}.index`, params: { id } }"
                class="btn btn-neutral btn-sm"
              >
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_BRANCHES_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_BRANCHES_SUPPLIER,
} from "actions/platforms";

const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.branches`;
const CRUD_PERMISSION_CODE = `platform_branches`;
export default {
  name: "PlatformBranchShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      branch_id: this.$router.currentRoute.params.branch_id,
      latitude: "",
      longitude: "",
      name: "",
      usesWallet: false,
      phone: "",
      external_id: "",
      description: "",
      country_id: "",
      city_id: "",
      zone_id: "",
      serverError: "",
      responseStatus: "",
      is_loaded: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(PLATFORMS_BRANCHES_SUPPLIER, {
          platformId: this.id,
          id: this.branch_id,
        })
        .then((response) => {
          this.usesWallet = response?.data?.usesWallet;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
          id: this.branch_id,
        })
        .then((response) => {
          this.latitude = response.data.latitude;
          this.name = response.data.name;
          this.longitude = response.data.longitude;
          this.description = response.data.description;
          this.phone = response.data.phone;
          this.external_id = response.data.externalId;
          this.country_id = response.data.zone?.city?.countryId;
          this.city_id = response.data.zone?.city?.id;
          this.zone_id = response.data.zone?.id;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    isCityEnabled() {
      return false;
    },
    isCityDisabled() {
      return !this.isCityEnabled();
    },
    isZoneEnabled() {
      return false;
    },
    isZoneDisabled() {
      return !this.isZoneEnabled();
    },
  },
};
</script>
