<template>
  <div :id="id" class="google-maps"></div>
</template>

<script>
export default {
  name: "FieldsMapsAssignments",
  props: {
    centerPosition: {
      type: Object,
      default: function() {
        return { lat: 24.7253981, lng: 46.3398977 };
      }
    },
    zoom: {
      type: Number,
      default: 6
    },
    id: {
      type: String,
      default: "map"
    },
    error: {
      type: String
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      map: null,
      google: null,
      points: [],
      markers: {},
      options: {
        libraries: []
      }
    };
  },
  mounted() {
    this.initMap();
  },
  watch: {
    items: function(newVal, oldVal) {
      this.prepareItems();
    }
  },
  methods: {
    initMap() {
      this.$google
        .then(google => {
          this.google = google;
          this.map = new this.google.maps.Map(
            document.getElementById(this.id),
            {
              center: this.getCenterPosition(),
              zoom: this.zoom
            }
          );
          if (this.items.length) {
            this.prepareItems();
          }
        })
        .catch(error => {});
    },
    setMapCenter() {
      this.map.setCenter(this.getCenterPosition());
    },
    getCenterPosition() {
      if (this.points.length) {
        let max_x = Math.max.apply(
          Math,
          this.points.map(function(point) {
            return point[1];
          })
        );
        let min_x = Math.min.apply(
          Math,
          this.points.map(function(point) {
            return point[1];
          })
        );

        let max_y = Math.max.apply(
          Math,
          this.points.map(function(point) {
            return point[0];
          })
        );
        let min_y = Math.min.apply(
          Math,
          this.points.map(function(point) {
            return point[0];
          })
        );

        return { lat: (max_x + min_x) / 2, lng: (max_y + min_y) / 2 };
      }
      return this.centerPosition;
    },
    addMarker(options) {
      let marker = new this.google.maps.Marker({
        position: { lat: options.latitude, lng: options.longitude },
        map: this.map,
        icon: this.getIconByType(options.iconColor, options.type)
      });

      if (options.label) {
        marker = this.setMarkerMouseoverHandler(marker, options.label);
      }
      if (options.clickHandler) {
        marker = this.setMarkerClickHandler(marker, options.clickHandler);
      }

      this.markers[options.id] = marker;
    },
    getIconByType(iconColor, type) {
      let icons = {
        STORE: {
          url: require("@/assets/theme/icons/PICK_UP.png"),
          scaledSize: new google.maps.Size(50, 50),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0)
        },
        FIRST_ASSIGNED: {
          path: "m 12 2 c -4.4 0 -8 3.6 -8 8 c 0 6.8 8 14 8 14 s 8 -7.3 8 -14 c 0 -4.4 -3.6 -8 -8 -8 z m 0 11.5 z",
          fillColor: iconColor || "#F44336",
          fillOpacity: 0.6,
          scale: 1.5
        },
        DEFAULT: {
          path: "M12 2c-4.42 0-8 3.58-8 8 0 6.75 8 14 8 14s8-7.25 8-14c0-4.42-3.58-8-8-8zm0 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
          fillColor: iconColor || "#F44336",
          fillOpacity: 0.6,
          scale: 1.5
        }
      };
      return icons[type] ?? icons["DEFAULT"];
    },
    setPoints() {
      let points = [];
      Object.keys(this.items).forEach(key => {
        let item = this.items[key];
        points.push({
          0: item.longitude,
          1: item.latitude
        });
      });

      this.points = points;
    },
    deleteMarkers() {
      this.setMapOnAll(null);
      this.markers = {};
    },
    addMarkers() {
      Object.keys(this.items).forEach(key => {
        this.addMarker(this.items[key]);
      });
    },
    setMarkerClickHandler(marker, clickHandler) {
      this.google.maps.event.addListener(
        marker,
        "click",
        (function(clickHandler) {
          return function () {
            clickHandler();
          };
        })(clickHandler)
      );

      return marker;
    },
    setMarkerMouseoverHandler(marker, content) {
      let infoWindow = new google.maps.InfoWindow({
        content: `${content}`,
        disableAutoPan: true
      });
      this.google.maps.event.addListener(
        marker,
        "mouseover",
        (function(marker) {
          return function() {
            infoWindow.open(this.map, marker);
          }
        })(marker));

      this.google.maps.event.addListener(
        marker,
        "mouseout",
        (function() {
          return function() {
            infoWindow.close();
          };
        })(marker));

      return marker;
    },
    setMapZoom(zoom) {
      if (this.map) {
        this.map.setZoom(zoom ? zoom : this.zoom);
      }
    },
    setMapOnAll(map) {
      Object.keys(this.markers).forEach(key => {
        this.markers[key].setMap(map);
      });
    },
    prepareItems() {
      this.deleteMarkers();
      this.setPoints();
      this.addMarkers();
      this.setMapCenter();
      this.setMapZoom(13);
    }
  }
};
</script>

<style scoped></style>
