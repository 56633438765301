<template>
    <abstract-dropdown-api
            v-model="selectedValue"
            :error="error"
            :id="id"
            :disabled="disabled"
            :multiple="multiple"
            :placeholder="placeholder"
            :noResultMessage="noResultMessage"
            :allow-empty="allowEmpty"
            :action="action"
			:params="params"
            :customLabelHandler="customLabel"
            :prepareResponseDataHandler="prepareResponseData"
    />
</template>


<script>
    import {BANKS_LIST as CRUD_ACTION_LIST} from 'actions/banks';

    export default {
        name: "FieldsDropdownBanks",
        props: {
            value: {},
            id: {
                type: String,
                default: 'bank_id'
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'placeholders.dropdown',
            },
            noResultMessage: {
                type: String,
                default: 'messages.no_result_found',
            },
            error: {
                type: String
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            params: {
                type: Object,
                default: () => {
                }
            },
            action: {
                type: String,
                default: CRUD_ACTION_LIST
            },
        },
        data() {
            return {
                selectedValue: this.value,
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            customLabel(value) {
                return `${value.name}`;
            },
            prepareResponseData(data) {
                return data;
            }
        }
    }
</script>

<style scoped>

</style>
