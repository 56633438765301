<template>
  <span class="font-size-10">
    <countdown v-if="item.activeTaskExpectedArrivalTime && timeInMilliseconds"
      :time="timeInMilliseconds"
      v-slot="{ days, hours, minutes, seconds }"
    >
    {{ hours | twoDigits }}:{{ minutes | twoDigits }}:{{ seconds | twoDigits }}
    </countdown>
    <date-digital-clock v-else-if="item.activeTaskExpectedArrivalTime" :date="item.activeTaskExpectedArrivalTime" prefix="-"/>
  </span>
</template>
<script>

export default {
  name: "PlatformDashboardOrderCountDownCustom",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      timeInMilliseconds: 1,
      interval: null
    };
  },
  methods: {
    getDiffDateInMilliseconds() {
      let timeInMilliseconds = 0;

      if (this.item.activeTaskExpectedArrivalTime) {
        timeInMilliseconds = this.$moment(this.item.activeTaskExpectedArrivalTime).diff(this.$moment());
      }

      this.timeInMilliseconds = timeInMilliseconds >= 0 ? timeInMilliseconds : 0;
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.interval = setInterval(() => this.getDiffDateInMilliseconds(), 1000);
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString();
      }
      return value.toString();
    },
  },
};
</script>
