<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <!-- Begin Basic Information -->
            <div class="row">
              <div class="col-12">
                <div class="card-title">
                  <h1 class="text-primary">{{ $t(`labels.basic_information`) }}</h1>
                </div>
              </div>
              <div class="row card-body">
                <div class="col-12 col-md-6">
                  <div class="form-group ">
                    <label for="backend_id">{{ $t(`${transPrefix}.fields.backend_id`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                    <input  v-model="backend_id"
                            id="backend_id"
                            type="text"
                            class="form-control"
                            name="backend_id"
                            v-bind:class="{'is-invalid':fieldErrors.backendId}"
                    >
                    <span v-if="fieldErrors.backendId" class="invalid-feedback" role="alert">
                        <strong>{{ fieldErrors.backendId}}</strong>
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="payment_type">{{ $t(`${transPrefix}.fields.payment_type`) }} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                    <select-payment-types :error="fieldErrors.paymentType" :disabled="false" :multiple="false" v-model="payment_type"/>
                    <span v-if="fieldErrors.paymentType" class="invalid-feedback d-block" role="alert">
                        <strong>{{ fieldErrors.paymentType}}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Basic Information -->

            <template v-if="payment_type && backend_id">
              <!-- Begin Pickup Task Details -->
              <div class="row">
                <div class="col-12">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.pickup_task_details`) }}</h1>
                  </div>
                </div>

                <div class="row card-body">
                  <div class="col-12 col-md-4" v-if="payment_type==='CASH_ON_DELIVERY'">
                    <div class="form-group ">
                      <label for="pickupTask_payAtPickup">{{ $t(`${transPrefix}.fields.pay_at_pickup`)}}</label>
                      <input  v-model="pickupTask.payAtPickup"
                              id="pickupTask_payAtPickup"
                              type="number"
                              step="any"
                              class="form-control"
                              name="pickupTask_payAtPickup"
                              v-bind:class="{'is-invalid':fieldErrors['tasks[].payAtPickup']}"
                              :disabled="payment_type!=='CASH_ON_DELIVERY'"
                      >
                      <span v-if="fieldErrors['tasks[].payAtPickup']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].payAtPickup']}}</strong>
                        </span>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.address`) }}</h3>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div class="form-group">
                      <label for="branches">{{ $t(`fields.branches`)}}</label>
                      <search-platform-branches v-model="branch" placeholder="placeholders.search" :ajaxSearch="true"/>
                    </div>
                  </div>
                  <div class="row col-12" v-if="!branch">
                    <div class="col-12 col-lg-6 col-xl-3">
                      <div class="form-group ">
                        <label for="pickupTask_address_phone">{{ $t(`${transPrefix}.fields.address_phone`)}}</label>
                        <input  v-model="pickupTask.address.phone"
                                id="pickupTask_address_phone"
                                type="text"
                                class="form-control"
                                name="pickupTask_address_phone"
                        >
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-3">
                      <div class="form-group ">
                        <label for="pickupTask_address_description">{{ $t(`${transPrefix}.fields.address_description`)}}</label>
                        <input v-model="pickupTask.address.description"
                                  id="pickupTask_address_description"
                                  type="text"
                                  class="form-control"
                                  name="pickupTask_address_description"
                                  v-bind:class="{'is-invalid':fieldErrors['tasks[].address.description']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.description']" class="invalid-feedback" role="alert">
                              <strong>{{ fieldErrors['tasks[].address.description']}}</strong>
                          </span>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-3">
                      <div class="form-group ">
                        <label for="pickupTask_address_latitude">{{ $t(`${transPrefix}.fields.address_latitude`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                        <input  v-model="pickupTask.address.latitude"
                                id="pickupTask_address_latitude"
                                type="number"
                                step="any"
                                class="form-control"
                                v-bind:class="{'is-invalid':fieldErrors['tasks[].address.latitude']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.latitude']" class="invalid-feedback" role="alert">
                              <strong>{{ fieldErrors['tasks[].address.latitude']}}</strong>
                          </span>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 col-xl-3">
                    <div class="form-group ">
                      <label for="pickupTask_address_longitude">{{ $t(`${transPrefix}.fields.address_longitude`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                      <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                        <i class="fa fa-map-pin "></i>
                      </a>
                      <input  v-model="pickupTask.address.longitude"
                              id="pickupTask_address_longitude"
                              type="number"
                              step="any"
                              class="form-control"
                              v-bind:class="{'is-invalid':fieldErrors['tasks[].address.longitude']}"
                      >
                      <span v-if="fieldErrors['tasks[].address.longitude']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].address.longitude']}}</strong>
                        </span>
                    </div>
                  </div>
                  </div>
                  <div v-if="pickupMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                    <button class="btn btn-danger btn-sm" @click.prevent="hidePickupMapPicker">
                      {{ $t(`labels.close`) }}
                      <i class="fa fa-window-close"></i>
                    </button>
                    <maps-pick-address :position="{lat:pickupTask.address.latitude,lng:pickupTask.address.longitude}" v-model="pickupMarkerPosition"/>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row justify-content-end">
                  <div class="col px-0">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.products_opt`) }}</h3>
                    </div>
                  </div>
                  <div class="col text-right">
                    <button class="btn btn-success btn-sm mx-2" @click.prevent="addPickupTaskItemRow">
                      <strong>{{ $t(`labels.add_new_product`) }}</strong> <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <template v-for="(pickupTaskItem,index) in pickupTask.items">
                      <div class="col-12">
                        <h4 class="text-primary d-inline">{{ $t(`labels.product`) }} {{index+1}}</h4>
                        <a href="#" class="text-danger mx-2" @click.prevent="removePickupTaskItemRow(index)">
                          <i class="fa fa-trash"></i>
                        </a>
                      </div>
                      <div class="col-12 col-md-3">
                          <div class="form-group ">
                            <label :for="`name_${index}`">{{ $t(`fields.name`)}}</label>
                            <input  v-model="pickupTaskItem.name"
                                    :id="`name_${index}`"
                                    type="text"
                                    class="form-control"
                                    v-bind:class="{'is-invalid':fieldErrors['tasks[].items[].name']}"
                            >
                            <span v-if="fieldErrors['tasks[].items[].name']" class="invalid-feedback" role="alert">
                                <strong>{{ fieldErrors['tasks[].items[].name']}}</strong>
                            </span>
                          </div>
                    </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group ">
                          <label :for="`description_${index}`">{{ $t(`fields.description`)}}</label>
                          <input  v-model="pickupTaskItem.description"
                                  :id="`description_${index}`"
                                  type="text"
                                  class="form-control"
                                  v-bind:class="{'is-invalid':fieldErrors['tasks[].items[].description']}"
                          >
                          <span v-if="fieldErrors['tasks[].items[].description']" class="invalid-feedback" role="alert">
                              <strong>{{ fieldErrors['tasks[].items[].description']}}</strong>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group ">
                          <label :for="`quantity_${index}`">{{ $t(`fields.quantity`)}}</label>
                          <input  v-model="pickupTaskItem.quantity"
                                  :id="`quantity_${index}`"
                                  type="number"
                                  step="1"
                                  class="form-control"
                                  v-bind:class="{'is-invalid':fieldErrors['tasks[].items[].quantity']}"
                          >
                          <span v-if="fieldErrors['tasks[].items[].quantity']" class="invalid-feedback" role="alert">
                              <strong>{{ fieldErrors['tasks[].items[].quantity']}}</strong>
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-3">
                        <div class="form-group ">
                          <label :for="`price_${index}`">{{ $t(`fields.price`)}}</label>
                          <input  v-model="pickupTaskItem.price"
                                  :id="`price_${index}`"
                                  type="number"
                                  step="any"
                                  class="form-control"
                                  v-bind:class="{'is-invalid':fieldErrors['tasks[].items[].price']}"
                          >
                          <span v-if="fieldErrors['tasks[].items[].price']" class="invalid-feedback" role="alert">
                              <strong>{{ fieldErrors['tasks[].items[].price']}}</strong>
                          </span>
                        </div>
                      </div>
                      <div class="col-12">
                        <hr/>
                      </div>
                  </template>
                </div>
              </div>
              <!-- End Pickup Task Details -->

              <!-- Begin Delivery Task Details -->
              <div class="row">
                <div class="col-12">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.delivery_task_details`) }}</h1>
                  </div>
                </div>
                <div class="row card-body mb-5">
                  <div class="col-12 col-md-4" v-if="payment_type==='CASH_ON_DELIVERY'">
                    <div class="form-group ">
                      <label for="deliveryTask_collectAtDelivery">{{ $t(`${transPrefix}.fields.collect_at_delivery`)}}</label>
                      <input  v-model="deliveryTask.collectAtDelivery"
                              id="deliveryTask_collectAtDelivery"
                              type="number"
                              step="any"
                              class="form-control"
                              name="deliveryTask_collectAtDelivery"
                              v-bind:class="{'is-invalid':fieldErrors['tasks[].collectAtDelivery']}"
                              :disabled="payment_type!=='CASH_ON_DELIVERY'"
                      >
                      <span v-if="fieldErrors['tasks[].collectAtDelivery']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].collectAtDelivery']}}</strong>
                        </span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card-title">
                      <h3 class="text-primary d-inline">{{ $t(`labels.address`) }}</h3>
                    </div>
                  </div>
                  <div class="row col-12">
                    <div class="col-12 col-md-6 col-xl-3">
                      <div class="form-group ">
                        <label for="deliveryTask_address_phone">{{ $t(`${transPrefix}.fields.address_phone`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small> </label>
                        <input  v-model="deliveryTask.address.phone"
                                id="deliveryTask_address_phone"
                                type="text"
                                class="form-control"
                                name="deliveryTask_address_phone"
                                v-bind:class="{'is-invalid':fieldErrors['tasks[].address.phone']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.phone']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].address.phone']}}</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                      <div class="form-group ">
                        <label for="deliveryTask_address_description">{{ $t(`${transPrefix}.fields.address_description`)}}</label>
                        <input v-model="deliveryTask.address.description"
                               id="deliveryTask_address_description"
                               type="text"
                               class="form-control"
                               name="deliveryTask_address_description"
                               v-bind:class="{'is-invalid':fieldErrors['tasks[].address.description']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.description']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].address.description']}}</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                      <div class="form-group ">
                        <label for="deliveryTask_address_latitude">{{ $t(`${transPrefix}.fields.address_latitude`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showDeliveryMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                        <input  v-model="deliveryTask.address.latitude"
                                id="deliveryTask_address_latitude"
                                type="number"
                                step="any"
                                class="form-control"
                                v-bind:class="{'is-invalid':fieldErrors['tasks[].address.latitude']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.latitude']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['tasks[].address.latitude']}}</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3">
                      <div class="form-group ">
                        <label for="deliveryTask_address_longitude">{{ $t(`${transPrefix}.fields.address_longitude`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                        <a href="#" class="mx-1 text-blue" @click.prevent="showDeliveryMapPicker">
                          <i class="fa fa-map-pin "></i>
                        </a>
                        <input  v-model="deliveryTask.address.longitude"
                                id="deliveryTask_address_longitude"
                                type="number"
                                step="any"
                                class="form-control"
                                v-bind:class="{'is-invalid':fieldErrors['tasks[].address.longitude']}"
                        >
                        <span v-if="fieldErrors['tasks[].address.longitude']" class="invalid-feedback" role="alert">
                          <strong>{{ fieldErrors['tasks[].address.longitude']}}</strong>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="deliveryMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                    <button class="btn btn-danger btn-sm" @click.prevent="hideDeliveryMapPicker">
                      {{ $t(`labels.close`) }}
                      <i class="fa fa-window-close"></i>
                    </button>
                    <maps-pick-address id="deliveryPicker" :position="{lat:deliveryTask.address.latitude,lng:deliveryTask.address.longitude}" v-model="deliveryMarkerPosition"/>
                  </div>
                </div>
              </div>
              <!-- End Delivery Task Details -->

              <!-- Begin Meta Data Details-->

              <div class="row justify-content-end">
                <div class="col">
                  <div class="card-title">
                    <h1 class="text-primary d-inline">{{ $t(`labels.meta_data_opt`) }}</h1>
                  </div>
                </div>
                <div class="col text-right">
                  <button class="btn btn-success btn-sm mx-2" @click.prevent="addMetaDataItemRow">
                    {{ $t(`labels.add_new_item`) }} <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="row card-body mb-5 pt-0">
                <template v-for="(metaDataItem,index) in metaDataItems">
                  <div class="col-12">
                    <h3 class="text-primary d-inline">{{ $t(`labels.item`) }} {{index+1}}</h3>
                    <a href="#" class="text-danger mx-2" @click.prevent="removeMetaDataItemRow(index)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group ">
                      <label :for="`label_${index}`">{{ $t(`fields.label`)}}</label>
                      <input  v-model="metaDataItem.label"
                              :id="`label_${index}`"
                              type="text"
                              class="form-control"
                              v-bind:class="{'is-invalid':fieldErrors['metaData[].label']}"
                      >
                      <span v-if="fieldErrors['metaData[].label']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['metaData[].label']}}</strong>
                        </span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group ">
                      <label :for="`data_${index}`">{{ $t(`fields.data`)}}</label>
                      <input  v-model="metaDataItem.data"
                              :id="`data_${index}`"
                              type="text"
                              class="form-control"
                              v-bind:class="{'is-invalid':fieldErrors['metaData[].data']}"
                      >
                      <span v-if="fieldErrors['metaData[].data']" class="invalid-feedback" role="alert">
                            <strong>{{ fieldErrors['metaData[].data']}}</strong>
                        </span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group ">
                      <label :for="`language_code_${index}`">{{ $t(`fields.language_code`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                      <select-language-codes v-model="metaDataItem.languageCode" :id="`language_code_${index}`" :error="fieldErrors['metaData[].languageCode']"/>
                      <span v-if="fieldErrors['metaData[].languageCode']" class="invalid-feedback d-block" role="alert">
                          <strong>{{ fieldErrors['metaData[].languageCode']}}</strong>
                      </span>
                    </div>
                  </div>
                  <div class="col-12">
                    <hr/>
                  </div>
                </template>
              </div>

              <!-- End Meta Data Details-->

            </template>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { ORDERS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms_portal/orders';
  const ORDERS_CODE="orders";
  const CRUD_CODE=`platforms_portal.${ORDERS_CODE}`;
  const CRUD_TRANS_CODE=`${ORDERS_CODE}`;
  export default {
    name: "PlatformsPortalOrderCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        metaDataItems:[],
        pickupTask:{
          "taskType": "PICK_UP",
          "rank": 0,
          "collectAtDelivery": 0,
          "payAtPickup": 0,
          "name":"PICK_UP",
          "address": {
            "phone": "",
            "description": "",
            "latitude": "",
            "longitude": ""
          },
          "items":[],
        },
        deliveryTask:{
          "taskType": "DELIVERY",
          "rank": 1,
          "collectAtDelivery": 0,
          "payAtPickup": 0,
          "name":"DELIVERY",
          "address": {
            "phone": "",
            "description": "",
            "latitude": "",
            "longitude": ""
          },
        },
        backend_id: '',
        payment_type:'',

        pickupMapPicker:false,
        deliveryMapPicker:false,
        pickupMarkerPosition:{},
        deliveryMarkerPosition:{},
        branch:null,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    watch:{
      pickupMarkerPosition:function(newVal,oldVal){
        this.pickupTask.address.latitude=newVal.lat;
        this.pickupTask.address.longitude=newVal.lng;

      },
      deliveryMarkerPosition:function(newVal,oldVal){
        this.deliveryTask.address.latitude=newVal.lat;
        this.deliveryTask.address.longitude=newVal.lng;
      },
      payment_type:function (newVal,oldVal) {
        if(newVal!=='CASH_ON_DELIVERY'){
          this.deliveryTask.collectAtDelivery=0;
          this.pickupTask.payAtPickup=0;
        }
      },
      branch:function (newVal,oldVal) {
        if(newVal && typeof newVal ==="object"){
          this.pickupTask.address.latitude=newVal.latitude;
          this.pickupTask.address.longitude=newVal.longitude;
          this.pickupTask.address.phone=newVal.phone;
          this.pickupTask.address.description=newVal.description;
          this.pickupTask.name=newVal.name;
        }else{
          this.pickupTask.address.latitude=
          this.pickupTask.address.longitude=
          this.pickupTask.address.phone=
          this.pickupTask.address.description="";
          this.pickupTask.name="PICK_UP";
        }
      }
    },
    methods: {
      prepareRequestData(){
        return {
          paymentType: this.sanitizeField('payment_type'),
          backendId: this.sanitizeField('backend_id'),
          metaData: this.sanitizeField('metaDataItems'),
          tasks: [
            this.sanitizeField('pickupTask'),
            this.sanitizeField('deliveryTask')
          ],
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`})
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      addMetaDataItemRow(){
        this.metaDataItems.push({label: '', data: '' , languageCode: ''});
      },
      removeMetaDataItemRow(index){
        this.metaDataItems.splice(index,1);
      },
      addPickupTaskItemRow(){
        this.pickupTask.items.push({name: '', description: '' , quantity: 0,price:0});
      },
      removePickupTaskItemRow(index){
        this.pickupTask.items.splice(index,1);
      },
      hidePickupMapPicker(){
        this.pickupMapPicker=false;
      },
      showPickupMapPicker(){
        this.pickupMapPicker=true;
      },
      hideDeliveryMapPicker(){
        this.deliveryMapPicker=false;
      },
      showDeliveryMapPicker(){
        this.deliveryMapPicker=true;
      }
    }
  };
</script>
