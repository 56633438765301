import {
  USERS_LIST as CRUD_ACTION_LIST,
  USERS_CREATE as CRUD_ACTION_CREATE,
  USERS_SHOW as CRUD_ACTION_SHOW,
  USERS_DELETE as CRUD_ACTION_DELETE,
  USERS_ROLES_LIST as ROLES_CRUD_ACTION_LIST,
  USERS_STATUS_LIST as STATUS_ACTION_LIST, 
  USERS_ROLES_UPDATE as ROLES_CRUD_ACTION_UPDATE
} from "../actions/users";
import axioses from "@/axios/config";

const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const routePrefix='users';
const rolesPrefix='roles';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
      axioses.base
          .post(`/${routePrefix}/create`, fData,additionalHeaders)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROLES_CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}/${rolesPrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [STATUS_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: [
                "CREATED", "VERIFIED", "BLOCKED", "DELETED",
            ]
        };
        resolve(response);
    });
  },

  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/delete`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROLES_CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', null, { root: true });
      axioses.base
        .put(`/${routePrefix}/${params.id}/${rolesPrefix}`, {...params})
        .then(response => {
          resolve(response);
          commit('STOP_LOADING', null, { root: true });
        })
        .catch(error => {
          reject(error);
          commit('STOP_LOADING', null, { root: true });
        });
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
