<template>
  <div aria-labelledby="customer-tab" class="tab-pane fade" id="customer" role="tabpanel" v-if="fields.backend_id">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="backend_id">{{ $t(`${transPrefix}.fields.customer_id`)}}</label>
          <input class="form-control" id="backend_id" name="backend_id" type="text" v-model="fields.backend_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name">{{ $t(`fields.name`) }}</label>
          <input v-model="fields.name" id="name" type="text" class="form-control" name="name" disabled/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsCustomer",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
  };
</script>