<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="app_device_type">{{ $t(`fields.type`)}}</label>
                  <select-app-device-types v-model="type" :action="action" :error="fieldErrors.type"/>
                  <span v-if="fieldErrors.type" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.type }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`${transPrefix}.fields.bundle_id`)}}</label>
                  <input  v-model="bundle_id"
                          id="bundle_id"
                          type="text"
                          class="form-control"
                          name="bundle_id"
                          v-bind:class="{'is-invalid':fieldErrors.bundleId}"
                  >
                  <span v-if="fieldErrors.bundleId" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.bundleId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`${transPrefix}.fields.firebase_api_key`)}}</label>
                  <input  v-model="firebase_api_key"
                          id="firebase_api_key"
                          type="text"
                          class="form-control"
                          name="firebase_api_key"
                          v-bind:class="{'is-invalid':fieldErrors.firebaseApiKey}"
                  >
                  <span v-if="fieldErrors.firebaseApiKey" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.firebaseApiKey }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { APPS_CREATE as CRUD_ACTION_CREATE,DEVICE_TYPE_LIST } from 'actions/platforms_portal/apps';
  const PLATFORMS_CRUD_CODE="platforms";
  const APPS_CRUD_CODE="apps";
  const PLATFORMS_APPS_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.${APPS_CRUD_CODE}`;
  const CRUD_CODE=`platforms_portal.${APPS_CRUD_CODE}`;
  const CRUD_TRANS_CODE=`${PLATFORMS_APPS_CRUD_CODE}`;
  export default {
    name: "PlatformsPortalAppCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        platformsRoutePrefix: `${PLATFORMS_CRUD_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CRUD_CODE}`,
        action:DEVICE_TYPE_LIST,
        type: '',
        name: '',
        bundle_id: '',
        firebase_api_key: '',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          type: this.sanitizeField('type'),
          name: this.sanitizeField('name'),
          bundleId: this.sanitizeField('bundle_id'),
          firebaseApiKey: this.sanitizeField('firebase_api_key'),
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
                .then(() => {
                  swal({
                    icon: 'success',
                    text: this.$i18n.t("messages.create_successfully"),
                    buttons:false,
                    timer: 5000
                  });
                  this.$router.push({ name: `${this.routePrefix}.index`})
                })
                .catch(error => {
                  this.loading = false
                  this.fieldErrors={};
                  this.serverError='';

                  if(error.response){
                    let responseData=error.response.data;

                    if(responseData.errors){
                      this.fieldErrors=responseData.errors;
                    }else{
                      this.serverError = 'Invalid Request.'
                    }
                  }else{
                    this.serverError = this.$i18n.t("messages.no_internet_connection");
                  }

                  if(this.fieldErrors.error){
                    this.serverError = this.fieldErrors.error;
                  }
                })
      }
    }
  };
</script>
