<template>
  <div>
    <loader-dots  :color="getRandomColor()"  :duration="1" :size="7" /> 
  </div>
</template>

<style scoped lang="scss">

.background-loader {
    padding-top: 3.7rem;
    align-items: start;
    position: fixed;
    background: unset!important;
}


</style>

<script>
import loaderDots from '@nulldreams/vue-loading/src/components/dots'

export default {
  name: "loading",
  props: {},
  components: {
      loaderDots
  },
  data() {
    return {
      colors : ["#5e72e4", "#5603ad", "#8965e0", "#f3a4b5", "#f5365c", "#fb6340", "#ffd600", "#2dce89", "#2bffc6"]
    };
  },
  methods: {
    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    }
  }
};
</script>
