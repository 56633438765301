<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`}"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id">{{ $t(`fields.id`)}}</label>
                  <input  v-model="shift_id" id="id" type="text" class="form-control" name="id" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name" id="name" type="text" class="form-control" name="name" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="startAt">{{ $t(`fields.start_at`)}}</label>
                  <input  v-model="startAt" id="startAt" type="text" class="form-control" name="startAt" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="finishAt">{{ $t(`fields.finish_at`)}}</label>
                  <input  v-model="finishAt" id="finishAt" type="text" class="form-control" name="finishAt" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="type">{{ $t(`fields.type`)}}</label>
                  <input  v-model="type" id="type" type="text" class="form-control" name="type" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="status">{{ $t(`fields.status`)}}</label>
                  <input  v-model="status" id="status" type="text" class="form-control" name="status" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="finishStatus">{{ $t(`fields.finish_status`)}}</label>
                  <input  v-model="finishStatus" id="finishStatus" type="text" class="form-control" name="finishStatus" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="dayOfWeek">{{ $t(`fields.day_of_week`)}}</label>
                  <input  v-model="dayOfWeek" id="dayOfWeek" type="text" class="form-control" name="dayOfWeek" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="captainsCapacity">{{ $t(`fields.captains_capacity`)}}</label>
                  <input  v-model="captainsCapacity" id="captainsCapacity" type="text" class="form-control" name="captainsCapacity" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="captainsFulfilledCapacity">{{ $t(`fields.captains_fulfilled_capacity`)}}</label>
                  <input  v-model="captainsFulfilledCapacity" id="captainsFulfilledCapacity" type="text" class="form-control" name="captainsFulfilledCapacity" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="manHour">{{ $t(`fields.manHour`)}}</label>
                  <input  v-model="manHour" id="manHour" type="text" class="form-control" name="manHour" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="capabilities">{{ $t(`fields.capabilities`) }}</label>
                  <capabilities-field :itemID="shift_id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="createdAt">{{ $t(`fields.created_at`)}}</label>
                  <input  v-model="createdAt" id="createdAt" type="text" class="form-control" name="createdAt" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="updatedAt">{{ $t(`fields.updated_at`)}}</label>
                  <input  v-model="updatedAt" id="updatedAt" type="text" class="form-control" name="updatedAt" disabled>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col">
                <shift-captains-list :platformId="id" :branchId="branch_id" :shiftId="shift_id" :areaType="areaType" :captainsPermissionPrefix="captainsPermissionPrefix" :refreshList="refreshCaptainList" />
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <attach-captain-action :platformId="id" :branchId="branch_id" :shiftId="shift_id" :areaType="areaType" v-can:add="captainsPermissionPrefix" :callback="attachCaptainCallback" />
                <router-link :to="{ name: `${routePrefix}.index`,params: {id, branch_id}}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_AREAS_SHIFTS_SHOW as CRUD_ACTION_SHOW } from 'actions/platforms';
  import shiftCaptainsList from "./components/shift_captains";
  import attachCaptainAction from "./components/attach_captain";
  import capabilitiesField from "./fields/capabilities";
  const PLATFORMS_CRUD_CODE="platforms";
  const AREAS_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.branches`;
  const CRUD_CODE=`${AREAS_CRUD_CODE}.shifts`;
  const CRUD_PERMISSION_CODE=`platform_area_shifts`;
  const CAPTAINS_CRUD_PERMISSION_CODE = `platform_area_shift_captains`;
  const AREA_TYPE = "BRANCH";
  export default {
    name: "PlatformAreaShiftShow",
    components:{
      shiftCaptainsList,
      attachCaptainAction,
      capabilitiesField,
    },
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        platformsRoutePrefix: `${AREAS_CRUD_CODE}`,
        platformsTransPrefix: `${AREAS_CRUD_CODE}`,
			  captainsPermissionPrefix: `${CAPTAINS_CRUD_PERMISSION_CODE}`,
        id:this.$router.currentRoute.params.id,
        branch_id:this.$router.currentRoute.params.branch_id,
        shift_id: this.$router.currentRoute.params.shift_id,
        name: '',
        startAt: '',
        finishAt: '',
        finishStatus: '',
        status: '',
        type: '',
        captainsCapacity: '',
        captainsFulfilledCapacity: '',
        dayOfWeek: '',
        manHour: '',
        capabilities:[],
        areaType: AREA_TYPE,
        createdAt: '',
        updatedAt: '',
        serverError: '',
        responseStatus:'',
        refreshCaptainList: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          platformId: this.id,
          area_type: this.areaType,
          area_id: this.branch_id,
          id: this.shift_id,
        }).then(response=>{
          this.name=response.data.name;
          this.finishStatus=response.data.finishStatus;
          this.startAt=response.data.startAt;
          this.status=response.data.status;
          this.type=response.data.type;
          this.finishAt=response.data.finishAt;
          this.dayOfWeek=response.data.dayOfWeek;
          this.captainsCapacity=response.data.captainsCapacity;
          this.captainsFulfilledCapacity=response.data.captainsFulfilledCapacity;
          this.createdAt=response.data.createdAt;
          this.updatedAt=response.data.updatedAt;
          this.capabilities=response.data.capabilities;
          this.manHour=response.data.manHour;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      attachCaptainCallback(isLoading){
        this.refreshCaptainList = !isLoading;
      }
    },
    
  };
</script>
