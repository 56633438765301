import { render, staticRenderFns } from "./manual_assign.vue?vue&type=template&id=a8eab342&scoped=true&"
import script from "./manual_assign.vue?vue&type=script&lang=js&"
export * from "./manual_assign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8eab342",
  null
  
)

export default component.exports