<template>
    <multiselect
            v-bind:class="{'is-invalid':error}"
            ref="multiSelect"
            :id="id"
            :disabled="disabled"
            :multiple="multiple"
            :track-by="keyField"
            v-model="selectedValue"
            @input="updateValue"
            @select="onSelectHandler"
            @remove="onRemoveHandler"
            :options="showSelectAll ? [{ label : 'labels.select_all' , items }] : items"
            :searchable="true"
            :custom-label="customLabel"
            :close-on-select="true"
            :show-labels="false"
            :loading="isLoading"
            :allow-empty="allowEmpty"
            :hide-selected="hideSelected"
            :group-values="groupValuesKey"
            :group-label="groupLabelKey"
            :group-select="showSelectAll"
            :placeholder="$t(placeholder)">

        <template slot="option" slot-scope="props">{{ props.option.$isLabel ? $t(props.option.$groupLabel) : customLabel(props.option) }}</template>

        <template slot="tag" slot-scope="{ option, remove }">
            <span class="multiselect__tag" v-bind:class="{'disabled':disabled}">
                <span>{{ customLabel(option) }}</span>
                <i v-if="!disabled" aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="remove(option)"></i>
            </span>
        </template>

        <span slot="noResult">{{ $t(noResultMessage) }}</span>

        <template slot="clear" slot-scope="props">
            <div class="multiselect__clear" v-if="!isEmpty(selectedValue) && allowEmpty && !disabled" @mousedown.prevent.stop="clearSelected(props.search)"></div>
        </template>

        <template slot="afterList">
            <div v-observe-visibility="pagination"></div>
        </template>

    </multiselect>
</template>


<script>
    export default {
        name: "AbstractsDropdown",
        props: {
            value: {},
            id: {
                type: String,
                default: "dropdown"
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hideSelected: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'placeholders.dropdown',
            },
            noResultMessage: {
                type: String,
                default: 'messages.no_result_found',
            },
            error: {
                type: String
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            canSelectAll: {
                type: Boolean,
                default: false,
            },
            paginationHandler: {
                type: Function,
                default: () => {
                }
            },
            customLabelHandler: {
                type: Function,
            },
            onAddHandler: {
                type: Function,
                default: () => {
                }
            },
            onRemoveHandler: {
                type: Function,
                default: () => {
                }
            },
            items: {
                type: Array,
                default: []
            },
            keyField: {
                type: String,
                default: "id",
            },
            isLoading: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                selectedValue: null,
                showSelectAll: this.canSelectAll,
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                if (this.isEmpty(newVal)) {
                    this.selectedValue = null;
                }
                this.getDefaultValue();
            },
            items: function (newVal, oldVal) {
                this.getDefaultValue();
            },
            selectedValue: function(newVal,oldVal){
                new Promise(resolve => setTimeout(resolve, 100)).then(()=>{
                    this.showSelectAll = this.canSelectAll && this.isEmpty(newVal);
                });
            },
        },
        computed: {
            computedCustomLabelHandler(){
                if (typeof this.customLabelHandler === 'function')
                    return this.customLabelHandler.bind(this);

                return ((value) => {
                    return this.$i18n.t(value.name);
                })
            },
            groupValuesKey(){
                return this.showSelectAll ? `items` : '';
            },
            groupLabelKey(){
                return this.showSelectAll ? `label` : '';
            }
        },
        methods: {
            pagination(isVisible) {
                this.paginationHandler(isVisible);
            },
            customLabel(value) {
                return this.computedCustomLabelHandler(value);
            },
            updateValue(value) {
                if (this.multiple) {
                    let items = value.map(item => item[this.keyField]);
                    this.$emit('input', items);
                } else {
                    this.$emit('input', value?.returnValue ? value : (value ? value[this.keyField] : value));
                }
            },
            isEmpty(value) {
                return ((!Array.isArray(value) && !value) || (Array.isArray(value) && value.length === 0));
            },
            getDefaultValue() {

                if (!this.isEmpty(this.value) && !this.isSameSelected()) {
                    if (this.multiple) {
                        this.selectedValue = this.items.filter(item => (Array.isArray(this.value) && this.value.includes("" + item[this.keyField])) || "" + item[this.keyField] === "" + this.value);
                    } else {
                        this.selectedValue = this.items.find(item => "" + item[this.keyField] === "" + (this.value?.returnValue ? this.value[this.keyField] : this.value));
                    }
                    if (!this.isSameSelected()) {
                        this.pagination(true);
                    }
                }
                return this.selectedValue;
            },
            isSameSelected() {
                if (this.multiple) {
                    let value = this.convertToArray(this.value),
                        selectedValue = this.convertToArray(this.selectedValue);
                    return selectedValue.length === value.length;
                } else {
                    let value = "" + (this.value?.returnValue ? this.value[this.keyField] : this.value);
                    let selectedValue = this.selectedValue != null ? "" + this.selectedValue[this.keyField] : null;
                    return selectedValue === value;
                }
            },
            convertToArray(value) {

                if (!Array.isArray(value)) {
                    if (value == null) {
                        value = [];
                    } else {
                        value = [this.value];
                    }
                }

                return value;
            },
            clearSelected() {
                this.onRemoveAll(this.selectedValue);
                this.selectedValue = null;
                this.$emit('input', null);
            },
            onRemoveAll(selectedItems) {
                Object.keys(selectedItems).forEach(key => {
                    this.onRemoveHandler(selectedItems[key]);
                });
            },
            onSelectHandler(selectedItems) {
                if(this.$refs.multiSelect.closeOnSelect) this.$refs.multiSelect.deactivate();
                this.onAddHandler(selectedItems);
            },
        }
    }
</script>

<style scoped>

</style>
