import Index from "@/views/suppliers_portal/captains/index";
import Create from "@/views/suppliers_portal/captains/create";
import Show from "@/views/suppliers_portal/captains/show";
import Edit from "@/views/suppliers_portal/captains/edit";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../../helper"

const URL_PREFIX='suppliers_portal/captains';
const CODE='suppliers_portal.captains';
const TRANS_CODE='captains';
const PERMISSION_PREFIX='supplier_captains';

const routes = [
  {
    path: `${URL_PREFIX}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.list_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/create`,
    name:`${CODE}.create`,
    component: Create,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_add`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.create_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `crud.create`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/:id`,
    name:`${CODE}.show`,
    component: Show,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_view`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.show_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.show`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: `${URL_PREFIX}/:id`,
    name:`${CODE}.edit`,
    component: Edit,
    meta: {
      'can': [`${PERMISSION_PREFIX}_can_edit`],
      'removeClass' : [`bg-default`],
      'title': `${TRANS_CODE}.edit_title`,
      'breadDetails': {
        'title': `${TRANS_CODE}.title`,
        'index_route': `${CODE}.index`,
        'show': true,
        'items': [
          {
            'title': `:id`,
          },
          {
            'title': `crud.edit`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
