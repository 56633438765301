<template>
    <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse" id="navbarSupportedContent  ml-md-0 ">
                <!-- Navbar links -->
                <ul class="navbar-nav align-items-center">
                    <TopNavbarSideMenu/>
                    <TopNavbarNotifications/>
                </ul>
                <ul class="navbar-nav align-items-center ml-auto ">
                    <TopNavbarLanguageSwitcher/>
                </ul>
                <TopNabarProfile/>
            </div>
        </div>
    </nav>
</template>

<script>
    import TopNabarProfile from "./partials/TopNabarProfile";
    import TopNavbarSideMenu from "./partials/TopNavbarSideMenu";
    import TopNavbarLanguageSwitcher from "./partials/TopNavbarLanguageSwitcher";
    import TopNavbarNotifications from "./partials/TopNavbarNotifications";
    export default {
        name: "TopNavbar",
        components: {
            TopNavbarNotifications,
            TopNavbarLanguageSwitcher,
            TopNavbarSideMenu,
            TopNabarProfile
        },
    }
</script>

<style scoped>

</style>
