import {
    ORDERS_LIST as CRUD_ACTION_LIST,
    ORDERS_CREATE as CRUD_ACTION_CREATE,
    ORDERS_SHOW as CRUD_ACTION_SHOW,
    ORDERS_UPDATE as CRUD_ACTION_UPDATE,
    ORDERS_DELETE as CRUD_ACTION_DELETE,
    ORDERS_CANCEL as CRUD_ACTION_CANCEL,
    ORDERS_REASSIGN as CRUD_ACTION_REASSIGN,
    ORDERS_MANUAL_ASSIGN as CRUD_ACTION_MANUAL_ASSIGN,
    ORDER_TASKS_UPDATE_STATUS as CRUD_ACTION_UPDATE_TASK,
    ORDER_TASKS_UNLOCK as CRUD_ACTION_UNLOCK_TASK,
    ORDER_TASKS_START as CRUD_ACTION_START_TASK,
    ORDERS_CANCELLATION_REASONS_LIST as CRUD_CANCELLATION_REASONS_LIST,
    ORDER_TYPES_LIST as ORDER_TYPES_LIST,
    ORDERS_TRACKING_LIST as TRACKING_ACTION_LIST,
    ORDERS_PAYMENT_TYPE_LIST as PAYMENT_TYPE_ACTION_LIST,
    ORDERS_STATUS_LIST as STATUS_ACTION_LIST,
    ORDERS_TASKS_TIMELINES_SHOW as TASK_TIMELINES_CRUD_ACTION_SHOW,
    ORDERS_TASKS_SHOW as TASKS_CRUD_ACTION_SHOW,
    ORDERS_ASSIGNMENTS_TRACKING_LIST,
} from "../actions/orders";
import axioses from "@/axios/config";
import qs from "qs"
const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};
const routePrefix='orders';
const orderTaskTimelineRoutePrefix='tasks';
const assignmentRoutePrefix='assignments';
const assignmentTrackingRoutePrefix='snapshot';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/create`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_CANCEL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/cancel`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REASSIGN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/reassign`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_MANUAL_ASSIGN]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}/manual_assign/${params.captain_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE_TASK]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .patch(`tasks/${params.id}/COMPLETED`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UNLOCK_TASK]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .put(`tasks/${params.id}/unlock`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_START_TASK]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .patch(`tasks/${params.id}/STARTED`, fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_CANCELLATION_REASONS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/cancellation_reasons`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [TRACKING_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .get(`/${routePrefix}/tracking`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [PAYMENT_TYPE_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["CASH_ON_DELIVERY", "PREPAID"]
        };
        resolve(response);
    });
  },
  [STATUS_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: [
                "NOT_ASSIGNED", "ASSIGNED", "COLLECTING_ORDER", "ON_THE_WAY", "DELIVERED", "CANCELED", "FAILED_TO_ASSIGN", "RE_ASSIGNING", "RETURNING", "RETURNED",
                "NEAR_PICK_UP", "AT_PICK_UP", "NEAR_DELIVERY", "AT_DELIVERY", "RECOVERED", "RETURNING_TO_AREA", 
            ]
        };
        resolve(response);
    });
  },
  [ORDER_TYPES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .get(`/${routePrefix}/delivery-types`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [TASK_TIMELINES_CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${orderTaskTimelineRoutePrefix}/${params.id}/history`,{ params:{...params.data} })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ORDERS_ASSIGNMENTS_TRACKING_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${assignmentRoutePrefix}/${params.assignmentId}/${assignmentTrackingRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
