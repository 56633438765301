import {
    WALLETS_LIST as CRUD_ACTION_LIST,
    WALLETS_OWNER_TYPE_LIST as CRUD_ACTION_TYPE_LIST,
    WALLETS_BATCH_UPLOAD,
    WALLETS_CSV_TEMPLATE_DOWNLOAD 
} from "../actions/wallets";
import axioses from "@/axios/config";
import qs from "qs";

const additionalHeaders = {
  headers: {"Content-Type": "multipart/form-data",},
}

const routePrefix='wallet';
const path ='transactions'

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params.data, { skipNulls: true, indices: false })}})
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/owner-types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },

  [WALLETS_CSV_TEMPLATE_DOWNLOAD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .get(
          `/${routePrefix}/${path}/template/csv`
        )
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [WALLETS_BATCH_UPLOAD]: ({ commit, dispatch }, params) => {
    const { file } = params;
    const formData = new FormData();
    formData.append("file", file, file.name);
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });
      axioses.base
        .post(
          `/${routePrefix}/${path}/upload/csv`,
          formData,
          additionalHeaders
        )
        .then((response) => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch((error) => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
