import {
  TRACKING_ADD_UPDATE_CAPTAIN,
  TRACKING_UPDATE_CAPTAIN_STATUS,
  TRACKING_ADD_UPDATE_BUSY_CAPTAINS,
  TRACKING_SET_ACTIVE_ORDER,
  TRACKING_RESET_CAPTAINS_CAPTAINS,
  TRACKING_REFRESH_LIST,
  TRACKING_RESET_BRANCH,
  TRACKING_SET_BRANCH,
  TRACKING_SET_ZONE,
  TRACKING_RESET_ZONE,
  TRACKING_RESET_SCOPED_CAPTAINS,
  TRACKING_SET_SCOPED_CAPTAINS,
  TRACKING_RESET_SCOPED_CAPTAINS_ORDERS,
  TRACKING_SET_SCOPED_CAPTAINS_ORDERS,
  TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS,
  TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS,
} from "../../actions/sockets/tracking";
import {AUTH_LOGOUT} from "../../actions/auth";

const state = {
  captains: localStorage.getItem("tracking_captains")?JSON.parse(localStorage.getItem("tracking_captains")) :{},
  branch: {},
  scopedCaptains: {},
  scopedCaptainsOrders : {},
  dashboardCaptainsOrders : {},
  busyCaptains: new Set(),
  connectedCaptains: new Set(),
  connectedCaptainsSize: 0,
  activeOrder: {},
  refreshList: {},
};

const getters = {
  getStompConnectionUrl:function(state,getters,rootState,rootGetters){
    return rootGetters.isStompLoaded?rootGetters.getStomp.connectionUrl:''
  },
  getStompConnectionsTopic:function(state,getters,rootState,rootGetters){
    return rootGetters.isStompLoaded?rootGetters.getStomp.connectionsTopic:'';
  },
  getStompLocationsTopic:function(state,getters,rootState,rootGetters){
    return rootGetters.isStompLoaded?rootGetters.getStomp.locationsTopic:'';
  },
  getCaptains: function(state){
    return state.captains;
  },
  getBranch: function(state){
    return state.branch;
  },
  getScopedCaptains: function(state){
    return state.scopedCaptains;
  },
  getScopedCaptainsOrders: function(state){
    return state.scopedCaptainsOrders;
  },
  getDashboardCaptainsOrders: function(state){
    return state.dashboardCaptainsOrders;
  },
  getBusyCaptains: function (state) {
    return state.busyCaptains;
  },
  getConnectedCaptains: function (state) {
    return state.connectedCaptains;
  },
  getConnectedCaptainsSize: function (state) {
    return state.connectedCaptainsSize;
  },
  getActiveOrder: function (state) {
    return state.activeOrder
  },
  refreshListListener: function (state) {
    return state.refreshList
  }
};

const actions = {};

const mutations = {
  [TRACKING_ADD_UPDATE_CAPTAIN]: (state,captain) => {
    let captainAttributes={
      accuracy: captain.accuracy,
      captainId: captain.captainId,
      date: captain.date,
      latitude: captain.latitude,
      longitude: captain.longitude,
      speed: captain.speed,
      time: captain.time,
      connected: !!captain.connected,
      isBusy: !!captain.isBusy,
    };
    if(state.captains[captain.captainId]) {
      state.captains[captain.captainId] = {
        ...state.captains[captain.captainId],
        ...captainAttributes
      };
    }else{
      state.captains[captain.captainId] = captainAttributes;
    }

    //To Trigger Captain Changed
    let tempCaptains=state.captains;
    state.captains = {};
    state.captains = tempCaptains;

    if(state.captains[captain.captainId]?.connected) {
      state.connectedCaptains.add(captain.captainId);
    } else {
      state.connectedCaptains.delete(captain.captainId);
    }

    state.connectedCaptainsSize = state.connectedCaptains.size;

    localStorage.setItem("tracking_captains",JSON.stringify(state.captains));
  },

  [TRACKING_UPDATE_CAPTAIN_STATUS]: (state,captain) => {
    if(state.captains[captain.captainId]){
      state.captains[captain.captainId]={
        ...state.captains[captain.captainId],
        ...{
          connected: captain.connected,
          // date: captain.date,
        }
      };
      localStorage.setItem("tracking_captains",JSON.stringify(state.captains));
    }
  },
  [TRACKING_RESET_CAPTAINS_CAPTAINS]: (state) => {
    state.captains = {};
    state.connectedCaptains = new Set();
    state.connectedCaptainsSize = 0;
    localStorage.removeItem("tracking_captains");
  },
  [AUTH_LOGOUT]: state => {
    state.captains={};
    localStorage.removeItem("tracking_captains");
  },
  [TRACKING_ADD_UPDATE_BUSY_CAPTAINS]: (state,data) => {
    state.busyCaptains= data.busyCaptains;
  },
  [TRACKING_SET_ACTIVE_ORDER]: (state,activeOrder) => {
    state.activeOrder= activeOrder;
  },
  [TRACKING_REFRESH_LIST]: (state,refreshList) => {
    state.refreshList= refreshList;
  },
  [TRACKING_SET_BRANCH]: (state, branch) => {
    state.branch = {
      ...branch,
      latitude: parseFloat(branch.latitude),
      longitude: parseFloat(branch.longitude),
    };
  },
  [TRACKING_RESET_BRANCH]: (state) => {
    state.branch = {};
  },
  [TRACKING_SET_ZONE]: (state, branch) => {
    state.branch = {
      ...branch,
      points: branch.geom.coordinates[0][0],
    };
  },
  [TRACKING_RESET_ZONE]: (state) => {
    state.zone = {};
  },
  [TRACKING_SET_SCOPED_CAPTAINS]: (state, scopedCaptains) => {
    state.scopedCaptains = scopedCaptains;
  },
  [TRACKING_RESET_SCOPED_CAPTAINS]: (state) => {
    state.scopedCaptains = {};
  },
  [TRACKING_SET_SCOPED_CAPTAINS_ORDERS]: (state, scopedCaptainsOrders ) => {
    state.scopedCaptainsOrders  = scopedCaptainsOrders ;
  },
  [TRACKING_RESET_SCOPED_CAPTAINS_ORDERS]: (state) => {
    state.scopedCaptainsOrders = {};
  },
  [TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS]: (state, dashboardCaptainsOrders ) => {
    state.dashboardCaptainsOrders  = dashboardCaptainsOrders ;
  },
  [TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS]: (state) => {
    state.dashboardCaptainsOrders = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
