import Index from "@/views/suppliers_portal/tracking/index";

import {
  commonBeforeEnter,
  ifAuthenticated,
  ifNotAuthenticated,
} from "../../helper";

const URL_PREFIX = "suppliers_portal/tracking";
const CODE = "suppliers_portal.tracking";
const TRANS_CODE = "tracking";
const PERMISSION_PREFIX = "supplier_tracking";

const routes = [
  {
    path: `${URL_PREFIX}`,
    name: `${CODE}.index`,
    component: Index,
    meta: {
      // can: [`${PERMISSION_PREFIX}_can_view`],
      removeClass: [`bg-default`],
      removeClass: [`bg-default`, "g-sidenav-pinned"],
      addClass: [`g-sidenav-hidden`],
      title: `${TRANS_CODE}.list_title`,
      breadDetails: {
        title: `${TRANS_CODE}.title`,
        index_route: ``,
        show: false,
        container_fluid: false,
        add_primary_color: false,
        items: [],
      },
    },
    beforeEnter: function(to, from, next) {
      if (ifAuthenticated(to, from, next)) {
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
