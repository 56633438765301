<template>
    <div class="row">
      <div class="col">
        <div class="card">
          <!-- Card header -->
          <card-header :title="$router.currentRoute.meta.title" />
          <!-- Card body -->
          <form role="form" @submit.prevent="create">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group ">
                    <label for="type">{{ $t(`fields.type`)}}</label>
                    <select-platform-area-pricing-rule-types v-model="type" :params="{ platform_id: id, area_type:'ZONE', area_id: zone_id }" :error="fieldErrors.type"/>
                    <span v-if="fieldErrors.type" class="invalid-feedback d-block" role="alert">
                        <strong>{{ fieldErrors.type }}</strong>
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="amount">{{ $t(`fields.amount`)}}</label>
                    <input  v-model="amount" id="name" type="number" step="any" class="form-control" name="amount" v-bind:class="{'is-invalid':fieldErrors.amount}">
                    <span v-if="fieldErrors.amount" class="invalid-feedback" role="alert">
                        <strong>{{ fieldErrors.amount }}</strong>
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="needX()">
                  <div class="form-group">
                    <label for="x">{{ $t(`fields.x`)}}</label>
                    <input  v-model="x" id="x" type="text" class="form-control" name="x" v-bind:class="{'is-invalid':fieldErrors.x}">
                    <span v-if="fieldErrors.x" class="invalid-feedback" role="alert">
                        <strong>{{ fieldErrors.x }}</strong>
                    </span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="x">{{ $t(`fields.isVisibleToCaptain`)}}</label>
                    <div>
                      <label class="custom-toggle">
                        <input type="checkbox" id="suspended" v-model="isVisibleToCaptain">
                        <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                        <span v-if="fieldErrors.isVisibleToCaptain" class="invalid-feedback" role="alert">
                          <strong>{{ fieldErrors.isVisibleToCaptain }}</strong>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card footer -->
            <div class="card-footer">
              <div class="row">
                <div class="col text-right">
                  <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                    <i class="fa fa-arrow-left"></i>
                  </router-link>
                  <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                    <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                    <i class="fa fa-save"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
<script>
    import { PLATFORMS_AREAS_PRICING_RULES_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';
    const PLATFORMS_CRUD_CODE="platforms";
    const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
    const CRUD_CODE=`${ZONES_CRUD_CODE}.pricing_rules`;
    export default {
      name: "PlatformZonePricingRuleCreate",
      data(){
        return {
          routePrefix: CRUD_CODE,
          transPrefix: CRUD_CODE,
          zonesRoutePrefix: ZONES_CRUD_CODE,
          id:this.$router.currentRoute.params.id,
          zone_id:this.$router.currentRoute.params.zone_id,
          type: '',
          amount: '',
          x: '',
          serverError: '',
          fieldErrors: {},
          loading: false,
          isVisibleToCaptain: true
        }
      },
      methods: {
        prepareRequestData(){
          return {
            platform_id:this.id,
            owner_type: "ZONE",
            owner_id: this.zone_id,
            type: this.sanitizeField('type'),
            amount: this.sanitizeField('amount'),
            x: this.needX() ? this.sanitizeField('x') : "",
            isVisibleToCaptain: this.sanitizeField('isVisibleToCaptain')
          }
        },
        create() {
          this.loading = true;
          this.$store.dispatch(CRUD_ACTION_CREATE, {
            ...this.prepareRequestData(),
          })
          .then(() => {
            swal({
              icon: 'success',
              text: this.$i18n.t("messages.create_successfully"),
              buttons:false,
              timer: 5000
            });
            this.$router.push({ name: `${this.routePrefix}.index`})
          })
          .catch(error => {
            this.loading = false;
            this.fieldErrors={};
            this.serverError='';

            if(error.response){
              let responseData=error.response.data;

              if(responseData.errors){
                this.fieldErrors=responseData.errors;
              }else{
                this.serverError = 'Invalid Request.'
              }
            }else{
              this.serverError = this.$i18n.t("messages.no_internet_connection");
            }

            if(this.fieldErrors.error){
              this.serverError = this.fieldErrors.error;
            }
          })
        },
        needX(){
          return this.type?.search(/_X_/) !== -1;
        },
      }
    };
  </script>
