<template>
    <div  aria-hidden="true" aria-labelledby="modal-cancel-order" class="modal fade" id="modal-cancel-order"  role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">{{ $t('messages.cancel_item')}}</h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form role="form" @submit.prevent="cancel">
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="order_cancellation_reason">{{ $t(`fields.reason`)}}</label>
                    <select-order-cancellation-reasons v-model="reason" :params="{...activeParams, ...additionalParams}" :error="fieldErrors.reason"/>
                    <span v-if="fieldErrors.reason" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.reason}}</strong>
                    </span>
                  </div>
                </div>
                <div class="col-12" v-if="reasonNeedExplain()">
                  <div class="form-group">
                    <label for="explain">{{ $t(`labels.explain`)}}</label>
                    <textarea  v-model="explain"
                            id="explain"
                            rows="5"
                            class="form-control"
                            name="explain"
                            v-bind:class="{'is-invalid':fieldErrors.otherReason}"
                    ></textarea>
                    <span v-if="fieldErrors.otherReason" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.otherReason}}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-sm ml-auto" data-dismiss="modal" type="button">
                {{ $t('labels.close') }}
              </button>
              <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                {{ $t('labels.submit') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
  import { ORDERS_CANCEL as CRUD_ACTION_CANCEL} from 'actions/orders';
    export default {
        name: "OrdersActionsModalsCancel",
        props: {
            activeParams:{
                type:Object,
                default:function(){
                  return {};
                }
            },
            additionalParams:{
              type:Object,
              default:function(){
               return {};
              }
            },
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data(){
            return {
                serverError: '',
                fieldErrors: {},
                loading: false,
                reason:"",
                explain:"",
                options:{},
                activeId:null,
                activeIndex:null,
            }
        },
        watch:{
          activeParams:function(newVal, oldVal) {
            this.serverError=null;
            this.option="";
            this.activeId=newVal.activeId;
            this.activeIndex=newVal.activeIndex;
            this.activeParams=newVal;
            this.reason="";
            this.explain="";
          },
        },
        methods:{
            cancel(){
                this.loading = true;
                this.$store.dispatch(CRUD_ACTION_CANCEL, {
                    id:this.activeParams.activeId,
                    data: {
                      ...this.prepareRequestData(),
                      ...this.additionalParams
                    },
                })
                .then(() => {
                  this.$emit('input',false);
                  document.querySelector('#modal-cancel-order [data-dismiss]').click();
                    this.loading = false;
                    this.reason="";
                    this.explain="";
                    this.fieldErrors={};
                    this.serverError='';
                    this.successCallback();
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.cancel_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    this.loading = false;
                    this.fieldErrors={};
                    this.serverError='';

                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors){
                            this.fieldErrors=responseData.errors;
                        }else{
                            this.serverError = 'Invalid Request.'
                        }
                    }else{
                        this.serverError = this.$i18n.t("messages.no_internet_connection");
                    }

                    if(this.fieldErrors.error){
                        this.serverError = this.fieldErrors.error;
                    }
                })
            },
            prepareRequestData(){
                return {
                  reason:this.sanitizeField('reason'),
                  otherReason:this.sanitizeField('explain'),
                }
            },
            reasonNeedExplain(){
              return ['OTHER'].includes(this.reason);
            }
        }
    }
</script>

<style scoped>

</style>
