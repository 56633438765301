 import {
    SUPPLIER_DETAILS_SHOW as CRUD_ACTION_SHOW,
     SUPPLIER_DETAILS_UPDATE as CRUD_ACTION_UPDATE,
 } from "../../actions/suppliers_portal/supplier";
 import axioses from "@/axios/config";
 import qs from "qs"
 const routePrefix='suppliers/details';

 const state = {};

 const getters = {};

 const actions = {
     [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
         return new Promise((resolve, reject) => {
         commit('START_LOADING', null, { root: true });
         axioses.supplierPortal
             .get(`${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
             .then(response => {
                 resolve(response);
                 commit('STOP_LOADING', null, { root: true });
             })
             .catch(error => {
                 commit('STOP_LOADING', null, { root: true });
                 reject(error);
             });
         });
     },
    [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', null, { root: true });
            axioses.supplierPortal
                .put(`${routePrefix}`,{...params.data})
                .then(response => {
                    resolve(response);
                    commit('STOP_LOADING', null, { root: true });
                })
                .catch(error => {
                    commit('STOP_LOADING', null, { root: true });
                    reject(error);
                });
        });
    },
 };

 const mutations = {};

 export default {
     state,
     getters,
     actions,
     mutations
 };
