import {
    ROLES_LIST as CRUD_ACTION_LIST,
    ROLES_CREATE as CRUD_ACTION_CREATE,
    ROLES_SHOW as CRUD_ACTION_SHOW,
    ROLES_UPDATE as CRUD_ACTION_UPDATE,
    ROLES_DELETE as CRUD_ACTION_DELETE,
    ROLES_PERMISSIONS_LIST,
    ROLES_PERMISSIONS_UPDATE,
    ROLES_PERMISSIONS_DELETE
} from "../actions/roles";
import axioses from "@/axios/config";

const routePrefix='roles';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/create`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROLES_PERMISSIONS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}/privileges`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [ROLES_PERMISSIONS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}/privileges/${params.permission_id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [ROLES_PERMISSIONS_DELETE]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            commit('START_LOADING', null, { root: true });
            axioses.base
                .delete(`/${routePrefix}/${params.id}/privileges/${params.permission_id}`)
                .then(response => {
                    resolve(response);
                    commit('STOP_LOADING', null, { root: true });
                })
                .catch(error => {
                    commit('STOP_LOADING', null, { root: true });
                    reject(error);
                });
        });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
