<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-teal p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.total`) }}: {{ totalCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-yellow p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.busy`) }}: {{ totalBusyCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-purple text-white p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.free`) }}: {{ totalFreeCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert alert-success p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.connected`) }}:
          {{ totalConnectedCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-red text-white p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.not_connected`) }}:
          {{ totalNotConnectedCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-pink p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.roaming`) }}: {{ totalRoamingCaptains }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-blue text-white p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.in_area`) }}: {{ totalInAreaCaptains }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const CRUD_CODE = "tracking";
export default {
  name: "TrackingCounter",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE
    };
  },
  computed: {
    ...mapGetters([
      "getCaptains",
      "getBusyCaptains",
      "getScopedCaptains",
      "getSearchFilters",
      "getConnectedCaptainsSize"
    ]),
    totalCaptains() {
      return Object.keys(this.getScopedCaptains).length;
    },
    totalBusyCaptains() {
      let busyCaptains = [];
      Object.values(this.getScopedCaptains).forEach(captain => {
        if (["BUSY"].includes(captain.workingStatus)) {
          busyCaptains.push(captain);
        }
      });
      return busyCaptains.length;
    },
    totalRoamingCaptains() {
      let roamingCaptains = [];
      Object.values(this.getScopedCaptains).forEach(captain => {
        if (["ROAMING"].includes(captain.locationStatus) && ["FREE"].includes(captain.workingStatus)) {
          roamingCaptains.push(captain);
        }
      });
      return roamingCaptains.length;
    },
    totalInAreaCaptains() {
      let inAreaCaptains = [];
      Object.values(this.getScopedCaptains).forEach(captain => {
        if (["IN_AREA"].includes(captain.locationStatus) && ["FREE"].includes(captain.workingStatus)) {
          inAreaCaptains.push(captain);
        }
      });
      return inAreaCaptains.length;
    },
    totalConnectedCaptains() {
      return this.getConnectedCaptainsSize;
    },
    totalNotConnectedCaptains() {
      return this.totalCaptains - this.totalConnectedCaptains;
    },
    totalFreeCaptains() {
      return this.totalCaptains - this.totalBusyCaptains;
    }
  }
};
</script>
