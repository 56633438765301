<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="allocating_algorithm">{{ $t(`fields.allocation_algorithm`) }}</label>
                  <select-platform-setting-algorithms v-model="allocating_algorithm" :params="{ platformId: id }"
                    :multiple="false" />
                  <span v-if="fieldErrors.allocatingAlgorithm" class="invalid-feedback d-block" role="alert">
                    <strong>{{ fieldErrors.allocatingAlgorithm }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="allocating_algorithm">{{ $t(`fields.stc_pay_key`) }}</label>
                  <input v-model="stcPayKey" id="stcPayKey" type="text" class="form-control" name="stcPayKey">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="allocating_algorithm">{{ $t(`fields.stc_pay_mid`) }}</label>
                  <input v-model="stcPayMid" id="stcPayMid" type="text" class="form-control" name="stcPayMid">
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">


              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="pickup_task_confirmation_image_required">{{
                      $t(`${transPrefix}.fields.pickup_task_confirmation_image_required`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="" v-model="pickup_task_confirmation_image_required">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="delivery_task_confirmation_image_required">{{
                      $t(`${transPrefix}.fields.delivery_task_confirmation_image_required`)}}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="" v-model="delivery_task_confirmation_image_required">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>








              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="order_is_returnable">{{ $t(`${transPrefix}.fields.order_is_returnable`) }}</label>
                    <span v-if="fieldErrors.orderIsReturnable" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.orderIsReturnable }}</strong>
                    </span>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="order_is_returnable" v-model="order_is_returnable"
                        v-bind:class="{ 'is-invalid': fieldErrors.orderIsReturnable }">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="reveal_as_client">{{ $t(`${transPrefix}.fields.reveal_as_client`) }}</label>
                    <span v-if="fieldErrors.revealAsClient" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.revealAsClient }}</strong>
                    </span>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="reveal_as_client" v-model="reveal_as_client"
                        v-bind:class="{ 'is-invalid': fieldErrors.revealAsClient }">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="captain_receipt_required">{{
                      $t(`${transPrefix}.fields.captain_receipt_required`)}}</label>
                    <span v-if="fieldErrors.revealAsClient" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.revealAsClient }}</strong>
                    </span>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input type="checkbox" id="captain_receipt_required" v-model="captain_receipt_required"
                        v-bind:class="{ 'is-invalid': fieldErrors.revealAsClient }">
                      <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.show` }" class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_SETTINGS_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_SETTINGS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/platforms";
const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.settings`;
export default {
  name: "PlatformSettingEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      pickup_task_confirmation_image_required: "",
      delivery_task_confirmation_image_required: "",
      order_is_returnable: "",
      reveal_as_client: "",
      captain_receipt_required: "",
      allocating_algorithm: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: 'edit',
      loading: false,
      stcPayMid: "",
      stcPayKey: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
        })
        .then((response) => {
          this.pickup_task_confirmation_image_required =
            response.data.pickupTaskConfirmationImageRequired;
          this.delivery_task_confirmation_image_required =
            response.data.deliveryTaskConfirmationImageRequired;
          this.order_is_returnable = response.data.orderIsReturnable;
          this.reveal_as_client = response.data.revealAsClient;
          this.captain_receipt_required = response.data.captainReceiptRequired;
          this.allocating_algorithm = response.data.allocatingAlgorithm;
          this.stcPayMid = response.data.stcPayMid;
          this.stcPayKey = response.data.stcPayKey;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        pickupTaskConfirmationImageRequired: this.sanitizeField(
          "pickup_task_confirmation_image_required"
        ),
        deliveryTaskConfirmationImageRequired: this.sanitizeField(
          "delivery_task_confirmation_image_required"
        ),
        orderIsReturnable: this.sanitizeField("order_is_returnable"),
        revealAsClient: this.sanitizeField("reveal_as_client"),
        captainReceiptRequired: this.sanitizeField("captain_receipt_required"),
        allocatingAlgorithm: this.sanitizeField("allocating_algorithm"),
        stcPayMid: this.sanitizeField("stcPayMid"),
        stcPayKey: this.sanitizeField("stcPayKey"),
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.show`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
