import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFoundRoutes from "./modules/page_not_found";
import PageUnauthorizedRoutes from "./modules/page_unauthorized";
import Root from "./Root";
import authRoutes from "./modules/auth";
import dashboardRoutes from "./modules/dashboard";
import citiesRoutes from "./modules/cities";
import zonesRoutes from "./modules/zones";
import platformsRoutes from "./modules/platforms";
import ordersRoutes from "./modules/orders";
import profileRoutes from "./modules/profile";
import platformsPortalOrdersRoutes from "./modules/platforms_portal/orders";
import platformsPortalUsersRoutes from "./modules/platforms_portal/users";
import platformsPortalAppsRoutes from "./modules/platforms_portal/apps";
import platformsPortalInvoicesRoutes from "./modules/platforms_portal/invoices";
import platformsPortalPlatformRoutes from "./modules/platforms_portal/platform";
import platformsPortalBranchesRoutes from "./modules/platforms_portal/branches";
import suppliersPortalCaptainsRoutes from "./modules/suppliers_portal/captains";
import suppliersPortalCaptainRegistrationRequestsRoutes from "./modules/suppliers_portal/captain_registration_requests";
import suppliersPortalCaptainPerformanceRoutes from "./modules/suppliers_portal/captain_performance";
import suppliersPortalSupplierRoutes from "./modules/suppliers_portal/supplier";
import suppliersPortalReportsRoutes from "./modules/suppliers_portal/reports";
import suppliersPortalOrdersRoutes from "./modules/suppliers_portal/orders";
import suppliersPortalTrackingRoutes from "./modules/suppliers_portal/tracking";
import walletTransactionRoutes from "./modules/wallet_transactions";

import i18n, {
  loadLocaleMessagesAsync,
  defaultLocale,
  getLocaleFromPath,
} from "@/i18n";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle,
} from "@/util/i18n/document";

import { supportedLocalesInclude } from "@/util/i18n/supported-locales";

Vue.use(VueRouter);
const { locale } = i18n;

const routes = [
  {
    path: "/",
    redirect: `${locale}/login`,
  },
  {
    path: `/:locale`,
    component: Root,
    children: [
      ...authRoutes,
      ...dashboardRoutes,
      ...citiesRoutes,
      ...zonesRoutes,
      ...profileRoutes,
      ...walletTransactionRoutes,
      ...platformsPortalOrdersRoutes,
      ...platformsPortalUsersRoutes,
      ...platformsPortalAppsRoutes,
      ...platformsPortalInvoicesRoutes,
      ...platformsPortalPlatformRoutes,
      ...platformsPortalBranchesRoutes,
      ...suppliersPortalCaptainsRoutes,
      ...suppliersPortalCaptainRegistrationRequestsRoutes,
      ...suppliersPortalCaptainPerformanceRoutes,
      ...suppliersPortalSupplierRoutes,
      ...suppliersPortalReportsRoutes,
      ...suppliersPortalOrdersRoutes,
      ...suppliersPortalTrackingRoutes,
      ...PageUnauthorizedRoutes,
      ...PageNotFoundRoutes,
    ],
  },
  ...PageNotFoundRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (supportedLocalesInclude(getLocaleFromPath(to.fullPath))) {
    const { locale } = to.params;
    loadLocaleMessagesAsync(locale).then(() => {
      setDocumentLang(locale);
      setDocumentDirectionPerLocale(locale);
      //TODO Add meta title
      //setDocumentTitle(i18n.t("app.title"))
    });
    next();
  } else {
    next(`${defaultLocale}${to.fullPath}`);
  }
});

export default router;
