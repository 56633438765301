<template>
  <searchContainer
    :searchHandler="search"
    :resetHandler="reset"
    :accordionClasses="'bg-white p-2 border border-light rounded'"
    :containerClasses="'px-1'"
  >
    <template v-slot:items>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="branch_id">{{ $t(`fields.branch_id`) }}</label>
            <select-dashboard-platform-branches
              v-model="filters.branch_id"
              :platform_id="platformId"
              :multiple="true"
              :error="fieldErrors.branchId"
            />
            <span v-if="fieldErrors.branchId" class="invalid-feedback d-block" role="alert">
                <strong>{{ fieldErrors.branchId }}</strong>
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="zone_id">{{ $t(`fields.zone_id`) }}</label>
            <select-dashboard-platform-zones
              v-model="filters.zone_id"
              :platform_id="platformId"
              :multiple="true"
              :error="fieldErrors.zoneId"
            />
            <span v-if="fieldErrors.zoneId" class="invalid-feedback d-block" role="alert">
                <strong>{{ fieldErrors.zoneId }}</strong>
            </span>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="order_status">{{ $t(`fields.status`) }}</label>
            <select-order-status v-model="filters.order_status" placeholder="placeholders.all" :multiple="true" :excludeValues="['DELIVERED', 'CANCELED', 'FAILED_TO_ASSIGN', 'RETURNED']"/>
            <span v-if="fieldErrors.orderStatus" class="invalid-feedback d-block" role="alert">
                <strong>{{ fieldErrors.orderStatus }}</strong>
            </span>
          </div>
        </div>
        
      </div>
    </template>
  </searchContainer>
</template>
<script>
import { mapGetters } from "vuex";
import { CRUD_SEARCH_RESET } from "actions/crud";
import searchContainer from "@/components/crud/card/CardSearch";

const CRUD_CODE = "dashboard";
export default {
  name: "DashboardSearch",
  components: {
    searchContainer
  },
  props: {
    listHandler: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      transPrefix: CRUD_CODE,
      platformId: this.$router.currentRoute.params.id,
      filters: {
        branch_id: this.getFilterByKey("branch_id"),
        zone_id: this.getFilterByKey("zone_id"),
        order_status: this.getFilterByKey("order_status")
      },
      filtersMap: {
        branch_id: "branchIds",
        zone_id: "zoneIds",
        order_status: "orderStatus"
      },
      fieldErrors: {}
    };
  },
  mounted() {
    this.setSearchParams(this.filters, this.filtersMap, {
      routeParams: this.getFilters(this.filters)
    });
  },
  computed: {
    ...mapGetters(["getSearchFilters"])
  },
  methods: {
    search() {
      const hasBranchId = (this.filters.branch_id || []).length;
      const hasZoneId = (this.filters.zone_id || []).length;

      this.setSearchParams(this.filters, this.filtersMap, {
        routeParams: this.getFilters(this.filters)
      });

      this.pushRoute(this.getSearchFilters);
      this.listHandler();

      if (!hasBranchId && !hasZoneId) {
        this.fieldErrors["branchId"] = this.$i18n.t("validation.either_required");
        this.fieldErrors["zoneId"] = this.$i18n.t("validation.either_required");
      } else {
        this.fieldErrors["branchId"] = null;
        this.fieldErrors["zoneId"] = null;
      }
    },
    reset() {
      this.$store.dispatch(CRUD_SEARCH_RESET);

      this.$store.dispatch(CRUD_SEARCH_RESET).then(() => {
        this.setSearchParams(this.filters, this.filtersMap, {
          routeParams: this.getFilters(this.filters)
        });
      });
      this.filters = this.clearFilters(this.filters);
      this.pushRoute();
      this.listHandler();
      this.fieldErrors = {};
    },
    pushRoute(query = {}) {
      this.$router.push({ name: this.$route.name, query }).catch(() => {});
    }
  }
};
</script>
