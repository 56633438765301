export const PLATFORMS_LIST = "PLATFORMS_LIST";
export const PLATFORMS_CREATE = "PLATFORMS_CREATE";
export const PLATFORMS_UPDATE = "PLATFORMS_UPDATE";
export const PLATFORMS_DELETE = "PLATFORMS_DELETE";
export const PLATFORMS_SHOW = "PLATFORMS_SHOW";
export const PLATFORMS_USERS_LIST = "PLATFORMS_USERS_LIST";
export const PLATFORMS_USERS_CREATE = "PLATFORMS_USERS_CREATE";
export const PLATFORMS_USERS_SHOW = "PLATFORMS_USERS_SHOW";
export const PLATFORMS_APPS_LIST = "PLATFORMS_APPS_LIST";
export const PLATFORMS_APPS_CREATE = "PLATFORMS_APPS_CREATE";
export const PLATFORMS_APPS_UPDATE = "PLATFORMS_APPS_UPDATE";
export const PLATFORMS_APPS_DELETE = "PLATFORMS_APPS_DELETE";
export const PLATFORMS_APPS_SHOW = "PLATFORMS_APPS_SHOW";
export const PLATFORMS_DEVICE_TYPE_LIST = "PLATFORMS_DEVICE_TYPE_LIST";
export const PLATFORMS_CONFIGS_SHOW = "PLATFORMS_CONFIGS_SHOW";
export const PLATFORMS_CONFIGS_UPDATE = "PLATFORMS_CONFIGS_UPDATE";
export const PLATFORMS_FILES_SHOW = "PLATFORMS_FILES_SHOW";
export const PLATFORMS_FILES_UPDATE = "PLATFORMS_FILES_UPDATE";
export const PLATFORMS_INVOICES_LIST = "PLATFORMS_INVOICES_LIST";
export const PLATFORMS_INVOICES_CREATE = "PLATFORMS_INVOICES_CREATE";
export const PLATFORMS_INVOICES_SHOW = "PLATFORMS_INVOICES_SHOW";
export const PLATFORMS_INVOICES_STATUS_LIST = "PLATFORMS_INVOICES_STATUS_LIST";
export const PLATFORMS_INVOICES_UPDATE_STATUS= "PLATFORMS_INVOICES_UPDATE_STATUS";
export const PLATFORMS_DETAILS_SHOW = "PLATFORMS_DETAILS_SHOW";
export const PLATFORMS_DETAILS_UPDATE = "PLATFORMS_DETAILS_UPDATE";
export const PLATFORMS_SETTINGS_SHOW = "PLATFORMS_SETTINGS_SHOW";
export const PLATFORMS_SETTINGS_UPDATE = "PLATFORMS_SETTINGS_UPDATE";
export const PLATFORMS_SETTINGS_ALGORITHMS_LIST = "PLATFORMS_SETTINGS_ALGORITHMS_LIST";
export const PLATFORMS_BRANCHES_LIST = "PLATFORMS_BRANCHES_LIST";
export const PLATFORMS_BRANCHES_SHOW = "PLATFORMS_BRANCHES_SHOW";
export const PLATFORMS_BRANCHES_CREATE = "PLATFORMS_BRANCHES_CREATE";
export const PLATFORMS_BRANCHES_UPDATE = "PLATFORMS_BRANCHES_UPDATE";
export const PLATFORMS_BRANCHES_DELETE = "PLATFORMS_BRANCHES_DELETE";
export const PLATFORMS_BRANCHES_SUPPLIER = "PLATFORMS_BRANCHES_SUPPLIER";
export const PLATFORMS_ZONES_CREATE = "PLATFORMS_ZONES_CREATE";
export const PLATFORMS_ZONES_LIST = "PLATFORMS_ZONES_LIST";
export const PLATFORMS_ZONES_SHOW = "PLATFORMS_ZONES_SHOW";
export const PLATFORMS_ZONES_UPDATE = "PLATFORMS_ZONES_UPDATE";
export const PLATFORMS_ZONES_DELETE = "PLATFORMS_ZONES_DELETE";
export const PLATFORMS_ZONES_SUPPLIER = "PLATFORMS_ZONES_SUPPLIER";
export const PLATFORMS_AREAS_SETTINGS_SHOW = "PLATFORMS_AREAS_SETTINGS_SHOW";
export const PLATFORMS_AREAS_SETTINGS_UPDATE = "PLATFORMS_AREAS_SETTINGS_UPDATE";
export const PLATFORMS_AREAS_SHIFTS_LIST = "PLATFORMS_AREAS_SHIFTS_LIST";
export const PLATFORMS_AREAS_SHIFTS_SHOW = "PLATFORMS_AREAS_SHIFTS_SHOW";
export const PLATFORMS_AREAS_SHIFTS_CREATE = "PLATFORMS_AREAS_SHIFTS_CREATE";
export const PLATFORMS_AREAS_SHIFTS_UPDATE = "PLATFORMS_AREAS_SHIFTS_UPDATE";
export const PLATFORMS_AREAS_SHIFTS_DELETE = "PLATFORMS_AREAS_SHIFTS_DELETE";
export const PLATFORMS_AREAS_SHIFTS_STATUS_LIST = "PLATFORMS_AREAS_SHIFTS_STATUS_LIST";
export const PLATFORMS_AREAS_SHIFTS_TYPE_LIST = "PLATFORMS_AREAS_SHIFTS_TYPE_LIST";
export const PLATFORMS_AREAS_SHIFTS_DAY_OF_WEEK_LIST = "PLATFORMS_AREAS_SHIFTS_DAY_OF_WEEK_LIST";
export const PLATFORMS_AREAS_SHIFTS_CAPTAINS_LIST = "PLATFORMS_AREAS_SHIFTS_CAPTAINS_LIST";
export const PLATFORMS_AREAS_SHIFTS_CAPTAINS_ATTACH = "PLATFORMS_AREAS_SHIFTS_CAPTAINS_ATTACH";
export const PLATFORMS_AREAS_SHIFTS_CAPTAINS_DEATTACH = "PLATFORMS_AREAS_SHIFTS_CAPTAINS_DEATTACH";
export const PLATFORMS_BRANCHES_TRACKING_LIST = "PLATFORMS_BRANCHES_TRACKING_LIST";
export const PLATFORMS_ZONES_TRACKING_LIST = "PLATFORMS_ZONES_TRACKING_LIST";
export const PLATFORMS_AREAS_SHIFTS_SUMMARIES_LIST = "PLATFORMS_AREAS_SHIFTS_SUMMARIES_LIST";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_LIST = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_LIST";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_SHOW = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_SHOW";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_CREATE = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_CREATE";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_UPDATE = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_UPDATE";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_DELETE = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_DELETE";
export const PLATFORMS_AREAS_SHIFTS_PRICING_RULES_TYPE_LIST = "PLATFORMS_AREAS_SHIFTS_PRICING_RULES_TYPE_LIST";
export const PLATFORMS_AREAS_PRICING_RULES_TYPE_LIST = "PLATFORMS_AREAS_PRICING_RULES_TYPE_LIST";
export const PLATFORMS_AREAS_SETTINGS_ASSIGNMENT_ALGORITHM_TYPE_LIST = "PLATFORMS_AREAS_SETTINGS_ASSIGNMENT_ALGORITHM_TYPE_LIST";
export const PLATFORMS_DASHBOARD_CAPTAINS_LIST = "PLATFORMS_DASHBOARD_CAPTAINS_LIST";
export const PLATFORMS_DASHBOARD_ORDERS_LIST = "PLATFORMS_DASHBOARD_ORDERS_LIST";
export const PLATFORMS_AREAS_SHIFTS_DASHBOARD_LIST = "PLATFORMS_AREAS_SHIFTS_DASHBOARD_LIST";
export const PLATFORMS_AREAS_PRICING_RULES_LIST = "PLATFORMS_AREAS_PRICING_RULES_LIST";
export const PLATFORMS_AREAS_PRICING_RULES_SHOW = "PLATFORMS_AREAS_PRICING_RULES_SHOW";
export const PLATFORMS_AREAS_PRICING_RULES_CREATE = "PLATFORMS_AREAS_PRICING_RULES_CREATE";
export const PLATFORMS_AREAS_PRICING_RULES_UPDATE = "PLATFORMS_AREAS_PRICING_RULES_UPDATE";
export const PLATFORMS_AREAS_PRICING_RULES_DELETE = "PLATFORMS_AREAS_PRICING_RULES_DELETE";
export const PLATFORMS_AREAS_ATTENDANCES_LIST = "PLATFORMS_AREAS_ATTENDANCES_LIST";
export const SHIFTS_ADD_CAPABILITIES = "SHIFTS_ADD_CAPABILITIES";
export const SHIFTS_REMOVE_CAPABILITIES = "SHIFTS_REMOVE_CAPABILITIES";
export const AREA_PRICING_RULES_ADD_CAPABILITIES = "AREA_PRICING_RULES_ADD_CAPABILITIES";
export const AREA_PRICING_RULES_REMOVE_CAPABILITIES = "AREA_PRICING_RULES_REMOVE_CAPABILITIES";
export const SHIFT_PRICING_RULES_ADD_CAPABILITIES = "SHIFT_PRICING_RULES_ADD_CAPABILITIES";
export const SHIFT_PRICING_RULES_REMOVE_CAPABILITIES = "SHIFT_PRICING_RULES_REMOVE_CAPABILITIES";
export const SHIFT_LATE_ATTENDANCES = "SHIFT_LATE_ATTENDANCES";
