<template>
    <abstract-dropdown-api
            v-model="selectedValue"
            :error="error"
            :id="id"
            :disabled="disabled"
            :multiple="multiple"
            :placeholder="placeholder"
            :noResultMessage="noResultMessage"
            :allow-empty="allowEmpty"
            :action="action"
			:params="params"
            :customLabelHandler="customLabel"
            :prepareResponseDataHandler="prepareResponseData"
    />
</template>

<script>
    import {TICKETS_RELATED_TYPE_LIST as CRUD_ACTION_LIST} from 'actions/tickets';

    export default {
        name: "FieldsDropdownTicketRelatedTypes",
        props: {
            value: {},
            id: {
                type: String,
                default: 'ticket_related_type'
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: 'placeholders.dropdown',
            },
            noResultMessage: {
                type: String,
                default: 'messages.no_result_found',
            },
            error: {
                type: String
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            params: {
                type: Object,
                default: () => {
                }
            },
            action: {
                type: String,
                default: CRUD_ACTION_LIST
            },
        },
        data() {
            return {
                selectedValue: this.value,
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                this.selectedValue = newVal;
            },
            selectedValue: function (newVal, oldVal) {
                this.$emit('input', newVal);
            }
        },
        methods: {
            customLabel(value) {
                return this.$i18n.t(`tickets.lists.related_type.${value.name}`);
            },
            prepareResponseData(data) {
                let preparedData = [];
                data.forEach(function (value, index) {
                    preparedData.push({'id': value, 'name': `${value}`});
                });
                return preparedData;
            }
        }
    }
</script>

<style scoped>

</style>
