<template>
    <div class="row">
      <div class="col">
        <card-container
                :title="$router.currentRoute.meta.title"
                :headers="tableHeaders"
                :lastPage="lastPage"
                :totalElements="totalElements"
                :currentPage="getCurrentPage()"
                :clickHandler="pagination">
  
          <template v-slot:card-header>
            <router-link :to="{ name: `${zonesRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
              <i class="fa fa-arrow-left"></i>
            </router-link>
          </template>
          <template v-slot:table-rows>
            <tr v-for="item in items" :key="item.id">
              <td> {{ item.id }} </td>
              <td> {{ item.areaId }} </td>
              <td> {{ item.areaType}} </td>
              <td> {{ item.shiftId}} </td>
              <td> {{ item.captainId}} </td>
              <td> {{ item.channel}} </td>
              <td> {{ item.status}} </td>
              <td> {{ item.createdAt}} </td>
              <td> {{ item.expiresAt}} </td>
              <table-item-actions :actions="tableItemActions" :params="{platform_id: id, area_type: 'ZONE', area_id: zone_id}"/>
            </tr>
            <tr class="text-center" v-if="!items.length">
              <td colspan="999">{{ $t('messages.no_result_found')}}</td>
            </tr>
          </template>
        </card-container>
      </div>
    </div>
  </template>
  <script>
  
    import { PLATFORMS_AREAS_ATTENDANCES_LIST as CRUD_ACTION_LIST} from 'actions/platforms';
    const PLATFORMS_CRUD_CODE="platforms";
    const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
    const CRUD_CODE=`${ZONES_CRUD_CODE}.attendances`;
    const CRUD_TRANS_CODE=`${CRUD_CODE}`;
    const CRUD_PERMISSION_CODE=`platform_area_shifts`;
    const ZONES_CODE= 'zones.attendances'
    export default {
      name: "PlatformZoneAttendancesIndex",
      components:{
      },
      data(){
        return {
          routePrefix: `${CRUD_CODE}`,
          transPrefix: `${CRUD_TRANS_CODE}`,
          permissionPrefix: CRUD_PERMISSION_CODE,
          zonesRoutePrefix: ZONES_CRUD_CODE,
          id:this.$router.currentRoute.params.id,
          zone_id:this.$router.currentRoute.params.zone_id,
          tableHeaders:[
            {
               'title':`fields.id`
            },
            {
               'title':`${ZONES_CODE}.fields.areaId`
            },
            {
              'title':`${ZONES_CODE}.fields.areaType`
            },
            {
              'title':`${ZONES_CODE}.fields.shiftId`
            },
            {
              'title':`${ZONES_CODE}.fields.captainId`
            },
            {
              'title':`${ZONES_CODE}.fields.channel`
            },
            {
              'title':`${ZONES_CODE}.fields.status`
            },
            {
              'title':`fields.created_at`
            },
            {
              'title':`fields.expires_at`
            }
          ],
          items: [],
          perPage:20,
          totalElements:0,
          lastPage:0
        }
      },
      mounted() {
        this.getList();
      },
      methods: {
        getList(){
          this.$store.dispatch(CRUD_ACTION_LIST,{
            platform_id: this.id,
            area_type: "ZONE",
            area_id: this.zone_id,
            pageId: this.getCurrentPage()-1,
          }).then(response=>{
            this.items=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
          });
        },
        pagination(page){
          if(page!== this.getCurrentPage()){
            this.$router.push({
              name: `${this.routePrefix}.index`,
              query:{
                page
              }
            });
            this.getList();
          }
        },
        getCurrentPage(){
          let currentPage=this.$route.query.page??1;
          return parseInt(currentPage>=1?currentPage:1);
        },
      }
    };
  </script>
  