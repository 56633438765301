<template>
  <div>
    <header class="mb-2 mt-1 container-fluid">
      <search />
    </header>
    <div class="mx-3 px-1" v-if="getSearchFiltersSensitized.assignmentId">
      <div class="">
        <maps-assignments :items="assignment.mapItems" />
        <orders v-model="assignment"></orders>
      </div>
    </div>
    <footer>
      <counters :assignment="assignment"></counters>
    </footer>
  </div>
</template>
<script>
import search from "./partials/search";
import orders from "./partials/orders";
import counters from "./partials/counters";
import { mapGetters } from "vuex";

const ORDERS_CRUD_CODE = "orders";
const CRUD_CODE = `${ORDERS_CRUD_CODE}.assignment_tracking`;
export default {
  name: "AssignmentTrackingIndex",
  components: {
    search,
    orders,
    counters
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      assignment: {}
    };
  },
  computed: {
    ...mapGetters(["getSearchFilters", "getSearchFiltersSensitized"])
  }
};
</script>
