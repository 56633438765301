<template>
    <div v-if="!thumbnail && !disabled" class="custom-theme-file" v-bind:class="{'is-invalid':error}">
        <label :for="id" class="btn btn-success btn-sm">
            {{ $t('labels.upload') }} <i class="fa fa-upload"></i>
        </label>
        <input  @change="onFileChange"
                :id="id"
                type="file"
                class="form-control d-none"
                name="image"
                accept="image/*"
                v-bind:class="{'is-invalid':error}"
        >
    </div>
    <div class="custom-theme-file" v-bind:class="{'is-invalid':error}" v-else>
        <a v-if="thumbnail" :href="thumbnail" target="_blank">
            <img v-if="thumbnail" :src="thumbnail" class="img-thumbnail mh-100 mw-100 w-50 h-50 mb-2 d-block">
        </a>
        <button type="button" v-if="!disabled" class="btn btn-danger btn-sm" @click.prevent="removeImage">
            {{ $t('labels.delete_image') }} <i class="fa fa-trash"></i>
        </button>
    </div>
</template>


<script>
    export default {
        name: "FieldsFilesImage",
        props: {
            thumbnailImage:{
                type:String,
            },
            id: {
                type:String,
                default:'image'
            },
            error:{
                type:String
            },
            disabled:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return {
                thumbnail:this.thumbnailImage,
                file:'',
            }
        },
        mounted() {
        },
        watch: {
            thumbnailImage: function(newVal, oldVal) {
                this.thumbnail=newVal;
            }
        },
        methods: {
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;

                this.createImage(files[0]);
            },
            createImage(file) {
                let reader = new FileReader();
                let vm = this;

                this.file=file;

                reader.onload = (e) => {
                    vm.thumbnail = e.target.result;
                };
                reader.readAsDataURL(file);

                this.prepareImageObject();
            },
            removeImage(e) {
                this.file='';
                this.thumbnail='';
                this.prepareImageObject();
            },
            prepareImageObject(){
                this.$emit('input',{
                    file:this.file,
                    thumbnail:this.thumbnail
                });
            }
        }
    }
</script>

<style scoped>

</style>
