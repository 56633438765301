<template>
<div class="row">
    <div class="col">
    <card-container
            :title="$router.currentRoute.meta.title"
            :headers="tableHeaders"
            :lastPage="lastPage"
            :totalElements="totalElements"
            :currentPage="getCurrentPage()"
            :clickHandler="pagination">

        <template v-slot:card-header>

        </template>


        <template v-slot:table-rows>
        <tr v-for="item in items" :key="item.id">
            <td>
                <a v-if="item.invoice" :href="item.invoice.url" class="nav-link" target="_blank"> {{ $t('labels.download') }}</a>
            </td>
            <td>
                <a v-if="item.report" :href="item.report.url" class="nav-link" target="_blank"> {{ $t('labels.download') }}</a>
            </td>
            <td> {{ item.status }} </td>
            <td class="text-direction-ltr"> {{ item.fromDate | moment("Y-MM-DD") }} </td>
            <td class="text-direction-ltr"> {{ item.toDate | moment("Y-MM-DD") }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
        </tr>
        <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
        </tr>
        </template>

        <template v-slot:card-footer>
            <export-action :crudCode="routePrefix" v-can:report="permissionPrefix"></export-action>
        </template>
    </card-container>
    </div>
</div>
</template>
<script>

import { INVOICES_LIST as CRUD_ACTION_LIST} from 'actions/platforms_portal/invoices';
const INVOICES_CODE="platforms.invoices";
const CRUD_CODE=`platforms_portal.${INVOICES_CODE}`;
const CRUD_PERMISSION_CODE=`partner_${INVOICES_CODE}`;
export default {
    name: "PlatformsPortalInvoiceIndex",
    data(){
        return {
            routePrefix: CRUD_CODE,
            transPrefix: INVOICES_CODE,
            permissionPrefix: CRUD_PERMISSION_CODE,
            tableHeaders:[
            {
                'title':`fields.invoice`
            },
            {
                'title':`fields.report`
            },
            {
                'title':`fields.status`
            },
            {
                'title':`fields.from_date`
            },
            {
                'title':`fields.to_date`
            },
            {
                'title':`fields.created_at`
            }
            ],
            items: [],
            perPage:20,
            totalElements:0,
            lastPage:0,
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList(){
            this.$store.dispatch(CRUD_ACTION_LIST,{
            pageId: this.getCurrentPage()-1,
            ...this.getSearchFiltersSensitized
            }).then(response=>{
            this.items=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
            });
        },
        pagination(page){
            if(page!== this.getCurrentPage()){
            this.$router.push({
                name: `${this.routePrefix}.index`,
                query:{
                page,
                ...this.getSearchFiltersIfExists
                }
            });
            this.getList();
            }
        },
        getCurrentPage(){
            let currentPage=this.$route.query.page??1;
            return parseInt(currentPage>=1?currentPage:1);
        },
    }
};
</script>
