<template>
  <div aria-labelledby="meta_data-tab" class="tab-pane fade" id="meta_data" role="tabpanel">
    <div class="row">
      <div class="col-12">
        <table-container :headers="headers">
          <tr :key="item.id" v-for="item in items">
            <td>{{ item.data }}</td>
            <td>{{ item.label }}</td>
            <td>{{ item.languageCode }}</td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">
              {{ $t("messages.no_result_found") }}
            </td>
          </tr>
        </table-container>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsMetaData",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: function(){
          return []
        }
      },
      headers: {
        type: Array,
        default: function(){
          return [
            {
              title: `fields.data`,
            },
            {
              title: `fields.label`,
            },
            {
              title: `fields.language_code`,
            },
          ]
        }
      },
    },
    data(){
      return {
      }
    },
  };
</script>