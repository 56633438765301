<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="id">{{ $t(`fields.id`)}}</label>
                        <input v-model="filters.id" id="id" type="text" class="form-control" name="id">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="id_number">{{ $t(`fields.id_number`)}}</label>
                        <input v-model="filters.id_number" id="id_number" type="text" class="form-control" name="id_number">
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                        <input v-model="filters.mobile_number" id="mobile_number" type="text" class="form-control" name="mobile_number">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="country_id">{{ $t(`fields.country_id`)}}</label>
                        <input v-model="filters.country_id" id="country_id" type="text" class="form-control" name="country_id">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="city_id">{{ $t(`fields.city_id`)}}</label>
                        <input v-model="filters.city_id" id="city_id" type="text" class="form-control" name="city_id">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="start_date">{{ $t(`fields.start_date`)}}</label>
                        <input class="form-control" id="start_date" name="start_date" type="date" v-model="filters.start_date">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="end_date">{{ $t(`fields.end_date`)}}</label>
                        <input class="form-control" id="end_date" name="end_date" type="date" v-model="filters.end_date">
                    </div>
                </div>
            
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const CRUD_CODE = "captain_registration_requests";
    export default {
        name: "SuppliersPortalCaptainSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    id: this.getFilterByKey('id'),
                    id_number: this.getFilterByKey('id_number'),
                    mobile_number: this.getFilterByKey('mobile_number'),
                    country_id: this.getFilterByKey('country_id'),
                    city_id: this.getFilterByKey('city_id'),
                    start_date: this.getFilterByKey('start_date'),
                    end_date: this.getFilterByKey('end_date'),

                },
                filtersMap: {
                    id: 'id',
                    id_number: 'idNumber',
                    mobile_number: 'mobileNumber',
                    country_id: 'countryId',
                    city_id: 'cityId',
                    start_date: 'startDate',
                    end_date: 'endDate',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
