import store from "../store";
import {CRUD_UPDATE_SEARCH} from "actions/crud";

export default {
    methods: {
        getFilters: function (filters) {
            let clonedFilters = {};

            Object.keys(filters).forEach(key => {
                clonedFilters[key] = filters[key]
            });

            return clonedFilters;
        },
        clearFilters: function (filters) {
            Object.keys(filters).forEach(key => {
                filters[key] = null;
            });
            return filters;
        },
        getFilterByKey: function (key) {
            let params = new URLSearchParams(window.location.search), value = params.getAll(key);
            switch (value.length) {
                case 0:
                    value = "";
                    break;
                case 1:
                    value = value[0];
            }
            return value ? value : null;
        },
        setSearchParams: function (filters, filtersMap, additionalParams = {}) {
            store.commit(CRUD_UPDATE_SEARCH, {
                'show': true,
                'items': this.getFilters(filters),
                'filtersMap': filtersMap,
                'routeParams': this.getQueryStringParams(),
                ...additionalParams
            });
        },
        getQueryStringParams: function () {
            let filters = {}, params = new URLSearchParams(window.location.search);
            for (const key of params.keys()) {
                filters[key] = this.getFilterByKey(key);
            }
            return filters;
        }
    }
}
