import {
    APP_VERSIONS_LIST as CRUD_ACTION_LIST,
    APP_VERSIONS_CREATE as CRUD_ACTION_CREATE,
    APP_VERSIONS_SHOW as CRUD_ACTION_SHOW,
    APP_VERSIONS_UPDATE as CRUD_ACTION_UPDATE,
    APP_VERSIONS_DELETE as CRUD_ACTION_DELETE,
    APP_VERSIONS_UPDATE_ACTION_LIST as CRUD_ACTION_UPDATE_ACTION_LIST,
    APP_VERSIONS_DEVICE_TYPE_LIST as CRUD_ACTION_DEVICE_TYPE_LIST,
} from "../actions/app_versions";
import axioses from "@/axios/config";

const routePrefix='app_versions';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/create`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        let response ={
            data: ["NO_UPDATE", "RECOMMENDED", "FORCE"]
        };
        resolve(response);
    });
  },
  [CRUD_ACTION_DEVICE_TYPE_LIST]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
            let response ={
                data: ["Android", "IOS"]
            };
            resolve(response);
        });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
