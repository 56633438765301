<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${platformsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
          <router-link :to="{ name: `${routePrefix}.shifts.dashboard.index`}"  class="btn btn-primary btn-sm" v-b-tooltip.hover="{ animation: false}" :title="$t(`${routePrefix}.shifts.dashboard.list_short_title`)">
            <i class="ni ni-tv-2"></i>
          </router-link>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.externalId }} </td>
            <td> {{ item.name }} </td>
            <td> {{ (item.zone && item.zone.city && ($i18n.locale === 'ar' ? item.zone.city.nameAr : item.zone.city.name)) }}</td>
            <td> {{ (item.zone && ($i18n.locale === 'ar' ? item.zone.nameAr : item.zone.name)) }}</td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
            <table-item-actions :actions="tableItemActions" :params="{id, 'branch_id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { PLATFORMS_BRANCHES_LIST as CRUD_ACTION_LIST, PLATFORMS_BRANCHES_DELETE as CRUD_ACTION_DELETE} from 'actions/platforms';
  const PLATFORMS_CRUD_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CRUD_CODE}.branches`;
  const CRUD_TRANS_CODE=`${CRUD_CODE}`;
  const CRUD_PERMISSION_CODE=`platform_branches`;
  export default {
    name: "PlatformBranchIndex",
    data(){
      return {
        routePrefix: `${CRUD_CODE}`,
        transPrefix: `${CRUD_TRANS_CODE}`,
        permissionPrefix: CRUD_PERMISSION_CODE,
        platformsRoutePrefix: PLATFORMS_CRUD_CODE,
        platformsTransPrefix: PLATFORMS_CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title':`fields.external_id`
          },
          {
            'title':`fields.name`
          },
          {
            'title':`fields.city`
          },
          {
            'title':`fields.zone`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'shifts',
            route_name:`${CRUD_CODE}.shifts.index`,
            color_class:`text-teal`,
            icon:`fa fa-briefcase`,
            can:`platform_area_shifts_can_view`,
            title: `${CRUD_CODE}.shifts.title`,
          },
          {
             code:'pricing_rules',
             route_name:`${CRUD_CODE}.pricing_rules.index`,
             color_class:`text-success`,
             icon:`fa fa-money-bill`,
             can:`platform_area_shifts_can_view`,
             title: `${CRUD_CODE}.pricing_rules.title`,
          },
          {
            code:'tracking',
            route_name:`${CRUD_CODE}.tracking.index`,
            can:`${CRUD_PERMISSION_CODE}_can_update`,
            color_class:`text-gray`,
            icon:`fa fa-map`,
            title: `${CRUD_CODE}.tracking.title`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_update`,
          },
          {
            code:'settings',
            route_name:`${CRUD_CODE}.settings.show`,
            color_class:`text-success`,
            icon:`fa fa-cogs`,
            can:`platform_area_settings_can_view`,
            title: `${CRUD_CODE}.settings.title`,
          },
          {
            code:'attendances',
            route_name:`${CRUD_CODE}.attendances.index`,
            color_class:`text-blue`,
            icon:`fa fa-user-plus`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
            title: `${CRUD_CODE}.attendances.title`,
          },
          {
            code:'delete',
            can:`${CRUD_PERMISSION_CODE}_can_delete`,
            action:CRUD_ACTION_DELETE,
            successCallback:this.getList
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          platformId:this.id,
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
