<template>
    <div class="table-responsive">
        <table class="table align-items-center table-flush">
            <table-header :headers="headers"/>
            <tbody class="list">
                <slot />
            </tbody>
        </table>
    </div>
</template>


<script>
    export default {
        props: {
            headers: {
                type: Array
            },
        },
        name: "TableContainer",
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>