import {
    SHARED_LISTS_LANGUAGE_CODE_LIST as LANGUAGE_CODE_LIST,
    SHARED_LISTS_YES_NO_LIST as YES_NO_LIST,
} from "../actions/shared_lists";
import axioses from "@/axios/config";

const state = {};

const getters = {};

const actions = {
    [LANGUAGE_CODE_LIST]: ({commit, dispatch}, params) => {
        return new Promise((resolve, reject) => {
            let response = {
                data: ["ar", "en", "ur"]
            };
            resolve(response);
        });
    },
    [YES_NO_LIST]: ({commit, dispatch}, params) => {
        return new Promise((resolve, reject) => {
            let response = {
                data: ["true", "false"]
            };
            resolve(response);
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
