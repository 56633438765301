import {
    INVOICES_LIST as CRUD_ACTION_LIST,
} from "../../actions/platforms_portal/invoices";
import axioses from "@/axios/config";
import qs from "qs"
const routePrefix='invoices';

const state = {};

const getters = {};

const actions = {
    [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
        return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.platformPortal
            .get(`platforms/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
