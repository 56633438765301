import { render, staticRenderFns } from "./platform_area_shift_statuses.vue?vue&type=template&id=19e6fdf8&scoped=true&"
import script from "./platform_area_shift_statuses.vue?vue&type=script&lang=js&"
export * from "./platform_area_shift_statuses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e6fdf8",
  null
  
)

export default component.exports