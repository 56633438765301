<template>
  <div class="">
    <button
      class="active-order-tracking-btn btn btn-success"
      @click.prevent="openSideMenu()"
    >
      <span>
        <i class="fa fa-arrow-circle-left"></i>
      </span>
      {{ $t("labels.orders") }}
    </button>
    <nav
      class="navbar navbar-vertical pt-5 navbar-expand-xs navbar-light closed right-0 pb-0 component-sidenav"
      id="sidenav-active-order-tracking"
    >
      <div class="scrollbar-inner bg-lighter">
        <div
          class="d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100"
        >
          <div class="pt-3 mx-0 row flex-row-reverse w-100">
            <div class="col-6 p-0 text-right">
              <a href="#" class="p-2" @click.prevent="hideSideMenu()">
                <i class="fa fa-arrow-circle-right"></i>
              </a>
            </div>
            <div class="col-6">
              <div class="form-group position-relative search mr--4 mt--1 mb-2">
                <label
                  for="filter_order_id"
                  class="position-absolute left-2 top-1 opacity-7"
                >
                  <i class="fa fa-search"></i>
                </label>
                <input
                  v-model="filters.order_id"
                  id="filter_order_id"
                  type="text"
                  class="form-control form-control-sm border-primary border-2"
                  name="filter_order_id"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
          <div class="collapse navbar-collapse">
            <div
              v-if="loading"
              class="text-center position-fixed overflow-hidden bottom-0 top-0 mt-5 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal"
            >
              <span
                class="spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"
              ></span>
            </div>
            <!-- Nav items -->
            <ul class="navbar-nav">
              <li
                class="nav-item nav-link tracking-order-item mx-0 px-0 row"
                v-for="item in getItemsByFilter()"
                :key="item.id"
              >
                <div class="col-8">
                  <h4 class="order-id mb-0 font-size-12">
                    {{ item.backendId }}
                  </h4>
                </div>
                <div class="col-8">
                  <span class="order-id mb-0 font-size-10">
                    {{ item.assignmentId }}
                  </span>
                </div>
                <div class="col-8 text-left mt--1">
                  <span class="order-id mb-0 font-size-10">
                    {{ item.tripId }}
                  </span>
                </div>
                <div class="col-4 text-right mt--1" >
                  <span class="font-size-10">
                    {{ item.tripIndex }}
                  </span>
                </div>
                <div class="col-8 text-left mt--1">
                  <span class="platform font-size-10">
                    <span class="text-direction-ltr">
                      {{ item.createdAt | moment("hh:mm:ss A") }}
                    </span>
                  </span>
                </div>
                <div class="col-4 text-right mt--1" v-if="item.captainId">
                  <span class="font-size-10">
                    <i class="fas fa-car text-blue"></i>
                    {{ item.captainId }}
                  </span>
                </div>
                <div class="col-12">
                  <a
                    @click.stop="showItemHandler(item)"
                    class="text-white btn btn-block btn-sm btn-primary mt-1 font-size-12"
                    v-can:view="orderPermissionPrefix"
                  >
                    <span><i class="fas fa-eye"></i></span>
                    <span class="mr-2">
                      {{ $t("crud.show") }}
                    </span>
                  </a>
                </div>
              </li>
              <li
                class="nav-item nav-link mx-0 px-0 row"
                v-if="getItemsByFilter().length === 0"
              >
                <div class="col-12 text-center">
                  <h2 class="order-id">{{ $t("messages.no_result_found") }}</h2>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import { ORDERS_ASSIGNMENTS_TRACKING_LIST as CRUD_ACTION_LIST } from "actions/orders";
const ORDER_CODE = `orders`;
const ORDER_PERMISSION_CODE = `${ORDER_CODE}`;
const CRUD_CODE = `${ORDER_CODE}.assignment_tracking`;
const CRUD_PERMISSION_CODE = `${ORDER_CODE}`;

export default {
  name: "AssignmentTrackingOrders",
  props: {},
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      orderRoutePrefix: ORDER_CODE,
      orderTransPrefix: ORDER_CODE,
      orderPermissionPrefix: ORDER_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      branch_id: this.$router.currentRoute.params.branch_id,
      items: [],
      assignmentId: "",
      captainsCount: 0,
      bikesCount: 0,
      carsCount: 0,
      distanceMeasurementType: "",
      firstAssignedOrderId: "",
      loading: false,
      filters: {
        order_id: ""
      }
    };
  },
  watch: {
    getSearchFilters: function(newVal, oldVal) {
      this.refreshList();
    },
    items: function(newVal, oldVal) {
      this.$emit("input", this.getAssignemnt());
      if(!this.items.length) {
        swal({
          icon: "error",
          text: this.$i18n.t(`${this.routePrefix}.errors.empty_list`),
          buttons: false,
          timer: 5000
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersSensitized",
      "getSearchFiltersIfExists"
    ])
  },
  mounted() {
    $(
      ".scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome"
    )
      .scrollbar()
      .scrollLock();
      this.refreshList();
  },
  methods: {
    openSideMenu() {
      $("#sidenav-active-order-tracking")
        .removeClass("closed")
        .addClass("opened");
    },
    hideSideMenu() {
      $("#sidenav-active-order-tracking")
        .removeClass("opened")
        .addClass("closed");
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getList() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          ...this.getSearchFiltersSensitized
        })
        .then(response => {
          this.assignmentId = response.data.assignmentId;
          this.captainsCount = response.data.captainsCount;
          this.bikesCount = response.data.bikesCount;
          this.distanceMeasurementType = response.data.distanceMeasurementType;
          this.carsCount = response.data.carsCount;
          this.firstAssignedOrderId = response.data.firstAssignedOrderId;
          this.items = response.data.orders;
          this.loading = false;
        })
        .catch(error => {
          this.captainsCount = 0;
          this.bikesCount = 0;
          this.carsCount = 0;
          this.distanceMeasurementType = "";
          this.firstAssignedOrderIid = "";
          this.items = [];
          this.loading = false;
        });
    },
    getAssignemnt() {
      return {
        assignmentId: this.assignmentId,
        firstAssignedOrderId: this.firstAssignedOrderId,
        bikesCount: this.bikesCount,
        captainsCount: this.captainsCount,
        carsCount: this.carsCount,
        distanceMeasurementType: this.distanceMeasurementType,
        mapItems: this.getMapItems()
      };
    },
    getMapItems() {
      let mapItems = [];
      if (this.items.length) {
        const trips = this.groupBy(this.items, "tripId");
        const storeLocation = (this.items[0]?.tasks || []).find(
          task => task.taskType === "PICK_UP"
        );
        mapItems.push({
          id: "STORE",
          type: "STORE",
          iconColor: null,
          latitude: storeLocation?.latitude,
          longitude: storeLocation?.longitude
        });
        Object.keys(trips).forEach((key, tripIndex) => {
          let orders = trips[key];
          orders.forEach((order, orderIndex) => {
            let deliveryTask = order.tasks.find(
              task => task.taskType === "DELIVERY"
            );

            let clickHandler = () => {
              let routeData = this.$router.resolve({
                name: `${this.orderRoutePrefix}.show`,
                params: { id: order.id }
              });
              window.open(routeData.href, "_blank");
            };

            mapItems.push({
              id: `${tripIndex}-${orderIndex}`,
              type: this.prepareOrderIconType(order, tripIndex),
              iconColor: this.prepareOrderIconColor(order, tripIndex),
              latitude: deliveryTask.latitude,
              longitude: deliveryTask.longitude,
              clickHandler,
              label: this.prepareOrderTooltip(order)
            });
          });
        });
      }
      return mapItems;
    },
    isRelatedToOtherAssignment(order) {
      return !order.assignmentId || order.assignmentId != this.assignmentId;
    },
    isFirstAssignedOrder(order) {
      return this.firstAssignedOrderId == order.id;
    },
    prepareOrderIconType(order, tripIndex) {
      return this.isFirstAssignedOrder(order)
        ? "FIRST_ASSIGNED"
        : `TRIP-${tripIndex}`;
    },
    prepareOrderIconColor(order, tripIndex) {
      let colors = [
        "#F44336",
        "#FFEB3B",
        "#00BCD4",
        "#4CAF50",
        "#9C27B0",
        "#2196F3",
        "#FF9800",
        "#3F51B5",
        "#03A9F4",
        "#009688",
        "#FF5722",
        "#795548"
      ];
      return this.isRelatedToOtherAssignment(order)
        ? "#CFD8DC"
        : colors[tripIndex % colors.length];
    },
    prepareOrderTooltip(order) {
      let firstAssignedOrderLabel = this.isFirstAssignedOrder(order)
        ? `
            <div>
              <h4 class="mb-0 text-left"> 
                <span>
                  <i class="fa fa-flag"></i>
                </span>
              </h3>
            </div>
          `
        : ``;

      let labelRelatedToCurrentAssignemnt = !this.isRelatedToOtherAssignment(order)
      ? `
          <div>
            <strong>
              ${this.$i18n.t(`${this.orderTransPrefix}.fields.trip_id`)}:
            </strong> 
            ${order.tripId}
          </div>
          <div>
            <strong>
              ${this.$i18n.t(`${this.orderTransPrefix}.fields.trip_index`)}:
            </strong> 
            ${order.tripIndex}
            </div>
          <div>
            <strong>
              ${this.$i18n.t(`fields.captain_id`)}:
            </strong> 
            ${order.captainId}
          </div>
      `
      : ``;

      let label = `
        <div class="col-12 px-2">
          ${firstAssignedOrderLabel}
          <div>
            <strong>
              ${this.$i18n.t(`${this.orderTransPrefix}.fields.backend_id`)}:
            </strong> 
            ${order.backendId}
          </div>
          ${labelRelatedToCurrentAssignemnt}
        </div>
      `;

      return label;
    },
    refreshList() {
      this.getList();
      this.filters = this.clearFilters(this.filters);
    },
    showItemHandler(item) {
      let routeData = this.$router.resolve({
        name: `${this.orderRoutePrefix}.show`,
        params: { id: item.id }
      });
      window.open(routeData.href, "_blank");
    },
    getItemsByFilter() {
      let items = this.items;
      Object.keys(this.filters).forEach(key => {
        let filterValue = this.filters[key];
        if (filterValue) {
          switch (key) {
            case "order_id":
              items = items.filter(function(item) {
                return item.backendId.search(`${filterValue}`) !== -1;
              });
              break;
          }
        }
      });
      return items;
    }
  }
};
</script>
<style type="text/css" scoped>
.scrollbar-inner .scroll-element.scroll-y {
  background-color: #e5e5e594 !important;
  width: 8px !important;
  opacity: 1 !important;
}
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: unset !important;
}

.loader.force-right-0 {
  right: 0 !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}
</style>
