<template>
  <div class="row">
    <div class="col">
      <card-container
        :title="$router.currentRoute.meta.title"
        :headers="tableHeaders"
        :lastPage="lastPage"
        :totalElements="totalElements"
        :currentPage="getCurrentPage()"
        :clickHandler="pagination"
      >
        <template v-slot:card-header>
          <router-link
            :to="{ name: `${branchesRoutePrefix}.index` }"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList" />
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.areaName }}</td>
            <td>{{ item.shiftTime }}</td>
            <td>{{ item.areaTotalCaptains }}</td>
            <td>{{ item.capacity }}</td>
            <td>{{ item.freeCount }}</td>
            <td>{{ item.busyCount }}</td>
            <td>{{ item.returningToAreaCount }}</td>
            <td>{{ item.connectedCount }}</td>
            <td>{{ item.disconnectedCount }}</td>
            <td>{{ item.inAreaCount }}</td>
            <td>{{ item.roamingCount }}</td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id, 'shift_id': item.id, 'branch_id': item.areaId}">
            </table-item-actions>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t("messages.no_result_found") }}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { PLATFORMS_AREAS_SHIFTS_DASHBOARD_LIST as CRUD_ACTION_LIST } from "actions/platforms";
import search from "./partials/search";
const PLATFORMS_CRUD_CODE = "platforms";
const BRANCHES_CRUD_CODE = `${PLATFORMS_CRUD_CODE}.branches`;
const CRUD_CODE = `${BRANCHES_CRUD_CODE}.shifts.dashboard`;
const CRUD_TRANS_CODE = `${CRUD_CODE}`;
const CRUD_PERMISSION_CODE = `platform_area_shifts`;
export default {
  name: "PlatformBranchShiftDashboardIndex",
  components: {
    search,
  },
  data() {
    return {
      routePrefix: `${CRUD_CODE}`,
      transPrefix: `${CRUD_TRANS_CODE}`,
      permissionPrefix: CRUD_PERMISSION_CODE,
      branchesRoutePrefix: BRANCHES_CRUD_CODE,
      platformsTransPrefix: BRANCHES_CRUD_CODE,
      id: this.$router.currentRoute.params.id,
      branch_id: this.$router.currentRoute.params.branch_id,
      tableHeaders: [
        {
          title: `fields.branch_name`,
        },
        {
          title: `fields.time`,
        },
        {
          title: `fields.branch_capacity`,
        },
        {
          title: `fields.capacity`,
        },
        {
          title: `fields.free_count`,
        },
        {
          title: `fields.busy_count`,
        },
        {
          title: `fields.returning_to_area_count_icon`,
        },
        {
          title: `fields.connected_count_icon`,
        },
        {
          title: `fields.disconnected_count_icon`,
        },
        {
          title: `fields.in_area_count_icon`,
        },
        {
          title: `fields.roaming_count_icon`,
        },
        {
          title: `fields.actions`
        },
      ],
      tableItemActions:[
          {
            code:'show',
            route_name:`${BRANCHES_CRUD_CODE}.shifts.show`,
            icon:`fas fa-eye`,
            title: `${CRUD_CODE}.title`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
      ],
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersIfExists",
      "getSearchFiltersSensitized",
    ]),
  },
  methods: {
    getList() {
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          platformId: this.id,
          areaType: 'BRANCH',
          areaIds: [this.branch_id],
          pageId: this.getCurrentPage() - 1,
          ...this.getSearchFiltersSensitized,
        })
        .then((response) => {
          this.items = response.data.data;
          this.perPage = response.data.perPage;
          this.totalElements = response.data.totalElements;
          this.lastPage = response.data.pageCount;
        });
    },
    pagination(page) {
      if (page !== this.getCurrentPage()) {
        this.$router.push({
          name: `${this.routePrefix}.index`,
          query: {
            page,
          },
        });
        this.getList();
      }
    },
    getCurrentPage() {
      let currentPage = this.$route.query.page ?? 1;
      return parseInt(currentPage >= 1 ? currentPage : 1);
    },
  },
};
</script>