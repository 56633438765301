export const CAPTAINS_LIST = "SUPPLIERS_PORTAL_CAPTAINS_LIST";
export const CAPTAINS_CREATE = "SUPPLIERS_PORTAL_CREATE";
export const CAPTAINS_SHOW = "SUPPLIERS_PORTAL_CAPTAINS_SHOW";
export const GET_BLOCKED_REASON =
  "SUPPLIERS_PORTAL_CAPTAINS_GET_BLOCKED_REASON";
export const CAPTAINS_UPDATE = "SUPPLIERS_PORTAL_CAPTAINS_UPDATE";
export const CAPTAINS_ADD_CAPABILITIES =
  "SUPPLIERS_PORTAL_CAPTAINS_ADD_CAPABILITIES";
export const CAPTAINS_REMOVE_CAPABILITIES =
  "SUPPLIERS_PORTAL_CAPTAINS_REMOVE_CAPABILITIES";
