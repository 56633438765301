<template>
  <div>
    <header class="mb-2 mt-1 container-fluid">
      <search />
    </header>
    <div v-if="(getSearchFiltersSensitized.branchIds || []).length >= 1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-2 mb-2 px-1">
            <captains></captains>
          </div>
          <div class="col-12 col-xl-10">
            <orders></orders>
          </div>
        </div>
      </div>
      <footer>
        <counters></counters>
      </footer>
   </div>
   <div v-if="(getSearchFiltersSensitized.zoneIds || []).length >= 1">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-2 mb-2 px-1">
            <captains></captains>
          </div>
          <div class="col-12 col-xl-10">
            <orders></orders>
          </div>
        </div>
      </div>
      <footer>
        <counters></counters>
      </footer>
   </div>
  </div>
</template>
<script>
import search from "./partials/search";
import captains from "./partials/captains";
import orders from "./partials/orders";
import counters from "./partials/counters";
import Stomp from "webstomp-client";
import { mapGetters, mapState } from "vuex";
import {
  TRACKING_ADD_UPDATE_CAPTAIN,
  TRACKING_UPDATE_CAPTAIN_STATUS
} from "actions/sockets/tracking";
const PLATFORMS_CRUD_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CRUD_CODE}.dasboard`;
const CRUD_TRANS_CODE = `${CRUD_CODE}`;

export default {
  name: "DashboardIndex",
  components: {
    orders,
    captains,
    counters,
    search
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_TRANS_CODE,
      stompClient: null,
      stompOptions: {
        debug: false,
        heartbeat: {
          outgoing: 0,
          incoming: 20000
        }
      }
    };
  },
  mounted() {
    if (this.isStompLoaded) {
      this.connect();
    }
  },
  watch: {
    isStompLoaded: function(newVal, oldVal) {
      if (newVal) {
        this.connect();
      }
    }
  },
  computed: {
    ...mapGetters([
      "isStompLoaded",
      "getStompConnectionUrl",
      "getStompConnectionsTopic",
      "getStompLocationsTopic",
      "getAuthorizationHeader",
      "getScopedCaptains"
    ]),
    ...mapGetters(["getSearchFilters", "getSearchFiltersSensitized"])
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    connect() {
      this.stompClient = Stomp.client(
        this.getStompConnectionUrl,
        this.stompOptions
      );
      this.stompClient.connect(
        { "X-Authorization": this.getAuthorizationHeader },
        frame => {
          this.stompClient.subscribe(this.getStompConnectionsTopic, tick => {
            let responseBody = JSON.parse(tick.body);
            this.$store.commit(TRACKING_UPDATE_CAPTAIN_STATUS, {
              ...responseBody
            });
          });

          this.stompClient.subscribe(this.getStompLocationsTopic, tick => {
            let responseBody = JSON.parse(tick.body);
            responseBody.connected = true;

            if (this.getScopedCaptains[responseBody.captainId]) {
              this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN, {
                ...responseBody
              });
            }
          });
        },
        error => {
          console.log(error);
        }
      );
    },
    disconnect() {
      if (this.isConnected()) {
        this.stompClient.disconnect();
      }
    },
    isConnected() {
      return this.stompClient ? this.stompClient.connected : false;
    }
  }
};
</script>
