<template>
    <a href="javascript:;" @click.stop="showManualAssignPopUp(itemID)" class="btn btn-block btn-sm btn-info mt-1">
        <span>
          <i class="fas fa-random"></i>
        </span>
        <span class="mr-2">
          {{ $t(`labels.assign`) }}
        </span>
    </a>
</template>

<script>
    import { ORDERS_MANUAL_ASSIGN as CRUD_ACTION_MANUAL_ASSIGN } from "actions/orders";
    import { TRACKING_REFRESH_LIST } from "actions/sockets/tracking";

    export default {
        name: "TrackingOrderActionsManualAssign",
        props: {
            itemID:{}
        },
        data(){
            return {}
        },
        methods:{
            showManualAssignPopUp(id) {
                swal({
                    title: this.$i18n.t("labels.manually_assign"),
                    text: this.$i18n.t("labels.manually_assign_item"),
                    type: "input",
                    showCancelButton: true,
                    closeOnConfirm: false,
                    content: "input",
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.manually_assign"),
                    ],
                    inputPlaceholder: "Write something",
                }).then((value) => {
                    const captainId = parseInt(value, 0);
                    if (value !== null && Object.keys(value).length !== 0 && captainId) {
                        this.manuallyAssignOrder(id, captainId);
                    }
                });
            },
            manuallyAssignOrder(id, captain_id) {
                this.$store.dispatch(CRUD_ACTION_MANUAL_ASSIGN, {
                    id,
                    captain_id
                })
                .then(() => {
                    this.$emit('input',false);

                    this.$store.commit(TRACKING_REFRESH_LIST,{
                        key: "ALL",
                        refresh: true
                    });

                    swal({
                        icon: "success",
                        text: this.$i18n.t("messages.manually_assign_success"),
                        buttons: false,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    let message = "";
                    if (error.response) {
                        let responseData = error.response.data;

                        if (responseData.errors && responseData.errors.error) {
                            message = responseData.errors.error;
                        } else {
                            message = this.$i18n.t("messages.manually_assign_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: "error",
                            text: message,
                            buttons: false,
                            timer: 5000,
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
