<template>
    <router-link :to="{ name:options.route_name,params:{...params},query:{...query}}" class="px-1" v-bind:class="[options.color_class?options.color_class:'text-yellow']" v-b-tooltip.hover="{ animation: false}" :title="$t(options.title?options.title:'crud.edit')" v-can="options.can">
        <i class="" v-bind:class="[options.icon?options.icon:'fas fa-edit']"></i>
    </router-link>
</template>


<script>
    export default {
        name: "TableItemActionsEdit",
        props: {
            options: {
                default:function(){
                    return {};
                }
            },
            params:{
                type:Object,
                default:function(){
                    return {};
                }
            },
            query:{
                type:Object,
                default:function(){
                    return {};
                }
            },
            itemID:{}
        },
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>
