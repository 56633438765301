<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="media align-items-center">
                <a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
                  <img alt="Image placeholder" :src="getImageThumbnail(item)">
                </a>
                <div class="media-body">
                  <span class="name mb-0 text-sm"></span>
                </div>
              </div>
            </td>
            <td> {{ item.name }} </td>
            <td> {{ item.email }} </td>
            <td> {{ item.role }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
            <table-item-actions :actions="tableItemActions" :params="{'id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { USERS_LIST as CRUD_ACTION_LIST,USERS_DELETE as CRUD_ACTION_DELETE } from 'actions/platforms_portal/users';
  const USERS_CODE="users";
  const CRUD_CODE=`platforms_portal.${USERS_CODE}`;
  const CRUD_PERMISSION_CODE=`partner_${USERS_CODE}`;
  export default {
    name: "PlatformsPortalUsersIndex",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: USERS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        tableHeaders:[
          {
            'title':`fields.image`
          },
          {
            'title':`fields.name`
          },
          {
            'title':`fields.email`
          },
          {
            'title': `fields.roles`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'delete',
            can:`${CRUD_PERMISSION_CODE}_can_delete`,
            action:CRUD_ACTION_DELETE,
            successCallback:this.getList
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
      getImageThumbnail(item){
        let image='';
        if(item.profilePicture){
          image=item.profilePicture.url;
        }
        return image;
      }
    }
  };
</script>
