<template>
    <div :id="id" class="google-maps"></div>
</template>

<script>
    export default {
        name: "FieldsMapsPickAddress",
        props: {
          position:{
              type:Object,
              default:function(){
                  return {};
              }
          },
          centerPosition:{
            type:Object,
            default:function(){
                return {lat: 24.7253981, lng: 46.3398977};
            }
          },
          zoom:{
            type:Number,
            default:6,
          },
          mode:{
            type:String,
            default:'create'
          },
          id: {
            type:String,
            default:'map'
          },
          error:{
            type:String
          }
        },
        data(){
            return {
                map:null,
                drawingManager:null,
                selectedShape:null,
                google:null,
                options:{
                    libraries:[],
                }
            }
        },
        mounted() {
            this.prepareOptions();
            this.initMap();
        },
        watch: {
            position: function(newVal, oldVal) {
                this.setMapCenter();
                this.deleteSelectedShape();
                this.drawingShapeForCurrentPoints();
                // if(this.position.lat && this.position.lng){
                //     this.setDrawingMode();
                // }
            }
        },
        methods: {
            initMap(){

                this.$google.then(google=>{
                    this.google=google;
                    this.map = new this.google.maps.Map(document.getElementById(this.id), {
                        center: this.getCenterPosition(),
                        zoom: this.zoom,
                    });

                    if(this.hasDrawingLibrary()){
                        this.addDrawingManagerToMap();
                    }
                    this.drawingShapeForCurrentPoints();
                    // Define the LatLng coordinates for the outer path.

                }).catch(error=>{});
            },
            setMapCenter(){
              this.map.setCenter(this.getCenterPosition());
            },
            getCenterPosition(){
                if(this.position.lat && this.position.lng){

                    return this.getMarkerPosition();
                }
                return this.centerPosition;
            },
            getMarkerPosition(){
                return {
                    lat:parseFloat(this.position.lat),
                    lng:parseFloat(this.position.lng)
                };
            },
            prepareOptions(){
               if(['create','edit'].includes(this.mode)){
                   this.options.libraries.push('drawing');
               }
           },
           hasDrawingLibrary(){
                return this.options.libraries.includes('drawing');
           },
           getDefaultDrawingMode(){
                let drawingMode=null;
                if(['create','edit'].includes(this.mode)){
                    drawingMode= this.google.maps.drawing.OverlayType.MARKER;
                }
             return drawingMode;
           },
           setDrawingMode(type=null){
             if(this.hasDrawingLibrary()){
                 this.drawingManager.setDrawingMode(null);
             }
           },
           addDrawingManagerToMap(){
               this.drawingManager = new this.google.maps.drawing.DrawingManager({
                   drawingMode: this.getDefaultDrawingMode(),
                   drawingControl: true,
                   drawingControlOptions: {
                       position: this.google.maps.ControlPosition.TOP_CENTER,
                       drawingModes: [
                           this.google.maps.drawing.OverlayType.MARKER,
                       ],
                   },
                   markerOptions: {
                       draggable:true
                   },
               });
               this.drawingManager.setMap(this.map);
               this.google.maps.event.addListener(this.drawingManager, 'overlaycomplete',this.onCompleteDrawingShape);
           },
           drawingShapeForCurrentPoints(){
                if(this.position.lat && this.position.lng){
                   // Construct the Marker.
                   const selectedShape = new this.google.maps.Marker({
                       position: this.getMarkerPosition(),
                       draggable:!!this.hasDrawingLibrary()
                   });

                   selectedShape.setMap(this.map);
                   this.addEventsToShape(selectedShape);
                }
           },
            addEventsToShape(shape){
                this.google.maps.event.addListener(shape.getPosition(), 'set_at',this.onUpdateShape);
                this.google.maps.event.addListener(shape.getPosition(), 'insert_at',this.onUpdateShape);
                this.google.maps.event.addListener(shape.getPosition(), 'remove_at',this.onUpdateShape);
                this.google.maps.event.addListener(shape, 'dragend',this.onUpdateShape);
                this.selectedShape=shape;
            },
            deleteSelectedShape(){
                if(this.selectedShape){
                    this.selectedShape.setMap(null);
                    this.selectedShape = null;
                }
            },
            setGeomPointsOfShape(){
                this.$emit('input', {lat:this.selectedShape.getPosition().lat(),lng:this.selectedShape.getPosition().lng()});
            },
            onUpdateShape(){
                this.setGeomPointsOfShape();
            },
            onCompleteDrawingShape(event){
                //Delete Selected Shape if Exists
                this.deleteSelectedShape();

                this.addEventsToShape(event.overlay);
                this.setGeomPointsOfShape();

                //Set Drawing Mode To null
                // this.setDrawingMode();
            }
        }
    }
</script>

<style scoped>

</style>
