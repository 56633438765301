<template>
  <div class="row">
    <div class="col mb-2 px-1" v-for="item in getItemsByFilter()" :key="item.id">
      <items :label="item.label" :items="item.orders" :loading="loading" />
    </div>
  </div>
</template>
<script>
import items from "./items";
import { mapGetters } from "vuex";
import { PLATFORMS_DASHBOARD_ORDERS_LIST as CRUD_ACTION_LIST } from "actions/platforms";
import { TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS, TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS } from "actions/sockets/tracking";
const CRUD_CODE = "tracking";
const CRUD_PERMISSION_CODE = `${CRUD_CODE}`;
const ORDER_CODE = `orders`;
const ORDER_PERMISSION_CODE = `${ORDER_CODE}`;

export default {
  name: "PlatformDashboardOrders",
  props: {},
  components: {
    items,
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      orderRoutePrefix: ORDER_CODE,
      orderTransPrefix: ORDER_CODE,
      orderPermissionPrefix: ORDER_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
      currentPage: 1,
      loading: false,
      activeItem: "",
      filters: {
        id: ""
      },
      interval: null
    };
  },
  watch: {
    getSearchFilters: function(newVal, oldVal) {
      this.refreshList();
    }
  },
  computed: {
    ...mapGetters([
      "getSearchFilters",
      "getSearchFiltersIfExists",
      "getSearchFiltersSensitized"
    ])
  },
  beforeDestroy() {
    this.$store.commit(TRACKING_RESET_DASHBOARD_CAPTAINS_ORDERS);
    clearInterval(this.interval);
  },
  created() {
    this.refreshList();
    this.interval = setInterval(() => this.refreshList(), 15000);
  },
  methods: {
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    getList(clearList = false) {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          platformId: this.id,
          pageId: this.getCurrentPage(),
          withLoading: false,
          ...this.getSearchFiltersSensitized
        })
        .then(response => {
          if (clearList) {
            this.items = [];
          }

          const preparedResponseData = [];
          let allOrders = [];
          Object.keys(response.data).forEach((key, index) => {
            preparedResponseData.push({
              id: index,
              label: `${key} (${response.data[key].length})`,
              orders: response.data[key]
            });
            allOrders = allOrders.concat(response.data[key]);
          });

          this.items = [...this.items, ...preparedResponseData];
          this.perPage = 20;
          this.totalElements = 15;
          this.lastPage = 0;
          this.loading = false;


          const captainsOrders = this.groupBy(allOrders, 'captainId');
          const captainsOrdersGrouped = {};

          Object.keys(captainsOrders).forEach((key, index) => {
            captainsOrdersGrouped[key] = {
              total: captainsOrders[key].length,
              orders: captainsOrders[key],
              returningOrders: captainsOrders[key].filter((order) => ['RETURNING', 'RETURNING_TO_AREA'].includes(order.status)),
            }
          });

          this.$store.commit(TRACKING_SET_DASHBOARD_CAPTAINS_ORDERS, captainsOrdersGrouped);
        });
    },
    pagination(isVisible) {
      if (this.hasNextPage() && isVisible) {
        this.currentPage += 1;
        this.getList();
      }
    },
    getCurrentPage() {
      return this.currentPage - 1;
    },
    getLastPage() {
      return this.lastPage - 1;
    },
    hasNextPage() {
      return (
        this.getCurrentPage() < this.getLastPage() && this.items.length > 0
      );
    },
    refreshList() {
      this.currentPage = 1;
      this.getList(true);
      this.filters = this.clearFilters(this.filters);
    },
    getItemsByFilter() {
      let items = this.items;
      Object.keys(this.filters).forEach(key => {
        let filterValue = this.filters[key];
        if (filterValue) {
          switch (key) {
            case "id":
              items = items.filter(function(item) {
                return `${item.id}`.search(`${filterValue}`) !== -1;
              });
              break;
          }
        }
      });
      return items;
    }
  }
};
</script>
<style type="text/css" scoped>
.loader.force-right-0 {
  right: 0 !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-9 {
  font-size: 9px;
}

.img-fit {
  object-fit: cover;
}

.avatar {
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
}
</style>
