import store from "../store";

export default {
    methods: {
        can: function(prefix,action){
            let permission=`${prefix}${action?`_can_${action}`:''}`;
            return store.getters.can(permission);
        },
    }
}
