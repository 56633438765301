import { render, staticRenderFns } from "./captain_id_type.vue?vue&type=template&id=6021adc4&scoped=true&"
import script from "./captain_id_type.vue?vue&type=script&lang=js&"
export * from "./captain_id_type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6021adc4",
  null
  
)

export default component.exports