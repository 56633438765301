import {
    IBANS_LIST as CRUD_ACTION_LIST,
    IBANS_CREATE as CRUD_ACTION_CREATE,
    IBANS_SHOW as CRUD_ACTION_SHOW,
    IBANS_UPDATE as CRUD_ACTION_UPDATE,
    IBANS_DELETE as CRUD_ACTION_DELETE
} from "../actions/ibans";
import axioses from "@/axios/config";

const routePrefix='captains/ibans';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/create`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`captains/${params.id}/iban`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
