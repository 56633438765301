<template>
  <div aria-labelledby="general-tab" class="tab-pane fade show active" id="general" role="tabpanel">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="backend_id">{{ $t(`${transPrefix}.fields.backend_id`)}}</label>
          <input class="form-control" id="backend_id" name="backend_id" type="text" v-model="fields.backend_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="id">{{ $t(`${transPrefix}.fields.id`)}}</label>
          <input class="form-control" id="id" name="id" type="text" v-model="fields.id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.branch_id">
        <div class="form-group">
          <label for="branch_id">{{ $t(`${transPrefix}.fields.branch_id`)}}</label>
          <input class="form-control" id="branch_id" name="branch_id" type="text" v-model="fields.branch_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.platform_zone_id">
        <div class="form-group">
          <label for="platform_zone_id">{{ $t(`${transPrefix}.fields.platform_zone_id`)}}</label>
          <input class="form-control" id="platform_zone_id" name="platform_zone_id" type="text" v-model="fields.platform_zone_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.trip_id">
        <div class="form-group">
          <label for="trip_id">{{ $t(`${transPrefix}.fields.trip_id`)}}</label>
          <input class="form-control" id="trip_id" name="trip_id" type="text" v-model="fields.trip_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.trip_index">
        <div class="form-group">
          <label for="trip_index">{{ $t(`${transPrefix}.fields.trip_index`)}}</label>
          <input class="form-control" id="trip_index" name="trip_index" type="text" v-model="fields.trip_index" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.assignment_id">
        <div class="form-group">
          <label for="assignment_id">{{ $t(`${transPrefix}.fields.assignment_id`)}}</label>
          <input class="form-control" id="assignment_id" name="assignment_id" type="text" v-model="fields.assignment_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="earnings">{{ $t(`${transPrefix}.fields.earnings`) }}</label>
          <input class="form-control" id="earnings" name="earnings" type="text" v-model="fields.earnings" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="payment_type">{{ $t(`${transPrefix}.fields.payment_type`) }}</label>
          <select-payment-types :disabled="true" :multiple="false" v-model="fields.payment_type"/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="order_status">{{ $t(`fields.status`) }}</label>
          <select-order-status :disabled="true" :multiple="false" v-model="fields.order_status"/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.type">
        <div class="form-group">
          <label for="type">{{ $t(`fields.type`) }}</label>
          <input class="form-control" id="type" name="type" type="text" v-model="fields.type" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.city">
        <div class="form-group">
          <label for="city">{{ $t(`fields.city`) }}</label>
          <input class="form-control" id="city" name="city" type="text" v-model="fields.city" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.createdAt">
        <div class="form-group">
          <label for="created_at">{{ $t(`fields.created_at`) }}</label>
          <input class="form-control" id="created_at" name="created_at" type="text" v-model="fields.createdAt" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.allocation_window_id">
        <div class="form-group">
          <label for="allocation_window_id">{{ $t(`fields.allocation_window_id`) }}</label>
          <input class="form-control" id="allocation_window_id" name="allocation_window_id" type="text" v-model="fields.allocation_window_id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="fields.dispatched_by">
        <div class="form-group">
          <label for="manual_dispatch">{{ $t(`fields.dispatched_by`) }}</label>
          <input class="form-control" id="manual_dispatch" name="manual_dispatch" type="text" v-model="fields.dispatched_by" disabled/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsGeneral",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
    data(){
      return {
      }
    },
  };
</script>