<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <a v-if="!serverError && platform_order_url" :href="platform_order_url" target="_blank"  class="btn btn-primary btn-sm">
            <span class="d-none d-lg-inline mr-2">{{ $t(`labels.show_on`)}} {{ $t(`fields.platform`)}} </span>
            <span><i class="fa fa-eye"></i></span>
          </a>
        </card-header>
        <!-- Tabs -->
        <ul class="nav nav-tabs px-2" role="tablist">
          <li class="nav-item">
            <a href="#general" id="general-tab" aria-controls="general" aria-selected="true" class="nav-link active" data-toggle="tab" role="tab">
              {{ $t("tabs.general") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#tasks" id="tasks-tab" aria-controls="tasks" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.tasks") }}
            </a>
          </li>
          <li class="nav-item" v-if="captain.id">
            <a href="#captain" id="captain-tab" aria-controls="captain" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.captain") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#map" id="map-tab" aria-controls="map" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.map") }}
            </a>
          </li>
          <li class="nav-item" v-if="this.tracking_url">
            <a href="#tracking" id="tracking-tab" aria-controls="tracking" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.tracking") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#meta_data" aria-controls="meta_data" aria-selected="false" class="nav-link" data-toggle="tab" id="meta_data-tab" role="tab">
              {{ $t("tabs.meta_data") }}
            </a>
          </li>
          <li class="nav-item" v-if="customer.backendId">
            <a href="#customer" id="customer-tab" aria-controls="customer" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.customer") }}
            </a>
          </li>
          <li class="nav-item" v-if="timelines && timelines.length > 0">
            <a href="#order_timeline" aria-controls="order_timeline" aria-selected="false" class="nav-link" data-toggle="tab" id="order_timeline-tab" role="tab">
              {{ $t("tabs.timeline") }}
            </a>
          </li>
        </ul>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="alert alert-danger" v-if="serverError">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="tab-content" v-if="!serverError && isItemFound">
            <general-tab :fields="getGeneralTabFields()" :transPrefix="transPrefix"/>
            <meta-data-tab :items="metaDataItems" :transPrefix="transPrefix"/>
            <tasks-tab :items="tasksItems" :transPrefix="transPrefix" :permissionPrefix="permissionPrefix"/>
            <map-tab :items="tasksItems"/>
            <tracking-tab :url="this.tracking_url"/>
            <captain-tab :fields="getCaptainTabFields()" :transPrefix="transPrefix"/>

            <customer-tab :fields="getCustomerTabFields()" :transPrefix="transPrefix"/>
            <timeline-tab :items="timelines" :transPrefix="transPrefix"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link :to="{ name: `${routePrefix}.index` }" class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
              <a class="btn btn-danger btn-sm" v-if="cancellationReasons.length > 0"
                  data-target="#modal-cancellation-reasons"
                  data-toggle="modal"
                  href="javascript:;"
                  v-b-tooltip.hover="{ animation: false}"
                  :title="$t('labels.cancellation_reasons')">
                  <i class="fas fa-ban"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
            aria-hidden="true"
            aria-labelledby="modal-cancellation-reasons"
            class="modal fade"
            id="modal-cancellation-reasons"
            role="dialog"
            tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg"  role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.cancellation_reasons") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <ul class="list">

                <li v-for="(cancellationReason,index) in cancellationReasons" :key="index">
                  {{cancellationReason.message}}  <p v-if="cancellationReason.otherReason" style="color: #1496ba; font-size: 12px;">    -{{cancellationReason.otherReason}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
            >
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ORDERS_SHOW as CRUD_ACTION_SHOW } from "actions/platforms_portal/orders";
import generalTab from "./tabs/general";
import metaDataTab from "./tabs/meta_data";
import tasksTab from "./tabs/tasks";
import mapTab from "./tabs/map";
import trackingTab from "./tabs/tracking";
import customerTab from "./tabs/customer";
import captainTab from "./tabs/captain";
import timelineTab from "./tabs/timeline";

const ORDERS_CODE="orders";
const CRUD_CODE=`platforms_portal.${ORDERS_CODE}`;
const CRUD_PERMISSION_CODE=`partner_${ORDERS_CODE}`;
export default {
  name: "PlatformsPortalOrderShow",
  components:{
    generalTab,
    metaDataTab,
    tasksTab,
    mapTab,
    trackingTab,
    captainTab,
    customerTab,
    timelineTab,
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: ORDERS_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      backend_id: "",
      branch_id: "",
      dispatched_by: "",
      city: "",
      payment_type: "",
      order_status: "",
      type: "",
      platform_order_url:"",
      customer: {},
      captain: {},
      metaDataItems: [],
      tasksItems: [],
      timelines: [],
      cancellationReasons: [],
      serverError: "",
      isItemFound: false,
      tracking_url: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store.dispatch(CRUD_ACTION_SHOW, {
          id: this.id,
        })
        .then((response) => {
          this.backend_id = response.data.backendId;
          this.branch_id = response.data.branchId;
          this.dispatched_by = response.data.manuallyDispatched?response.data.manuallyDispatched.dispatchedBy : "";
          this.city = response.data.city;
          this.order_status = response.data.status;
          this.payment_type = response.data.paymentType;
          this.platform_order_url=response.data.platformOrderUrl;
          this.customer=response.data.customer?response.data.customer:{};
          this.captain=response.data.captain?response.data.captain:{};
          this.type = response.data.deliveryType;
          this.tracking_url = response.data.trackingUrl;
          this.cancellationReasons=response.data.cancellationReasons;
          this.metaDataItems = response.data.metaData;
          this.timelines = response.data.timelines;
          this.tasksItems = response.data.tasks;

          this.isItemFound = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }
          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    getGeneralTabFields(){
      return {
        order_status:this.order_status,
        backend_id:this.backend_id,
        branch_id: this.branch_id,
        dispatched_by:this.dispatched_by,
        city:this.city,
        payment_type:this.payment_type,
        type: this.type,
      };
    },
    getCustomerTabFields(){
      return {
        backend_id:this.customer.backendId,
        name:this.customer.name,
      };
    },
    getCaptainTabFields(){
      return {
        id:this.captain.id,
        name:this.captain.name,
        mobileNumber:this.captain.mobileNumber,
      };
    },
  },
};
</script>
