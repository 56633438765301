import PageNotFound from "@/views/PageNotFound.vue";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='page_not_found';
const routes = [
  {
    path: "*",
    component: PageNotFound,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.title`,
    },
    beforeEnter: function (to,from, next){
      commonBeforeEnter(to, from, next);
      next();
    },
  },
];

export default routes;
