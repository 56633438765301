import { render, staticRenderFns } from "./platform_area_shift_pricing_rule_types.vue?vue&type=template&id=4cd926bb&scoped=true&"
import script from "./platform_area_shift_pricing_rule_types.vue?vue&type=script&lang=js&"
export * from "./platform_area_shift_pricing_rule_types.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd926bb",
  null
  
)

export default component.exports