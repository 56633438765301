<template>
    <a href="javascript:;" class="btn btn-danger btn-sm" :data-target="modalSelector" data-toggle="modal"
       @click="setActiveParams(index,itemID)"
    >
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.cancel`) }}
        </span>
        <span>
          <i class="fas fa-stop"></i>
        </span>
    </a>
</template>

<script>
    import { CRUD_UPDATE_MODAL } from "actions/crud";
    export default {
        name: "OrderActionsCancel",
        props: {
            itemID:{},
            index: {},
            modalSelector:{
                type:String,
                default:"#modal-cancel-order"
            },
            additionalParams:{
                type:Object,
                default:function(){
                    return {};
                }
            }
        },
        data(){
            return {}
        },
        methods:{
            setActiveParams(index,id) {
                this.$emit('input',{
                    activeId : id,
                    activeIndex : index,
                    ...this.additionalParams
                });
                this.$store.commit(CRUD_UPDATE_MODAL,{
                    'id': this.modalSelector,
                });
            },

        }
    }
</script>

<style scoped>

</style>
