<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-6">
              <div class="form-group ">
                <label for="name">{{ $t(`fields.name`) }}</label>
                <input
                  v-model="name"
                  id="name"
                  type="text"
                  class="form-control"
                  name="name"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6" style="margin-top:39px">
              <div class="form-group row">
                <div class="col-8">
                  <label for="wallet_enabled">{{
                    $t(`fields.uses_wallet`)
                  }}</label>
                </div>
                <div class="col-4 text-right">
                  <label class="custom-toggle">
                    <input
                      type="checkbox"
                      id="wallet_enabled"
                      v-model="usesWallet"
                      disabled
                    />
                    <span
                      class="custom-toggle-slider rounded-circle"
                      :data-label-off="$t('labels.no')"
                      :data-label-on="$t('labels.yes')"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12">
              <maps-general
                v-model="points"
                :points="oldPoints"
                :mode="map_mode"
              />
            </div>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <router-link
                :to="{ name: `${routePrefix}.index`, params: { id } }"
                class="btn btn-neutral btn-sm"
              >
                <i class="fa fa-arrow-left"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_ZONES_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_ZONES_SUPPLIER,
} from "actions/platforms";

const PLATFORMS_CODE = "platforms";
const CRUD_CODE = `${PLATFORMS_CODE}.zones`;
const CRUD_PERMISSION_CODE = `platform_zones`;
export default {
  name: "PlatformZoneShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      platformsRoutePrefix: `${PLATFORMS_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CODE}`,
      id: this.$router.currentRoute.params.id,
      zone_id: this.$router.currentRoute.params.zone_id,
      name: "",
      usesWallet: false,
      points: [],
      oldPoints: [],
      map_mode: "show",
      serverError: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(PLATFORMS_ZONES_SUPPLIER, {
          id: this.id,
          zone_id: this.zone_id,
        })
        .then((response) => {
          this.usesWallet = response?.data?.usesWallet;
          this.is_loaded = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
          id: this.zone_id,
        })
        .then((response) => {
          this.name = response.data.name;
          this.oldPoints = this.points = response.data.geom
            ? response.data.geom.coordinates[0][0]
            : [];
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    isCityEnabled() {
      return false;
    },
    isCityDisabled() {
      return !this.isCityEnabled();
    },
    isZoneEnabled() {
      return false;
    },
    isZoneDisabled() {
      return !this.isZoneEnabled();
    },
  },
};
</script>
