<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${citiesRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
          <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
            <i class="fa fa-plus"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.name }} </td>
            <td> {{ item.nameAr }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
            <table-item-actions :actions="tableItemActions" :params="{id,city_id,'zone_id':item.id}"/>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { ZONES_LIST as CRUD_ACTION_LIST, ZONES_DELETE as CRUD_ACTION_DELETE, } from 'actions/zones';
  const COUNTRIES_CODE="countries";
  const CITIES_CODE=`${COUNTRIES_CODE}.cities`;
  const CITIES_TRANS_CODE="cities";
  const CRUD_CODE=`${CITIES_CODE}.zones`;
  const CONNECTIONS_CODE = `${CRUD_CODE}.connections`
  const CRUD_TRANS_CODE="zones";
  const CRUD_PERMISSION_CODE="locals";
  export default {
    name: "CountryCityZoneIndex",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        citiesRoutePrefix: `${CITIES_CODE}`,
        citiesTransPrefix: `${CITIES_TRANS_CODE}`,
        countriesRoutePrefix: `${COUNTRIES_CODE}`,
        countriesTransPrefix: `${COUNTRIES_CODE}`,
        id:this.$router.currentRoute.params.id,
        city_id:this.$router.currentRoute.params.city_id,
        tableHeaders:[
          {
            'title':`fields.en_name`
          },
          {
            'title':`fields.ar_name`
          },
          {
            'title':`fields.created_at`
          },
          {
            'title':`fields.actions`
          }
        ],
        tableItemActions:[
          {
            code:'show',
            route_name:`${CRUD_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
          },
          {
            code:'edit',
            route_name:`${CRUD_CODE}.edit`,
            can:`${CRUD_PERMISSION_CODE}_can_edit`,
          },
          {
            code:'delete',
            can:`${CRUD_PERMISSION_CODE}_can_delete`,
            action:CRUD_ACTION_DELETE,
            successCallback:this.getList
          },
          {
            code:'tracking',
            route_name: `${CONNECTIONS_CODE}.show`,
            can:`${CRUD_PERMISSION_CODE}_can_view`,
            icon: `fa fa-map text-gray`,
            title: `${CRUD_TRANS_CODE}.tracking.title`
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          cityId:this.city_id,
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
