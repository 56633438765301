<template>
  <searchContainer :searchHandler="search" :resetHandler="reset">
    <template v-slot:items>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="start_date">{{ $t(`fields.start_date`) }}</label>
            <input
              class="form-control"
              id="start_date"
              name="start_date"
              type="date"
              v-model="filters.start_date"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="end_date">{{ $t(`fields.end_date`) }}</label>
            <input
              class="form-control"
              id="end_date"
              name="end_date"
              type="date"
              v-model="filters.end_date"
            />
          </div>
        </div>
      </div>
    </template>
  </searchContainer>
</template>
<script>
import { mapGetters } from "vuex";
import { CRUD_SEARCH_RESET } from "actions/crud";
import searchContainer from "@/components/crud/card/CardSearch";

const CRUD_CODE = "captain_performance";
export default {
  name: "SuppliersPortalCaptainSearch",
  components: {
    searchContainer,
  },
  props: {
    listHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const today = new Date().toISOString().slice(0, 10);
    return {
      transPrefix: CRUD_CODE,
      filters: {
        fromDate: this.getFilterByKey("start_date") || today,
        toDate: this.getFilterByKey("end_date") || today
      },
      filtersMap: {
        start_date: "fromDate",
        end_date: "toDate"
      },
    };
  },
  mounted() {
    this.setSearchParams(this.filters, this.filtersMap);
  },
  computed: {
    ...mapGetters(["getSearchFilters"])
  },
  methods: {
    search() {
      this.resetPagination();
      this.setSearchParams(this.filters, this.filtersMap, {
        routeParams: this.getFilters(this.filters),
      });
      this.pushRoute(this.getSearchFilters);
      this.listHandler();
    },
    reset() {
      this.resetPagination();
      this.$store.dispatch(CRUD_SEARCH_RESET);
      this.filters = this.clearFilters(this.filters);
      this.pushRoute();
      this.listHandler();
    },
    resetPagination() {
      //Reset Pagination
      this.$emit("input", 0);
    },
    pushRoute(query = {}) {
      this.$router.push({ name: this.$route.name, query }).catch(() => {});
    },
  },
};
</script>
