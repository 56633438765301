<template>
  <div class="">
    <nav
      class="navbar navbar-vertical pt-4 navbar-expand-xs navbar-light opened left-0 pb-0 component-sidenav position-static shadow-none border border-light rounded"
      id="sidenav-captains-tracking"
    >
      <div class="scrollbar-inner bg-lighter">
        <div
          class="d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100"
        >
          <div class="pt-1 mx-0 row flex-row-reverse w-100">
            <div class="col-12 p-0 text-center">
              <h6>{{ label }}</h6>
              <span v-if="loading"
                  class="spinner-border position-absolute top-0 right-2 spinner-border-sm text-sm text-dark"
              ></span>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
          <div class="collapse navbar-collapse">
            <!-- Nav items -->
            <ul class="navbar-nav">
              <li
                class="nav-item nav-link tracking-order-item mx-0 px-3 row"
                v-bind:class="{ active: activeItem.id === item.id }"
                v-bind:style="{ background: item.color }"
                v-for="item in getItemsByFilter()"
                :key="item.id"
                @click="clickItemHandler(item)"
              >
                <div class="col-12 p-0">
                  <div class="d-flex justify-content-between">
                    <a
                      href="javascript:;"
                      @click.prevent="clickBackendIdHandler(item)"
                      class="backend-id mb-0 font-size-10 text-nowrap text-truncate text-left text-blue"
                    >
                      {{ item.backendId }}
                    </a>
                    <a
                      href="javascript:;"
                      @click.prevent="clickBranchHandler(item)"
                      class="branch-name mb-0 font-size-10 text-nowrap text-truncate text-right text-warning"
                    >
                      {{ item.branchName }}
                    </a>
                    <a
                      href="javascript:;"
                      @click.prevent="clickZoneHandler(item)"
                      class="branch-name mb-0 font-size-10 text-nowrap text-truncate text-right text-warning"
                    >
                      {{ item.zoneName }}
                    </a>
                  </div>
                </div>
                <div class="justify-content-between col-12 d-flex p-0">
                  <span
                    class="font-size-10"
                    v-bind:style="{ color: item.statusColor }"
                  >
                    {{ $t(`orders.lists.status.${item.status}`) }}
                  </span>
                  <span class="font-size-10">
                    {{ item.activeTaskType }}
                  </span>
                  <span
                    class="font-size-10"
                    v-bind:style="{ color: item.activeTaskStatusColor }"
                  >
                    {{ item.activeTaskStatus }}
                  </span>
                </div>
                <div
                  class="justify-content-between col-12 d-flex p-0"
                  v-if="item.captainId || item.tripIndex"
                >
                  <a
                    href="javascript:;"
                    @click.prevent="clickCaptainHandler(item)"
                    class="text-blue"
                  >
                    <span class="font-size-10">
                      <i class="fas fa-car text-blue"></i>
                      {{ item.captainId }}
                    </span>
                  </a>
                  <span class="font-size-10">
                    {{ $t(`orders.fields.trip_index`) }}: {{ item.tripIndex }} / {{item.tripCount}}
                  </span>
                </div>
                <div class="justify-content-between col-12 d-flex p-0">
                  <span class="font-size-10">
                    <countdownCustom :item="item" />
                  </span>
                  <span class="font-size-10">
                   <date-digital-clock :date="item.createdAt" />
                  </span>
                </div>
              </li>
              <li
                class="nav-item nav-link  mx-0 px-0 row"
                v-if="getItemsByFilter().length === 0"
              >
                <div class="col-12 text-center">
                  <h2 class="order-id d-none">
                    {{ $t("messages.no_result_found") }}
                  </h2>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import $ from "jquery";
import countdownCustom from "./countdown_custom.vue";

const CRUD_CODE = "dashnoard";
const CRUD_PERMISSION_CODE = `${CRUD_CODE}`;
const ORDER_CODE = `orders`;
const BRANCH_CODE = `platforms.branches`;
const ZONE_CODE = `platforms.zones`;
const CAPTAIN_CODE = `captains`;

export default {
  name: "PlatformDashboardOrderItems",
  components: {
    countdownCustom,
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      orderRoutePrefix: ORDER_CODE,
      branchRoutePrefix: BRANCH_CODE,
      zoneRoutePrefix: ZONE_CODE,
      captainRoutePrefix: CAPTAIN_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      activeItem: "",
      filters: {
        id: ""
      }
    };
  },
  mounted() {
    $(
      ".scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome"
    )
      .scrollbar()
      .scrollLock();
  },
  beforeDestroy() {
    this.clickItemHandler({});
  },
  methods: {
    openSideMenu() {
      $("#sidenav-captains-tracking")
        .removeClass("closed")
        .addClass("opened");
    },
    hideSideMenu() {
      $("#sidenav-captains-tracking")
        .removeClass("opened")
        .addClass("closed");
    },
    clickItemHandler(item) {
      if (item.id) {
        this.showItemHandler(item);
      }
    },
    clickBackendIdHandler(item) {
      if (item.id) {
        let routeData = this.$router.resolve({
          name: `${this.orderRoutePrefix}.show`,
          params: { id: item.id }
        });
        window.open(routeData.href, "_blank");
      }
    },
    clickBranchHandler(item) {
      if (item.branchId) {
        let routeData = this.$router.resolve({
          name: `${this.branchRoutePrefix}.tracking.index`,
          params: { id: this.id, branch_id: item.branchId }
        });
        window.open(routeData.href, "_blank");
      }
    },
    clickZoneHandler(item) {
      if (item.zoneId) {
        let routeData = this.$router.resolve({
          name: `${this.zoneRoutePrefix}.tracking.index`,
          params: { id: this.id, zone_id: item.zoneId }
        });
        window.open(routeData.href, "_blank");
      }
    },
    clickCaptainHandler(item) {
      if (item.captainId) {
        let routeData = this.$router.resolve({
          name: `${this.captainRoutePrefix}.show`,
          params: { id: item.captainId }
        });
        window.open(routeData.href, "_blank");
      }
    },
    showItemHandler(item) {},
    getItemsByFilter() {
      let items = this.items;
      Object.keys(this.filters).forEach(key => {
        let filterValue = this.filters[key];
        if (filterValue) {
          switch (key) {
            case "id":
              items = items.filter(function(item) {
                return `${item.id}`.search(`${filterValue}`) !== -1;
              });
              break;
          }
        }
      });
      return items;
    },
  },
};
</script>
<style type="text/css" scoped>
.scrollbar-inner .scroll-element.scroll-y {
  background-color: #e5e5e594 !important;
  width: 8px !important;
  opacity: 1 !important;
}
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: unset !important;
}

.loader.force-right-0 {
  right: 0 !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-9 {
  font-size: 9px;
}

.img-fit {
  object-fit: cover;
}
.navbar.component-sidenav {
  height: calc(100vh - 250px);
  max-width: unset;
}

.avatar {
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
}
</style>
