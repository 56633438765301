<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`) }}</label>
                  <files-image
                    v-model="image"
                    :thumbnailImage="image.thumbnail"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="identity">{{ $t(`fields.identity`) }}</label>
                  <files-image
                    id="identity"
                    v-model="identity"
                    :thumbnailImage="identity.thumbnail"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="registration_license">{{
                    $t(`fields.registration_license`)
                  }}</label>
                  <files-image
                    id="registration_License"
                    v-model="registration_license"
                    :thumbnailImage="registration_license.thumbnail"
                  />
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="driving_license">{{
                    $t(`fields.driving_license`)
                  }}</label>
                  <files-image
                    id="driving_license"
                    v-model="driving_license"
                    :thumbnailImage="driving_license.thumbnail"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`) }}</label>
                  <input
                    v-model="name"
                    id="name"
                    type="text"
                    class="form-control"
                    name="name"
                    v-bind:class="{ 'is-invalid': fieldErrors.name }"
                  />
                  <span
                    v-if="fieldErrors.name"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{
                    $t(`fields.mobile_number`)
                  }}</label>
                  <input
                    v-model="mobile_number"
                    id="mobile_number"
                    type="text"
                    class="form-control"
                    name="mobile_number"
                    v-bind:class="{ 'is-invalid': fieldErrors.mobileNumber }"
                  />
                  <span
                    v-if="fieldErrors.mobileNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.mobileNumber }}</strong>
                  </span>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <div class="form-group ">
                    <label for="country_id">{{ $t(`fields.country`) }}</label>
                    <select-countries
                      v-model="country_id"
                      :action="countries_list_action"
                      :error="fieldErrors.countryId"
                    />
                    <span
                      v-if="fieldErrors.countryId"
                      class="invalid-feedback"
                      role="alert"
                    >
                      <strong>{{ fieldErrors.countryId }}</strong>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="city_id">{{ $t(`fields.city`) }}</label>
                  <select-cities
                    v-model="city_id"
                    :country_id="country_id"
                    :action="cities_list_action"
                    id="city_id"
                    :disabled="!country_id"
                    :error="fieldErrors.cityId"
                  />
                  <span
                    v-if="fieldErrors.cityId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.cityId }}</strong>
                  </span>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="date_of_birth">{{
                    $t(`fields.date_of_birth`)
                  }}</label>
                  <input
                    v-model="date_of_birth"
                    id="date_of_birth"
                    type="date"
                    class="form-control"
                    name="date_of_birth"
                    v-bind:class="{ 'is-invalid': fieldErrors.dateOfBirth }"
                  />
                  <span
                    v-if="fieldErrors.dateOfBirth"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.dateOfBirth }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{
                    $t(`fields.nationality`)
                  }}</label>
                  <vue-country-code
                    id="nationality"
                    class="w-25"
                    v-if="loadNationality()"
                    @onSelect="onSelectNationality"
                    :defaultCountry="nationality"
                    :dropdownOptions="{ disabledDialCode: true }"
                  />
                  <input
                    :value="nationality_label"
                    type="text"
                    class="form-control w-75 d-inline-block"
                    name="nationality"
                    v-bind:class="{ 'is-invalid': fieldErrors.nationality }"
                  />
                  <span
                    v-if="fieldErrors.nationality"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.nationality }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_number">{{ $t(`fields.id_number`) }}</label>
                  <input
                    v-model="id_number"
                    id="id_number"
                    type="text"
                    class="form-control"
                    name="id_number"
                    v-bind:class="{ 'is-invalid': fieldErrors.idNumber }"
                  />
                  <span
                    v-if="fieldErrors.idNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.idNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="status">{{ $t(`fields.status`) }}</label>
                  <input
                    v-model="status"
                    id="status"
                    type="text"
                    class="form-control"
                    name="status"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="platform_id">{{
                    $t(`fields.preferred_platform`)
                  }}</label>
                  <select-platforms
                    v-model="platform_id"
                    :error="fieldErrors.preferredPlatformId"
                  />
                  <span
                    v-if="fieldErrors.preferredPlatformId"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.preferredPlatformId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="bank_id">{{ $t(`fields.bank`) }}</label>
                  <select-banks
                    v-model="bank_id"
                    id="bank_id"
                    :action="banks_list_action"
                    :error="fieldErrors.bankId"
                  />
                  <span
                    v-if="fieldErrors.bankId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.bankId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="iban">{{ $t(`fields.iban`) }}</label>
                  <input
                    v-model="iban"
                    id="iban"
                    type="text"
                    class="form-control"
                    name="iban"
                    v-bind:class="{ 'is-invalid': fieldErrors.iban }"
                  />
                  <span
                    v-if="fieldErrors.iban"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.iban }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="iban_name">{{ $t(`fields.iban_name`) }}</label>
                  <input
                    v-model="iban_name"
                    id="iban_name"
                    type="text"
                    class="form-control"
                    name="iban_name"
                    v-bind:class="{ 'is-invalid': fieldErrors.ibanName }"
                  />
                  <span
                    v-if="fieldErrors.ibanName"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.ibanName }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="fintech_account_id">{{
                    $t(`fields.fintech_account_id`)
                  }}</label>
                  <input
                    v-model="fintech_account_id"
                    id="fintech_account_id"
                    type="text"
                    class="form-control"
                    name="fintech_account_id"
                    v-bind:class="{
                      'is-invalid': fieldErrors.fintechAccountId
                    }"
                  />
                  <span
                    v-if="fieldErrors.fintechAccountId"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.fintechAccountId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="preferred_vertical">{{
                    $t(`fields.preferred_vertical`)
                  }}</label>
                  <input
                    v-model="preferred_vertical"
                    id="preferred_vertical"
                    type="text"
                    class="form-control"
                    name="preferred_vertical"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_plate_number">{{
                    $t(`fields.vehicle_plate_number`)
                  }}</label>
                  <input
                    v-model="vehicle_plate_number"
                    id="vehicle_plate_number"
                    type="text"
                    class="form-control"
                    name="vehicle_plate_number"
                    v-bind:class="{
                      'is-invalid': fieldErrors.vehiclePlateNumber
                    }"
                  />
                  <span
                    v-if="fieldErrors.vehiclePlateNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.vehiclePlateNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_sequence_number">{{
                    $t(`fields.vehicle_sequence_number`)
                  }}</label>
                  <input
                    v-model="vehicle_sequence_number"
                    id="vehicle_sequence_number"
                    type="text"
                    class="form-control"
                    name="vehicle_sequence_number"
                    v-bind:class="{
                      'is-invalid': fieldErrors.vehicleSequenceNumber
                    }"
                  />
                  <span
                    v-if="fieldErrors.vehicleSequenceNumber"
                    class="invalid-feedback"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.vehicleSequenceNumber }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link
                  :to="{ name: `${routePrefix}.index` }"
                  class="btn btn-neutral btn-sm"
                >
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button
                  type="submit"
                  class="btn btn-primary btn-sm"
                  :disabled="loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { CAPTAIN_REGISTRATION_REQUEST_SHOW as CRUD_ACTION_SHOW } from "actions/suppliers_portal/captain_registration_requests";
import { CAPTAIN_REGISTRATION_REQUEST_UPDATE as CRUD_ACTION_UPDATE } from "actions/suppliers_portal/captain_registration_requests";
import { COUNTRIES_PUBLIC_LIST } from "actions/countries";
import { CITIES_PUBLIC_LIST } from "actions/cities";
import { BANKS_PUBLIC_LIST } from "actions/banks";

const CAPTAINS_CODE = "captain_registration_requests";
const CRUD_CODE = `suppliers_portal.${CAPTAINS_CODE}`;
const CRUD_PERMISSION_CODE = `supplier_${CAPTAINS_CODE}`;

export default {
  name: "SuppliersPortalCaptainRegistrationRequestShow",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CAPTAINS_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      id_number: "",
      status: "",
      country_name: "",
      city_name: "",
      city_id: "",
      country_id: "",
      mobile_number: "",
      nationality: "",
      preferred_platform: "",
      nationality_label: "",
      is_nationality_load: false,
      date_of_birth: "",
      name: "",
      bank: "",
      iban: "",
      iban_name: "",
      fintech_account_id: "",
      preferred_vertical: "",
      vehicle_plate_number: "",
      vehicle_sequence_number: "",
      serverError: "",
      image: {
        file: "",
        thumbnail: ""
      },
      driving_license: {
        file: "",
        thumbnail: ""
      },
      identity: {
        file: "",
        thumbnail: ""
      },
      registration_license: {
        file: "",
        thumbnail: ""
      },
      responseStatus: "",
      banks_list_action: BANKS_PUBLIC_LIST,
      countries_list_action: COUNTRIES_PUBLIC_LIST,
      cities_list_action: CITIES_PUBLIC_LIST,
      fieldErrors: {},
      bank_id: "",
      platform_id: "",
      loading: false
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus && this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          id: this.id
        })
        .then(response => {
          console.log("response :", response.data);
          const {
            preferredPlatform,
            country,
            city,
            bank,
            iban,
            ibanName,
            fintechAccountId,
            preferredVertical,
            vehiclePlateNumber,
            vehicleSequenceNumber
          } = response.data;

          this.name = response.data.name;
          this.id_number = response.data.idNumber;
          this.status = response.data.status;
          this.country_name = country
            ? `${country.id}: ${country.name} - ${country.nameAr}`
            : "";
          this.city_name = city
            ? `${city.id}: ${city.name} - ${city.nameAr}`
            : "";
          this.city_id = city ? city.id : "";
          this.country_id = country ? country.id : "";
          this.preferred_platform = preferredPlatform
            ? `${preferredPlatform.id}: ${preferredPlatform.name} - ${preferredPlatform.nameAr}`
            : "";
          this.platform_id = preferredPlatform ? preferredPlatform.id : "";
          this.bank = bank ? `${bank.id}: ${bank.name}` : "";
          this.bank_id = bank ? bank.id : "";
          this.date_of_birth = response.data.dob
            ? this.$moment(response.data.dob).format("Y-MM-DD")
            : "";
          this.nationality = response.data.nationality;
          this.mobile_number = response.data.mobileNumber
            ? response.data.mobileNumber
            : "";
          this.image.thumbnail = response.data.profilePicFile
            ? response.data.profilePicFile.url
              ? response.data.profilePicFile.url
              : ""
            : "";
          this.driving_license.thumbnail = response.data.drivingLicenseFile
            ? response.data.drivingLicenseFile.url
            : "";
          this.identity.thumbnail = response.data.idFile
            ? response.data.idFile.url
            : "";
          this.is_nationality_load = !!this.nationality;
          this.responseStatus = response.status;
          this.iban = iban ? iban : "";
          this.iban_name = ibanName ? ibanName : "";
          this.fintech_account_id = fintechAccountId ? fintechAccountId : "";
          this.preferred_vertical = preferredVertical ? preferredVertical : "";
          this.vehicle_plate_number = vehiclePlateNumber
            ? vehiclePlateNumber
            : "";
          this.vehicle_sequence_number = vehicleSequenceNumber
            ? vehicleSequenceNumber
            : "";
        })
        .catch(error => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    onSelectNationality({ name, iso2 }) {
      this.nationality = iso2.toLowerCase();
      this.nationality_label = `${name} (${iso2})`;
    },
    loadNationality() {
      return this.isItemFound() && this.is_nationality_load;
    },
    isCityEnabled() {
      console.log("this.country_id :", this.country_id);
      return this.country_id;
    },
    isCityDisabled() {
      return !this.isCityEnabled();
    },
    prepareDetailsRequestData() {
      let details = {};
      if (this.date_of_birth || this.nationality) {
        details = {
          nationality: this.sanitizeField("nationality"),
          dateOfBirth: this.sanitizeField("date_of_birth"),
          vehiclePlateNumber: this.sanitizeField("vehicle_plate_number"),
          vehicleSequenceNumber: this.sanitizeField("vehicle_sequence_number")
        };
      }
      return details;
    },
    prepareIbanRequestData() {
      let iban = {};
      if (this.iban_name || this.bank_id || this.iban) {
        iban = {
          ibanName: this.sanitizeField("iban_name"),
          iban: this.sanitizeField("iban"),
          bankId: this.sanitizeField("bank_id")
        };
      }
      return iban;
    },
    prepareRequestData() {
      return {
        name: this.sanitizeField("name"),
        fintechAccountId: this.sanitizeField("fintech_account_id"),
        mobileNumber: this.sanitizeField("mobile_number"),
        countryId: this.sanitizeField("country_id"),
        cityId: this.sanitizeField("city_id"),
        idNumber: this.sanitizeField("id_number"),
        preferredPlatformId: this.sanitizeField("platform_id"),
        "userMobile.mobileNumber": this.sanitizeField("mobile_number"),
        "userMobile.countryId": this.sanitizeField("country_id"),
        ...this.prepareIbanRequestData(),
        ...this.prepareFile("image", "profilePicture"),
        ...this.prepareFile("driving_license", "drivingLicense"),
        ...this.prepareFile("registration_license", "registrationLicense"),
        ...this.prepareFile("identity", "identity"),
        ...this.prepareDetailsRequestData()
      };
    },
    update() {
      this.loading = true;
      console.log("this.prepareRequestData() :", this.prepareRequestData());
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          }
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.create_successfully"),
            buttons: false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` });
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    }
  }
};
</script>
