<template>
    <div class="row">
      <div class="col">
        <card-container
                :title="$router.currentRoute.meta.title"
                :headers="tableHeaders"
                :lastPage="lastPage"
                :totalElements="totalElements"
                :currentPage="getCurrentPage()"
                :clickHandler="pagination">
  
          <template v-slot:card-header>
            <router-link :to="{ name: `${zonesRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
              <i class="fa fa-arrow-left"></i>
            </router-link>
            <router-link :to="{ name: `${routePrefix}.create`}" class="btn btn-primary btn-sm" v-can:add="permissionPrefix">
              <i class="fa fa-plus"></i>
            </router-link>
          </template>
  
          <template v-slot:card-header-search>
            <search v-model="lastPage" :listHandler="getList"/>
          </template>
  
          <template v-slot:table-rows>
            <tr v-for="item in items" :key="item.id">
              <td> {{ item.dayOfWeek }} </td>
              <td> {{ item.type }} </td>
              <td> {{ item.captainsCapacity }}/{{ item.captainsFulfilledCapacity }}</td>
              <td> {{ item.manHour }}</td>
              <td> {{ item.startAt }} </td>
              <td> {{ item.finishAt }} </td>
              <td> <i class="fa fa-circle" v-bind:class="item.status == 'CURRENTLY_ACTIVE' ? 'text-success' : 'text-danger'"></i> - {{item.status}} </td>
              <td> {{ item.finishStatus }} </td>
              <table-item-actions :actions="tableItemActions" :params="{id, zone_id, 'area_type': 'ZONE', area_id: zone_id, 'shift_id':item.id}"/>
            </tr>
            <tr class="text-center" v-if="!items.length">
              <td colspan="999">{{ $t('messages.no_result_found')}}</td>
            </tr>
          </template>
        </card-container>
      </div>
    </div>
  </template>
  <script>
  
    import {mapGetters} from "vuex";
    import { PLATFORMS_AREAS_SHIFTS_LIST as CRUD_ACTION_LIST, PLATFORMS_AREAS_SHIFTS_DELETE as CRUD_ACTION_DELETE} from 'actions/platforms';
    import search from "./partials/search";
    const PLATFORMS_CRUD_CODE="platforms";
    const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
    const CRUD_CODE=`${ZONES_CRUD_CODE}.shifts`;
    const CRUD_TRANS_CODE=`${CRUD_CODE}`;
    const CRUD_PERMISSION_CODE=`platform_area_shifts`;
    const ZONE_CODE = 'zones';
    export default {
      name: "PlatformZoneShiftIndex",
      components:{
        search,
      },
      data(){
        return {
          routePrefix: `${CRUD_CODE}`,
          transPrefix: `${CRUD_TRANS_CODE}`,
          permissionPrefix: CRUD_PERMISSION_CODE,
          zonesRoutePrefix: ZONES_CRUD_CODE,
          platformsTransPrefix: ZONES_CRUD_CODE,
          id:this.$router.currentRoute.params.id,
          zone_id:this.$router.currentRoute.params.zone_id,
          tableHeaders:[
            {
               'title':`fields.day_of_week`
            },
            {
               'title':`fields.type`
            },
            {
               'title':`fields.captains_capacity`
            },
            {
              'title':`fields.manHour`
            },
            {
              'title':`fields.start_at`
            },
            {
              'title':`fields.finish_at`
            },
            {
              'title':`fields.status`
            },
            {
              'title':`fields.finish_status`
            },
            {
              'title':`fields.actions`
            }
          ],
          tableItemActions:[
            {
              code:'show',
              route_name:`${CRUD_CODE}.show`,
              can:`${CRUD_PERMISSION_CODE}_can_view`,
            },
            {
              code:'edit',
              route_name:`${CRUD_CODE}.edit`,
              can:`${CRUD_PERMISSION_CODE}_can_edit`,
            },
            {
              code:'pricing_rules',
              route_name:`${CRUD_CODE}.pricing_rules.index`,
              color_class:`text-success`,
              icon:`fa fa-money-bill`,
              can:`${CRUD_PERMISSION_CODE}_can_view`,
              title: `${ZONE_CODE}.shifts.pricing_rules.title`,
            },
            {
              code:'summaries',
              route_name:`${CRUD_CODE}.summaries.index`,
              color_class:`text-gray`,
              icon:`fa fa-briefcase`,
              can:`${CRUD_PERMISSION_CODE}_can_view`,
              title: `${ZONE_CODE}.shifts.summaries.title`,
            },
            {
              code:'late_attendances',
              route_name:`${CRUD_CODE}.late_attendances.index`,
              color_class:`text-warning`,
              icon:`fa fa-exclamation-triangle`,
              can:`${CRUD_PERMISSION_CODE}_can_view`,
              title: `${ZONE_CODE}.shifts.late_attendances.title`,
            },
            {
              code:'delete',
              can:`${CRUD_PERMISSION_CODE}_can_delete`,
              action:CRUD_ACTION_DELETE,
              successCallback:this.getList
            },
          ],
          items: [],
          perPage:20,
          totalElements:0,
          lastPage:0
        }
      },
      mounted() {
        this.getList();
      },
      computed:{
        ...mapGetters([
          "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
        ])
      },
      methods: {
        getList(){
          this.$store.dispatch(CRUD_ACTION_LIST,{
            platformId:this.id,
            area_type: "ZONE",
            area_id: this.zone_id,
            pageId: this.getCurrentPage()-1,
            ...this.getSearchFiltersSensitized
          }).then(response=>{
            this.items=response.data.data;
            this.perPage=response.data.perPage;
            this.totalElements=response.data.totalElements;
            this.lastPage=response.data.pageCount;
          });
        },
        pagination(page){
          if(page!== this.getCurrentPage()){
            this.$router.push({
              name: `${this.routePrefix}.index`,
              query:{
                page
              }
            });
            this.getList();
          }
        },
        getCurrentPage(){
          let currentPage=this.$route.query.page??1;
          return parseInt(currentPage>=1?currentPage:1);
        },
      }
    };
  </script>
  