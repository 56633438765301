<template>
    <thead class="thead-light">
        <tr>
            <th v-for="header in headers">
                {{ $t(header.title) }}
            </th>
        </tr>
    </thead>
</template>


<script>
    export default {
        props: {
            headers: {
                type: Array
            },
        },
        name: "TableHeader",
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>