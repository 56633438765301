var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('card-header',{attrs:{"title":_vm.$router.currentRoute.meta.title}}),_c('form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.serverError)?_c('div',{staticClass:"alert alert-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t("labels.error"))+" : ")]),_vm._v(" "+_vm._s(_vm.serverError)+" ")]):_vm._e()])]),(_vm.isItemFound())?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"stackingMaxOrdersPerCaptain"}},[_vm._v(_vm._s(_vm.$t("fields.stacking_max_orders_per_captain")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stackingMaxOrdersPerCaptain),expression:"stackingMaxOrdersPerCaptain"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.fieldErrors.stackingMaxOrdersPerCaptain,
                  },attrs:{"id":"stackingMaxOrdersPerCaptain","type":"number","step":"1"},domProps:{"value":(_vm.stackingMaxOrdersPerCaptain)},on:{"input":function($event){if($event.target.composing){ return; }_vm.stackingMaxOrdersPerCaptain=$event.target.value}}}),(_vm.fieldErrors.stackingMaxOrdersPerCaptain)?_c('span',{staticClass:"invalid-feedback d-block",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.fieldErrors.stackingMaxOrdersPerCaptain))])]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"stackingMaxOrdersPerBike"}},[_vm._v(_vm._s(_vm.$t("fields.stacking_max_orders_per_bike")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stackingMaxOrdersPerBike),expression:"stackingMaxOrdersPerBike"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.fieldErrors.stackingMaxOrdersPerBike,
                  },attrs:{"id":"stackingMaxOrdersPerBike","type":"number","step":"1"},domProps:{"value":(_vm.stackingMaxOrdersPerBike)},on:{"input":function($event){if($event.target.composing){ return; }_vm.stackingMaxOrdersPerBike=$event.target.value}}}),(_vm.fieldErrors.stackingMaxOrdersPerBike)?_c('span',{staticClass:"invalid-feedback d-block",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.fieldErrors.stackingMaxOrdersPerBike))])]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"stackingMaxDistanceForBikeInKm"}},[_vm._v(_vm._s(_vm.$t("fields.stacking_max_distance_for_bike_in_km")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stackingMaxDistanceForBikeInKm),expression:"stackingMaxDistanceForBikeInKm"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.fieldErrors.stackingMaxDistanceForBikeInKm,
                  },attrs:{"id":"stackingMaxDistanceForBikeInKm","type":"number","step":"any"},domProps:{"value":(_vm.stackingMaxDistanceForBikeInKm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.stackingMaxDistanceForBikeInKm=$event.target.value}}}),(_vm.fieldErrors.stackingMaxDistanceForBikeInKm)?_c('span',{staticClass:"invalid-feedback d-block",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.fieldErrors.stackingMaxDistanceForBikeInKm))])]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"stackingMaxDistanceForCarInKm"}},[_vm._v(_vm._s(_vm.$t("fields.stacking_max_distance_for_car_in_km")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.stackingMaxDistanceForCarInKm),expression:"stackingMaxDistanceForCarInKm"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.fieldErrors.stackingMaxDistanceForCarInKm,
                  },attrs:{"id":"stackingMaxDistanceForCarInKm","type":"number","step":"any"},domProps:{"value":(_vm.stackingMaxDistanceForCarInKm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.stackingMaxDistanceForCarInKm=$event.target.value}}}),(_vm.fieldErrors.stackingMaxDistanceForCarInKm)?_c('span',{staticClass:"invalid-feedback d-block",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.fieldErrors.stackingMaxDistanceForCarInKm))])]):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"in_area_radius_meters"}},[_vm._v(_vm._s(_vm.$t("fields.in_area_radius_meters")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.in_area_radius_meters),expression:"in_area_radius_meters"}],staticClass:"form-control",attrs:{"id":"in_area_radius_meters","type":"text","name":"in_area_radius_meters"},domProps:{"value":(_vm.in_area_radius_meters)},on:{"input":function($event){if($event.target.composing){ return; }_vm.in_area_radius_meters=$event.target.value}}})])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"assignment_interval_minutes"}},[_vm._v(_vm._s(_vm.$t("fields.assignment_interval_minutes")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assignment_interval_minutes),expression:"assignment_interval_minutes"}],staticClass:"form-control",attrs:{"id":"assignment_interval_minutes","type":"number","name":"assignment_interval_minutes"},domProps:{"value":(_vm.assignment_interval_minutes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.assignment_interval_minutes=$event.target.value}}})])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"assignment_algorithm_type"}},[_vm._v(_vm._s(_vm.$t("fields.assignment_algorithm_type")))]),_c('select-platform-area-setting-assignment-algorithm-types',{attrs:{"params":{
                    platform_id: _vm.id,
                    area_type: 'ZONE',
                    area_id: _vm.zone_id,
                  },"multiple":false},model:{value:(_vm.assignment_algorithm_type),callback:function ($$v) {_vm.assignment_algorithm_type=$$v},expression:"assignment_algorithm_type"}})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"attendance_key"}},[_vm._v(_vm._s(_vm.$t("fields.attendance_key")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attendance_key),expression:"attendance_key"}],staticClass:"form-control",attrs:{"id":"attendance_key","type":"text","name":"attendance_key"},domProps:{"value":(_vm.attendance_key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.attendance_key=$event.target.value}}})])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"attendance_validity_minutes"}},[_vm._v(_vm._s(_vm.$t("fields.attendance_validity_minutes")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.attendance_validity_minutes),expression:"attendance_validity_minutes"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.fieldErrors.stackingMaxDistanceForCarInKm,
                  },attrs:{"id":"attendance_validity_minutes","type":"number","name":"attendance_validity_minutes"},domProps:{"value":(_vm.attendance_validity_minutes)},on:{"input":function($event){if($event.target.composing){ return; }_vm.attendance_validity_minutes=$event.target.value}}}),(_vm.fieldErrors.attendanceValidityMinutes)?_c('span',{staticClass:"invalid-feedback d-block",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(_vm.fieldErrors.attendanceValidityMinutes))])]):_vm._e()])])]):_vm._e()]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-right"},[_c('router-link',{staticClass:"btn btn-neutral btn-sm",attrs:{"to":{
                  name: (_vm.routePrefix + ".index"),
                  params: { id: _vm.id, zone_id: _vm.zone_id },
                }}},[_c('i',{staticClass:"fa fa-arrow-left"})]),(_vm.isItemFound())?_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm"}):_vm._e(),_c('i',{staticClass:"fa fa-save"})]):_vm._e()],1)])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }