<template>
    <a href="javascript:;" @click.prevent="showReAssignPopUp(itemID)" class="btn btn-warning btn-sm">
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.reassign`) }}
        </span>
        <span>
          <i class="fas fa-arrows-alt-h"></i>
        </span>
    </a>
</template>

<script>
    import { ORDERS_REASSIGN as CRUD_ACTION_REASSIGN } from "actions/orders";
    export default {
        name: "OrderActionsReAssign",
        props: {
            itemID:{}
        },
        data(){
            return {}
        },
        methods:{
            showReAssignPopUp(id) {
                swal({
                    title: this.$i18n.t("labels.reassign_item"),
                    text: this.$i18n.t("messages.reassign_item"),
                    icon: "warning",
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.reassign"),
                    ],
                    dangerMode: true,
                }).then((willReAssign) => {
                    if (willReAssign) {
                        this.reAssignOrder(id);
                    }
                });
            },
            reAssignOrder(id) {
                this.$store.dispatch(CRUD_ACTION_REASSIGN, {
                    id
                })
                .then(() => {
                    this.$emit('input',false);
                    swal({
                        icon: "success",
                        text: this.$i18n.t("messages.reassign_success"),
                        buttons: false,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    let message = "";
                    if (error.response) {
                        let responseData = error.response.data;

                        if (responseData.errors && responseData.errors.error) {
                            message = responseData.errors.error;
                        } else {
                            message = this.$i18n.t("messages.reassign_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: "error",
                            text: message,
                            buttons: false,
                            timer: 5000,
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>