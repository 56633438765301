<template>
  <div aria-labelledby="tasks-tab" class="tab-pane fade" id="tasks" role="tabpanel">
    <div class="row">
      <div class="col-12">
        <table-container :headers="headers">
          <tr :key="item.id" v-for="item in items">
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.rank }}</td>
            <td>{{ item.taskStatus }}</td>
            <td>{{ item.taskType }}</td>
            <td class="text-direction-ltr">
              {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td class="text-direction-ltr">
              {{ item.arrivedAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td class="text-direction-ltr">
              {{ item.completedAt | moment("Y-MM-DD hh:mm:ss A") }}
            </td>
            <td>
              <a @click.prevent="setActiveTask(item)" data-target="#modal-additional-information" v-b-tooltip.hover="{ animation: false}" :title="$t('crud.show')" class="text-info px-1" data-toggle="modal" href="javascript:;">
                <i class="fas fa-eye"></i>
              </a>
              <a @click.prevent="getTaskTimeline(item.id)" data-target="#modal-timeline" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.timeline')" class="text-gray px-1" data-toggle="modal" href="javascript:;">
                <i class="fa fa-sort-numeric-down"></i>
              </a>
            </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">
              {{ $t("messages.no_result_found") }}
            </td>
          </tr>
        </table-container>
      </div>
    </div>

    <div aria-hidden="true" id="modal-additional-information" aria-labelledby="modal-additional-information" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.additional_information") }}
            </h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="collect_at_delivery">{{ $t(`${transPrefix}.fields.collect_at_delivery`) }}</label>
                  <input class="form-control" id="collect_at_delivery" name="collect_at_delivery" type="text" v-model="activeTask.collectAtDelivery" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="pay_at_pickup">{{ $t(`${transPrefix}.fields.pay_at_pickup`) }}</label>
                  <input class="form-control" id="pay_at_pickup" name="pay_at_pickup" type="text" v-model="activeTask.payAtPickup" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_description">{{ $t(`${transPrefix}.fields.address_description`) }}</label>
                  <input class="form-control" id="address_description" name="address_description" type="text" v-model="activeTaskAddress.description" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_email">{{ $t(`${transPrefix}.fields.address_email`) }}</label>
                  <input class="form-control" id="address_email" name="address_email" type="text" v-model="activeTaskAddress.email" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_phone">{{ $t(`${transPrefix}.fields.address_phone`) }}</label>
                  <input class="form-control" id="address_phone" name="address_phone" type="text" v-model="activeTaskAddress.phone" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_latitude">{{ $t(`${transPrefix}.fields.address_latitude`) }}</label>
                  <input class="form-control" id="address_latitude" name="address_latitude" type="text" v-model="activeTaskAddress.latitude" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_longitude">{{ $t(`${transPrefix}.fields.address_longitude`) }}</label>
                  <input class="form-control" id="address_longitude" name="address_longitude" type="text" v-model="activeTaskAddress.longitude" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="activeTask.delivery_confirmation_code">
                <div class="form-group">
                  <label for="delivery_confirmation_code">{{ $t(`${transPrefix}.fields.delivery_confirmation_code`) }}</label>
                  <input class="form-control" id="delivery_confirmation_code" name="delivery_confirmation_code" type="text" v-model="activeTask.deliveryConfirmationCode" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="activeTask.receipt">
                <div class="form-group">
                  <label for="receipt">{{ $t(`${transPrefix}.fields.receipt`)}}</label>
                  <files-image v-model="activeTask.receipt" id="receipt" :disabled="true" :thumbnailImage="activeTask.receipt.url"/>
                </div>
              </div>
              <div class="col-12 col-md-6" v-if="activeTaskAddress.imageUrl">
                <div class="form-group">
                  <label for="addressImage">{{ $t(`${transPrefix}.fields.address_image`)}}</label>
                  <files-image v-model="activeTaskAddress.imageUrl" id="addressImage" :disabled="true" :thumbnailImage="activeTaskAddress.imageUrl"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h6 class="modal-title mb-3">
                  {{ $t("tabs.items") }}
                </h6>
                <table-container :headers="taskItemsTableHeaders">
                  <tr :key="item.id" v-for="item in activeTaskItems">
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.quantity }}</td>
                  </tr>
                  <tr class="text-center" v-if="!activeTaskItems.length">
                    <td colspan="999">
                      {{ $t("messages.no_result_found") }}
                    </td>
                  </tr>
                </table-container>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary ml-auto" data-dismiss="modal" type="button">
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div aria-hidden="true" id="modal-timeline" aria-labelledby="modal-timeline" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-timeline">
              {{ $t("labels.timeline") }}
            </h6>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="vertical-timeline vertical-timeline--animate" v-for="item in getItemSortedByDate">
                  <div class="vertical-timeline-element">
                    <div>
                      <span class="vertical-timeline-element-icon bounce-in" v-bind:style="{ color: item.statusColor }">
                        <i class="fas fa-dot-circle"></i>
                      </span>
                      <div class="vertical-timeline-element-content bounce-in">
                        <h4 class="timeline-title">{{ item.statusDisplayName }}</h4>
                        <h6 class="timeline-message" v-if="item.captainId">{{ $t('fields.captain_id') }} : {{ item.captainId }}</h6>
                        <p></p>
                        <span class="vertical-timeline-element-date text-direction-ltr">{{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary ml-auto" data-dismiss="modal" type="button">
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ORDERS_TASKS_TIMELINES_SHOW as TASKS_TIMELINES_SHOW } from "actions/platforms_portal/orders";
  const CRUD_CODE = "orders";
  export default {
    name: "PlatformsPortalOrderTabsTasks",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      permissionPrefix:{
        type: String,
        default: CRUD_CODE
      },
      items: {
        type: Array,
        default: function(){
          return []
        }
      },
      headers: {
        type: Array,
        default: function(){
          return [
            {
              title: `fields.name`,
            },
            {
              title: `fields.description`,
            },
            {
              title: `${CRUD_CODE}.fields.rank`,
            },
            {
              title: `fields.status`,
            },
            {
              title: `fields.type`,
            },
            {
              title: `fields.created_at`,
            },
            {
              title: `fields.arrived_at`,
            },
            {
              title: `fields.completed_at`,
            },
            {
              title: `fields.actions`,
            },
          ]
        }
      },
      taskItemsTableHeaders: {
        type: Array,
        default: function(){
          return [
            {
              title: `fields.name`,
            },
            {
              title: `fields.description`,
            },
            {
              title: `fields.price`,
            },
            {
              title: `fields.quantity`,
            },
          ]
        }
      },
    },
    data(){
      return {
        activeTaskAddress: {},
        activeTask: {},
        activeTaskItems: [],
        activeTimelines: []
      }
    },
    computed:{
      getItemSortedByDate(){
        return this.activeTimelines.sort(function(a, b) {
          let dateA = new Date(a.createdAt),
                  dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
      }
    },
    methods:{
      setActiveTask(item) {
        this.activeTaskAddress = item.address;
        this.activeTask = item;
        this.activeTaskItems = item.items;
      },
      getTaskTimeline(id, additionalParams={}) {
        this.activeTimelines = [];
        this.$store.dispatch(TASKS_TIMELINES_SHOW, {
          id,
          ...additionalParams
        })
        .then((response) => {
          this.activeTimelines = response.data;
        })
        .catch((error) => {
          let message = "";
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors && responseData.errors.error) {
              message = responseData.errors.error;
            } else {
              message = this.$i18n.t("messages.update_error");
            }
          }
          if(message){
            swal({
              icon: "error",
              text: message,
              buttons: false,
              timer: 5000,
            });
          }
        });
      },
    }
  };
</script>
