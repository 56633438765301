import {
  CAPTAIN_REGISTRATION_REQUEST_LIST as CRUD_ACTION_LIST,
  CAPTAIN_REGISTRATION_REQUEST_SHOW as CRUD_ACTION_SHOW,
  CAPTAIN_REGISTRATION_REQUEST_UPDATE as CRUD_ACTION_UPDATE,
  GET_REJECTION_REASON
} from "../../actions/suppliers_portal/captain_registration_requests";
import axioses from "@/axios/config";

const routePrefix = "captain_registration_requests";

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });

      axioses.supplierPortal
        .get(`/${routePrefix}`, { params })
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.supplierPortal
        .get(`/${routePrefix}/${params.id}`)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      let fData = new FormData();
      Object.keys(params.data).forEach(key => {
        if (
          typeof params.data[key] !== "undefined" &&
          params.data[key] !== null
        ) {
          fData.append(key, params.data[key]);
        }
      });
      axioses.supplierPortal
        .put(`/${routePrefix}/${params.id}`, fData)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [GET_REJECTION_REASON]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.supplierPortal
        .get(`/${routePrefix}/${params.id}/rejection_reason`)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  }
};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
