import { USER_REQUEST, USER_ERROR, USER_SUCCESS,USER_PRIVILEGES } from "../actions/user";
import axioses from "@/axios/config";
import {AUTH_LOGOUT} from "../actions/auth";
import {STOMP_UPDATE} from "../actions/sockets/stomp";

const state = { status: "", profile: localStorage.getItem("profile") || {} ,privileges:localStorage.getItem("privileges") || [] };

export function includesAll(haystack, elements){
  if(typeof elements ==='string'){
    elements=elements.split(",");
  }
  return elements.every(element => haystack.includes(element));
}

const getters = {
  getProfile(state){
    let profile=state.profile;
    if(typeof profile ==='string'){
      profile=JSON.parse(profile);
    }
    return profile;
  },
  getPrivileges(state){
    let privileges=state.privileges;
    if(typeof privileges ==='string'){
      privileges=privileges.split(",");
    }
    return privileges;
  },
  can(state,getters){
    return function(permission){
      return includesAll(getters.getPrivileges,permission)
    }
  },
  isProfileLoaded: state => !!state.profile.name
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(USER_REQUEST);
      axioses.base
          .get("/users/me")
          .then(response => {
            commit(USER_SUCCESS, response.data.user);
            commit(USER_PRIVILEGES,response.data.privileges);
            commit(STOMP_UPDATE, response.data.stomp,{ root :true });
            resolve();
          })
          .catch(error => {
            commit(USER_ERROR);
            // if resp is unauthorized, logout, to
            if(!['Request aborted','Network Error'].includes(error.message)){
              dispatch(AUTH_LOGOUT);
            }
            reject(error);
          });
    });
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, user) => {
    state.status = "success";
    localStorage.setItem("profile",JSON.stringify(user));
    state.profile=user;
  },
  [USER_PRIVILEGES]:(state,privileges)=>{
    localStorage.setItem("privileges",privileges);
    state.privileges = privileges;
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
    state.privileges = [];
    localStorage.removeItem("privileges");
    localStorage.removeItem("profile");
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
