<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`,params:{id} }"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="external_id">{{ $t(`fields.external_id`)}}</label>
                  <input  v-model="external_id" id="external_id" type="text" class="form-control" name="external_id" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name" id="name" type="text" class="form-control" name="name" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="phone">{{ $t(`fields.phone`)}}</label>
                  <input  v-model="phone" id="phone" type="text" class="form-control" name="phone" disabled>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="latitude">{{ $t(`fields.latitude`) }}</label>
                  <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                    <i class="fa fa-map-pin "></i>
                  </a>
                  <input class="form-control" id="latitude" name="latitude" type="number" step="any" v-model="latitude" disabled/>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="longitude">{{ $t(`fields.longitude`) }}</label>
                  <a href="#" class="mx-1 text-blue" @click.prevent="showPickupMapPicker">
                    <i class="fa fa-map-pin "></i>
                  </a>
                  <input class="form-control" id="longitude" name="longitude" type="number" step="any" v-model="longitude" disabled/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group ">
                  <label for="description">{{ $t(`fields.description`)}}</label>
                  <textarea v-model="description" class="form-control" id="description" name="description" rows="5" disabled></textarea>
                </div>
              </div>
              <div v-if="pickupMapPicker" class="col-12 position-fixed top-9 left-0 p-5"  style="z-index: 99999">
                <button class="btn btn-danger btn-sm" @click.prevent="hidePickupMapPicker">
                  {{ $t(`labels.close`) }}
                  <i class="fa fa-window-close"></i>
                </button>
                <maps-pick-address mode="show" :position="{lat:latitude,lng:longitude}" v-model="pickupMarkerPosition"/>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { BRANCHES_SHOW as CRUD_ACTION_SHOW} from 'actions/platforms_portal/branches';
  const PLATFORMS_CRUD_CODE="platforms";
  const BRANCHES_CRUD_CODE="branches";
  const PLATFORMS_BRANCHES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.${BRANCHES_CRUD_CODE}`;
  const CRUD_CODE=`platforms_portal.${BRANCHES_CRUD_CODE}`;
  const CRUD_TRANS_CODE=`${PLATFORMS_BRANCHES_CRUD_CODE}`;
  const CRUD_PERMISSION_CODE=`partner_branches`;
  export default {
    name: "PlatformsPortalBranchShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_TRANS_CODE,
        permissionPrefix: `${CRUD_PERMISSION_CODE}`,
        platformsRoutePrefix: `${PLATFORMS_CRUD_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        latitude: '',
        longitude: '',
        name: '',
        external_id: '',
        phone: '',
        description: '',
        pickupMapPicker:false,
        pickupMarkerPosition:{},
        serverError: '',
        responseStatus:'',
      }
    },
    watch:{
      pickupMarkerPosition:function(newVal,oldVal){
        this.latitude=newVal.lat;
        this.longitude=newVal.lng;
      },
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.latitude=response.data.latitude;
          this.name=response.data.name;
          this.longitude=response.data.longitude;
          this.description=response.data.description;
          this.phone=response.data.phone;
          this.external_id=response.data.externalId;
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      hidePickupMapPicker(){
        this.pickupMapPicker=false;
      },
      showPickupMapPicker(){
        this.pickupMapPicker=true;
      },
    },
  };
</script>
