<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header>
          <router-link :to="{ name: `${shiftsRoutePrefix}.index`}"  class="btn btn-primary btn-sm">
            <i class="fa fa-arrow-left"></i>
          </router-link>
        </template>

        <template v-slot:table-rows>
          <tr v-for="item in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.captainId }} </td>
            <td> {{ item.totalDeliveredOrders }} </td>
            <td> {{ item.totalDeliveredOrdersOnTime }} </td>
            <td> {{ item.totalDelayInSeconds }} </td>
            <td> {{ item.totalDeliveredPickupTasksCount }} </td>
            <td> {{ item.totalDeliveredPickupTasksOnTimeCount }} </td>
            <td> {{ item.totalDelayInSecondsForPickupTasks }} </td>
            <td> {{ item.totalDeliveredDeliveryTasksCount }} </td>
            <td> {{ item.totalDeliveredDeliveryTasksOnTimeCount }} </td>
            <td> {{ item.totalDelayInSecondsForDeliveryTasks }} </td>
            <td> {{ item.totalDeliveredReturnToAreaTasksCount }} </td>
            <td> {{ item.totalDeliveredReturnToAreaTasksOnTimeCount }} </td>
            <td> {{ item.totalDelayInSecondsForReturnToAreaTasks }} </td>
            <td> {{ item.totalTimeInSeconds }} </td>
            <td> {{ item.totalBusyTimeInSeconds }} </td>
            <td> {{ item.totalSlackTimeInSeconds }} </td>
            <td> {{ item.totalRoamingFreelyTimeInSeconds }} </td>
            <td> {{ item.totalDisconnectedTimeInSeconds }} </td>
            <td> {{ item.totalOvertimeInSeconds }} </td>
            <td> {{ item.walletTransactionId }} </td>
            <td> {{ item.snapshotCount }} </td>
            <td class="text-direction-ltr"> {{ item.shiftStartedAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.captainStartedAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.firstInAreaAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.shiftCompletedAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { PLATFORMS_AREAS_SHIFTS_SUMMARIES_LIST as CRUD_ACTION_LIST} from 'actions/platforms';
  const PLATFORMS_CRUD_CODE="platforms";
  const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
  const SHIFTS_CRUD_CODE=`${ZONES_CRUD_CODE}.shifts`;
  const CRUD_CODE=`${SHIFTS_CRUD_CODE}.summaries`;
  const CRUD_TRANS_CODE=`${CRUD_CODE}`;
  const CRUD_PERMISSION_CODE=`platform_area_shifts`;
  export default {
    name: "PlatformZoneShiftSummaryIndex",
    components:{
    },
    data(){
      return {
        routePrefix: `${CRUD_CODE}`,
        transPrefix: `${CRUD_TRANS_CODE}`,
        permissionPrefix: CRUD_PERMISSION_CODE,
        shiftsRoutePrefix: SHIFTS_CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        zone_id:this.$router.currentRoute.params.zone_id,
        shift_id:this.$router.currentRoute.params.shift_id,
        tableHeaders:[
          {
             'title':`fields.id`
          },
          {
             'title':`fields.captain_id`
          },
          {
            'title':`fields.total_delivery_orders`
          },
          {
            'title':`fields.total_delivery_orders_on_time`
          },
          {
            'title':`fields.delay_in_seconds`
          },
          {
            'title':`fields.total_delivered_pickup_tasks_count`
          },
          {
            'title':`fields.total_delivered_pickup_tasks_on_time_count`
          },
          {
            'title':`fields.total_delay_in_seconds_for_pickup_tasks`
          },
          {
            'title':`fields.total_delivered_delivery_tasks_count`
          },
          {
            'title':`fields.total_delivered_delivery_tasks_on_time_count`
          },
          {
            'title':`fields.total_delay_in_seconds_for_delivery_tasks`
          },
          {
            'title':`fields.total_delivered_return_to_area_tasks_count`
          },
          {
            'title':`fields.total_delivered_return_to_area_tasks_on_time_count`
          },
          {
            'title':`fields.total_delay_in_seconds_for_return_to_area_tasks`
          },
          {
            'title':`fields.total_time_in_second`
          },
          {
            'title':`fields.total_busy_time_in_seconds`
          },
          {
            'title':`fields.total_slack_time_in_seconds`
          },
          {
            'title':`fields.total_roaming_freely_time_in_seconds`
          },
          {
            'title':`fields.total_disconnected_time_in_seconds`
          },
          {
            'title':`fields.total_overtime_in_seconds`
          },
          {
            'title':`fields.transaction_id`
          },
          {
            'title':`fields.snapshot_count`
          },
          {
            'title':`fields.shift_started_at`
          },
          {
            'title':`fields.captain_started_at`
          },
          {
            'title':`fields.captain_in_area_at`
          },
          {
            'title':`fields.shift_completed_at`
          },
          {
            'title':`fields.created_at`
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          platformId:this.id,
          area_type: "ZONE",
          area_id: this.zone_id,
          shift_id: this.shift_id,
          pageId: this.getCurrentPage()-1,
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
