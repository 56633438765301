import Show from "@/views/suppliers_portal/supplier/show";
import Edit from "@/views/suppliers_portal/supplier/edit";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../../helper"

const URL_PREFIX = 'suppliers_portal/supplier';
const CODE = 'suppliers_portal.supplier';
const TRANS_CODE = 'suppliers';
const PERMISSION_PREFIX = 'supplier_supplier';
const DETAILS_TRANS_CODE = 'details';


const routes = [
    {
        path: `${URL_PREFIX}`,
        name: `${CODE}.show`,
        component: Show,
        meta: {
            'can': [`${PERMISSION_PREFIX}_can_view`],
            'removeClass' : [`bg-default`],
            'title': `${TRANS_CODE}.list_title`,
            'breadDetails': {
                'title': `${TRANS_CODE}.title`,
                'index_route': `${CODE}.index`,
                'show': true,
                'items': [
                    {
                        'title': `${TRANS_CODE}.${DETAILS_TRANS_CODE}.title`,
                    },
                    {
                        'title': `crud.list`,
                    }
                ]
            }
        },
        beforeEnter: function (to,from, next){
            if(ifAuthenticated(to, from, next)){
                commonBeforeEnter(to, from, next);
            }
        },
    },
    {
        path: `${URL_PREFIX}/edit`,
        name: `${CODE}.edit`,
        component: Edit,
        meta: {
            'can': [`${PERMISSION_PREFIX}_can_edit`],
            'removeClass' : [`bg-default`],
            'title': `${TRANS_CODE}.edit_title`,
            'breadDetails': {
                'title': `${TRANS_CODE}.title`,
                'index_route': `${CODE}.index`,
                'show': true,
                'items': [
                    {
                        'title': `${TRANS_CODE}.${DETAILS_TRANS_CODE}.title`,
                    },
                    {
                        'title': `crud.edit`,
                    }
                ]
            }
        },
        beforeEnter: function (to,from, next){
            if(ifAuthenticated(to, from, next)){
                commonBeforeEnter(to, from, next);
            }
        },
    }
];

export default routes;
