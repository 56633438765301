<template>
    <li class="nav-item dropdown" v-if="isStompLoaded">
        <a class="nav-link" v-bind:class="{'notification-disabled fa':isNotificationDisabled}" @click.prevent="disableEnable()">
            <i class="ni ni-bell-55"></i>
        </a>
        <a class="nav-link d-none" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="ni ni-bell-55"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-xl  dropdown-menu-right  py-0 overflow-hidden d-none">
            <!-- Dropdown header -->
            <div class="px-3 py-3">
                <h6 class="text-sm text-muted m-0">You have <strong class="text-primary">13</strong> notifications.</h6>
            </div>
            <!-- View all -->
            <a href="#!" class="dropdown-item text-center text-primary font-weight-bold py-3">View all</a>
        </div>
    </li>
</template>

<script>
    import { NOTIFICATIONS_UPDATE_STATUS } from "actions/sockets/notifications";
    import Stomp from "webstomp-client";
    import { mapGetters, mapState } from "vuex";
    export default {
        name: "TopNavbarNotifications",
        data(){
            return {
                stompClient:null,
                stompOptions:{
                    debug:false,
                    heartbeat:{
                        outgoing : 0,
                        incoming : 20000
                    }
                },
            };
        },
        watch: {
            isStompLoaded:function(newVal, oldVal){
                if(newVal){
                    this.connectDisconnectBasedNotificationStatus();
                }
            },
        },
        computed: {
            ...mapGetters(["isStompLoaded","getStompConnectionUrl","getStompNotificationsTopic","getAuthorizationHeader","isNotificationDisabled"]),
        },
        beforeDestroy() {
            this.disconnect();
        },
        mounted() {
            this.connectDisconnectBasedNotificationStatus();
        },
        methods:{
            disableEnable(){
                this.$store.commit(NOTIFICATIONS_UPDATE_STATUS,{
                    status:!this.isNotificationDisabled
                });
                this.connectDisconnectBasedNotificationStatus();
            },
            connectDisconnectBasedNotificationStatus(){
                if(this.isStompLoaded){
                    if(this.isNotificationDisabled){
                        this.disconnect();
                    }else{
                        this.connect();
                    }
                }
            },
            connect() {
                this.stompClient = Stomp.client(this.getStompConnectionUrl,this.stompOptions);
                this.stompClient.connect(
                    {"X-Authorization": this.getAuthorizationHeader},
                    frame => {
                        if(this.getStompNotificationsTopic) {
                            this.stompClient.subscribe(this.getStompNotificationsTopic, tick => {
                                let responseBody=JSON.parse(tick.body);
                                this.appNotification(responseBody);
                                this.webNotification(responseBody);
                                if(this.isNotificationDisabled){
                                    this.disconnect();
                                }
                            });
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            disconnect(){
                if (this.isConnected()) {
                    this.stompClient.disconnect();
                }
            },
            isConnected(){
                return this.stompClient?this.stompClient.connected:false;
            },
            appNotification(responseBody){
                this.$notify({
                    type:'success',
                    title: responseBody.title,
                    text: responseBody.message,
                });
            },
            webNotification(responseBody){
                if(document.hidden){
                    this.$notification.show(responseBody.title, {
                        body: responseBody.message,
                        icon: require('../../../assets/notification_icon.jpeg')
                    }, {
                        onerror: function () {},
                        onclick: function () {},
                        onclose: function () {},
                        onshow: function () {}
                    });
                }
            }
        }
    }
</script>

<style scoped>

    .notification-disabled::before{
        content: "\f05e";
        position: absolute;
        color: red;
        z-index: 1;
        font-size: 14px;
    }
    .nav-link{
        cursor: pointer;
    }
</style>
