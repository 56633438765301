<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" >
          <router-link
            v-if="assignment_id"
            :to="{
              name: `${routePrefix}.assignment_tracking.index`,
              query: { assignment_id }
            }"
            class="btn btn-primary btn-sm"
            v-can:view="permissionPrefix"
          >
            <span class="d-none d-lg-inline mr-2">{{ $t(`${routePrefix}.assignment_tracking.title`)}}</span>
            <span><i class="fa fa-road"></i></span>
          </router-link>
          <router-link v-if="allocation_window_id" :to="{ name: `${routePrefix}.allocation_windows.index`,params:{id,allocation_window_id} }"  class="btn btn-primary btn-sm" v-can:view_history="permissionPrefix">
            <span class="d-none d-lg-inline mr-2">{{ $t(`${routePrefix}.allocation_windows.title`)}}</span>
            <span><i class="fa fa-map-marker"></i></span>
          </router-link>
          <a v-if="!serverError && platform_order_url" :href="platform_order_url" target="_blank"  class="btn btn-primary btn-sm">
            <span class="d-none d-lg-inline mr-2">{{ $t(`labels.show_on`)}} {{$i18n.locale==='ar'?platform_ar_name:platform_en_name}}</span>
            <span><i class="fa fa-eye"></i></span>
          </a>
        </card-header>
        <!-- Tabs -->
        <ul class="nav nav-tabs px-2" role="tablist">
          <li class="nav-item">
            <a href="#general" id="general-tab" aria-controls="general" aria-selected="true" class="nav-link active" data-toggle="tab" role="tab">
              {{ $t("tabs.general") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#tasks" id="tasks-tab" aria-controls="tasks" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.tasks") }}
            </a>
          </li>
          <li class="nav-item" v-if="captain.id">
            <a href="#captain" id="captain-tab" aria-controls="captain" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.captain") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#map" id="map-tab" aria-controls="map" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.map") }}
            </a>
          </li>
          <li class="nav-item" v-if="tracking_url">
            <a href="#tracking" id="tracking-tab" aria-controls="tracking" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.tracking") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#meta_data" aria-controls="meta_data" aria-selected="false" class="nav-link" data-toggle="tab" id="meta_data-tab" role="tab">
              {{ $t("tabs.meta_data") }}
            </a>
          </li>
          <li class="nav-item">
            <a href="#platform" id="platform-tab" aria-controls="platform" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.platform") }}
            </a>
          </li>
          <li class="nav-item" v-if="customer.backendId">
            <a href="#customer" id="customer-tab" aria-controls="customer" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.customer") }}
            </a>
          </li>
          <li class="nav-item" v-if="allocation_window_id">
            <a href="#allocation_window" id="allocation_window-tab" aria-controls="allocation_window" aria-selected="false" class="nav-link" data-toggle="tab" role="tab">
              {{ $t("tabs.allocation_window") }}
            </a>
          </li>
          <li class="nav-item" v-if="timelines && timelines.length > 0">
            <a href="#order_timeline" aria-controls="order_timeline" aria-selected="false" class="nav-link" data-toggle="tab" id="order_timeline-tab" role="tab">
              {{ $t("tabs.timeline") }}
            </a>
          </li>
        </ul>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="alert alert-danger" v-if="serverError">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="tab-content" v-if="!serverError && isItemFound">
            <general-tab :fields="getGeneralTabFields()" :transPrefix="transPrefix"/>
            <platform-tab :fields="getPlatformTabFields()" :transPrefix="transPrefix"/>
            <meta-data-tab :items="metaDataItems" :transPrefix="transPrefix"/>
            <tasks-tab :items="tasksItems" :transPrefix="transPrefix" :permissionPrefix="permissionPrefix" :updateTaskItemHandler="updateTaskItemHandler"/>
            <map-tab :items="tasksItems"/>
            <tracking-tab :url="this.tracking_url" />
            <captain-tab :fields="getCaptainTabFields()" :transPrefix="transPrefix" :permissionPrefix="permissionPrefix"/>
            <customer-tab :fields="getCustomerTabFields()" :transPrefix="transPrefix"/>
            <allocation-window-tab :fields="getAllocationWindowTabFields()" :transPrefix="transPrefix" :permissionPrefix="permissionPrefix"/>
            <timeline-tab :items="timelines" :transPrefix="transPrefix"/>
          </div>
        </div>
        <!-- Card footer -->
        <div class="card-footer">
          <div class="row">
            <div class="col text-right">
              <manual-assign-action v-model="can_manually_assign_order" v-if="can_manually_assign_order" :itemID="id" v-can:manually_assign="permissionPrefix"/>
              <re-assign-action v-model="can_re_assign_order" v-if="can_re_assign_order" :itemID="id" v-can:re_assign="permissionPrefix"/>
              <cancel-action v-if="can_cancel_order" :itemID="id" v-can:cancel="permissionPrefix" />
              <router-link :to="{ name: `${routePrefix}.index` }" class="btn btn-neutral btn-sm">
                <i class="fa fa-arrow-left"></i>
              </router-link>
                <a class="btn btn-danger btn-sm" v-if="cancellationReasons.length > 0"
                   data-target="#modal-cancellation-reasons"
                   data-toggle="modal"
                   href="javascript:;"
                   v-b-tooltip.hover="{ animation: false}"
                   :title="$t('labels.cancellation_reasons')"
                >
                  <i class="fas fa-ban"></i>
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div
            aria-hidden="true"
            aria-labelledby="modal-cancellation-reasons"
            class="modal fade"
            id="modal-cancellation-reasons"
            role="dialog"
            tabindex="-1"
    >
      <div
              class="modal-dialog modal-dialog-centered modal-lg"
              role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="modal-title-default">
              {{ $t("labels.cancellation_reasons") }}
            </h6>
            <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="modal"
                    type="button"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <ul class="list">

                <li v-for="(cancellationReason,index) in cancellationReasons" :key="index">
                  {{cancellationReason.message}}  <p v-if="cancellationReason.otherReason" style="color: #1496ba; font-size: 12px;">    -{{cancellationReason.otherReason}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button
                    class="btn btn-primary ml-auto"
                    data-dismiss="modal"
                    type="button"
            >
              {{ $t("labels.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <cancel-action-model v-if="can_cancel_order" v-model="can_cancel_order" :activeParams="{activeId:id}" v-can:cancel="permissionPrefix"/>
  </div>
</template>
<script>
import { ORDERS_SHOW as CRUD_ACTION_SHOW } from "actions/orders";
import generalTab from "./tabs/general";
import platformTab from "./tabs/platform";
import metaDataTab from "./tabs/meta_data";
import tasksTab from "./tabs/tasks";
import mapTab from "./tabs/map";
import trackingTab from "./tabs/tracking";
import captainTab from "./tabs/captain";
import customerTab from "./tabs/customer";
import allocationWindowTab from "./tabs/allocation_window";
import timelineTab from "./tabs/timeline";
import manualAssignAction from "./actions/manual_assign";
import reAssignAction from "./actions/re_assign";
import cancelAction from "./actions/cancel";
import cancelActionModel from "./actions/modals/cancel";

const CRUD_CODE = "orders";
export default {
  name: "OrderShow",
  components:{
    generalTab,
    platformTab,
    metaDataTab,
    tasksTab,
    mapTab,
    trackingTab,
    captainTab,
    customerTab,
    allocationWindowTab,
    timelineTab,
    manualAssignAction,
    reAssignAction,
    cancelAction,
    cancelActionModel
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_CODE,
      id: this.$router.currentRoute.params.id,
      can_cancel_order: false,
      can_re_assign_order: false,
      can_manually_assign_order: false,
      backend_id: "",
      branch_id: "",
      platform_zone_id: "",
      trip_id: "",
      trip_index: "",
      assignment_id: "",
      dispatched_by: "",
      city: "",
      payment_type: "",
      order_status: "",
      tracking_url: "",
      type: "",
      earnings: 0,
      captain: {},
      allocation_window_id:null,
      platform_ar_name: "",
      platform_en_name: "",
      platform_image: {
        file: "",
        thumbnail: "",
      },
      customer: {},
      platform_order_url:"",
      metaDataItems: [],
      timelines: [],
      tasksItems: [],
      cancellationReasons: [],
      serverError: "",
      isItemFound: false,
      allocation_window: {},

    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store.dispatch(CRUD_ACTION_SHOW, {
          id: this.id,
        })
        .then((response) => {
          this.backend_id = response.data.backendId;
          this.branch_id = response.data.branchId;
          this.platform_zone_id = response.data.platformZoneId;
          this.trip_id=response.data.tripId;
          this.assignment_id=response.data.assignmentId
          this.trip_index = response.data.tripIndex;
          this.dispatched_by = response.data.manuallyDispatched?response.data.manuallyDispatched.dispatchedBy : "";
          this.city = response.data.city
          this.order_status = response.data.status;
          this.payment_type = response.data.paymentType;
          this.earnings = response.data.earnings;
          this.captain=response.data.captain?response.data.captain:{};
          this.allocation_window=response.data.allocationWindow?response.data.allocationWindow:{};
          this.allocation_window_id=response.data.allocationWindowId;
          this.type=response.data.deliveryType;
          this.createdAt=response.data.createdAt?this.$moment(response.data.createdAt).format("Y-MM-DD T HH:mm"):"";

          this.tracking_url = response.data.trackingUrl;

          this.can_cancel_order = response.data.canCancelOrder;
          this.can_re_assign_order = response.data.canReAssignOrder;
          this.can_manually_assign_order = response.data.canManuallyAssign;

          this.cancellationReasons=response.data.cancellationReasons;
          this.platform_en_name = response.data.platform ? response.data.platform.name : "";
          this.platform_ar_name = response.data.platform ? response.data.platform.nameAr : "";
          this.platform_image.thumbnail = response.data.platform ? response.data.platform.image ? response.data.platform.image.url : "" : "";
          this.platform_order_url=response.data.platformOrderUrl;
          this.customer=response.data.customer?response.data.customer:{};

          this.metaDataItems = response.data.metaData;
          this.timelines = response.data.timelines;
          this.tasksItems = response.data.tasks;

          this.isItemFound = true;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";
          if (error.response) {
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }
          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    updateTaskItemHandler(item,index){
      let taskItemsCloned = this.tasksItems;
      new Promise((resolve, reject) => {
        this.tasksItems= [];
        resolve();
      }).then(() =>{
        taskItemsCloned[index] = item;
        this.tasksItems = taskItemsCloned;
      });
    },
    getGeneralTabFields(){
      return {
        id:this.id,
        order_status:this.order_status,
        backend_id:this.backend_id,
        branch_id:this.branch_id,
        platform_zone_id: this.platform_zone_id,
        trip_index:this.trip_index,
        trip_id:this.trip_id,
        assignment_id:this.assignment_id,
        payment_type:this.payment_type,
        earnings:this.earnings,
        dispatched_by:this.dispatched_by,
        city:this.city,
        type:this.type,
        allocation_window_id:this.allocation_window_id,
        createdAt:this.createdAt,
      };
    },
    getPlatformTabFields(){
      return {
        platform_ar_name:this.platform_ar_name,
        platform_en_name:this.platform_en_name,
        platform_image:this.platform_image,
      };
    },
    getCustomerTabFields(){
      return {
        backend_id:this.customer.backendId,
        name:this.customer.name,
      };
    },
    getCaptainTabFields(){
      return {
        ...this.captain,
      };
    },
    getAllocationWindowTabFields(){
      return {
        ...this.allocation_window,
        createdAt: this.allocation_window.createdAt ? this.$moment(this.allocation_window.createdAt).format("Y-MM-DD hh:mm:ss A") : "",
        assignedAt: this.allocation_window.assignedAt ? this.$moment(this.allocation_window.assignedAt).format("Y-MM-DD hh:mm:ss A") : "",

    };
    },
  },
};
</script>
