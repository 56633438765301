<template>
	<card-container
		:title="$t('captains.title')"
		:headers="tableHeaders"
		:lastPage="lastPage"
		:totalElements="totalElements"
		:currentPage="getCurrentPage()"
		:clickHandler="pagination"
	>
		<template v-slot:table-rows>
			<tr v-for="(item,index) in items" :key="item.id">
				<td>
					<div class="media align-items-center">
						<a v-if="getImageThumbnail(item)" href="javascript:;" class="avatar rounded-circle">
							<img alt="Image placeholder" :src="getImageThumbnail(item)">
						</a>
						<div class="media-body">
							<span class="name mb-0 text-sm"></span>
						</div>
					</div>
				</td>
				<td> {{ item.id }} </td>
				<td> {{ item.user.name }} </td>
				<td> {{ item.city.name }} </td>
				<td> {{ item.user.email }} </td>
				<td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A") }} </td>
				<table-item-actions :actions="tableItemActions">
					<template v-slot:additional-items>
						<de-attach-captain-action :platformId="platformId" :areaType='areaType' :branchId="branchId" :shiftId="shiftId" :captainId="item.id" :successCallback="getList" v-can:add="captainsPermissionPrefix"/>
					</template>
				</table-item-actions>
			</tr>
			<tr class="text-center" v-if="!items.length">
				<td colspan="999">{{ $t('messages.no_result_found')}}</td>
			</tr>
		</template>
	</card-container>
</template>

<script>
import { PLATFORMS_AREAS_SHIFTS_CAPTAINS_LIST as CRUD_ACTION_LIST } from 'actions/platforms';
import deAttachCaptainAction from "./de_attach_captain";
export default {
	name: "ShiftCaptainsList",
	components:{
		deAttachCaptainAction,
	},
	props: {
		platformId: '',
		branchId: '',
		shiftId: '',
		areaType: '',
		captainsPermissionPrefix: '',
		refreshList: {
			type: Boolean,
			default:false,
		},
	},
	data() {
		return {
			tableHeaders: [
				{
					'title': `fields.image`
				},
				{
					'title': `fields.id`
				},
				{
					'title': `fields.name`
				},
				{
					'title': `fields.city`
				},
				{
					'title': `fields.email`
				},
				{
					'title': `fields.created_at`
				},
				{
					'title': `fields.actions`
				}
			],
			tableItemActions:[],
			items: [],
			perPage: 20,
			totalElements: 0,
			lastPage: 0
		}
	},
	mounted() {
		this.getList();
	},
	watch: {
		refreshList: function (newVal, oldVal) {
			if (newVal) {
				this.getList();
			}
		}
	},
	methods: {
		getList() {
			this.$store.dispatch(CRUD_ACTION_LIST, {
				pageId: this.getCurrentPage()-1,
				platformId: this.$props.platformId,
				areaType: this.$props.areaType,
				areaId: this.$props.branchId,
				shiftId: this.$props.shiftId,
			}).then(response => {
				this.items = response.data.data;
				this.perPage = response.data.perPage;
				this.totalElements = response.data.totalElements;
				this.lastPage = response.data.pageCount;
			});
		},
		pagination(page){
			if(page!== this.getCurrentPage()){
				this.$router.push({
					name: `${this.routePrefix}.index`,
					query:{
						page, 
						...this.getSearchFiltersIfExists
					}
				});
				this.getList();
			}
		},
		getCurrentPage(){
			let currentPage=this.$route.query.page??1;
			return parseInt(currentPage>=1?currentPage:1);
		},
		getImageThumbnail(item){
			let image='';
			if(item.user && item.user.profilePicture){
				image=item.user.profilePicture.url;
			}
			return image;
		}
	}
}
</script>

<style scoped>
</style>
