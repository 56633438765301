<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="day_of_weeks">{{ $t(`fields.day_of_week`)}}</label>
                        <select-platform-area-shift-day-of-weeks v-model="filters.day_of_week" :params="{platformId: id, areaId : branch_id, areaType: 'BRANCH'}" placeholder="placeholders.all" :multiple="false"/>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="status">{{ $t(`fields.status`)}}</label>
                        <select-platform-area-shift-statuses v-model="filters.status" :params="{platformId: id, areaId : branch_id, areaType: 'BRANCH'}" placeholder="placeholders.all" :multiple="false"/>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="type">{{ $t(`fields.type`)}}</label>
                        <select-platform-area-shift-types v-model="filters.type" :params="{platformId: id, areaId : branch_id, areaType: 'BRANCH'}" placeholder="placeholders.all" :multiple="false"/>
                    </div>
                </div>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";

    const PLATFORMS_CRUD_CODE="platforms";
    const BRANCHES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.branches`;
    const CRUD_CODE=`${BRANCHES_CRUD_CODE}.shifts`;
    export default {
        name: "PlatformBranchShiftSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                id: this.$router.currentRoute.params.id,
                branch_id: this.$router.currentRoute.params.branch_id,
                filters: {
                    id: this.getFilterByKey('id'),
                    day_of_week: this.getFilterByKey('day_of_week'),
                    status: this.getFilterByKey('status'),
                },
                filtersMap: {
                    id: 'id',
                    day_of_week: 'dayOfWeek',
                    status: 'status',
                }
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
        }
    };
</script>
