
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_PASSWORD_FORGET,
  AUTH_PASSWORD_RESET
} from "../actions/auth";
import { USER_REQUEST, USER_SUCCESS,USER_PRIVILEGES } from "../actions/user";
import {STOMP_UPDATE} from "../actions/sockets/stomp";
import axioses from "@/axios/config";
import axios from "axios";
import i18n from "@/i18n";


const state = {
  token: localStorage.getItem("access_token") || "",
  refresh_token: localStorage.getItem("refresh_token") || "",
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getAuthorizationHeader(state){
    if(!!state.refresh_token){
      return 'Bearer ' + state.refresh_token;
    }
    return "";
  }
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      // commit('START_LOADING');
      axioses.base
          .post("/users/login", {
            email: credentials.username,
            password: credentials.password
          })
          .then(response => {
            const jwt=response.data.jwt;
            const user=response.data.user;
            const stomp=response.data.stomp;
            const privileges=response.data.privileges;
            localStorage.setItem("access_token", jwt.jwtToken);
            localStorage.setItem("refresh_token", jwt.refreshToken);
            commit(AUTH_SUCCESS, jwt);
            commit(USER_SUCCESS, user,{ root :true });
            commit(USER_PRIVILEGES, privileges,{ root :true });
            commit(STOMP_UPDATE, stomp,{ root :true });
            resolve(response);
          })
          .catch(error => {
            commit(AUTH_ERROR, error);
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            reject(error);
          });
    });
  },
  [AUTH_PASSWORD_FORGET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.publicPortal
            .post(`/users/reset-password/submit`, params)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [AUTH_PASSWORD_RESET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      if(params.newPassword!==params.newPasswordConfirmed){
        reject({
          response:{
            data:{
              errors:{
                newPasswordConfirmed:i18n.t('validation.password_confirmed')
              }
            }
          }
        });
      }else{
        axioses.publicPortal
            .post(`/users/reset-password/validate`, params)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
      }
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {

      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      delete axios.defaults.headers.common['Authorization'];
      commit(AUTH_LOGOUT);
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, jwt) => {
    state.status = "success";
    state.token = jwt.jwtToken;
    state.refresh_token = jwt.refreshToken;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.refresh_token = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
