//Note For Anas: i havent included it in captains store, as it can be used as a seperate store
import {
    FINTECHS_SHOW as CRUD_ACTION_SHOW,
    FINTECHS_UPDATE as CRUD_ACTION_UPDATE,
} from "../actions/fintechs";
import axioses from "@/axios/config";

const routePrefix='captains/fintech';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`captains/${params.id}/fintech`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
