import axios from 'axios';
import store from "@/store";
import i18n from "@/i18n";
import router from '@/router';
import {AUTH_LOGOUT} from "../store/actions/auth";

const base = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  }
});

const platformPortal = axios.create({
  baseURL: process.env.VUE_APP_API_PLATFORM_PORTAL_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  }
});

const supplierPortal = axios.create({
  baseURL: process.env.VUE_APP_API_SUPPLIER_PORTAL_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  }
});

const publicPortal= axios.create({
  baseURL: process.env.VUE_APP_API_PUBLIC_PORTAL_URL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  }
});

function showAlert(message,type='success'){
  swal({
    icon: type,
    text: i18n.t(message),
    buttons:false,
    timer: 5000
  });
}

base.interceptors.response.use(null, error => {
  if(error.response && error.response.status){
    let responseData=error.response.data;
    if(!(responseData.errors && responseData.errors.error)) {
      let name = "",message="";
      switch (error.response.status) {
        case 401:
          store.dispatch(AUTH_LOGOUT);
          name = 'auth.login';
          break;
        case 403:
          name = 'unauthorized';
          break;
        case 500:
          message = "messages.server_error";
          break;
      }

      if(message){
        showAlert(message,'error');
      }

      if(name){
        router.push({name});
      }

    }
  }else{
    showAlert("messages.no_internet_connection",'info');
  }
  return Promise.reject(error);
});

base.interceptors.request.use(
config => {
  if(store.getters.isAuthenticated){
    config.headers['Authorization'] = store.getters.getAuthorizationHeader;
  }
  if(i18n.locale){
    config.headers['Accept-Language'] = i18n.locale;
  }

  return config
}, error => Promise.reject(error));

platformPortal.interceptors.response.use(null, error => {
  if(error.response && error.response.status){
    let responseData=error.response.data;
    if(!(responseData.errors && responseData.errors.error)) {
      let name = "",message="";
      switch (error.response.status) {
        case 401:
          store.dispatch(AUTH_LOGOUT);
          name = 'auth.login';
          break;
        case 403:
          name = 'unauthorized';
          break;
        case 500:
          message = "messages.server_error";
          break;
      }

      if(message){
        showAlert(message,'error');
      }

      if(name){
        router.push({name});
      }

    }
  }else{
    showAlert("messages.no_internet_connection",'info');
  }
  return Promise.reject(error);
});

platformPortal.interceptors.request.use(
config => {
  if(store.getters.isAuthenticated){
    config.headers['Authorization'] = store.getters.getAuthorizationHeader;
  }
  if(i18n.locale){
    config.headers['Accept-Language'] = i18n.locale;
  }

  return config
}, error => Promise.reject(error));

supplierPortal.interceptors.response.use(null, error => {
  if(error.response && error.response.status){
    let responseData=error.response.data;
    if(!(responseData.errors && responseData.errors.error)) {
      let name = "",message="";
      switch (error.response.status) {
        case 401:
          store.dispatch(AUTH_LOGOUT);
          name = 'auth.login';
          break;
        case 403:
          name = 'unauthorized';
          break;
        case 500:
          message = "messages.server_error";
          break;
      }

      if(message){
        showAlert(message,'error');
      }

      if(name){
        router.push({name});
      }

    }
  }else{
    showAlert("messages.no_internet_connection",'info');
  }
  return Promise.reject(error);
});

supplierPortal.interceptors.request.use(
    config => {
      if(store.getters.isAuthenticated){
        config.headers['Authorization'] = store.getters.getAuthorizationHeader;
      }
      if(i18n.locale){
        config.headers['Accept-Language'] = i18n.locale;
      }

      return config
    }, error => Promise.reject(error));

publicPortal.interceptors.response.use(null, error => {
  if(error.response && error.response.status){
    let responseData=error.response.data;
    if(!(responseData.errors && responseData.errors.error)) {
      let name = "",message="";
      switch (error.response.status) {
        case 403:
          name = 'unauthorized';
          break;
        case 500:
          message = "messages.server_error";
          break;
      }

      if(message){
        showAlert(message,'error');
      }

      if(name){
        router.push({name});
      }

    }
  }else{
    showAlert("messages.no_internet_connection",'info');
  }
  return Promise.reject(error);
});

publicPortal.interceptors.request.use(
    config => {
      if(store.getters.isAuthenticated){
        config.headers['Authorization'] = store.getters.getAuthorizationHeader;
      }
      if(i18n.locale){
        config.headers['Accept-Language'] = i18n.locale;
      }

      return config
    }, error => Promise.reject(error));


const getPortalByType=(type) => {
  let axios;
  switch (type) {
    case "platforms":
      axios=platformPortal;
      break;
    case "public":
      axios=publicPortal;
      break;
    case "suppliers":
      axios=supplierPortal;
      break;
    case "operations":
    default:
      axios=base;
      break;
  }

  return axios;
};

export default {
  base,
  publicPortal,
  platformPortal,
  supplierPortal,
  getPortalByType
}
