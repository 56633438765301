import {
  CAPTAINS_LIST as CRUD_ACTION_LIST,
  CAPTAINS_CREATE as CRUD_ACTION_CREATE,
  CAPTAINS_SHOW as CRUD_ACTION_SHOW,
  CAPTAINS_ADD_CAPABILITIES as CRUD_ACTION_ADD_CAPABILITIES,
  CAPTAINS_REMOVE_CAPABILITIES as CRUD_ACTION_REMOVE_CAPABILITIES,
  CAPTAINS_UPDATE as CRUD_ACTION_UPDATE,
  GET_BLOCKED_REASON
} from "../../actions/suppliers_portal/captains";
import axioses from "@/axios/config";

const additionalHeaders = {
  headers: {
    "Content-Type": "multipart/form-data"
  }
};

const routePrefix = "captains";

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", params.withLoading, { root: true });

      axioses.supplierPortal
        .get(`/${routePrefix}`, { params })
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", params.withLoading, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", params.withLoading, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      let fData = new FormData();
      Object.keys(params).forEach(key => {
        if (typeof params[key] !== "undefined" && params[key] !== null) {
          fData.append(key, params[key]);
        }
      });
      axioses.supplierPortal
        .post(`/${routePrefix}/create`, fData, additionalHeaders)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.supplierPortal
        .get(`/${routePrefix}/${params.id}`)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [GET_BLOCKED_REASON]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      axioses.supplierPortal
        .get(`/${routePrefix}/${params.id}/naql_blocked_reasons`)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("START_LOADING", null, { root: true });
      let fData = new FormData();
      Object.keys(params.data).forEach(key => {
        if (
          typeof params.data[key] !== "undefined" &&
          params.data[key] !== null
        ) {
          fData.append(key, params.data[key]);
        }
      });
      axioses.supplierPortal
        .patch(`/${routePrefix}/${params.id}`, fData, additionalHeaders)
        .then(response => {
          resolve(response);
          commit("STOP_LOADING", null, { root: true });
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_ADD_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.supplierPortal
        .put(
          `/${routePrefix}/${params.id}/capabilities/${params.capability_id}/add`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit("STOP_LOADING", null, { root: true });
          reject(error);
        });
    });
  },
  [CRUD_ACTION_REMOVE_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.supplierPortal
        .delete(
          `/${routePrefix}/${params.id}/capabilities/${params.capability_id}/delete`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
