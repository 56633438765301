<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <files-image v-model="image" :disabled="true" :thumbnailImage="image.thumbnail"/>
                </div>
              </div>
            </div>
            <div class="row" v-if="!serverError">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="roles">{{ $t(`fields.roles`)}}</label>
                  <input  v-model="role"
                          id="role"
                          type="text"
                          class="form-control"
                          name="role"
                          disabled
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { USERS_SHOW as CRUD_ACTION_SHOW } from 'actions/platforms_portal/users';
  const USERS_CODE="users";
  const CRUD_CODE=`platforms_portal.${USERS_CODE}`;
  export default {
    name: "PlatformsPortalUserShow",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: USERS_CODE,
        id:this.$router.currentRoute.params.id,
        email: '',
        name: '',
        role: '',
        mobile_number: '',
        serverError: '',
        image:{
          file:'',
          thumbnail:'',
        },
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.id,
        }).then(response=>{
          this.email=response.data.email;
          this.name=response.data.name;
          this.role=response.data.role;
          this.mobile_number=response.data.userMobile;
          this.image.thumbnail=response.data.profilePicture?response.data.profilePicture.url:'';

        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
    }
  };
</script>
