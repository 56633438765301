import {
    PLATFORMS_LIST as CRUD_ACTION_LIST,
    PLATFORMS_CREATE as CRUD_ACTION_CREATE,
    PLATFORMS_SHOW as CRUD_ACTION_SHOW,
    PLATFORMS_UPDATE as CRUD_ACTION_UPDATE,
    PLATFORMS_DELETE as CRUD_ACTION_DELETE,
    PLATFORMS_USERS_LIST,
    PLATFORMS_USERS_CREATE,
    PLATFORMS_USERS_SHOW,
    PLATFORMS_APPS_LIST,
    PLATFORMS_APPS_CREATE,
    PLATFORMS_APPS_SHOW,
    PLATFORMS_APPS_UPDATE,
    PLATFORMS_APPS_DELETE,
    PLATFORMS_DEVICE_TYPE_LIST,
    PLATFORMS_CONFIGS_SHOW,
    PLATFORMS_CONFIGS_UPDATE,
    PLATFORMS_FILES_SHOW,
    PLATFORMS_FILES_UPDATE,
    PLATFORMS_INVOICES_LIST,
    PLATFORMS_INVOICES_CREATE,
    PLATFORMS_INVOICES_SHOW,
    PLATFORMS_INVOICES_STATUS_LIST,
    PLATFORMS_INVOICES_UPDATE_STATUS,
    PLATFORMS_DETAILS_SHOW,
    PLATFORMS_DETAILS_UPDATE,
    PLATFORMS_SETTINGS_SHOW,
    PLATFORMS_SETTINGS_UPDATE,
    PLATFORMS_BRANCHES_LIST,
    PLATFORMS_BRANCHES_SHOW,
    PLATFORMS_BRANCHES_CREATE,
    PLATFORMS_BRANCHES_UPDATE,
    PLATFORMS_BRANCHES_DELETE,
    PLATFORMS_AREAS_SETTINGS_SHOW,
    PLATFORMS_AREAS_SETTINGS_UPDATE,
    PLATFORMS_AREAS_SHIFTS_LIST,
    PLATFORMS_AREAS_SHIFTS_SHOW,
    PLATFORMS_AREAS_SHIFTS_CREATE,
    PLATFORMS_AREAS_SHIFTS_UPDATE,
    PLATFORMS_AREAS_SHIFTS_DELETE,
    PLATFORMS_AREAS_SHIFTS_DAY_OF_WEEK_LIST,
    PLATFORMS_AREAS_SHIFTS_STATUS_LIST,
    PLATFORMS_AREAS_SHIFTS_TYPE_LIST,
    PLATFORMS_AREAS_SHIFTS_CAPTAINS_LIST,
    PLATFORMS_AREAS_SHIFTS_CAPTAINS_ATTACH,
    PLATFORMS_AREAS_SHIFTS_CAPTAINS_DEATTACH,
    PLATFORMS_BRANCHES_TRACKING_LIST,
    PLATFORMS_SETTINGS_ALGORITHMS_LIST,
    PLATFORMS_AREAS_SHIFTS_SUMMARIES_LIST,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_LIST,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_SHOW,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_CREATE,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_DELETE,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_UPDATE,
    PLATFORMS_AREAS_SHIFTS_PRICING_RULES_TYPE_LIST,
    PLATFORMS_DASHBOARD_CAPTAINS_LIST,
    PLATFORMS_DASHBOARD_ORDERS_LIST,
    PLATFORMS_AREAS_SHIFTS_DASHBOARD_LIST,
    PLATFORMS_AREAS_PRICING_RULES_LIST,
    PLATFORMS_AREAS_PRICING_RULES_SHOW,
    PLATFORMS_AREAS_PRICING_RULES_CREATE,
    PLATFORMS_AREAS_PRICING_RULES_DELETE,
    PLATFORMS_AREAS_PRICING_RULES_UPDATE,
    PLATFORMS_AREAS_PRICING_RULES_TYPE_LIST,
    PLATFORMS_AREAS_SETTINGS_ASSIGNMENT_ALGORITHM_TYPE_LIST,
    SHIFTS_ADD_CAPABILITIES as CRUD_ACTION_ADD_CAPABILITIES,
    SHIFTS_REMOVE_CAPABILITIES as CRUD_ACTION_REMOVE_CAPABILITIES,
    AREA_PRICING_RULES_ADD_CAPABILITIES as AREA_PRICING_RULES_ADD_CAPABILITIES,
    AREA_PRICING_RULES_REMOVE_CAPABILITIES as AREA_PRICING_RULES_REMOVE_CAPABILITIES,
    SHIFT_PRICING_RULES_ADD_CAPABILITIES as SHIFT_PRICING_RULES_ADD_CAPABILITIES,
    SHIFT_PRICING_RULES_REMOVE_CAPABILITIES as SHIFT_PRICING_RULES_REMOVE_CAPABILITIES,
    SHIFT_LATE_ATTENDANCES as SHIFT_LATE_ATTENDANCES,
    PLATFORMS_ZONES_LIST,
    PLATFORMS_ZONES_CREATE,
    PLATFORMS_ZONES_SHOW,
    PLATFORMS_ZONES_UPDATE,
    PLATFORMS_ZONES_DELETE,
    PLATFORMS_ZONES_SUPPLIER,
    PLATFORMS_ZONES_TRACKING_LIST,
    PLATFORMS_BRANCHES_SUPPLIER,
    PLATFORMS_AREAS_ATTENDANCES_LIST
} from "../actions/platforms";
import axioses from "@/axios/config";
import qs from "qs";

const additionalHeaders={
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const routePrefix='platforms';
const usersRoutePrefix='users';
const appsRoutePrefix='apps';
const configsRoutePrefix='billing_configs';
const filesRoutePrefix='files';
const invoicesRoutePrefix='invoices';
const detailsRoutePrefix='details';
const settingsRoutePrefix='settings';
const branchesRoutePrefix='branches';
const areasShiftsRoutePrefix='shifts';
const branchesTrackingRoutePrefix='tracking';
const areasShiftsSummariesRoutePrefix='summaries';
const areasShiftsPricingRulesRoutePrefix='pricing_rules';
const branchesAttendances="attendances"
const dashboardRoutePrefix='dashboard';
const areaTypesRoutePrefix='area_types';
const areasRoutePrefix='areas';
const zonesRoutePrefix='zones';
const ownerTypesRoutePrefix='owner_types'
const ownersRoutePrefix='owners'


const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.base
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
  [CRUD_ACTION_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {

        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
      axioses.base
          .post(`/${routePrefix}/create`, fData, additionalHeaders)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  },
  [CRUD_ACTION_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });

        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });

        axioses.base
            .put(`/${routePrefix}/${params.id}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_USERS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${usersRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_USERS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {

        let fData=new FormData();
        Object.keys(params).forEach(key=>{
            if(typeof params[key] !== "undefined"  && params[key] !==null){
                fData.append(key,params[key]);
            }
        });
        axioses.base
            .post(`/${routePrefix}/${params.platformId}/${usersRoutePrefix}/create`, fData, additionalHeaders)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [PLATFORMS_USERS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${usersRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_APPS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${appsRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_APPS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/${params.platformId}/${appsRoutePrefix}/create`, params)
          .then(response => {
              resolve(response);
          })
          .catch(error => {
              reject(error);
          });
    });
  },
  [PLATFORMS_APPS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${appsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_APPS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${appsRoutePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_APPS_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/${appsRoutePrefix}/${params.app_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_DEVICE_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${appsRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_CONFIGS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${configsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_CONFIGS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${configsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_FILES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${filesRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_FILES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        let fData=new FormData();
        Object.keys(params.data).forEach(key=>{
            if(typeof params.data[key] !== "undefined"  && params.data[key] !==null){
                fData.append(key,params.data[key]);
            }
        });
        axioses.base
            .patch(`/${routePrefix}/${params.platformId}/${filesRoutePrefix}`,fData,additionalHeaders)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_INVOICES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${invoicesRoutePrefix}`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_INVOICES_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .post(`/${routePrefix}/${params.platformId}/${invoicesRoutePrefix}/create`, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [PLATFORMS_INVOICES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${invoicesRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_INVOICES_STATUS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${invoicesRoutePrefix}/status`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_INVOICES_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .patch(`/${routePrefix}/${params.platformId}/${invoicesRoutePrefix}/${params.id}/${params.status}`, {...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_DETAILS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${detailsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_DETAILS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${detailsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_SETTINGS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${settingsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_SETTINGS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${settingsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_SETTINGS_ALGORITHMS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${settingsRoutePrefix}/algorithms`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_SUPPLIER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}/${params.id}/supplier`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}`, params)
          .then(response => {
              resolve(response);
          })
          .catch(error => {
              reject(error);
          });
    });
  },
  [PLATFORMS_BRANCHES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/${branchesRoutePrefix}/${params.branch_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SETTINGS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.areaType}/${areasRoutePrefix}/${params.areaId}/${settingsRoutePrefix}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SETTINGS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.areaType}/${areasRoutePrefix}/${params.areaId}/${settingsRoutePrefix}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}`, params)
          .then(response => {
              resolve(response);
          })
          .catch(error => {
              reject(error);
          });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_STATUS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/statuses`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_CAPTAINS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.areaType}/${areasRoutePrefix}/${params.areaId}/${areasShiftsRoutePrefix}/${params.shiftId}/captains`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_CAPTAINS_ATTACH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.areaType}/${areasRoutePrefix}/${params.areaId}/${areasShiftsRoutePrefix}/${params.shiftId}/attach/${params.captainIds}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_CAPTAINS_DEATTACH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.areaType}/${areasRoutePrefix}/${params.areaId}/${areasShiftsRoutePrefix}/${params.shiftId}/de-attach/${params.captainId}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_DAY_OF_WEEK_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/day_of_weeks`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_BRANCHES_TRACKING_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${branchesRoutePrefix}/${params.branchId}/${branchesTrackingRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_SUMMARIES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsSummariesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .post(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}`, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_PRICING_RULES_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/${areasShiftsPricingRulesRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}/types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SETTINGS_ASSIGNMENT_ALGORITHM_TYPE_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${settingsRoutePrefix}/assignment_algorithm_types`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_DASHBOARD_CAPTAINS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/tracking/${routePrefix}/${params.platformId}/${dashboardRoutePrefix}/captains`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_DASHBOARD_ORDERS_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/tracking/${routePrefix}/${params.platformId}/${dashboardRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_SHIFTS_DASHBOARD_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/tracking/${areasShiftsRoutePrefix}/${dashboardRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .post(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}`, params)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_PRICING_RULES_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.platform_id}/${ownerTypesRoutePrefix}/${params.owner_type}/${ownersRoutePrefix}/${params.owner_id}/${areasShiftsPricingRulesRoutePrefix}/${params.pricing_rule_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_ADD_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .put(`/shifts/${params.id}/capabilities/${params.capability_id}/add`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [CRUD_ACTION_REMOVE_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .delete(`/shifts/${params.id}/capabilities/${params.capability_id}/delete`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [AREA_PRICING_RULES_ADD_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .put(`/pricing_rules_capabilities/areas/${params.id}/capabilities/${params.capability_id}/add`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [AREA_PRICING_RULES_REMOVE_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .delete(`/pricing_rules_capabilities/areas/${params.id}/capabilities/${params.capability_id}/delete`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [SHIFT_PRICING_RULES_ADD_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .put(`/pricing_rules_capabilities/shifts/${params.id}/capabilities/${params.capability_id}/add`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [SHIFT_PRICING_RULES_REMOVE_CAPABILITIES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        axioses.base
            .delete(`/pricing_rules_capabilities/shifts/${params.id}/capabilities/${params.capability_id}/delete`)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
  },
  [SHIFT_LATE_ATTENDANCES]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${areasShiftsRoutePrefix}/${params.shift_id}/late_attendances`,{ params })
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_CREATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      axioses.base
          .post(`/${routePrefix}/${params.platformId}/${zonesRoutePrefix}`, params)
          .then(response => {
              resolve(response);
          })
          .catch(error => {
              reject(error);
          });
    });
  },
  [PLATFORMS_ZONES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${zonesRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_SHOW]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${zonesRoutePrefix}/${params.id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .put(`/${routePrefix}/${params.platformId}/${zonesRoutePrefix}/${params.id}`,{...params.data})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .delete(`/${routePrefix}/${params.id}/${zonesRoutePrefix}/${params.zone_id}`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_SUPPLIER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', null, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.id}/${zonesRoutePrefix}/${params.zone_id}/supplier`)
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', null, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', null, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_ZONES_TRACKING_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platformId}/${zonesRoutePrefix}/${params.zoneId}/${branchesTrackingRoutePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
  [PLATFORMS_AREAS_ATTENDANCES_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}/${params.platform_id}/${areaTypesRoutePrefix}/${params.area_type}/${areasRoutePrefix}/${params.area_id}/${branchesAttendances}`,{ params ,paramsSerializer: params => {return qs.stringify(params, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
