<template>
    <div aria-labelledby="tracking-tab" class="tab-pane fade" id="tracking" role="tabpanel">
        <iframe
        :src="url"
        width="100%"
        height="500" >
        </iframe>
    </div>
</template>
<script>
export default {
name: "orderTracking",
props: ['url'],
data(){
    return {
    }
},
};
</script>