import Vue from "vue";
import store from "../store";
import i18n from "@/i18n";
import { CRUD_UPDATE_BREAD } from "actions/crud";
const { locale } = i18n;

export const ifNotAuthenticated = (to, from, next) => {

  if (!store.getters.isAuthenticated) {
    next();
    return true;
  }

  next({
    name: 'dashboard.index' ,
    params:{
      locale
    },
  });
  return false;

};

export const ifAuthenticated = (to, from, next) => {

  let redirectRouteName='';
  if (!store.getters.isAuthenticated) {
    redirectRouteName='auth.login';
  }else if(to.meta.can && !store.getters.can(to.meta.can)){
    redirectRouteName='unauthorized';
  }

  if(redirectRouteName) {
    next({
      name: redirectRouteName,
      params: {
        locale
      },
    });
    return false;
  }

  next();
  return true;
};

export const removeClass = (to, from, next) => {
  let elements=to.meta.removeClass;
  if(elements){
    if(typeof elements ==='string'){
      elements=elements.split(",");
    }
    elements.forEach(function(element){
      document.body.classList.remove(element);
    });
  }
};

export const addClass = (to, from, next) => {
  let elements=to.meta.addClass;
  if(elements){
    if(typeof elements === 'string'){
      elements=elements.split(",");
    }
    elements.forEach(function(element){
      document.body.classList.add(element);
    });
  }
};

export const setPageTitle = (to, from, next) => {
  let element=to.meta.title;
  if(element) {
    document.title = i18n.t(element);
  } else {
    document.title = process.env.VUE_APP_TITLE;
  }
};

export const updateBreadDetails = (to, from, next) => {
  let breadDetails = Vue.cloneDeep(to.meta.breadDetails);
  if(breadDetails){
    breadDetails.items.forEach(function(element){
      if(typeof element.title === 'string' && element.title.search(/^:/) !== -1){
        element.title = to.params[element.title.replace(":","")];
      }
    });
    store.commit(CRUD_UPDATE_BREAD,breadDetails);
  }
};

export const commonBeforeEnter = (to, from, next) => {
  removeClass(to, from, next);
  addClass(to, from, next);
  updateBreadDetails(to, from, next);
  setPageTitle(to, from, next);
};





