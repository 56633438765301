import Index from "@/views/dashboard/index";
import { ifAuthenticated, ifNotAuthenticated, commonBeforeEnter } from "../helper"


const CODE='dashboard';
const PERMISSION_PREFIX='dashboard';
const routes = [
  {
    path: `${CODE}`,
    name:`${CODE}.index`,
    component: Index,
    meta: {
      'removeClass' : [`bg-default`],
      'title': `${CODE}.list_title`,
      'breadDetails': {
        'title': `${CODE}.title`,
        'index_route': ``,
        'show': true,
        'add_primary_color': true,
        'items': [
          {
            'title': `crud.list`,
          }
        ]
      }
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
