<template>
    <ul class="navbar-nav align-items-center">
        <li class="nav-item dropdown">
            <a class="nav-link px-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" :src="getProfileImage()">
                  </span>
                    <div class="media-body  ml-2  d-none d-lg-block">
                        <span class="mb-0 text-sm  font-weight-bold">{{ getProfile.name }}</span>
                    </div>
                </div>
            </a>
            <div class="dropdown-menu  dropdown-menu-right ">
                <div class="dropdown-header noti-title">
                    <h6 class="text-overflow m-0">{{ $t('labels.welcome') }} {{ getProfile.name }}</h6>
                </div>
                <router-link :to="{ name: `profile.me` }" class="dropdown-item ">
                    <i class="ni ni-single-02"></i>
                    <span>{{ $t('profile.title') }}</span>
                </router-link>
                <div class="dropdown-divider"></div>
                <a href="javascript:;" @click="logout" class="dropdown-item">
                    <i class="ni ni-user-run"></i>
                    <span>{{ $t('labels.logout') }}</span>
                </a>
            </div>
        </li>
    </ul>
</template>

<script>
    import { AUTH_LOGOUT } from "actions/auth";
    import {mapGetters} from "vuex";
    export default {
        name: "TopNabarProfile",
        methods: {
            logout: function() {
                this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push({ name :'auth.login'}));
            },
            getProfileImage(){
                return this.getProfile.profilePicture?this.getProfile.profilePicture.url:require('../../../assets/theme/img/theme/avatar.png');
            },
        },
        computed: {
            ...mapGetters(["getProfile"]),
        }
    }
</script>

<style scoped>

</style>
