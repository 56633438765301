<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :error="fieldErrors.profilePicture" :thumbnailImage="image.thumbnail"/>
                  <span v-if="fieldErrors.profilePicture" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.profilePicture }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          disabled
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          disabled
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.me`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PROFILE_ME as CRUD_ACTION_SHOW, PROFILE_ME_EDIT as CRUD_ACTION_UPDATE } from 'actions/profile';
  const CRUD_CODE="profile";
  export default {
    name: "ProfileMeEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        id:this.$router.currentRoute.params.id,
        role: '',
        email: '',
        mobile_number: '',
        name: '',
        image:{
          file:'',
          thumbnail:'',
        },
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW).then(response=>{
          this.role=response.data.user.role;
          this.email=response.data.user.email;
          this.name=response.data.user.name;
          this.mobile_number=response.data.user.userMobile?response.data.user.userMobile.mobileNumber:'';
          this.image.thumbnail=response.data.profilePicture?response.data.profilePicture.url:'';
        }).catch(error => {
          this.loading = false
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareRequestData(){
        return {
          ...this.prepareFile('image',"profilePicture"),
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.me` })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
