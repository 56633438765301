import {
    WALLET_TRANSACTIONS_LIST as CRUD_ACTION_LIST,
} from "../actions/wallet_transactions";
import axioses from "@/axios/config";
import qs from "qs";

const routePrefix='wallet/transactions';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
        commit('START_LOADING', params.withLoading, { root: true });
        axioses.base
            .get(`/${routePrefix}`,{ params ,paramsSerializer: params => {return qs.stringify(params.data, { skipNulls: true, indices: false })}})
            .then(response => {
                resolve(response);
                commit('STOP_LOADING', params.withLoading, { root: true });
            })
            .catch(error => {
                commit('STOP_LOADING', params.withLoading, { root: true });
                reject(error);
            });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
