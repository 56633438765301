<template>
  <div class="container-fluid mt-2">
    <div class="row justify-content-center">
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-teal p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.captains`) }}: {{ assignment.captainsCount || 0 }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-yellow p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.cars`) }}: {{ assignment.carsCount || 0 }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-pink p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.bikes`) }}: {{ assignment.bikesCount || 0 }}
        </div>
      </div>
      <div class="col-12 col-md-3 col-xl-counters">
        <div class="alert bg-green p-2 px-4 m-0">
          {{ $t(`${transPrefix}.lists.counter.measurement`) }}: {{ assignment.distanceMeasurementType}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const ORDERS_CRUD_CODE = "orders";
const CRUD_CODE = `${ORDERS_CRUD_CODE}.assignment_tracking`;

export default {
  name: "AssignmentTrackingCounter",
  props: {
    assignment: {
      type: Object,
      default: function() {
        return {
          bikesCount: 0,
          carsCount: 0,
          captainsCount: 0,
          distanceMeasurementType: ''
        };
      }
    }
  },
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE
    };
  }
};
</script>
