<template>
  <div aria-labelledby="allocation_window-tab" class="tab-pane fade" id="allocation_window" role="tabpanel">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="id">{{ $t(`fields.id`)}}</label>
          <input class="form-control" id="id" name="id" type="text" v-model="fields.id" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="captainId">{{ $t(`fields.captain_id`)}}</label>
          <input class="form-control" id="captainId" name="captainId" type="text" v-model="fields.captainId" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="status">{{ $t(`fields.status`)}}</label>
          <input class="form-control" id="status" name="status" type="text" v-model="fields.status" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="allocation_algorithm">{{ $t(`fields.allocation_algorithm`)}}</label>
          <input class="form-control" id="allocation_algorithm" name="allocation_algorithm" type="text" v-model="fields.algorithm" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="stage">{{ $t(`fields.stage`)}}</label>
          <input class="form-control" id="stage" name="stage" type="text" v-model="fields.stage" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="earnings">{{ $t(`fields.earnings`)}}</label>
          <input class="form-control" id="earnings" name="earnings" type="text" v-model="fields.earnings" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="assigned_at">{{ $t(`fields.assigned_at`)}}</label>
          <input class="form-control" id="assigned_at" name="assigned_at" type="text" v-model="fields.assignedAt" disabled/>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="created_at">{{ $t(`fields.created_at`)}}</label>
          <input class="form-control" id="created_at" name="created_at" type="text" v-model="fields.createdAt" disabled/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "OrderTabsAllocationWindow",
    props: {
      transPrefix:{
        type: String,
        default: ''
      },
      fields: {
        type: Object,
        default: () => { }
      },
    },
    data(){
      return {
      }
    },
  };
</script>
