<template>
    <a href="javascript:;" @click.prevent="showDeAttachCaptainPopUp(platformId, branchId, shiftId, captainId)" class="btn btn-danger btn-sm">
        <span class="d-none d-lg-inline mr-2">
          {{ $t(`labels.de_attach_captain`) }}
        </span>
        <span>
          <i class="fas fa-unlink"></i>
        </span>
    </a>
</template>

<script>
    import { PLATFORMS_AREAS_SHIFTS_CAPTAINS_DEATTACH as CRUD_ACTION_DE_ATTACH_CAPTAIN } from "actions/platforms";
    export default {
        name: "ShiftActionsDeAttachCaptain",
        props: {
            platformId:{},
            branchId:{},
            shiftId:{},
            captainId:{},
            areaType:{},
            successCallback: {
                type: Function,
                default: () => { }
            },
        },
        data(){
            return {}
        },
        methods:{
            showDeAttachCaptainPopUp(platformId, branchId, shiftId, captainId) {
                swal({
                    title: this.$i18n.t("labels.de_attach_captain"),
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.de_attach_captain")
                    ],
                })
                .then((deAttach) => {
                    if (deAttach) {
                        this.deAttachCaptainToSupplier(platformId, branchId, shiftId, captainId);
                    }
                });
            },
            deAttachCaptainToSupplier(platformId, branchId, shiftId, captainId) {
                this.$store.dispatch(CRUD_ACTION_DE_ATTACH_CAPTAIN, {
                    platformId,
                    areaType: this.$props.areaType,
                    areaId: branchId,
                    shiftId,
                    captainId
                })
                .then(() => {
                    this.$props.successCallback();
                    swal({
                        icon: "success",
                        text: this.$i18n.t("messages.de_attach_captain_success"),
                        buttons: false,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    let message = "";
                    if (error.response) {
                        let responseData = error.response.data;

                        if (responseData.errors && responseData.errors.error) {
                            message = responseData.errors.error;
                        } else {
                            message = this.$i18n.t("messages.de_attach_captain_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: "error",
                            text: message,
                            buttons: false,
                            timer: 5000,
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
