<template>
    <div :id="id" class="google-maps"></div>
</template>

<script>
    export default {
        name: "FieldsMapsHeat",
        props: {
          centerPosition:{
            type:Object,
            default:function(){
                return {lat: 24.7253981, lng: 46.3398977};
            }
          },
          zoom:{
            type:Number,
            default:6,
          },
          id: {
            type:String,
            default:'map'
          },
          error:{
            type:String
          },
          points:{
              type:Array,
              default:function(){
                  return [];
              }
          },
        },
        data(){
            return {
                map:null,
                google:null,
                options:{
                    libraries:[],
                }
            }
        },
        mounted() {
            this.prepareOptions();
            this.initMap();
        },
        methods: {
            initMap(){

                this.$google.then(google=>{
                    this.google=google;
                    this.map = new this.google.maps.Map(document.getElementById(this.id), {
                        center: this.getCenterPosition(),
                        zoom: this.zoom,
                    });

                    var heatmap = new google.maps.visualization.HeatmapLayer({
                        data: this.points
                    });
                    heatmap.setMap(this.map);

                }).catch(error=>{
                });
            },
            prepareOptions(){
                this.options.libraries.push('visualization');
           },
            setMapCenter(){
              this.map.setCenter(this.getCenterPosition());
            },
            getCenterPosition(){
                return this.centerPosition;
            },
        }
    }
</script>

<style scoped>

</style>