<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title"/>
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="en_name">{{ $t(`fields.en_name`)}}</label>
                  <input  v-model="en_name"
                          id="en_name"
                          type="text"
                          class="form-control"
                          name="en_name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                        <strong>{{ fieldErrors.name }}</strong>
                    </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="ar_name">{{ $t(`fields.ar_name`)}}</label>
                  <input  v-model="ar_name"
                          id="ar_name"
                          type="text"
                          class="form-control"
                          name="ar_name"
                          v-bind:class="{'is-invalid':fieldErrors.nameAr}"
                  >
                  <span v-if="fieldErrors.nameAr" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.nameAr }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12">
                <maps-general v-model="points" :points="oldPoints" :mode="map_mode"/>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${zonesRoutePrefix}.index`,params:{id,city_id} }"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button v-if="isItemFound()" type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { ZONES_SHOW as CRUD_ACTION_SHOW,ZONES_UPDATE as CRUD_ACTION_UPDATE } from 'actions/zones';
  const CRUD_CODE="countries";
  const CITIES_CODE="cities";
  const ZONES_CODE="zones";
  export default {
    name: "CountryCityZoneEdit",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        citiesRoutePrefix: `${CRUD_CODE}.${CITIES_CODE}`,
        citiesTransPrefix: `${CITIES_CODE}`,
        zonesRoutePrefix: `${CRUD_CODE}.${CITIES_CODE}.${ZONES_CODE}`,
        zonesTransPrefix: `${ZONES_CODE}`,
        id:this.$router.currentRoute.params.id,
        city_id:this.$router.currentRoute.params.city_id,
        zone_id:this.$router.currentRoute.params.zone_id,
        en_name: '',
        ar_name: '',
        points:[],
        oldPoints:[],
        map_mode:'edit',
        serverError: '',
        fieldErrors: {},
        responseStatus:'',
        formMode: 'edit',
        loading: false,
      }
    },
    mounted() {
      this.getItem();
    },
    methods: {
      isItemFound(){
        return this.responseStatus!==404;
      },
      getItem(){
        this.$store.dispatch(CRUD_ACTION_SHOW,{
          id: this.zone_id,
        }).then(response=>{
          this.en_name=response.data.name;
          this.ar_name=response.data.nameAr;
          this.oldPoints=this.points=response.data.geom?response.data.geom.coordinates[0][0]:[];
        }).catch(error => {
          this.loading = false;
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        });
      },
      prepareGeomRequestData(){
        let geom={};
        if(this.points.length ){
          geom.geom= {
            type: "MultiPolygon",
            coordinates:[[this.points]],
          };
        }

        return geom;
      },
      prepareRequestData(){
        return {
          name: this.sanitizeField("en_name"),
          nameAr: this.sanitizeField("ar_name"),
          cityId: this.city_id,
          ...this.prepareGeomRequestData()
        }
      },
      update() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_UPDATE, {
          id: this.zone_id,
          data: {
            ...this.prepareRequestData()
          },
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.update_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.zonesRoutePrefix}.index`, params:{id:this.id,city_id:this.city_id} })
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            this.responseStatus=error.response.status;
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      }
    },
  };
</script>
