import { render, staticRenderFns } from "./captains_registration_requests_preferred_vertical.vue?vue&type=template&id=4b149e36&scoped=true&"
import script from "./captains_registration_requests_preferred_vertical.vue?vue&type=script&lang=js&"
export * from "./captains_registration_requests_preferred_vertical.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b149e36",
  null
  
)

export default component.exports