<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="update">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row" v-if="isItemFound()">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="stackingMaxOrdersPerCaptain">{{
                    $t(`fields.stacking_max_orders_per_captain`)
                  }}</label>
                  <input
                    v-model="stackingMaxOrdersPerCaptain"
                    id="stackingMaxOrdersPerCaptain"
                    type="number"
                    step="1"
                    class="form-control"
                    v-bind:class="{
                      'is-invalid': fieldErrors.stackingMaxOrdersPerCaptain,
                    }"
                  />
                  <span
                    v-if="fieldErrors.stackingMaxOrdersPerCaptain"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{
                      fieldErrors.stackingMaxOrdersPerCaptain
                    }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="stackingMaxOrdersPerBike">{{
                    $t(`fields.stacking_max_orders_per_bike`)
                  }}</label>
                  <input
                    v-model="stackingMaxOrdersPerBike"
                    id="stackingMaxOrdersPerBike"
                    type="number"
                    step="1"
                    class="form-control"
                    v-bind:class="{
                      'is-invalid': fieldErrors.stackingMaxOrdersPerBike,
                    }"
                  />
                  <span
                    v-if="fieldErrors.stackingMaxOrdersPerBike"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{ fieldErrors.stackingMaxOrdersPerBike }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="stackingMaxDistanceForBikeInKm">{{
                    $t(`fields.stacking_max_distance_for_bike_in_km`)
                  }}</label>
                  <input
                    v-model="stackingMaxDistanceForBikeInKm"
                    id="stackingMaxDistanceForBikeInKm"
                    type="number"
                    step="any"
                    class="form-control"
                    v-bind:class="{
                      'is-invalid': fieldErrors.stackingMaxDistanceForBikeInKm,
                    }"
                  />
                  <span
                    v-if="fieldErrors.stackingMaxDistanceForBikeInKm"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{
                      fieldErrors.stackingMaxDistanceForBikeInKm
                    }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="stackingMaxDistanceForCarInKm">{{
                    $t(`fields.stacking_max_distance_for_car_in_km`)
                  }}</label>
                  <input
                    v-model="stackingMaxDistanceForCarInKm"
                    id="stackingMaxDistanceForCarInKm"
                    type="number"
                    step="any"
                    class="form-control"
                    v-bind:class="{
                      'is-invalid': fieldErrors.stackingMaxDistanceForCarInKm,
                    }"
                  />
                  <span
                    v-if="fieldErrors.stackingMaxDistanceForCarInKm"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{
                      fieldErrors.stackingMaxDistanceForCarInKm
                    }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="in_area_radius_meters">{{
                    $t(`fields.in_area_radius_meters`)
                  }}</label>
                  <input
                    v-model="in_area_radius_meters"
                    id="in_area_radius_meters"
                    type="text"
                    class="form-control"
                    name="in_area_radius_meters"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="assignment_interval_minutes">{{
                    $t(`fields.assignment_interval_minutes`)
                  }}</label>
                  <input
                    v-model="assignment_interval_minutes"
                    id="assignment_interval_minutes"
                    type="number"
                    class="form-control"
                    name="assignment_interval_minutes"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="assignment_algorithm_type">{{
                    $t(`fields.assignment_algorithm_type`)
                  }}</label>
                  <select-platform-area-setting-assignment-algorithm-types
                    v-model="assignment_algorithm_type"
                    :params="{
                      platform_id: id,
                      area_type: 'ZONE',
                      area_id: zone_id,
                    }"
                    :multiple="false"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="attendance_key">{{
                    $t(`fields.attendance_key`)
                  }}</label>
                  <input
                    v-model="attendance_key"
                    id="attendance_key"
                    type="text"
                    class="form-control"
                    name="attendance_key"
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="attendance_validity_minutes">{{
                    $t(`fields.attendance_validity_minutes`)
                  }}</label>
                  <input
                    v-model="attendance_validity_minutes"
                    id="attendance_validity_minutes"
                    type="number"
                    class="form-control"
                    name="attendance_validity_minutes"
                    v-bind:class="{
                      'is-invalid': fieldErrors.stackingMaxDistanceForCarInKm,
                    }"
                  />
                  <span
                    v-if="fieldErrors.attendanceValidityMinutes"
                    class="invalid-feedback d-block"
                    role="alert"
                  >
                    <strong>{{
                      fieldErrors.attendanceValidityMinutes
                    }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link
                  :to="{
                    name: `${routePrefix}.index`,
                    params: { id, zone_id },
                  }"
                  class="btn btn-neutral btn-sm"
                >
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button
                  v-if="isItemFound()"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  :disabled="loading"
                >
                  <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PLATFORMS_AREAS_SETTINGS_SHOW as CRUD_ACTION_SHOW,
  PLATFORMS_AREAS_SETTINGS_UPDATE as CRUD_ACTION_UPDATE,
} from "actions/platforms";
const PLATFORMS_CRUD_CODE = "platforms";
const BRANCHES_CRUD_CODE = `${PLATFORMS_CRUD_CODE}.zones`;
const CRUD_CODE = `${BRANCHES_CRUD_CODE}`;
export default {
  name: "PlatformZoneSettingEdit",
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      platformsRoutePrefix: `${PLATFORMS_CRUD_CODE}`,
      platformsTransPrefix: `${PLATFORMS_CRUD_CODE}`,
      id: this.$router.currentRoute.params.id,
      zone_id: this.$router.currentRoute.params.zone_id,
      settings_id: this.$router.currentRoute.params.settings_id,
      stackingMaxOrdersPerCaptain: "",
      stackingMaxOrdersPerBike: "",
      stackingMaxDistanceForCarInKm: "",
      stackingMaxDistanceForBikeInKm: "",
      in_area_radius_meters: "",
      assignment_interval_minutes: "",
      assignment_algorithm_type: "",
      attendance_key: "",
      attendance_validity_minutes: "",
      serverError: "",
      fieldErrors: {},
      responseStatus: "",
      formMode: "edit",
      loading: false,
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    isItemFound() {
      return this.responseStatus !== 404;
    },
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          platformId: this.id,
          areaType: "ZONE",
          areaId: this.zone_id,
          id: this.settings_id,
        })
        .then((response) => {
          this.stackingMaxOrdersPerCaptain =
            response.data.stackingMaxOrdersPerCaptain;
          this.stackingMaxOrdersPerBike =
            response.data.stackingMaxOrdersPerBike;
          this.stackingMaxDistanceForBikeInKm =
            response.data.stackingMaxDistanceForBikeInKm;
          this.stackingMaxDistanceForCarInKm =
            response.data.stackingMaxDistanceForCarInKm;
          this.in_area_radius_meters = response.data.inAreaRadiusMeters;
          this.assignment_interval_minutes =
            response.data.assignmentIntervalMinutes;
          this.assignment_algorithm_type =
            response.data.assignmentAlgorithmType;
          this.attendance_key = response.data.attendanceKey;
          this.attendance_validity_minutes= response.data.attendanceValidityMinutes;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
    prepareRequestData() {
      return {
        stackingMaxOrdersPerCaptain: this.sanitizeField(
          "stackingMaxOrdersPerCaptain"
        ),
        stackingMaxOrdersPerBike: this.sanitizeField(
          "stackingMaxOrdersPerBike"
        ),
        stackingMaxDistanceForBikeInKm: this.sanitizeField(
          "stackingMaxDistanceForBikeInKm"
        ),
        stackingMaxDistanceForCarInKm: this.sanitizeField(
          "stackingMaxDistanceForCarInKm"
        ),
        inAreaRadiusMeters: this.sanitizeField("in_area_radius_meters"),
        assignmentIntervalMinutes: this.sanitizeField(
          "assignment_interval_minutes"
        ),
        assignmentAlgorithmType: this.sanitizeField(
          "assignment_algorithm_type"
        ),
        attendanceKey: this.sanitizeField("attendance_key"),
        attendanceValidityMinutes: this.sanitizeField("attendance_validity_minutes")
      };
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_UPDATE, {
          id: this.settings_id,
          areaType: "ZONE",
          areaId: this.zone_id,
          platformId: this.id,
          data: {
            ...this.prepareRequestData(),
          },
        })
        .then(() => {
          swal({
            icon: "success",
            text: this.$i18n.t("messages.update_successfully"),
            buttons: false,
            timer: 5000,
          });
          this.$router.push({
            name: `${this.routePrefix}.index`,
            params: { id: this.id },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.fieldErrors = {};
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
