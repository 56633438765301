import {
    CAPABILITES_LIST as CRUD_ACTION_LIST,
} from "../../actions/suppliers_portal/capabilities";
import axioses from "@/axios/config";

const routePrefix='capabilities';

const state = {};

const getters = {};

const actions = {
  [CRUD_ACTION_LIST]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('START_LOADING', params.withLoading, { root: true });
      axioses.supplierPortal
          .get(`/${routePrefix}`,{ params })
          .then(response => {
            resolve(response);
            commit('STOP_LOADING', params.withLoading, { root: true });
          })
          .catch(error => {
            commit('STOP_LOADING', params.withLoading, { root: true });
            reject(error);
          });
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
