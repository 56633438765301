<template>
    <div class="container-fluid mt-4">
      <div class="row justify-content-center">
        <div class="col-12 col-md-3 col-xl-counters">
          <div class="alert bg-teal">{{ $t(`${transPrefix}.lists.counter.total`) }}: {{ totalCaptains }}</div>
        </div>
        <div class="col-12 col-md-3 col-xl-counters">
          <div class="alert bg-yellow">{{ $t(`${transPrefix}.lists.counter.busy`) }}: {{ totalBusyCaptains }}</div>
        </div>
        <div class="col-12 col-md-3 col-xl-counters">
          <div class="alert bg-purple text-white">{{ $t(`${transPrefix}.lists.counter.free`) }}: {{ totalFreeCaptains }}</div>
        </div>
        <div class="col-12 col-md-3 col-xl-counters">
          <div class="alert alert-success">{{ $t(`${transPrefix}.lists.counter.connected`) }}: {{ totalConnectedCaptains }}</div>
        </div>
        <div class="col-12 col-md-3 col-xl-counters">
          <div class="alert bg-red text-white">{{ $t(`${transPrefix}.lists.counter.not_connected`) }}: {{ totalNotConnectedCaptains }}</div>
        </div>
      </div>
    </div>
</template>
<script>

  import { mapGetters } from "vuex";
  const CRUD_CODE="tracking";
  export default {
    name: "TrackingCounter",
    data() {
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
      }
    },
    computed: {
      ...mapGetters(["getCaptains", "getBusyCaptains", "getScopedCaptains", "getConnectedCaptainsSize"]),
      totalCaptains(){
        return Object.keys(this.getScopedCaptains).length;
      },
      totalBusyCaptains(){
        let busyCaptains = [];
        Object.values(this.getScopedCaptains).forEach(captain=>{
          if(["BUSY"].includes(captain.workingStatus)){
            busyCaptains.push(captain);
          }
        });
        return busyCaptains.length;
      },
      totalConnectedCaptains(){
        return this.getConnectedCaptainsSize;
      },
      totalNotConnectedCaptains(){
        return this.totalCaptains - this.totalConnectedCaptains;
      },
      totalFreeCaptains(){
        return this.totalCaptains - this.totalBusyCaptains;
      }
    },
  };
</script>
