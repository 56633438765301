<template>
  <searchContainer :searchHandler="search" :resetHandler="reset">
    <template v-slot:items>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="branch_id">{{ $t(`fields.branch_id`) }}</label>
            <select-platform-branches
              v-model="filters.branch_id"
              :platform_id="platformId"
              :multiple="true"
            />
          </div>
        </div>
      </div>
    </template>
  </searchContainer>
</template>
<script>
import { mapGetters } from "vuex";
import { CRUD_SEARCH_RESET } from "actions/crud";
import searchContainer from "@/components/crud/card/CardSearch";

const PLATFORMS_CRUD_CODE = "platforms";
const BRANCHES_CRUD_CODE = `${PLATFORMS_CRUD_CODE}.branches`;
const CRUD_CODE = `${BRANCHES_CRUD_CODE}.shifts`;
export default {
  name: "PlatformBranchShiftDashboardSearch",
  components: {
    searchContainer,
  },
  props: {
    listHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      transPrefix: CRUD_CODE,
      platformId: this.$router.currentRoute.params.id,
      filters: {
        branch_id: this.getFilterByKey("branch_id")
      },
      filtersMap: {
        branch_id: "areaIds"
      },
    };
  },
  mounted() {
    this.setSearchParams(this.filters, this.filtersMap);
  },
  computed: {
    ...mapGetters(["getSearchFilters"]),
  },
  methods: {
    search() {
      this.resetPagination();
      this.setSearchParams(this.filters, this.filtersMap, {
        routeParams: this.getFilters(this.filters),
      });
      this.pushRoute(this.getSearchFilters);
      this.listHandler();
    },
    reset() {
      this.resetPagination();
      this.$store.dispatch(CRUD_SEARCH_RESET);
      this.filters = this.clearFilters(this.filters);
      this.pushRoute();
      this.listHandler();
    },
    resetPagination() {
      //Reset Pagination
      this.$emit("input", 0);
    },
    pushRoute(query = {}) {
      this.$router.push({ name: this.$route.name, query }).catch(() => {});
    },
  },
};
</script>