import PageUnauthorized from "@/views/PageUnauthorized.vue";
import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='page_unauthorized';
const routes = [
  {
    path: "unauthorized",
    name:`unauthorized`,
    component: PageUnauthorized,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.title`,
    },
    beforeEnter: function (to,from, next){
      if(ifAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
