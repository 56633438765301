<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                  <input  v-model="name" id="name" type="text" class="form-control" name="name" v-bind:class="{'is-invalid':fieldErrors.name}">
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="startAt">{{ $t(`fields.start_at`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                  <input  v-model="startAt" id="startAt" type="time" step="1" class="form-control" name="startAt" v-bind:class="{'is-invalid':fieldErrors.startAt}">
                  <span v-if="fieldErrors.startAt" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.startAt }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="finishAt">{{ $t(`fields.finish_at`)}} <small class="text-danger">* ({{ $t(`labels.required`) }})</small></label>
                  <input  v-model="finishAt" id="finishAt" type="time" step="1" class="form-control" name="finishAt" v-bind:class="{'is-invalid':fieldErrors.finishAt}">
                  <span v-if="fieldErrors.finishAt" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.finishAt }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="type">{{ $t(`fields.type`)}}</label>
                  <select-platform-area-shift-types v-model="type" :params="{platformId: id, areaId: branch_id, areaType: 'BRANCH' }" :multiple="false"/>
                  <span v-if="fieldErrors.type" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.type}}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="dayOfWeek">{{ $t(`fields.day_of_week`)}}</label>
                  <select-platform-area-shift-day-of-weeks v-model="daysOfWeek" :params="{platformId: id, areaId: branch_id, areaType: 'BRANCH' }" :multiple="true"/>
                  <span v-if="fieldErrors.dayOfWeek" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.dayOfWeek}}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="captainsCapacity">{{ $t(`fields.captains_capacity`)}}</label>
                  <input  v-model="captainsCapacity"
                          id="captainsCapacity"
                          type="number"
                          step="1"
                          class="form-control"
                          v-bind:class="{'is-invalid':fieldErrors.captainsCapacity}"
                  >
                  <span v-if="fieldErrors.captainsCapacity" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.captainsCapacity}}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-top:39px">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="use_default_pricing_rules">{{
                      $t(`fields.use_default_pricing_rules`)
                    }}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input
                        type="checkbox"
                        id="use_default_pricing_rules"
                        v-model="useDefaultPricingRules"
                        @change="changeUseDefaultPricinRulesStatus(useDefaultPricingRules)"
                      />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div>
                  <div class="form-group">
                    <label for="type">{{ $t(`fields.pricing_group_id`)}}</label>
                    <select-pricing-groups v-model="pricingGroupId" :params="{platformId: id ,owner_id: branch_id, owner_type: 'PRICING_GROUP' }" :multiple="false"/>
                    <span v-if="fieldErrors.pricingGroupId" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.pricingGroupId}}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_AREAS_SHIFTS_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';
  const PLATFORMS_CRUD_CODE="platforms";
  const BRANCHES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.branches`;
  const CRUD_CODE=`${BRANCHES_CRUD_CODE}.shifts`;
  const CRUD_PERMISSION_CODE=`platform_area_shifts`;
  const AREA_TYPE = "BRANCH";
  export default {
    name: "PlatformBranchShiftCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        platformsRoutePrefix: `${PLATFORMS_CRUD_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CRUD_CODE}`,
        id:this.$router.currentRoute.params.id,
        branch_id:this.$router.currentRoute.params.branch_id,
        name: '',
        startAt: '',
        finishAt: '',
        daysOfWeek: '',
        pricingGroupId: '',
        type: '',
        captainsCapacity: '',
        useDefaultPricingRules: true,
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    methods: {
      prepareRequestData(){
        return {
          platformId:this.id,
          area_type: AREA_TYPE,
          area_id: this.branch_id,
          name: this.sanitizeField('name'),
          startAt: this.sanitizeField('startAt'),
          finishAt: this.sanitizeField('finishAt'),
          daysOfWeek: this.sanitizeField('daysOfWeek'),
          type: this.sanitizeField('type'),
          captainsCapacity: this.sanitizeField('captainsCapacity'),
          useDefaultPricingRules: this.useDefaultPricingRules,
          pricingGroupId: this.pricingGroupId
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`})
        })
        .catch(error => {
          this.loading = false;
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      changeUseDefaultPricinRulesStatus(useDefaultPricingRules) {
        this.useDefaultPricingRules = useDefaultPricingRules;
      },
    }
  };
</script>
