<template>
    <searchContainer :searchHandler="search" :resetHandler="reset">
        <template v-slot:items>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="backend_id">{{ $t(`${transPrefix}.fields.backend_id`)}}</label>
                        <input v-model="filters.backend_id" id="backend_id" type="text" class="form-control" name="backend_id">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="payment_type">{{ $t(`${transPrefix}.fields.payment_type`)}}</label>
                        <select-payment-types v-model="filters.payment_type" placeholder="placeholders.all" :multiple="false"/>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="order_status">{{ $t(`fields.status`)}}</label>
                        <select-order-status v-model="filters.order_status" placeholder="placeholders.all" :multiple="true"/>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group ">
                        <label for="country_id">{{ $t(`fields.country`)}}</label>
                        <select-countries v-model="filters.country_id" :action="countries_list_action" placeholder="placeholders.all" :disabled="canNotSelectCountry()"/>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group ">
                        <label for="city_id">{{ $t(`fields.city`)}}</label>
                        <select-cities v-model="filters.city_id" :action="cities_list_action" :country_id="filters.country_id" placeholder="placeholders.all" id="city_id" :disabled="canNotSelectCity()"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="created_from">{{ $t(`fields.created_from`)}}</label>
                        <input v-model="filters.created_from" id="created_from" type="date" class="form-control" name="created_from">
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="created_to">{{ $t(`fields.created_to`)}}</label>
                        <input v-model="filters.created_to" id="created_to" type="date" class="form-control" name="created_to">
                    </div>
                </div>
            </div>
        </template>
    </searchContainer>
</template>
<script>

    import {mapGetters} from "vuex";
    import {CRUD_SEARCH_RESET} from "actions/crud";
    import searchContainer from "@/components/crud/card/CardSearch";
    import {COUNTRIES_PUBLIC_LIST} from 'actions/countries';
    import {CITIES_PUBLIC_LIST} from 'actions/cities';

    const CRUD_CODE = "orders";
    export default {
        name: "PlatformsPortalOrderSearch",
        components: {
            searchContainer
        },
        props: {
            listHandler: {
                type: Function,
                default: () => {
                }
            },
        },
        data() {
            return {
                transPrefix: CRUD_CODE,
                filters: {
                    backend_id: this.getFilterByKey('backend_id'),
                    payment_type: this.getFilterByKey('payment_type'),
                    order_status: this.getFilterByKey('order_status'),
                    country_id: this.getFilterByKey('country_id'),
                    city_id: this.getFilterByKey('city_id'),
                    created_from: this.getFilterByKey('created_from'),
                    created_to: this.getFilterByKey('created_to'),
                },
                filtersMap: {
                    backend_id: 'backendId',
                    captain_id: 'captainId',
                    city_id: 'cityId',
                    payment_type: 'paymentType',
                    order_status: 'orderStatus',
                    created_from: 'startDate',
                    created_to: 'endDate',
                },
                countries_list_action: COUNTRIES_PUBLIC_LIST,
                cities_list_action: CITIES_PUBLIC_LIST,
            }
        },
        mounted() {
            this.setSearchParams(this.filters, this.filtersMap);
        },
        computed: {
            ...mapGetters([
                "getSearchFilters"
            ])
        },
        methods: {
            search() {
                this.resetPagination();
                this.setSearchParams(this.filters, this.filtersMap, {routeParams: this.getFilters(this.filters)});
                this.pushRoute(this.getSearchFilters);
                this.listHandler();
            },
            reset() {
                this.resetPagination();
                this.$store.dispatch(CRUD_SEARCH_RESET);
                this.filters = this.clearFilters(this.filters);
                this.pushRoute();
                this.listHandler();
            },
            resetPagination() {
                //Reset Pagination
                this.$emit('input', 0);
            },
            pushRoute(query = {}) {
                this.$router.push({name: this.$route.name, query}).catch(() => {});
            },
            canSelectCountry() {
                return true;
            },
            canNotSelectCountry() {
                return !this.canSelectCountry();
            },
            canSelectCity() {
                return this.filters.country_id;
            },
            canNotSelectCity() {
                return !this.canSelectCity();
            },
        }
    };
</script>
