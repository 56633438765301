<template>
  <div class="">
    <button class="awaiting-assign-order-tracking-btn btn btn-success" @click.prevent="openSideMenu()">
      {{ $t('labels.branch_captains') }}
      <span>
        <i class="fa fa-arrow-circle-right"></i>
      </span>
    </button>
    <nav class="navbar navbar-vertical pt-5 navbar-expand-xs navbar-light opened left-0 pb-0 component-sidenav" id="sidenav-branch-captains-tracking">
      <div class="scrollbar-inner bg-lighter">
        <div class="d-flex align-items-end bg-lighter pr-0 position-fixed top-0 left-0 w-100">
          <div class="pt-3 mx-0 row flex-row-reverse w-100">
            <div class="col-6 p-0 text-right">
              <a href="#" class="text-success p-2"  @click.prevent="refreshList(true)" v-b-tooltip.hover="{ animation: false}" :title="$t('labels.refresh')">
                <i class="fa fa-sync"></i>
              </a>
              <a href="#" class="p-2"  @click.prevent="hideSideMenu()">
                <i class="fa fa-arrow-circle-left"></i>
              </a>
            </div>
            <div class="col-6">
              <div class="form-group position-relative search mr--4 mt--1 mb-2">
                <label for="filter_id" class="position-absolute left-2 top-1 opacity-7">
                  <i class="fa fa-search"></i>
                </label>
                <input  v-model="filters.id"
                        id="filter_id"
                        type="text"
                        class="form-control form-control-sm border-primary border-2"
                        name="filter_id"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-inner bg-lighter">
          <!-- Collapse -->
          <div class="collapse navbar-collapse">
            <div v-if="loading" class="text-center position-fixed overflow-hidden bottom-0 top-0 mt-5 w-100 left-0 right-0 bg-lighter opacity-7 swal-overlay--show-modal">
              <span class="spinner-border loader text-dark m-auto position-absolute top-0 right-0 left-0 bottom-0 force-right-0"></span>
            </div>
            <!-- Nav items -->
            <ul class="navbar-nav">
              <li
                class="nav-item nav-link tracking-order-item mx-0 px-0 row"
                v-bind:class="{ active: activeItem.id === item.id }"
                v-for="item in getItemsByFilter()"
                :key="item.id"
                @click="clickItemHandler(item)"
              >
                <div class="col-12">
                  <div class="row">
                    <div class="col">
                      <div class="media align-items-center">
                        <a
                          v-if="getImageThumbnail(item)"
                          href="javascript:;"
                          class="avatar rounded-circle text-center mr-1"
                        >
                          <img
                            alt="Image placeholder"
                            class="img-fit"
                            :src="getImageThumbnail(item)"
                          />
                        </a>
                        <div class="row mx-1 overflow-hidden">
                          <h5
                            class="
                              order-id
                              mb-0
                              overflow-hidden
                              text-nowrap text-truncate
                            "
                          >
                            {{ item.id }} - {{ item.user.name }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <span
                    class="payment-type badge pb-1"
                    v-bind:class="{
                      'bg-purple text-white': isCaptainHasReturningOrders(item.id),
                      'bg-yellow text-black': !isCaptainHasReturningOrders(item.id) && isCaptainBusy(item.id),
                      'bg-pink text-white': !isCaptainHasOrders(item.id) && item.locationStatus == 'ROAMING',
                      'bg-blue text-white':   !isCaptainHasOrders(item.id) && item.locationStatus != 'ROAMING',
                    }"
                  >
                    {{ getCaptainStatus(item)}}
                  </span>
                </div>
                <div class="col-8 text-right">
                  <span class="total-distance">
                    <span
                      class="payment-type badge pb-1"
                      v-bind:class="{
                        'bg-success text-white': isCaptainConnected(item.id),
                        'bg-red text-white': !isCaptainConnected(item.id),
                      }"
                    >
                      {{
                        isCaptainConnected(item.id)
                          ? $t("tracking.lists.counter.connected")
                          : $t("tracking.lists.counter.not_connected")
                      }}
                    </span>
                  </span>
                </div>
                <div class="col-12 text-left">
                  <span class="text-xs">
                    No of Orders: {{ getScopedCaptainNumberOfOrders(item.id) }}
                  </span>
                </div>
              </li>
              <li
                class="nav-item nav-link mx-0 px-0 row"
                v-if="getItemsByFilter().length === 0"
              >
                <div class="col-12 text-center">
                  <h2 class="order-id">{{ $t("messages.no_result_found") }}</h2>
                </div>
              </li>
              <div v-observe-visibility="pagination" v-if="hasNextPage()" />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import { PLATFORMS_BRANCHES_TRACKING_LIST as CRUD_ACTION_LIST } from "actions/platforms";
const CRUD_CODE = "tracking";
const CRUD_PERMISSION_CODE = `${CRUD_CODE}`;
const CAPTAIN_CODE = `captains`;
const CAPTAIN_PERMISSION_CODE = `${CAPTAIN_CODE}`;
import { TRACKING_REFRESH_LIST } from "actions/sockets/tracking";
import { CRUD_SEARCH_RESET } from "actions/crud";
import {
  TRACKING_SET_BRANCH,
  TRACKING_RESET_BRANCH,
  TRACKING_SET_SCOPED_CAPTAINS,
  TRACKING_RESET_SCOPED_CAPTAINS,
} from "actions/sockets/tracking";

export default {
  name: "TrackingBranchCaptains",
  props: {},
  data() {
    return {
      routePrefix: CRUD_CODE,
      transPrefix: CRUD_CODE,
      permissionPrefix: CRUD_PERMISSION_CODE,
      captainRoutePrefix: CAPTAIN_CODE,
      captainTransPrefix: CAPTAIN_CODE,
      captainPermissionPrefix: CAPTAIN_PERMISSION_CODE,
      id: this.$router.currentRoute.params.id,
      branch_id: this.$router.currentRoute.params.branch_id,
      items: [],
      perPage: 20,
      totalElements: 0,
      lastPage: 0,
      currentPage: 1,
      loading: false,
      activeItem: "",
      filters: {
        id: "",
      },
    };
  },
  watch: {
    getActiveOrder: function (newVal, oldVal) {
      this.activeItem = newVal;
    },
    refreshListListener: function (newVal, oldVal) {
      if (newVal.refresh && newVal.key !== "AWAITING_ASSIGN_LIST") {
        this.refreshList();
        this.$store.commit(TRACKING_REFRESH_LIST, {
          key: "AWAITING_ASSIGN_LIST",
          refresh: newVal.key === "ALL",
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getActiveOrder",
      "refreshListListener",
      "getScopedCaptainsOrders",
      "getCaptains",
    ]),
  },
  mounted() {
    $(
      ".scrollbar-rail,.scrollbar-macosx,.scrollbar-light,.scrollbar-inner,.scrollbar-outer,.scrollbar-dynamic,.scrollbar-chrome"
    )
      .scrollbar()
      .scrollLock();
    this.refreshList();
  },
  beforeDestroy() {
    this.clickItemHandler({});
    this.$store.commit(TRACKING_RESET_SCOPED_CAPTAINS);
    this.$store.commit(TRACKING_RESET_BRANCH);
  },
  methods: {
    openSideMenu() {
      $("#sidenav-branch-captains-tracking")
        .removeClass("closed")
        .addClass("opened");
    },
    hideSideMenu() {
      $("#sidenav-branch-captains-tracking")
        .removeClass("opened")
        .addClass("closed");
    },
    getList(clearList = false) {
      this.loading = true;
      this.$store
        .dispatch(CRUD_ACTION_LIST, {
          platformId: this.id,
          branchId: this.branch_id,
          pageId: this.getCurrentPage(),
        })
        .then((response) => {
          if (clearList) {
            this.items = [];
          }

          this.items = [...this.items, ...response.data.captains];
          this.perPage = 20;
          this.totalElements = 15;
          this.lastPage = 0;
          this.loading = false;

          const captains = {};
          this.items.forEach((item) => {
            captains[item.id] = item;
          });

          this.$store.commit(TRACKING_SET_SCOPED_CAPTAINS, captains);
          this.$store.commit(TRACKING_SET_BRANCH, response.data.branch);
        });
    },
    pagination(isVisible) {
      if (this.hasNextPage() && isVisible) {
        this.currentPage += 1;
        this.getList();
      }
    },
    getCurrentPage() {
      return this.currentPage - 1;
    },
    getLastPage() {
      return this.lastPage - 1;
    },
    hasNextPage() {
      return (
        this.getCurrentPage() < this.getLastPage() && this.items.length > 0
      );
    },
    refreshList(refreshOtherLists = false) {
      this.clickItemHandler({});
      this.currentPage = 1;
      this.getList(true);
      this.filters = this.clearFilters(this.filters);

      if (refreshOtherLists) {
        this.$store.commit(TRACKING_REFRESH_LIST, {
          key: "AWAITING_ASSIGN_LIST",
          refresh: true,
        });
      }
    },
    clickItemHandler(item) {
      if (item.id) {
        this.showItemHandler(item);
      }
    },
    showItemHandler(item) {
      let routeData = this.$router.resolve({
        name: `${this.captainRoutePrefix}.show`,
        params: { id: item.id },
      });
      window.open(routeData.href, "_blank");
    },
    getItemsByFilter() {
      let items = this.items;
      Object.keys(this.filters).forEach((key) => {
        let filterValue = this.filters[key];
        if (filterValue) {
          switch (key) {
            case "id":
              items = items.filter(function (item) {
                return `${item.id}`.search(`${filterValue}`) !== -1;
              });
              break;
          }
        }
      });
      return items;
    },
    search() {
      this.setSearchParams(
        this.filters,
        {},
        { routeParams: this.getFilters(this.filters) }
      );
      this.clickItemHandler({ ignoreDefault: true });
    },
    reset() {
      this.$store.dispatch(CRUD_SEARCH_RESET);
      this.filters = this.clearFilters(this.filters);
      this.clickItemHandler({ ignoreDefault: true });
    },
    getCaptainStatus(captain) {
      let status = captain.locationStatus;

      if (this.isCaptainHasReturningOrders(captain.id)) {
        status = "Returning";
      } else if (this.isCaptainBusy(captain.id)) {
        status = "Busy";
      }

      return status;
    },
    isCaptainHasReturningOrders(captainID) {
      return !!this.getScopedCaptainsOrders[captainID]?.filter((order) =>
        ["RETURNING", "RETURNING_TO_AREA"].includes(order.status)
      )?.length;
    },
    isCaptainBusy(captainID) {
      return this.isCaptainHasOrders(captainID);
    },
    isCaptainHasOrders(captainID) {
      return !!this.getScopedCaptainNumberOfOrders(captainID);
    },
    getScopedCaptainNumberOfOrders(captainID) {
      let orders = this.getScopedCaptainsOrders[captainID] || [];
      return orders.length;
    },
    isCaptainConnected(captainID) {
      return this.getCaptains[captainID]?.connected || false;
    },
    getImageThumbnail(item) {
      let image = "";
      if (item.user && item.user.profilePicture) {
        image = item.user.profilePicture.url;
      }
      return image;
    },
  },
};
</script>
<style type="text/css"  scoped>
.scrollbar-inner .scroll-element.scroll-y {
  background-color: #e5e5e594 !important;
  width: 8px !important;
  opacity: 1 !important;
}
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  opacity: unset !important;
}

.loader.force-right-0 {
  right: 0 !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-9 {
  font-size: 9px;
}

.img-fit {
  object-fit: cover;
}

.avatar {
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
}
</style>
