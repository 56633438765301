<template>
    <span>
        <a href="javascript:;" @click.prevent="showDeletePopUp()" class=" px-1" v-bind:class="[options.color_class?options.color_class:'text-danger']" v-b-tooltip.hover="{ animation: false}" :title="$t(options.title?options.title:'crud.delete')" v-can="options.can">
            <i class="" v-bind:class="[options.icon?options.icon:'fas fa-trash']"></i>
        </a>
    </span>
</template>


<script>
    export default {
        name: "TableItemActionsDelete",
        props: {
            options: {
                default:function(){
                    return {};
                }
            },
            params:{
                type:Object,
                default:function(){
                    return {};
                }
            },
        },
        data(){
            return {

            }
        },
        methods:{
            showDeletePopUp(){
                swal({
                    title: this.$i18n.t("labels.delete_item"),
                    text: this.$i18n.t("messages.delete_item"),
                    icon: "warning",
                    buttons: [
                        this.$i18n.t("labels.cancel"),
                        this.$i18n.t("labels.yes")
                    ],
                    dangerMode: true,
                })
                .then((willDelete) => {
                    if (willDelete) {
                        this.deleteItem();
                    }
                });
            },
            deleteItem(){

                this.$store.dispatch(this.options.action, {
                    ...this.params
                })
                .then(() => {
                    if(typeof this.options.successCallback ==='function'){
                        this.options.successCallback();
                    }
                    swal({
                        icon: 'success',
                        text: this.$i18n.t("messages.delete_successfully"),
                        buttons:false,
                        timer: 5000
                    });
                })
                .catch(error => {
                    let message="";
                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors && responseData.errors.error){
                            message = responseData.errors.error;
                        }
                        else{
                            message = this.$i18n.t("messages.delete_error");
                        }
                    }
                    if(message){
                        swal({
                            icon: 'error',
                            text: message,
                            buttons:false,
                            timer: 5000
                        });
                    }

                })
            }
        }
    }
</script>

<style scoped>

</style>
