<template>
    <div>
        <div :id="id" class="google-maps" v-bind:style="{height: showMap ? '700px' : '0'}"></div>
        <div aria-hidden="true" aria-labelledby="modal-captain-information" class="modal fade" id="modal-captain-information"
             role="dialog" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-title-default">{{
                            $t('labels.captain_information')}}</h6>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="image">{{ $t(`fields.image`)}}</label>
                                    <files-image v-model="activeCaptainItem.image" :disabled="true" :thumbnailImage="activeCaptainItem.image.thumbnail"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="identity">{{ $t(`${captainTransPrefix}.fields.identity`)}}</label>
                                    <files-image id="identity" v-model="activeCaptainItem.identity" :disabled="true" :thumbnailImage="activeCaptainItem.identity.thumbnail"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="driving_license">{{ $t(`${captainTransPrefix}.fields.driving_license`)}}</label>
                                    <files-image id="driving_license" v-model="activeCaptainItem.driving_license" :disabled="true" :thumbnailImage="activeCaptainItem.driving_license.thumbnail"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="form-group">
                                    <label for="registration_license">{{ $t(`${captainTransPrefix}.fields.registration_license`)}}</label>
                                    <files-image id="registration_license" v-model="activeCaptainItem.registration_license" :disabled="true" :thumbnailImage="activeCaptainItem.registration_license.thumbnail"/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="name">{{ $t(`fields.id`)}}</label>
                                    <input  v-model="activeCaptainItem.id"
                                            id="id"
                                            type="text"
                                            class="form-control"
                                            name="id"
                                            disabled
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="name">{{ $t(`fields.name`)}}</label>
                                    <input  v-model="activeCaptainItem.name"
                                            id="name"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            disabled
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="email">{{ $t(`fields.email`)}}</label>
                                    <input  v-model="activeCaptainItem.email"
                                            id="email"
                                            type="text"
                                            class="form-control"
                                            name="email"
                                            disabled
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                                    <input  v-model="activeCaptainItem.mobile_number"
                                            id="mobile_number"
                                            type="text"
                                            class="form-control"
                                            name="mobile_number"
                                            disabled
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="id_number">{{ $t(`${captainTransPrefix}.fields.id_number`)}}</label>
                                    <input  v-model="activeCaptainItem.id_number"
                                            id="id_number"
                                            type="text"
                                            class="form-control"
                                            name="id_number"
                                            disabled
                                    >
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="id_type">{{ $t(`${captainTransPrefix}.fields.id_type`)}}</label>
                                    <select-captain-id-type v-model="activeCaptainItem.id_type" :disabled="true"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="status">{{ $t(`fields.status`)}}</label>
                                    <select-captain-status v-model="activeCaptainItem.status" id="status" :disabled="true"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                    <label for="working_status">{{ $t(`${captainTransPrefix}.fields.working_status`)}}</label>
                                    <select-captain-working-status v-model="activeCaptainItem.working_status" :disabled="true"/>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group ">
                                <label for="location_status">{{ $t(`fields.location_status`)}}</label>
                                <input  v-model="activeCaptainItem.location_status"
                                        id="location_status"
                                        type="text"
                                        class="form-control"
                                        name="location_status"
                                        disabled/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group row">
                                    <div class="col-8">
                                        <label for="ready_to_work">{{ $t(`${captainTransPrefix}.fields.ready_to_work`)}}</label>
                                    </div>
                                    <div class="col-4 text-right">
                                        <label class="custom-toggle">
                                            <input type="checkbox" id="ready_to_work" v-model="activeCaptainItem.ready_to_work" :disabled="true">
                                            <span class="custom-toggle-slider rounded-circle border-1" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="form-group row">
                                    <div class="col-8">
                                        <label for="suspended">{{ $t(`${captainTransPrefix}.fields.suspended`)}}</label>
                                    </div>
                                    <div class="col-4 text-right">
                                        <label class="custom-toggle">
                                            <input type="checkbox" id="suspended" v-model="activeCaptainItem.suspended" :disabled="true">
                                            <span class="custom-toggle-slider rounded-circle" :data-label-off="$t('labels.no')" :data-label-on="$t('labels.yes')"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary  ml-auto" data-dismiss="modal" type="button">
                            {{ $t('labels.close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <a id="open-captain-modal" class="d-none" data-target="#modal-captain-information"
           data-toggle="modal"
           href="javascript:;">
        </a>
    </div>
</template>

<script>
    import Stomp from "webstomp-client";
    import { TRACKING_ADD_UPDATE_CAPTAIN, TRACKING_UPDATE_CAPTAIN_STATUS, TRACKING_RESET_CAPTAINS_CAPTAINS } from "actions/sockets/tracking";
    import { CAPTAINS_SHOW as CAPTAINS_ACTION_SHOW } from 'actions/captains';
    import { mapGetters, mapState } from "vuex";
    const CAPTAIN_CODE="captains";
    export default {
        name: "FieldsMapsTracking",
        props: {
          points:{
              type:Array,
              default:function(){
                  return [];
              }
          },
          centerPosition:{
            type:Object,
            default:function(){
                return {lat: 24.7253981, lng: 46.3398977};
            }
          },
          zoom:{
            type:Number,
            default:6,
          },
          id: {
            type:String,
            default:'map'
          },
          error:{
            type:String
          },
          scopedByCaptains: {
              type: Boolean,
              default: false
          },
          showMap: {
              type: Boolean,
              default: true
          },
          captainShowAction: {
              type: String,
              default: CAPTAINS_ACTION_SHOW
          }
        },
        data(){
            return {
                map:null,
                google:null,
                options:{
                    libraries:[],
                },
                stompClient:null,
                stompOptions:{
                    debug:false,
                    heartbeat:{
                        outgoing : 0,
                        incoming : 20000
                    }
                },
                markers: {},
                orderMarkers: {},
                captainRoutePrefix: CAPTAIN_CODE,
                captainTransPrefix: CAPTAIN_CODE,
                activeCaptainItem:{
                    id:null,
                    id_number: '',
                    status: '',
                    connected: false,
                    id_type: '',
                    ready_to_work: false,
                    suspended: false,
                    working_status:'',
                    location_status: '',
                    default_city_id:'',
                    email: '',
                    mobile_number: '',
                    name:'',
                    serverError: '',
                    image:{
                        file:'',
                        thumbnail:'',
                    },
                    driving_license:{
                        file:'',
                        thumbnail:'',
                    },
                    registration_license:{
                        file:'',
                        thumbnail:'',
                    },
                    identity:{
                        file:'',
                        thumbnail:'',
                    },
                },
                taskMarkers:[],

            }
        },
        mounted() {
            this.resetCaptains();
            this.prepareOptions();
            this.initMap();
        },
        watch: {
            points: function(newVal, oldVal) {
                this.setMapCenter();
                this.drawingShapeForCurrentPoints();
            },
            isStompLoaded:function(newVal, oldVal){
                if(newVal){
                    this.connect();
                }
            },
            getSearchFilters:function(newFilters, oldFilters){
                this.showMarkers();
                this.moveToCaptain(newFilters.captain_id);
            },
            getActiveOrder:function(newVal,oldVal){

                this.deleteTaskMarkers();
                if(newVal.id){
                    if(!['RE_ASSIGNING','NOT_ASSIGNED'].includes(newVal.status)){
                        this.hideMarkers();
                        this.showMarkersByCaptainId(newVal.captainId);
                    }else{
                        this.showMarkers();
                    }

                    this.prepareTaskMapItems();
                }else if(!newVal.ignoreDefault){
                    this.showMarkers();
                    this.setMapCenter();
                    this.setMapZoom();
                }
            },
            getBranch:function(newVal,oldVal){
                if(newVal.points){
                    this.drawingShapeForCurrentPoints(newVal.points)
                }
                if(newVal.id){
                    this.updateOrCreateStoreMarker(`store-${newVal.id}`,newVal);
                    this.setMapCenter({lat:newVal.latitude,lng:newVal.longitude});
                    this.setMapZoom(15);
                }
            },
            getScopedCaptainsOrders:function(newVal,oldVal){
                this.fillMapWithOrders(newVal);
            },
            getScopedCaptains:function(newVal,oldVal){
                if (Object.keys(newVal) == 0) {
                    this.deleteMarkers();
                }
            }
        },
        computed: {
            ...mapGetters(["isStompLoaded","getStompConnectionUrl","getStompConnectionsTopic","getStompLocationsTopic","getAuthorizationHeader","getCaptains","getBusyCaptains","getActiveOrder", "getBranch", "getScopedCaptains", "getScopedCaptainsOrders"]),
            ...mapGetters([
                "getSearchFilters"
            ]),
            isMakerMustBeHiddenInMap(){
                return !!this.getActiveOrder.id
            },
            getTaskMapPoints(){
                let points=[];
                Object.keys(this.getTaskMapItems).forEach(key=>{
                    let item=this.getTaskMapItems[key];
                    points.push({
                        0:item.longitude,
                        1:item.latitude
                    })
                });

                return points;
            },
            getTaskMapItems(){
                let taskMapItems=[];
                if(this.getActiveOrder.tasks.length){
                    Object.keys(this.getActiveOrder.tasks).forEach(key=>{
                        let task=this.getActiveOrder.tasks[key],
                            latitude = task.latitude,
                            longitude = task.longitude,
                            type=task.taskType,
                            rank=task.rank*-1
                        ;
                        
                        taskMapItems.push({
                            type,
                            latitude,
                            longitude,
                            rank,
                        });
                    });
                }
                return taskMapItems;
            },
            getTaskMapCenter(){
                if(this.getTaskMapPoints.length){
                    let max_x=Math.max.apply(Math, this.getTaskMapPoints.map(function(point) { return point[1]; }));
                    let min_x=Math.min.apply(Math, this.getTaskMapPoints.map(function(point) { return point[1]; }));

                    let max_y=Math.max.apply(Math, this.getTaskMapPoints.map(function(point) { return point[0]; }));
                    let min_y=Math.min.apply(Math, this.getTaskMapPoints.map(function(point) { return point[0]; }));

                    return {lat:(max_x+min_x)/2,lng:(max_y+min_y)/2};
                }
                return null;
            }
        },
        beforeDestroy() {
            this.disconnect();
        },
        methods: {
            addTaskMarkers(){
                Object.keys(this.getTaskMapItems).forEach(key=>{
                    let marker =new this.google.maps.Marker({
                        position: {lat:this.getTaskMapItems[key].latitude,lng:this.getTaskMapItems[key].longitude},
                        map:this.map,
                        icon: this.getIconByType(this.getTaskMapItems[key].type),
                        zIndex: this.getTaskMapItems[key].rank,
                    });
                    this.taskMarkers.push(marker);
                });
            },
            getIconByType(type){
                let icons= {
                    'PICK_UP'               : require('@/assets/theme/icons/task-types/PICK_UP.png'),
                    'ON_DEMAND_PICK_UP'     : require('@/assets/theme/icons/task-types/PICK_UP.png'),
                    'DELIVERY'              : require('@/assets/theme/icons/task-types/DELIVERY.png'),
                    'RETURNING'             : require('@/assets/theme/icons/task-types/RETURNING.png'),
                    'RETURN_TO_AREA'        : require('@/assets/theme/icons/task-types/RETURNING.png'),
                    'DEFAULT'               : require('@/assets/theme/icons/task-types/PICK_UP.png')
                };
                return icons[type]??icons['DEFAULT'];
            },
            prepareTaskMapItems(){
                this.addTaskMarkers();
                this.setMapCenter(this.getTaskMapCenter);
                this.setMapZoom(13);
            },
            initMap(){
                this.$google.then(google=>{
                    this.google=google;
                    this.map = new this.google.maps.Map(document.getElementById(this.id), {
                        center: this.getCenterPosition(),
                        zoom: this.zoom,
                    });
                    if(this.isStompLoaded){
                        this.connect();
                    }
                    this.drawingShapeForCurrentPoints();

                }).catch(error=>{});
            },
            drawingShapeForCurrentPoints(points){
                if(points?.length){
                   let shapeCoords = [];
                   points.map(function(point, key) {
                       shapeCoords.push({lat:point[1],lng:point[0]});
                   });

                   const selectedShape = new this.google.maps.Polygon({
                       paths: shapeCoords,
                   });
                   selectedShape.setMap(this.map);
                   this.addEventsToShape(selectedShape);
                }
           },
            getIconByStatus(captainID,status){

                let icons=[
                    require('@/assets/theme/icons/red_point.png'),
                    require('@/assets/theme/icons/green_point.png'),
                    require('@/assets/theme/icons/yellow_point.png'),
                    require('@/assets/theme/icons/purple_point.png'),
                ];

                status=!!status+0;
                if(this.getBusyCaptains.has(captainID)){
                    status=2;
                }
                if(this.isCaptainHasReturningOrders(captainID)){
                    status=3;
                }
                return icons[status];
            },
            isCaptainHasReturningOrders(captainID) {
                return !!this.getScopedCaptainsOrders[captainID]?.filter((order) => ['RETURNING', 'RETURNING_TO_AREA'].includes(order.status))?.length;
            },
            isMarkerExists(captainID){
                return !!this.markers[captainID];
            },
            isOrderMarkerExists(orderID){
                return !!this.orderMarkers[orderID];
            },
            addMarker(captainID,options){
                let marker =new this.google.maps.Marker({
                    position: {lat:options.latitude,lng:options.longitude},
                    map:this.isMakerMustBeHiddenInMap?null:this.map,
                    icon: this.getIconByStatus(captainID,options.connected),
                    title: `${captainID}`,
                });
                marker=this.setMarkerClickHandler(marker,captainID,options);
                marker=this.setMarkerMouseoverHandler(marker,captainID,options);
                this.markers[captainID]=marker;
            },
            addStoreMarker(storeID, options){
                let marker =new this.google.maps.Marker({
                    position: {lat:options.latitude,lng:options.longitude},
                    map:this.isMakerMustBeHiddenInMap ? null : this.map,
                    icon: {
                        url: require('@/assets/theme/icons/PICK_UP.png'),
                        scaledSize: new google.maps.Size(40, 40),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 0),
                    },
                });
                marker=this.setStoreMarkerClickHandler(marker,storeID,options);
                this.markers[storeID] = marker;
            },
            updateStoreMarker(storeID,options){
                this.updateMarkerPosition(storeID,{lat:options.latitude,lng:options.longitude});
            },
            updateOrCreateStoreMarker(storeID,options){
                if(this.isMarkerExists(storeID)){
                    this.updateStoreMarker(storeID,options);
                }else{
                    this.addStoreMarker(storeID,options);
                }
            },
            addOrderMarker(orderID, options){
                let deliveryTask = options.tasks.find((item) => item.taskType === "DELIVERY");
                let marker =new this.google.maps.Marker({
                    position: {lat:parseFloat(deliveryTask?.latitude),lng:parseFloat(deliveryTask?.longitude)},
                    map:this.isMakerMustBeHiddenInMap ? null : this.map,
                    icon: {
                        path: "M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z",
                        fillColor: options.statusColor,
                        fillOpacity: 1,
                        strokeWeight: 1,
                        rotation: 0,
                        scale: 1,
                        anchor: new google.maps.Point(15, 30), 
                    },
                });
                marker=this.setOrderMarkerClickHandler(marker,orderID,options);
                this.orderMarkers[orderID] = marker;
            },
            updateOrderMarker(orderID,options){
                // this.updateMarkerPosition(orderID,{lat:options.latitude,lng:options.longitude});
            },
            updateOrCreateOrderMarker(orderID,options){
                if(this.isOrderMarkerExists(orderID)){
                    this.updateOrderMarker(orderID, options);
                }else{
                    this.addOrderMarker(orderID, options);
                }
            },
            updateOrCreateMarker(captainID,options){
                if(this.isMarkerExists(captainID)){
                    this.updateMarker(captainID,options);
                }else{
                    this.addMarker(captainID,options);
                }
            },
            updateMarker(captainID,options){
                this.updateMarkerPosition(captainID,{lat:options.latitude,lng:options.longitude});
                this.updateMarkerIcon(captainID,options.connected);
            },
            updateMarkerIcon(captainID,status){
                if(this.isMarkerExists(captainID)){
                    this.markers[captainID].setIcon(this.getIconByStatus(captainID,status));
                }
            },
            updateMarkerPosition(id,position){
                if(this.isMarkerExists(id)) {
                    this.animateMarker(this.markers[id], .5, position);
                }
            },
            setMarkerClickHandler(marker,captainID,options){
                let vm=this;
                this.google.maps.event.addListener(marker, 'click',(function (marker,captainID,options,vm) {
                    return function () {
                        vm.getCaptainByID(captainID);
                        document.getElementById('open-captain-modal').click();
                    }
                })(marker,captainID,options,vm));

                return marker;
            },
            setMarkerMouseoverHandler(marker,captainID,options){
                let vm=this;
                let infoWindow = new google.maps.InfoWindow({
                    content: `${captainID}`,
                    disableAutoPan: true,
                });
                this.google.maps.event.addListener(marker, 'mouseover',(function (marker,captainID,options,vm) {
                    return function () {
                        infoWindow.open(this.map, marker);
                    }
                })(marker,captainID,options,vm));

                this.google.maps.event.addListener(marker, 'mouseout',(function (marker,captainID,options,vm) {
                    return function () {
                        infoWindow.close();
                    }
                })(marker,captainID,options,vm));

                return marker;
            },
            setStoreMarkerClickHandler(marker,storeID,options){
                let vm=this;
                this.google.maps.event.addListener(marker, 'click',(function (marker,storeID,options,vm) {
                    return function () {}
                })(marker,storeID,options,vm));

                return marker;
            },
            setOrderMarkerClickHandler(marker,orderID,options){
                let vm=this;
                let infoWindow = new google.maps.InfoWindow({
                    content: `Order ID is: ${options.backendId}`,
                    disableAutoPan: true,
                });
                this.google.maps.event.addListener(marker, 'click',(function (marker,orderID,options,vm) {
                    return function () {
                        infoWindow.open(this.map, marker);
                    }
                })(marker,orderID,options,vm));

                return marker;
            },
            setMapOnAll(map) {
                Object.keys(this.markers).forEach(key=>{
                    this.markers[key].setMap(map);
                });
                Object.keys(this.orderMarkers).forEach(key=>{
                    this.orderMarkers[key].setMap(map);
                });
            },
            animateMarker(marker, duration, moveto) {
                var deltalat = (moveto.lat - marker.position.lat()) / 5,
                    deltalng = (moveto.lng - marker.position.lng()) / 5,
                    delay = 200 * duration;
                for (var i = 0; i < 5; i++) {
                    (function(duration_period) {
                        setTimeout(
                            function() {
                                var lat = marker.position.lat(), lng = marker.position.lng();
                                lat += deltalat;
                                lng += deltalng;
                                var latlng = new google.maps.LatLng(lat, lng);
                                marker.setPosition(latlng);
                            }, delay * duration_period
                        );
                    })(i)
                }
            },
            showMarkersByCaptainId(captainID){
                if(this.isMarkerExists(captainID)) {
                    this.markers[captainID].setMap(this.map);
                }
            },
            showMarkers(){
                this.setMapOnAll(this.map);
            },
            hideMarkers(){
                this.setMapOnAll(null);
            },
            deleteMarkers() {
                this.setMapOnAll(null);
                this.markers= {};
            },
            deleteTaskMarkers() {
                Object.keys(this.taskMarkers).forEach(key=>{
                    this.taskMarkers[key].setMap(null);
                });
                this.taskMarkers= [];
            },
            resetCaptains(){
                this.$store.commit(TRACKING_RESET_CAPTAINS_CAPTAINS);
            },
            connect() {
                this.stompClient = Stomp.client(this.getStompConnectionUrl,this.stompOptions);
                this.stompClient.connect(
                    {"X-Authorization": this.getAuthorizationHeader},
                    frame => {

                        this.stompClient.subscribe(this.getStompConnectionsTopic, tick => {
                            let responseBody=JSON.parse(tick.body);
                            this.updateMarkerIcon(responseBody.captainId,responseBody.connected);
                            this.$store.commit(TRACKING_UPDATE_CAPTAIN_STATUS,{
                                ...responseBody
                            });
                        });

                        this.stompClient.subscribe(this.getStompLocationsTopic, tick => {
                            let responseBody=JSON.parse(tick.body);
                            responseBody.connected=true;

                            if(!this.scopedByCaptains || this.getScopedCaptains[responseBody.captainId]){
                                this.updateOrCreateMarker(responseBody.captainId,responseBody);
                                this.$store.commit(TRACKING_ADD_UPDATE_CAPTAIN,{
                                    ...responseBody
                                });
                            }
                        });
                    },
                    error => {
                        console.log(error);
                    }
                );
            },
            disconnect(){
                if (this.isConnected()) {
                    this.stompClient.disconnect();
                }
            },
            isConnected(){
              return this.stompClient?this.stompClient.connected:false;
            },
            preFillMapWithCaptains(){
                Object.keys(this.getCaptains).forEach(key=>{
                    this.updateOrCreateMarker(key,this.getCaptains[key]);
                });
                this.moveToCaptain(this.getSearchFilters.captain_id);
            },
            fillMapWithOrders(captainsOrders){
                Object.values(captainsOrders).flat().forEach(item => {
                    this.updateOrCreateOrderMarker(item.id, item);
                });
            },
            setMapCenter(position){
                if(this.map){
                    this.map.setCenter(position?position:this.getCenterPosition());
                }
            },
            setMapZoom(zoom){
                if(this.map){
                    this.map.setZoom(zoom?zoom:this.zoom);
                }
            },
            getCenterPosition(){
                if(this.points.length){
                    let max_x=Math.max.apply(Math, this.points.map(function(point) { return point[1]; }));
                    let min_x=Math.min.apply(Math, this.points.map(function(point) { return point[1]; }));

                    let max_y=Math.max.apply(Math, this.points.map(function(point) { return point[0]; }));
                    let min_y=Math.min.apply(Math, this.points.map(function(point) { return point[0]; }));

                    return {lat:(max_x+min_x)/2,lng:(max_y+min_y)/2};
                }
                return this.centerPosition;
            },
            prepareOptions(){

            },
            getCaptainByID(id){
                this.$store.dispatch(this.captainShowAction, {
                    id: id,
                }).then(response=>{
                    this.activeCaptainItem.id=response.data.id;
                    this.activeCaptainItem.id_number=response.data.idNumber;
                    this.activeCaptainItem.status=response.data.status;
                    this.activeCaptainItem.working_status=response.data.workingStatus;
                    this.activeCaptainItem.location_status=response.data.locationStatus;
                    this.activeCaptainItem.default_city_id=response.data.defaultCityId;
                    this.activeCaptainItem.connected=response.data.connected;
                    this.activeCaptainItem.id_type=response.data.idType;
                    this.activeCaptainItem.ready_to_work=response.data.readyToWork;
                    this.activeCaptainItem.suspended=response.data.suspended;
                    this.activeCaptainItem.email=response.data.user?response.data.user.email:'';
                    this.activeCaptainItem.mobile_number=response.data.user?response.data.user.userMobile.mobileNumber:'';
                    this.activeCaptainItem.name=response.data.user?response.data.user.name:'';
                    this.activeCaptainItem.image.thumbnail=response.data.user?(response.data.user.profilePicture?response.data.user.profilePicture.url:''):'';
                    this.activeCaptainItem.driving_license.thumbnail=response.data.drivingLicenseFile?response.data.drivingLicenseFile.url:'';
                    this.activeCaptainItem.registration_license.thumbnail=response.data.registrationFile?response.data.registrationFile.url:'';
                    this.activeCaptainItem.identity.thumbnail=response.data.idFile?response.data.idFile.url:'';
                }).catch(error => {
                    this.loading = false
                    this.serverError='';

                    if(error.response){
                        let responseData=error.response.data;

                        if(responseData.errors){
                            this.fieldErrors=responseData.errors;
                        }else{
                            this.serverError = 'Invalid Request.'
                        }
                    }else{
                        this.serverError = this.$i18n.t("messages.no_internet_connection");
                    }

                    if(this.fieldErrors.error){
                        this.serverError = this.fieldErrors.error;
                    }
                });
            },
            resetMapConfig(){
                this.setMapCenter();
                this.setMapZoom();
            },
            moveToCaptain(captainID){
                if(this.isMarkerExists(captainID)){
                    let marker=this.markers[captainID]
                    this.setMapCenter({
                        lat:marker.position.lat(),
                        lng:marker.position.lng()
                    });
                    this.setMapZoom(15);
                }else{
                    this.resetMapConfig();
                }
            }
        }
    }
</script>

<style scoped>

</style>
