<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title">
          <router-link
            v-if="!serverError"
            :to="{ name: `${routePrefix}.edit`}"
            class="btn btn-primary btn-sm"
            v-can:edit="permissionPrefix"
          >
            <i class="fa fa-edit"></i>
          </router-link>
        </card-header>
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div v-if="serverError" class="alert alert-danger">
                <strong>{{ $t("labels.error") }} : </strong> {{ serverError }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!serverError">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="billingEmail">{{ $t(`${transPrefix}.fields.billing_email`) }}</label>
                <input
                  v-model="billingEmail"
                  id="billingEmail"
                  type="text"
                  class="form-control"
                  name="billingEmail"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="vatNumber">{{ $t(`${transPrefix}.fields.vat_number`) }}</label>
                <input
                  v-model="vatNumber"
                  id="vatNumber"
                  type="text"
                  class="form-control"
                  name="vatNumber"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="zipCode">{{ $t(`${transPrefix}.fields.zip_code`) }}</label>
                <input
                  v-model="zipCode"
                  id="zipCode"
                  type="text"
                  class="form-control"
                  name="zipCode"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="poBox">{{ $t(`${transPrefix}.fields.po_box`) }}</label>
                <input
                  v-model="poBox"
                  id="poBox"
                  type="text"
                  class="form-control"
                  name="poBox"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="addressLine1">{{ $t(`${transPrefix}.fields.address_line_1`) }}</label>
                <input
                  v-model="addressLine1"
                  id="addressLine1"
                  type="text"
                  class="form-control"
                  name="addressLine1"
                  disabled
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="addressLine2">{{ $t(`${transPrefix}.fields.address_line_2`) }}</label>
                <input
                  v-model="addressLine2"
                  id="addressLine2"
                  type="text"
                  class="form-control"
                  name="addressLine2"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SUPPLIER_DETAILS_SHOW as CRUD_ACTION_SHOW } from "actions/suppliers_portal/supplier";
const SUPPLIERS_CODE = "suppliers";
const CRUD_CODE = `${SUPPLIERS_CODE}.details`;
const CRUD_PERMISSION_CODE = `supplier_supplier`;
export default {
  name: "SupplierDetailsShow",
  data() {
    return {
      routePrefix: `suppliers_portal.supplier`,
      transPrefix: CRUD_CODE,
      permissionPrefix: `${CRUD_PERMISSION_CODE}`,
      suppliersRoutePrefix: `${SUPPLIERS_CODE}`,
      suppliersTransPrefix: `${SUPPLIERS_CODE}`,
      billingEmail: "",
      vatNumber: "",
      zipCode: "",
      poBox: "",
      addressLine1: "",
      addressLine2: "",
      serverError: "",
      responseStatus: "",
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(CRUD_ACTION_SHOW, {
          supplierId: this.id
        })
        .then((response) => {
          this.billingEmail = response.data.billingEmail;
          this.vatNumber = response.data.vatNumber;
          this.zipCode = response.data.zipCode;
          this.poBox = response.data.poBox;
          this.addressLine1 = response.data.addressLine1;
          this.addressLine2 = response.data.addressLine2;
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = "";

          if (error.response) {
            this.responseStatus = error.response.status;
            let responseData = error.response.data;

            if (responseData.errors) {
              this.fieldErrors = responseData.errors;
            } else {
              this.serverError = "Invalid Request.";
            }
          } else {
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if (this.fieldErrors.error) {
            this.serverError = this.fieldErrors.error;
          }
        });
    },
  },
};
</script>
