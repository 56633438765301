import Login from "@/views/auth/login.vue";
import PasswordForget from "@/views/auth/password/forget.vue";
import PasswordReset from "@/views/auth/password/reset.vue";

import {commonBeforeEnter, ifAuthenticated, ifNotAuthenticated} from "../helper"

const CODE='auth';
const routes = [
  {
    path: "/",
    component: Login,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.login.title`,
    },
    beforeEnter: function (to,from, next){
      if(ifNotAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: "login",
    name: `${CODE}.login`,
    component: Login,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.login.title`,
    },
    beforeEnter: function (to,from, next){
      if(ifNotAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: "password/forget",
    name: `${CODE}.password.forget`,
    component: PasswordForget,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.password.forget.title`,
    },
    beforeEnter: function (to,from, next){
      if(ifNotAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
  {
    path: "password/reset",
    name: `${CODE}.password.reset`,
    component: PasswordReset,
    meta: {
      'addClass' : [`bg-default`],
      'title': `${CODE}.password.reset.title`,
    },
    beforeEnter: function (to,from, next){
      if(ifNotAuthenticated(to, from, next)){
        commonBeforeEnter(to, from, next);
      }
    },
  },
];

export default routes;
