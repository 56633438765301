<template>
    <div class="card-footer py-4">
        <nav aria-label="..." class="row">
            <div v-if="totalElements"
                 class="col-12 col-md-6">
                 <h5 class="text-primary">{{$t(`fields.total`)}}: <strong>{{totalElements}}</strong></h5>
            </div>
            <div class="col-12 col-md-6">
                <paginate v-if="lastPage>=2 || currentPage!==1"
                    :page-count="lastPage"
                    :force-page="currentPage"
                    :click-handler="clickHandler"
                    :prev-text="`<i class='fas fa-angle-left'></i>`"
                    :next-text="`<i class='fas fa-angle-right'></i>`"
                    :container-class="'pagination justify-content-end mb-0'"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :page-link-class="'page-link'">
                </paginate>
            </div>
        </nav>
        <div class="navbar p-0 pt-md-0 pt-1">
            <slot/>
        </div>
        <slot name="bulk-actions"/>
    </div>
</template>


<script>
    export default {
        props: {
            lastPage: {
                type: Number,
                default:0,
            },
            currentPage: {
                type: Number,
                default:1
            },
            clickHandler: {
                type: Function,
                default: () => { }
            },
            totalElements: {
                type: Number
            }
        },
        name: "CardFooter",
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>
