<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error')}} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input v-model="name" class="form-control" id="name" name="name" rows="5" v-bind:class="{'is-invalid':fieldErrors.name}"></input>
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6" style="margin-top:39px">
                <div class="form-group row">
                  <div class="col-8">
                    <label for="wallet_enabled">{{
                      $t(`fields.uses_wallet`)
                    }}</label>
                  </div>
                  <div class="col-4 text-right">
                    <label class="custom-toggle">
                      <input
                        type="checkbox"
                        id="wallet_enabled"
                        v-model="usesWallet"
                        @change="changeWalletEnabledStatus(usesWallet)"
                      />
                      <span
                        class="custom-toggle-slider rounded-circle"
                        :data-label-off="$t('labels.no')"
                        :data-label-on="$t('labels.yes')"
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <maps-general v-model="points" :points="oldPoints" :mode="map_mode"/>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { PLATFORMS_ZONES_CREATE as CRUD_ACTION_CREATE } from 'actions/platforms';
  const PLATFORMS_CODE="platforms";
  const CRUD_CODE=`${PLATFORMS_CODE}.zones`;
  export default {
    name: "PlatformZoneCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CRUD_CODE,
        platformsRoutePrefix: `${PLATFORMS_CODE}`,
        platformsTransPrefix: `${PLATFORMS_CODE}`,
        id:this.$router.currentRoute.params.id,
        name: '',
        usesWallet: false,
        pickupMapPicker:false,
        pickupMarkerPosition:{},
        points:[],
        oldPoints:[],
        map_mode:'create',
        serverError: '',
        fieldErrors: {},
        loading: false,
      }
    },
    watch:{
      pickupMarkerPosition:function(newVal,oldVal){
        this.latitude=newVal.lat;
        this.longitude=newVal.lng;
      },
    },
    methods: {
      prepareGeomRequestData(){
        let geom={};
        if(this.points.length ){
          geom.geom= {
          type: "MultiPolygon",
          coordinates:[[this.points]],
          };
        }

        return geom;
      },
      prepareRequestData(){
        return {
          platformId:this.id,
          name: this.sanitizeField('name'),
          usesWallet: this.sanitizeField("usesWallet"),
          ...this.prepareGeomRequestData(),
        }
      },
      create() {
        this.loading = true;
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index`})
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      hidePickupMapPicker(){
        this.pickupMapPicker=false;
      },
      showPickupMapPicker(){
        this.pickupMapPicker=true;
      },
      isCityEnabled(){
        return this.country_id;
      },
      isCityDisabled(){
        return !this.isCityEnabled();
      },
      isZoneEnabled(){
        return this.city_id;
      },
      isZoneDisabled(){
        return !this.isZoneEnabled();
      }
    }
  };
</script>