<template>
    <div class="row">
      <div class="col">
        <div class="card">
          <!-- Card header -->
          <card-header :title="$router.currentRoute.meta.title" >
            <router-link v-if="!serverError" :to="{ name: `${routePrefix}.edit`}"  class="btn btn-primary btn-sm" v-can:edit="permissionPrefix">
              <i class="fa fa-edit"></i>
            </router-link>
          </card-header>        <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <div v-if="serverError" class="alert alert-danger">
                    <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                  </div>
                </div>
              </div>
              <div class="row" v-if="!serverError">
                <div class="col-12 col-md-6">
                  <div class="form-group ">
                    <label for="type">{{ $t(`fields.type`)}}</label>
                    <select-platform-area-pricing-rule-types v-model="type" :params="{ platform_id: id, area_type:'ZONE', area_id: zone_id }" :disabled="true"/>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group ">
                    <label for="amount">{{ $t(`fields.amount`)}}</label>
                    <input  v-model="amount" id="amount" type="text" class="form-control" name="amount" disabled>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="needX()">
                  <div class="form-group ">
                    <label for="x">{{ $t(`fields.x`)}}</label>
                    <input  v-model="x" id="x" type="text" class="form-control" name="x" disabled>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                   <div class="form-group">
                     <label for="capabilities">{{ $t(`fields.capabilities`) }}</label>
                     <capabilities-field :itemID="pricing_rule_id" :permissionPrefix="permissionPrefix" :capabilities="capabilities"/>
                   </div>
                 </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="x">{{ $t(`fields.isVisibleToCaptain`)}}</label>
                    <input  v-model="isVisibleToCaptain" id="isVisibleToCaptain" type="text" class="form-control" name="amount" disabled>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card footer -->
            <div class="card-footer">
              <div class="row">
                <div class="col text-right">
                  <router-link :to="{ name: `${routePrefix}.index`,params: {id, zone_id}}"  class="btn btn-neutral btn-sm">
                    <i class="fa fa-arrow-left"></i>
                  </router-link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
    import { PLATFORMS_AREAS_PRICING_RULES_SHOW as CRUD_ACTION_SHOW} from 'actions/platforms';
    import capabilitiesField from "../../branches/pricing_rules/fields/capabilities.vue";
    const PLATFORMS_CRUD_CODE="platforms";
    const ZONES_CRUD_CODE=`${PLATFORMS_CRUD_CODE}.zones`;
    const CRUD_CODE=`${ZONES_CRUD_CODE}.pricing_rules`;
    const CRUD_TRANS_CODE=`${CRUD_CODE}`;
    const CRUD_PERMISSION_CODE=`platform_area_shifts`;
    export default {
      name: "PlatformZonePricingRuleShow",
      components:{
         capabilitiesField,
      },
      data(){
        return {
          routePrefix: CRUD_CODE,
          transPrefix: CRUD_CODE,
          permissionPrefix: `${CRUD_PERMISSION_CODE}`,
          zonesRoutePrefix: ZONES_CRUD_CODE,
          id:this.$router.currentRoute.params.id,
          zone_id:this.$router.currentRoute.params.zone_id,
          pricing_rule_id:this.$router.currentRoute.params.pricing_rule_id,
          type: '',
          amount: '',
          x: '',
          capabilities:[],
          serverError: '',
          responseStatus:'',
          isVisibleToCaptain: true
        }
      },
      mounted() {
        this.getItem();
      },
      methods: {
        getItem(){
          this.$store.dispatch(CRUD_ACTION_SHOW,{
            platform_id: this.id,
            owner_type: "ZONE",
            owner_id: this.zone_id,
            pricing_rule_id: this.pricing_rule_id,
          }).then(response=>{
            this.type=response.data.type;
            this.amount=response.data.amount;
            this.x=response.data.x;
            this.capabilities=response.data.capabilities;
            this.isVisibleToCaptain = response.data?.isVisibleToCaptain;
          }).catch(error => {
            this.loading = false;
            this.serverError='';

            if(error.response){
              this.responseStatus=error.response.status;
              let responseData=error.response.data;

              if(responseData.errors){
                this.fieldErrors=responseData.errors;
              }else{
                this.serverError = 'Invalid Request.'
              }
            }else{
              this.serverError = this.$i18n.t("messages.no_internet_connection");
            }

            if(this.fieldErrors.error){
              this.serverError = this.fieldErrors.error;
            }
          });
        },
        needX(){
          return this.type?.search(/_X_/) !== -1;
        },
      }
    };
</script>
